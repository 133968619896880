import { useEffect } from "react";
import { useState } from "react";
import { GMT_TIMEZONE_OFFSET } from "../../../constants/Constants";
import Util, { isV2 } from "../../../util/Util";
import { useDateValueFilter, useStringValueFilter } from "../../engine/useValueFilter";
import { TextInput } from "carbon-components-react";


const DAY = 86400000;
//const SEVEN_HOURS = 25_200_000;
const SEVEN_HOURS = 0;

export function fromEpoch(epoch, end, padWorkingHours) {
    if (Util.isNumberExist(epoch)) {
        if (padWorkingHours && isV2()) {
            epoch = epoch + SEVEN_HOURS; // 12am to 7 pm
        }
        return new Date(epoch - GMT_TIMEZONE_OFFSET - (end ? DAY : 0)).toISOString().split('T')[0];
    } else {
        return null;
    }
}

export function toEpoch(date, end, padWorkingHours) {
    if (Util.isStringExists(date)) {
        let time = new Date(date).getTime();
        if (padWorkingHours && isV2()) {
            time = time + SEVEN_HOURS;
        }
        return time + GMT_TIMEZONE_OFFSET + (end ? DAY : 0)
    } else {
        return null;
    }
}

export function fromEpochUsingDate(epoch, end, padWorkingHours) {
    if (Util.isNumberExist(epoch)) {
        if (padWorkingHours && isV2()) {
            epoch = epoch + SEVEN_HOURS; // 12am to 7 pm
        }
        return new Date(epoch - GMT_TIMEZONE_OFFSET - (end ? DAY : 0));
    } else {
        return null;
    }
}


export function toEpochUsingDate(date, end, padWorkingHours) {
    if (date) {
        let time = date.getTime();
        if (padWorkingHours && isV2()) {
            time = time + SEVEN_HOURS;
        }
        return time + GMT_TIMEZONE_OFFSET + (end ? DAY : 0)
    } else {
        return null;
    }
}


export const DATE_RANGE_UTIL = {
    fromEpoch, toEpoch
}

export function DateRange({ state, id, property, skipAutoFilter, padWorkingHours }) {
    const [start, setStart] = useDateValueFilter(state, (id ?? property) + '-start', { property, skipAutoFilter, operator: "GTE" })
    const [end, setEnd] = useDateValueFilter(state, (id ?? property) + '-end', { property, skipAutoFilter, operator: "LT" })

    const [startValue, setStartValue] = useState(() => fromEpoch(start, false, padWorkingHours));
    const [endValue, setEndValue] = useState(() => fromEpoch(end, true, padWorkingHours));

    useEffect(() => {
        setStart(toEpoch(startValue, false, padWorkingHours))
    }, [startValue])
    useEffect(() => {
        setEnd(toEpoch(endValue, true, padWorkingHours))
    }, [endValue])


    return (<>
        <div className="filter-field">
            <label className="bx--label">From: </label>
            <input type="date" value={startValue} onChange={e => setStartValue(e.target.value)} />
        </div>
        <div className="filter-field">
            <label className="bx--label">To: </label>
            <input type="date" value={endValue} onChange={e => setEndValue(e.target.value)} />
        </div>
    </>);
}

export function DateRange2({ state, id, property, padWorkingHours }) {
    const [start, setStart] = useDateValueFilter(state, (id ?? property) + '-start', { property, operator: "GTE" })
    const [end, setEnd] = useDateValueFilter(state, (id ?? property) + '-end', { property, operator: "LT" })

    const [startValue, setStartValue] = useState(() => fromEpoch(start, false, padWorkingHours));
    const [endValue, setEndValue] = useState(() => fromEpoch(end, true, padWorkingHours));

    useEffect(() => {
        setStart(toEpoch(startValue, false, padWorkingHours))
    }, [startValue])
    useEffect(() => {
        setEnd(toEpoch(endValue, true, padWorkingHours))
    }, [endValue])


    return (<>
        <div className="filter-field">
            <input type="date" value={startValue} onChange={e => setStartValue(e.target.value)} />
        </div>
        <label className="bx--label" style={{ marginInline: '1rem' }}>to</label>
        <div className="filter-field">
            <input type="date" value={endValue} onChange={e => setEndValue(e.target.value)} />
        </div>
    </>);
}


export function StatelessDateRange({ start, setStart, end, setEnd, label, light }) {
    const [startValue, setStartValue] = useState(() => fromEpoch(start, false, false));
    const [endValue, setEndValue] = useState(() => fromEpoch(end, true, false));

    useEffect(() => {
        setStart(toEpoch(startValue, false, false))
    }, [startValue])
    useEffect(() => {
        setEnd(toEpoch(endValue, true, false))
    }, [endValue])


    return (<>
        {/* {start + '-'} */}
        <TextInput light={light} labelText={label + " From Date"} type="date" value={startValue} onChange={e => setStartValue(e.target.value)} />
        <TextInput light={light} labelText={label + " End Date"} type="date" value={endValue} onChange={e => setEndValue(e.target.value)} />
    </>);
}

export function StatelessDate({ start, setStart, label, light }) {
    const [startValue, setStartValue] = useState(() => fromEpoch(start, false, false));

    useEffect(() => {
        setStart(toEpoch(startValue, false, false))
    }, [startValue])

    return (<>
        {/* {start + '-'} */}
        <TextInput light={light} labelText={label} type="date" value={startValue} onChange={e => setStartValue(e.target.value)} />
    </>);
}


export function EndDateOnly({ state, id, property, skipAutoFilter, padWorkingHours }) {
    const [end, setEnd] = useDateValueFilter(state, (id ?? property) + '-end', { property, skipAutoFilter, operator: "LT" })
    const [endValue, setEndValue] = useState(() => fromEpoch(end, true, padWorkingHours));
    useEffect(() => {
        setEnd(toEpoch(endValue, true, padWorkingHours))
    }, [endValue])
    return (<>
        <div className="filter-field">
            <label className="bx--label">As of: </label>
            <input type="date" value={endValue} onChange={e => setEndValue(e.target.value)} />
        </div>
    </>);
}