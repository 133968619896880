import { InlineLoading } from "carbon-components-react";
import { useCallback, useEffect, useRef, useState } from "react";
import { useForceLockScroll } from "../hooks/useLockScroll";
import Portal, { BodyPortal } from "./Portal";
import html2canvas from 'html2canvas'

const PrintContentView = ({ printing, setPrinting, children }) => {
    const ref = useRef();

    useEffect(() => {
        if (printing) {
            let cancelled = false;
            const printContent = async () => {
                try {
                    window.document.head.insertAdjacentHTML('beforeend', /*html*/`<style>
                        @media print {
                            #root, .usePrintLoader {
                                display: none !important;
                            }
                        }
                    </style>`)

                    window.print()
                    setPrinting(false)

                    // const content = await html2canvas(ref.current, {
                    //     windowWidth: ref.current.clientWidth,
                    //     windowHeight: ref.current.clientHeight
                    // })
                    // if (cancelled) {
                    //     return;
                    // }

                    // content.toBlob(blob => {
                    //     if (cancelled) {
                    //         return;
                    //     }


                    //     if (blob && blob.size > 0) {
                    //         window.open(URL.createObjectURL(blob))
                    //     }
                    //     setPrinting(false)
                    // }, "image/jpeg", 1.0)
                } catch (e) {
                    setPrinting(false)
                }
            }
            printContent();
            return () => cancelled = true;
        }
    }, [printing])

    // useForceLockScroll(printing)
    return (
        printing && <BodyPortal>
            <div ref={ref} style={{}}>
                {children}
            </div>

            <div className="usePrintLoader" style={{ position: 'fixed', left: 0, top: 0, width: '100vw', height: '100vh', zIndex: 9999999999, background: 'black', pointerEvents: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <InlineLoading style={{ width: 'unset' }} />
                <p style={{ color: 'white' }}>Generating...</p>
            </div>
        </BodyPortal>
    )
}
export function usePrint() {
    const [printing, setPrinting] = useState(false);

    const PrintContent = useCallback((props) => <PrintContentView {...{ ...props, printing, setPrinting }} />, [printing])

    const print = () => {
        setPrinting(true)
    }

    return {
        PrintContent,
        print
    }
}