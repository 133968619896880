
import bg from '../../images/other/romario-rahela/bg.png'
import logo from '../../images/other/romario-rahela/logo.png'
import { Document, Font, View, Text, Page, Image, StyleSheet } from "@react-pdf/renderer";
import Util from '../../util/Util';
import Api from '../../session/Api';
import { OBJECT_TYPE_PRODUCT } from '../../constants/ObjectTypes';
import React from 'react';
import { RahelaRomarioInvoicePdf } from './RahelaRomarioInvoicePdf';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        width: '100%',
        paddingTop: 42,
        paddingBottom: 42,
        paddingLeft: 42,
        paddingRight: 42,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    pageBackground: {
        position: 'absolute',
        bottom: 0, right: 0,
        minWidth: '100%',
        minHeight: '100%',
        display: 'block',
        height: '100%',
        width: '100%',
        objectFit: 'cover',
    },
});

Font.register({
    family: "Montserrat",
    fonts: [
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCvr6Ew-Y3tcoqK5.ttf",
            fontWeight: 200
        },
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCtr6Ew-Y3tcoqK5.ttf",
        },
        {
            src: "//fonts.gstatic.com/s/montserrat/v25/JTUHjIg1_i6t8kCHKm4532VJOt5-QNFgpCu170w-Y3tcoqK5.ttf",
            fontWeight: 600
        }
    ]
})

Font.register({
    family: 'Roboto Mono',
    src: '//fonts.gstatic.com/s/robotomono/v22/L0xuDF4xlVMF-BfR8bXMIhJHg45mwgGEFl0_3vqPQ--5Ip2sSQ.ttf'
});

function addNewlineAfter13Characters(inputString) {
    const maxLength = 20;
    let result = '';

    for (let i = 0; i < inputString.length; i += maxLength) {
        const chunk = inputString.slice(i, i + maxLength);
        result += chunk + '\n';
    }

    return result;
}

const COLS = (showImage, showRRP) => {
    return [
        ...(showImage ? [
            {
                id: "image",
                name: "",
                flex: 1,
                alignment: "center",
                render: row => <Image style={{ width: 32, height: 32 }} src={Api.getThumbnailForSure(OBJECT_TYPE_PRODUCT, row.id)} />
            },
        ] : []),
        {
            id: "description",
            name: "Item",
            flex: 3,
            alignment: "flex-start",
            render: row => <Text style={{ fontSize: 8 }}>{addNewlineAfter13Characters(row.description)}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{addNewlineAfter13Characters("FM9936-WHITE/BLACK/.BLACK-NS")}</Text>
        },
        {
            id: "info",
            name: "Description",
            flex: 4,
            alignment: "flex-start",
            render: row => <Text style={{ fontSize: 8 }}>{row.info}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{'desc whatever fhgreighreu'}</Text>
        },
        {
            id: "qty",
            name: "Qty",
            flex: 1,
            alignment: "center",
            render: row => <Text style={{ fontSize: 8 }}>{row.qty}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>3000</Text>
        },
        ...(showRRP ? [
            {
                id: "rrp",
                name: "RRP",
                flex: 1,
                alignment: "center",
                render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.rrp)}</Text>
            },
        ] : []),
        {
            id: "unitPrice",
            name: "Rate",
            flex: 1,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.unitPrice)}</Text>
            // render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(633)}</Text>
        },
        {
            id: "total",
            name: "Amount",
            flex: 2,
            alignment: "flex-end",
            render: row => <Text style={{ fontSize: 8 }}>{Util.formatMoney(row.total)}</Text>
        },
    ]
}




const TableValueField = ({ label, mulitext, children }) => (
    <View>
        <View style={{ borderBottom: '0px solid black', backgroundColor: '#1c1c1c', paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2 }}>
            <Text style={{ fontSize: 10, lineHeight: 0, textTransform: 'uppercase', color: 'white', fontFamily: 'Montserrat', }}>{label}</Text>
        </View>
        <View style={{ backgroundColor: '#1c1c1c10', paddingLeft: 5, paddingRight: 5, paddingTop: mulitext ? 5 : 2, paddingBottom: mulitext ? 5 : 2 }}>
            <Text style={{ fontSize: mulitext ? 10 : 10, fontFamily: "Montserrat", fontWeight: 600 }}>{children ? children : ' '}</Text>
        </View>
    </View>
)

const TableHeader = ({ cols }) => (
    <View style={{ flexDirection: 'row' }}>
        {cols.map((col, i) => (
            <View key={col.id} style={{ backgroundColor: '#1c1c1c', paddingLeft: 5, paddingRight: 0, paddingTop: 2, paddingBottom: 2, flex: col.flex, border: 1, borderLeft: i == 0 ? 1 : 0, borderColor: '#1c1c1c' }}>
                <Text style={{ fontFamily: 'Montserrat', fontSize: 10, lineHeight: 0, color: 'white', textAlignment: 'center', textTransform: 'uppercase', margin: 0, padding: 0 }}>{col.name}</Text>
            </View>
        ))}
    </View>
)

const TableRow = ({ cols, rows, rowBorder, normalPaddingTop }) => (
    <View style={{ backgroundColor: '#1c1c1c10', borderBottom: 1, borderColor: '#1c1c1c', overflow: 'hidden' }}>
        {rows.map(row => (
            <View style={{ flexDirection: 'row', border: 1, borderTop: 0, borderBottom: rowBorder ? '1px solid #1c1c1c' : 0, borderColor: '#1c1c1c' }}>
                {cols.map((col, i) => (
                    <View key={col.id} style={{ flex: col.flex, minHeight: 22 + 10, borderLeft: i == 0 ? 0 : 1, paddingLeft: 1, paddingRight: 1, borderColor: '#1c1c1c', paddingTop: normalPaddingTop ? 2 : 10, justifyContent: 'center', alignItems: col.alignment }}>
                        <Text style={{ fontSize: 11, paddingHorizontal: 4, fontFamily: 'Roboto Mono' }}>
                            {Object.keys(row).length > 0 ? (
                                col.render(row)
                            ) : ''}
                        </Text>
                    </View>
                ))}
            </View>
        ))}
    </View>
)

const Table = ({ cols, rows, normalPaddingTop, rowBorder }) => (
    <View>
        <TableHeader cols={cols} />
        <TableRow cols={cols} rows={rows} normalPaddingTop={normalPaddingTop} rowBorder={rowBorder} />
    </View>
)


export function RahelaRomarioQuotationPdf(props) {
    const { invoice, showImage, showRRP, showMemo } = props;
    return <RahelaRomarioInvoicePdf {...props} title='Quotation' />
    return (
        <Document>
            <Page size="A4" orientation="portrait" style={styles.page}>
                <Image fixed src={bg} style={styles.pageBackground} />

                <View>
                    <View style={{ flexDirection: 'row', justifyContent: "flex-end", paddingTop: 0, marginBottom: 16 }}>
                        <View>
                            <Image style={{ width: 185, objectFit: 'contain', objectPosition: 'center' }} src={logo} />
                            <Text style={{ fontFamily: 'Montserrat', fontWeight: 600, marginTop: 28, fontSize: 24, color: '#1c1c1c' }}>Quotation</Text>
                            <Text style={{ fontFamily: 'Montserrat', fontSize: 14, marginTop: -6 }}>TRN: {invoice.systemCompany.trn}</Text>
                        </View>
                        <View style={{ flex: 1 }} />

                        <View>
                            {/* <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', }}>Authorized B2B partner for</Text>
                            <View style={{ width: '100%', flexDirection: 'row', alignItems: 'center', marginBottom: 10 }}>
                                <Text style={{ color: 'rgb(14, 19, 24)', fontSize: 12, fontFamily: 'Montserrat', paddingRight: 5 }}><Text style={{ fontWeight: 'bold' }}>adidas</Text> in GCC</Text>
                                <View style={{ flex: 1, height: 1, marginTop: -2, backgroundColor: 'rgb(14, 19, 24, 0.25)' }} />
                            </View>

                            <Text style={{ color: 'rgb(117, 120, 125)', fontSize: 10, fontFamily: 'Montserrat' }}>Business Bay, Tamani Arts Offices,</Text>
                            <Text style={{ color: 'rgb(117, 120, 125)', fontSize: 10, fontFamily: 'Montserrat' }}>Office #1948, Dubai, UAE</Text>
                            <Text style={{ color: 'rgb(117, 120, 125)', fontSize: 10, fontFamily: 'Montserrat' }}>customerservice@romariosportswear.com</Text>
                            <Text style={{ color: 'rgb(117, 120, 125)', fontSize: 10, fontFamily: 'Montserrat', fontWeight: 600 }}>www.romariosportswear.com</Text> */}

                        </View>
                        {/* <Text style={{ color: '' }}>Test 2</Text> */}
                    </View>

                    <View style={{ border: '0px solid black', marginBottom: 32 }}>
                        <View style={{ flexDirection: 'row', borderBottom: '0px solid black', }}>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Quotation no"}>{invoice.invoiceNo}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Date"}>{Util.formatDate(invoice.date, "DD/MM/YYYY")}</TableValueField>
                            </View>
                            <View style={{ flex: 1 }}>
                                <TableValueField label={"Rep"}>{Util.isStringExists(invoice.salesPersonNameFullName) ? invoice.salesPersonNameFullName : '-'}</TableValueField>
                            </View>

                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <View style={{ flex: 1 }}>
                                <TableValueField mulitext label={"To"}>{`${invoice.customerName ?? ''}\n${invoice.customerFullAddress ?? ''}\n${invoice.customerTrn ?? ''}`}</TableValueField>
                            </View>
                        </View>
                    </View>


                    <Table cols={COLS(showImage, showRRP)} rows={invoice.items.concat([{}])} />
                    <View style={{ flexDirection: 'row', marginBottom: 0 }}>
                        <View style={{ flex: 3, paddingTop: 32, paddingRight: 16 }}>
                            {showMemo && <TableValueField label={"Memo"}>
                                {invoice.memo}
                            </TableValueField>}
                            <TableValueField label={"Bank Details"}>
                                {`Account Name: ${invoice.systemCompany.name}
                                Bank Name: ${invoice.systemCompany.bankName}
                                Branch Name: ${invoice.systemCompany.branch}
                                Swift Code: ${invoice.systemCompany.swiftCode}
                                Account Number: ${invoice.systemCompany.bankAccountNo}
                                IBAN Number: ${invoice.systemCompany.iban}`}
                            </TableValueField>
                        </View>
                        <View style={{ flex: 2, }}>
                            <View style={{ backgroundColor: '#1c1c1c10', border: '1px solid #1c1c1c', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600 }}>Subtotal</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.subTotal)}</Text>
                            </View>
                            {invoice.totals.vatTotal > 0 && <View style={{ backgroundColor: '#1c1c1c10', border: '1px solid #1c1c1c', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                <Text style={{ fontSize: 14, fontFamily: 'Montserrat', fontWeight: 600 }}>VAT</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.vatTotal)}</Text>
                            </View>}

                            <View style={{ backgroundColor: '#1c1c1c10', border: '1px solid #1c1c1c', borderTop: 0, alignItems: 'center', paddingVertical: 7, paddingHorizontal: 15, flexDirection: 'row' }}>
                                <Text style={{ color: '#1c1c1c', fontSize: 16, fontFamily: 'Montserrat', fontWeight: 600 }}>Total</Text>
                                <Text style={{ fontSize: 12, fontFamily: 'Montserrat', flex: 1, textAlign: 'right' }}>AED {Util.formatMoney(invoice.totals.grandTotal)}</Text>
                            </View>
                        </View>
                    </View>

                    <View style={{ flexDirection: 'row', borderBottom: '0px solid black', }}>
                        <View style={{ flex: 1 }}>
                            <TableValueField label={"Phone"}>{invoice.systemCompany.tel}</TableValueField>
                        </View>
                        <View style={{ flex: 1 }}>
                            <TableValueField label={"Fax"}>{invoice.systemCompany.fax}</TableValueField>
                        </View>
                        <View style={{ flex: 1 }}>
                            <TableValueField label={"Email"}>{invoice.systemCompany.email}</TableValueField>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}