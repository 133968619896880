import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, NumberInput, TextInput, Toggle } from 'carbon-components-react';
import React, { createRef } from 'react'
import GridLayout from 'react-grid-layout';
import { Responsive, WidthProvider } from 'react-grid-layout';

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import BarcodeInput from './widgets/BarcodeInput';
import CartContent from './widgets/CartContent';
import CouponWidget from './widgets/CouponWidget';
import CustomerInfo from './widgets/CustomerInfo';
import PaymentWidget from './widgets/PaymentWidget';
import ProductViewer from './widgets/ProductViewer';

import { Purchase16, NotebookReference16, Star16, Scale32, Close16, Renew16, Screen32, ErrorFilled16, ArrowRight16, WarningFilled32, Cafe16, Cafe32, Time16, Hourglass16, DeliveryParcel16 } from '@carbon/icons-react'

import "./pos-page.scss"
import PosInfoWidget from './widgets/PosInfoWidget';
import Rate from '../../components/Rate';
import PosState, { getDefaultPosState } from './state/PosState';
import Page from '../../base/Page';
import Button from '../../components/Button';
import UIUtil from '../../util/UIUtil';
import Util from '../../util/Util';
import Api from '../../session/Api';
import { PosInfoView, PosInfoViewBtns } from './view/PosInfoView';
import PosDialogs from './PosDialogs';
import { setPosLayout } from '../../session/SessionManager';
import ReactTooltip from 'react-tooltip';
import SalesInfo from './widgets/SalesInfo';
import EditModeOverlayView from './view/EditModeOverlayView';
import { TicketSelector } from '../cinema-pos/ticket-selector';
import { hasCapabilitySupport } from '../../app/Capabilities';
import RestPaymentWidget from './restaurant/RestPaymentWidget';
import RestCartContent from './restaurant/RestCartContent';
import { RestProducts } from './restaurant/RestProducts';
import RestCustomerInfo from './restaurant/RestCustomerInfo';
import { Tabs } from './restaurant/tabs';
import { Orders } from './restaurant/pages/orders';
import { Kitchen } from './restaurant/pages/kitchen';
import { Tables } from './restaurant/pages/tables';
import { DEF_ORDER_STATE, OrderState } from './restaurant/engine/OrderState';
import { ActiveCarts } from './restaurant/pages/active-carts';
import { LiveCart } from './state/live-cart';
import { LiveActiveCarts } from './state/live-active-carts';



const ResponsiveGridLayout = WidthProvider(Responsive);
//const GridLayoutHeight = (height, rows) => (rowheight * 24) + (24 - 1) * marginY;

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const OPTIMAL_HEIGHT = 387 + 387 + 164 + 60;

class PosPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            ...getDefaultPosState(),

            showStartPos: false,
            startPosAmount: 0,

            rowHeight: 0,

            tab: "pos",
            ...DEF_ORDER_STATE(),

            showTableKey: true
        }

        this.posRootLayoutRef = createRef();
        this.posState = new PosState(() => this.state, this.setState.bind(this), this.props.mainApp)
        if (hasCapabilitySupport("restaurant")) {
            this.orderState = new OrderState(this.setState.bind(this), () => this.state, this.props.mainApp);
        }
    }

    isHeightSmall() {
        return window.innerHeight < 700;
    }

    calculateRowHeight() {
        //const height = window.innerHeight - 48 - (convertRemToPixels(1) * 2);
        const height = window.innerHeight - 48;

        //this.setState({rowHeight: ((height - (10 * (24 - 1))) / 24)})
        this.setState({ rowHeight: ((height - (4 * (22 - 1))) / 24) })
    }

    componentDidUpdate() {
        ReactTooltip.rebuild();
    }

    componentDidMount() {
        super.componentDidMount();

        this.pasteListener = (e => {
            if (this.state.tab !== "pos") {
                return;
            }

            if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
                e.preventDefault();
                const pasted = (e.clipboardData || window.clipboardData).getData('text');
                console.log(pasted)

                if (pasted !== undefined && pasted !== null && pasted.includes !== undefined && pasted.includes("/product-preview/")) {
                    const data = pasted.split("/");
                    this.posState.updateCartItem(data[data.length - 1], 1, false)
                } else {
                    this.posState.scanBarcode(pasted, true);
                }
            }
        }).bind(this);

        this.keyInputListener = (e => {
            if (this.state.tab !== "pos") {
                return;
            }

            if (!(e.target instanceof HTMLInputElement) && !(e.target instanceof HTMLTextAreaElement)) {
                // e.preventDefault();
                const keyInput = e.key;
                if (keyInput.length != 1) {
                    return;
                }

                if (!this.keyInputBuffer) {
                    this.keyInputBuffer = "";
                }
                this.keyInputBuffer += keyInput;

                clearTimeout(this.keyInputTimeout);
                this.keyInputTimeout = setTimeout(() => {
                    const pasted = this.keyInputBuffer;
                    this.keyInputBuffer = undefined;

                    if (pasted && pasted.length <= 1) {
                        return;
                    }

                    //console.log(pasted);
                    if (pasted !== undefined && pasted !== null && pasted.includes !== undefined && pasted.includes("/product-preview/")) {
                        const data = pasted.split("/");
                        this.posState.updateCartItem(data[data.length - 1], 1, false)
                    } else {
                        this.posState.scanBarcode(pasted, true);
                    }
                }, 100);
            }
        }).bind(this);

        this.resizeListener = (e => {
            this.calculateRowHeight();
        }).bind(this);


        window.addEventListener("paste", this.pasteListener);
        document.body.addEventListener("keydown", this.keyInputListener);
        window.addEventListener('resize', this.resizeListener);

        this.calculateRowHeight();


        if (this.orderState) {
            this.orderState.start();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("paste", this.pasteListener);
        document.body.removeEventListener("keydown", this.keyInputListener);
        window.removeEventListener('resize', this.resizeListener);

        this.posState.cancelSubscriptions();

        if (this.orderState) {
            this.orderState.stop();
        }
    }

    onPageStart() {
        this.posState.loadSession(true);
    }

    startSession() {
        if (!isNaN(this.state.startPosAmount) && this.state.startPosAmount !== "") {
            this.posState.startSession(this.state.startPosAmount);
            this.setState({ showStartPos: false })
        } else {
            UIUtil.showError("Invalid number inputted")
        }
    }

    getPosLayoutGrid(id) {
        for (const item of this.posState.getState().posLayout) {
            if (item.id == id) {
                return item.grid;
            }
        }
    }

    getLayout() {
        if (this.posState.isHasNoTerminalError()) {
            return (
                <div style={{ width: '100%', height: '100%', paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className="dashboard-card" style={{ background: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem' }}>
                        <Screen32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                        <h2 style={{ marginTop: '2rem' }}>Terminal not selected</h2>
                        <p>Set the terminal on this computer in order to continue</p>

                        <Button onClick={() => this.props.mainApp.setState({ showSetTerminalDialog: true })} style={{ marginTop: '6rem' }} renderIcon={ArrowRight16}>Setup Terminal</Button>
                    </div>
                </div>
            )
        }

        if (!this.posState.hasPos()) {
            return (
                <div style={{ width: '100%', height: '100%', paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                    <div className="dashboard-card" style={{ background: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem' }}>
                        <Screen32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                        <h2 style={{ marginTop: '2rem' }}>Not active</h2>
                        <p>Start POS session</p>

                        <Button
                            loading={this.posState.isLoadingPosAction()}
                            onClick={() => this.setState({ showStartPos: true, startPosAmount: 0 })} style={{ marginTop: '6rem' }} renderIcon={ArrowRight16}>Start</Button>
                    </div>

                    {this.renderDialogs()}
                </div>
            )
        }

        if (this.posState.isOnBreak()) {
            return (
                <div style={{ width: '100%', height: '100%', paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem', display: 'flex', flexDirection: 'column', justifyContent: 'center', background: 'black' }}>
                    <div style={{ background: '#161616', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem', color: 'white' }}>
                        <Cafe32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                        <h2 style={{ marginTop: '2rem' }}>On break</h2>
                        <p>Session not active</p>

                        <Button
                            loading={this.posState.isLoadingPosSetOnBreak()}
                            onClick={() => this.posState.showResumeFromBreakDialog()} style={{ marginTop: '6rem' }} renderIcon={ArrowRight16}>Resume</Button>
                    </div>

                    {this.renderDialogs()}
                </div>
            )
        }

        if (hasCapabilitySupport("restaurant")) {
            return (
                <div ref={this.posRootLayoutRef} style={{ width: '100%', height: '100%', }}>
                    {this.posState.getSession() && <LiveCart state={this.posState} />}
                    {!this.orderState.isLoading() && <LiveActiveCarts state={this.orderState} />}


                    <div style={{ height: '3rem', borderBottom: '1px solid #00000020', display: 'flex', alignItems: 'center', paddingRight: '1rem' }}>
                        <Tabs orderState={this.orderState} tab={this.state.tab} setTab={tab => this.setState({ tab })} />
                        {this.state.tab === "tables" && (
                            <div style={{ flex: 1 }} className='no-padding-toggle-control'>
                                <Toggle labelA='Show Key' labelB='Hide Key' checked={this.state.showTableKey} onChange={e => this.setState({ showTableKey: e.target.checked })} />
                            </div>
                        )}
                        {/* {this.state.tab === "pos" && (
                            <div>
                                <Button
                                    size="sm" kind="ghost" style={{ borderRadius: 25 }}
                                    loading={this.posState.isLoadingPosAction()}
                                    onClick={() => this.posState.setShowPosInfoDialog(true)}

                                    renderIcon={NotebookReference16}>
                                    Session
                                </Button>
                            </div>
                        )} */}
                    </div>
                    <div style={{ height: 'calc(100% - 3rem)' }}>
                        {this.state.tab === "pos" ? (<>
                            <div className="pos" style={{ display: 'flex', height: '100%', width: '100%', padding: '0rem', userSelect: 'none', WebkitUserSelect: 'none', }} >
                                <div style={{ height: '100%', flex: 1.25, display: 'flex', flexDirection: 'column', background: '#f4f4f4' }}>
                                    {/* <RestCartContent state={this.posState} noList /> */}
                                    <div style={{ flex: 1 }}>
                                        <RestProducts state={this.posState} />
                                    </div>
                                </div>
                                <div style={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column', }}>

                                    <div style={{ position: 'relative', flex: 2, minHeight: 0, }}>
                                        {!(this.posState?.shouldShowTableSelect?.()) &&
                                            <div style={{ width: '100%', position: 'absolute', left: 0, top: '-6rem', bottom: 0, zIndex: 8001, background: '#ffffff', borderLeft: '0px solid #F1F1F1', boxShadow: '0px 8px 17px 2px rgba(0,0,0,0.14) , 0px 3px 14px 2px rgba(0,0,0,0.12) , 0px 5px 5px -3px rgba(0,0,0,0.2) ' }}>
                                                <RestCartContent state={this.posState}>
                                                    <div style={{ minHeight: 64, background: '#1c1c1c', color: 'white', borderTop: '0px solid #00000020', borderBottom: '1px solid #ffffff10', }}>
                                                        <RestCustomerInfo state={this.posState} responsive />
                                                    </div>
                                                    <RestPaymentWidget state={this.posState} />
                                                </RestCartContent>
                                            </div>}
                                    </div>



                                </div>
                            </div>
                        </>) : this.state.tab === "carts" ? (
                            <div key={this.orderState.refreshKey}>
                                <ActiveCarts
                                    state={this.orderState} carts={this.orderState.carts}
                                    mainApp={this.props.mainApp}
                                />
                            </div>
                        ) : this.state.tab === "orders" ? (
                            // repeated IN KITCHEN!! and delivery amanger
                            <div key={this.orderState.refreshKey}>
                                <Orders
                                    state={this.orderState} orders={this.orderState.getFilteredOrders()}
                                    updateOrder={this.orderState.updateOrder.bind(this.orderState)}
                                    removeOrder={this.orderState.removeOrder.bind(this.orderState)}
                                    mainApp={this.props.mainApp}
                                />
                            </div>
                        ) : this.state.tab === "kitchen" ? (
                            // repeated IN KITCHEN!! and delivery amanger
                            <div key={this.orderState.refreshKey}>
                                <Kitchen
                                    state={this.orderState} orders={this.orderState.getFilteredOrdersForKitchen()}
                                    updateOrder={this.orderState.updateOrder.bind(this.orderState)}
                                    removeOrder={this.orderState.removeOrder.bind(this.orderState)}
                                    mainApp={this.props.mainApp} />
                            </div>
                        ) : this.state.tab === "tables" ? (
                            <Tables showKey={this.state.showTableKey} />
                        ) : null}
                    </div>

                    {this.renderDialogs()}
                </div>
            )
        }

        return (
            <div className="pos" ref={this.posRootLayoutRef} style={{ width: '100%', height: '100%', padding: '0rem', userSelect: 'none', WebkitUserSelect: 'none', }}>
                <div style={{ position: 'absolute', transition: '250ms', left: 0, top: this.state.posEditMode ? 0 : -50, zIndex: 100000, width: '100%', height: 48, background: '#cecece', display: 'flex', alignItems: 'center', paddingLeft: '1rem', paddingRight: '1rem' }}>
                    <Scale32 />
                    <h4 style={{ flex: 1 }}>Edit Mode</h4>

                    <Button
                        style={{ height: 40 }}
                        kind="ghost"
                        onClick={() => this.posState.restartPosLayout()}
                        // data-tip="Restore Layout Default"
                        renderIcon={Renew16}>
                        Restore Layout Default
                    </Button>

                    <Button
                        style={{ height: 40 }}
                        kind="danger-ghost"
                        onClick={() => this.posState.togglePosEditMode()}
                        // data-tip="Restore Layout Default"
                        renderIcon={Close16}>
                        Exit Edit Mode
                    </Button>
                </div>

                <ResponsiveGridLayout key={this.posState.getState().posLayoutKey} className="layout"
                    //cols={12} //rowHeight={30} //width={1300}
                    //breakpoints={{lg: 1200, md: 996, sm: 768, xs: 480, xxs: 0}}
                    //cols={{lg: 12, md: 10, sm: 6, xs: 4, xxs: 2}}
                    rowHeight={this.state.rowHeight}
                    //maxRows={24}
                    isDraggable={this.posState.getState().posEditMode}
                    isResizable={this.posState.getState().posEditMode}
                    margin={[4, 4]}
                    breakpoints={{ lg: 1200 }}
                    cols={{ lg: 12 }}
                    onLayoutChange={change => setPosLayout(JSON.stringify(change.map(item => ({
                        id: item.i,
                        grid: {
                            x: item.x,
                            y: item.y,
                            w: item.w,
                            h: item.h
                        }
                    }))))}
                >
                    <div key="cart-content" data-grid={this.getPosLayoutGrid('cart-content')} style={{ position: 'relative' }}>
                        <CartContent state={this.posState} />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="payment-widget" id="dark-pos-widget-id" className="dark-pos-widget" data-grid={this.getPosLayoutGrid('payment-widget')} >
                        <PaymentWidget state={this.posState} responsive />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="product-viewer" data-grid={this.getPosLayoutGrid('product-viewer')} >
                        <ProductViewer state={this.posState} />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>

                    <div key="pos-info" data-grid={this.getPosLayoutGrid('pos-info')} >
                        <PosInfoWidget state={this.posState} />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="sales-info" data-grid={this.getPosLayoutGrid('sales-info')} style={{ zIndex: 100 }}>
                        <SalesInfo state={this.posState} responsive />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="customer-info" data-grid={this.getPosLayoutGrid('customer-info')} >
                        <CustomerInfo state={this.posState} responsive />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="coupon-widget" data-grid={this.getPosLayoutGrid('coupon-widget')} >
                        <CouponWidget state={this.posState} />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                    <div key="barcode-input" data-grid={this.getPosLayoutGrid('barcode-input')} >
                        <BarcodeInput state={this.posState} />
                        {this.state.posEditMode && <EditModeOverlayView />}
                    </div>
                </ResponsiveGridLayout>

                {this.renderDialogs()}

                <ReactTooltip effect="solid" className="pos-tooltip" />


                {this.posState.isShowTicketSelector() &&
                    <TicketSelector
                        defaultValues={this.posState.getTicketSelectorDefaultValues()}
                        onTicketsConfirmed={state => this.posState.onUpdateSessionAfterTicketAdded(state)}
                        onClose={() => this.posState.closeTicketSelector()} />}
            </div>
        )
    }

    renderDialogs() {
        return (<>
            <PosDialogs posState={this.posState} tab={this.state.tab} />

            <ComposedModal key="start-session-dialog" size="sm" open={this.state.showStartPos} onClose={() => this.setState({ showStartPos: false })}>
                <ModalHeader label="POS" title="Start Session" />
                <ModalBody>
                    <NumberInput
                        invalidText="Invalid number"
                        data-modal-primary-focus
                        value={this.state.startPosAmount}
                        onChange={(e, { value }) => this.setState({ startPosAmount: value })}
                        hideSteppers
                        label="Cash open amount"
                    />
                </ModalBody>
                <ModalFooter
                // onRequestSubmit={() => this.startSession()}
                // primaryButtonText="Start" secondaryButtonText="Cancel"
                >

                    <Button kind="secondary" onClick={() => this.setState({ showStartPos: false })}>
                        Cancel
                    </Button>
                    <Button
                        onClick={() => this.startSession()}>
                        Start
                    </Button>

                </ModalFooter>
            </ComposedModal>
        </>)
    }

    isPageLoading() {
        return this.posState.isLoadingPos();
    }

    isPageInError() {
        return this.posState.isInError();
    }

    isPageLoadable() {
        return false;
    }

}

export default PosPage;