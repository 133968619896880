
import React from 'react';
import { Page, Document, Image, StyleSheet, Text, View } from '@react-pdf/renderer';
import logo from '../../../images/doc-logo.png'
import Util from '../../../util/Util';
import { TableHeader } from './TableHeader';
import Table from './Table';
import ReceiptLogo from '../../../images/ReceiptLogo.png'
import { INVOICE_ALT_PDF, THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { PvgTradingStatementsOfAccount } from '../../../pdfs-alt/pvg-trading/PvgTradingStatementsOfAccount';
import { hasCapabilitySupport } from '../../../app/Capabilities';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 20,
        paddingRight: 20,
        paddingBottom: 20,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    logo: {
        width: 178,
        height: 37,
    },
    titleContainer: {
        flexDirection: 'row',
        marginTop: 0,
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center'
    },
    reportTitle: {
        color: '#00148C',
        letterSpacing: 4,
        fontSize: 14,
        textAlign: 'center',
        textTransform: 'uppercase',
    },
    reportSub: {
        fontSize: 12,
        textTransform: 'uppercase',
        fontStyle: 'bold',
        textAlign: 'center',
    }
});

const Divider = ({ fixed }) => <View fixed={fixed} style={{ width: '100%', backgroundColor: 'black', height: 2, marginBottom: 5 }} />

const Field = ({ label, value }) => (
    <View style={{ marginBottom: 5 }}>
        <Text style={{ fontSize: 8, opacity: 0.65 }}>{label}</Text>
        <Text style={{ fontSize: 10 }}>{value}</Text>
    </View>
)

export const ExcellsReportPdf = ({ report, currency, ...props }) => {
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                {/* <Image style={styles.logo} src={logo} /> */}
                <View style={{ flexDirection: 'row', alignItems: 'flex-start', marginBottom: 0 }}>
                    <Image src={logo} style={{ width: 80, height: 80 }} />
                    <View style={{ flex: 1 }} />
                    {hasCapabilitySupport("shipping") && <>
                        <View style={{ marginTop: 0, alignItems: 'flex-end' }}>
                            <Text style={{ fontSize: 10, marginBottom: 0, lineHeight: 1.4, fontFamily: "Helvetica-Bold", textAlign: 'right', color: '#00148C' }}>{"EXCELLS SHIPPING LLC"}</Text>
                            <Text style={{ fontSize: 8, fontFamily: "Helvetica", lineHeight: 1.4, textAlign: 'right', color: '#00148C' }}>{"P.O.BOX: 63067, DUBAI - U.A.E\nTEL: +971 4 2209620, FAX: +971 4 2209610\nEmail: info@excellsshipping.com\nWebsite: www.excellsshipping.com"}</Text>
                        </View>
                    </>}
                </View>

                <View fixed style={styles.titleContainer}>
                    <Text style={styles.reportTitle}>Statement of Accounts</Text>
                </View>
                <View fixed style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', gap: 12 }}>
                    <View style={{ flex: 1 }}>
                        <Field label="Account No" value={Util.getVoucherNumber(report.id)} />
                    </View>
                    <View style={{ flex: 2 }}>
                        <View style={{
                            alignItems: 'center',
                            justifyContent: 'center'
                        }}>
                            <Text style={styles.reportSub}>For the period of {Util.getDateOnly(report.startDate)} to {Util.getDateOnly(report.endDate)}</Text>
                        </View>
                    </View>
                    <View style={{ flex: 1, flexDirection: 'column', justifyContent: 'flex-end' }}>
                        <Field label="Name" value={report.name} />
                    </View>
                </View>
                <Divider fixed />

                {/* <View fixed style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <View style={{ flex: 1 }}>
                        <Field label="Account No" value={Util.getVoucherNumber(report.id)} />
                    </View>
                    {Util.isStringExists(report.accountType) &&
                        <View style={{ flex: 1 }}>
                            <Field label="Type" value={report.accountType} />
                        </View>}
                    <View style={{ flex: 1 }}>
                        <Field label="Name" value={report.name} />
                    </View>
                </View> */}

                <Table report={report} />
                <View fixed style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 2, paddingBottom: 2, marginTop: 10, justifyContent: 'flex-end', alignItems: 'center' }}>
                    <Text style={{ fontSize: 10, textAlign: 'cente', fontWeight: 600 }} render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`} />
                </View>

                <Text style={{ marginTop: 50, opacity: 0.65, fontSize: 8 }}>
                    This is a computer generated document and does not require any signature
                </Text>
                <Text style={{ opacity: 0.65, fontSize: 8 }}>
                    The details of amount are displayed in {currency}
                </Text>
            </Page>
        </Document>
    )
}