import { hasCapabilitySupport } from "../../../app/Capabilities";
import { COMPANY_NAME } from "../../../app/Config";
import { isAbsharShop } from "../../../app/app-util";
import { RestBusinessSummary } from "../../../pages/rest-business-summary";
import { printPosReceipt } from "../../../pdfs/pos-receipt/PosReceiptPdf";
import Api from "../../../session/Api";
import { getAccount } from "../../../session/SessionManager";
import UIUtil from "../../../util/UIUtil";
import Util from "../../../util/Util";
import { generateBitmap, openBase64ImageInNewTab } from "../../../util/useBitmap";
import ThermalPrinterDriver from "../ThermalPrinterDriver";
import { ArabicPosReceipt } from "../resource/arabic-pos-receipt";
import { BillPrint } from "../resource/bill-print";
import { CouponPrint } from "../resource/coupon-print";
import { KotPrint } from "../resource/kot-print";
import { PosCartPrint } from "../resource/pos-cart-print";

class ECSPOSServerThermalPrinterDriver extends ThermalPrinterDriver {

    port = 0;

    async connect(params) {
        this.port = params.port;
    }

    async disconnect() { }

    async printCoupons(posSessionSnapshot, coupons) {
        for (const coupon of coupons) {
            const doc = await generateBitmap(<CouponPrint request={posSessionSnapshot} coupon={coupon} />)
            if (Util.isDebug()) {
                openBase64ImageInNewTab(doc)
            }

            const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
                method: "POST",
                body: doc
            })
            if (!res.ok) {
                throw new Error();
            }
        }
    }

    async printKot(order, kot, filterClasses, bottomText) {
        const receipt = await generateBitmap(<KotPrint request={{
            order, kot, filterClasses, bottomText,
            __company_name: COMPANY_NAME.toUpperCase(),
            __current_date: Util.getFullDate(new Date().getTime()),
            __printed_by: getAccount().fullName
        }} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(receipt)
        }

        const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
            method: "POST",
            body: receipt
        })
        if (!res.ok) {
            throw new Error();
        }
    }

    async printBill(order, paymentMethods) {
        const session = await Api.asyncGetSalesOrderPosSession(order.id);

        const receipt = await generateBitmap(<BillPrint order={order} request={{
            ...session,
            paymentMethods,
            __company_name: COMPANY_NAME.toUpperCase(),
            __current_date: Util.getFullDate(new Date().getTime()),
        }} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(receipt)
        }

        const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
            method: "POST",
            body: receipt
        })
        if (!res.ok) {
            throw new Error();
        }
    }

    async printPosCart(posSessionSnapshot) {
        let companyName = COMPANY_NAME.toUpperCase();
        if (isAbsharShop()) {
            companyName = "Grand Abshar Sweet Shop"
        }

        const receipt = await generateBitmap(<PosCartPrint request={{
            ...posSessionSnapshot,
            __company_name: companyName,
            __current_date: Util.getFullDate(new Date().getTime()),
            __voucher_no: Util.getVoucherNumber(posSessionSnapshot.lastStockFlowId)
        }} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(receipt)
        }
        const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
            method: "POST",
            body: receipt
        })

        if (!res.ok) {
            throw new Error();
        }
    }

    async printReceipt(posSessionSnapshot) {
        let companyName = COMPANY_NAME.toUpperCase();

        if (isAbsharShop()) {
            companyName = "Grand Abshar Sweet Shop"
        }

        if (hasCapabilitySupport("arabicReceipt")) {
            const receipt = await generateBitmap(<ArabicPosReceipt request={{
                ...posSessionSnapshot,
                __company_name: companyName,
                __current_date: Util.getFullDate(new Date().getTime()),
                __voucher_no: Util.getVoucherNumber(posSessionSnapshot.lastStockFlowId)
            }} />)
            if (Util.isDebug()) {
                openBase64ImageInNewTab(receipt)
                if (posSessionSnapshot._transient_param_coupons?.length) {
                    await this.printCoupons(posSessionSnapshot, posSessionSnapshot._transient_param_coupons);
                }
            }
            const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
                method: "POST",
                body: receipt
            })

            if (!res.ok) {
                throw new Error();
            }
        } else {
            const res = await fetch(`http://localhost:${this.port}/print-receipt`, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    ...posSessionSnapshot,
                    __company_name: companyName,
                    __current_date: Util.getFullDate(new Date().getTime()),
                    __voucher_no: Util.getVoucherNumber(posSessionSnapshot.lastStockFlowId)
                })
            })
            if (!res.ok) {
                throw new Error();
            }
        }

        if (posSessionSnapshot._transient_param_coupons?.length) {
            await this.printCoupons(posSessionSnapshot, posSessionSnapshot._transient_param_coupons);
        }
    }

    async printSalesSummary(report) {
        const res = await fetch(`http://localhost:${this.port}/print-sales-summary`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                ...report,
                __current_date: Util.getFullDate(new Date().getTime()),
                __opening_date: Util.getFullDate(report.openingDate),
                __closing_date: Util.isNumberExist(report.closingDate) ? Util.getFullDate(report.closingDate) : null
            })
        })
        if (!res.ok) {
            throw new Error();
        }
    }

    async printRestBusinessSummary(report) {
        const doc = await generateBitmap(<RestBusinessSummary type={"thermal"} report={report} />)
        if (Util.isDebug()) {
            openBase64ImageInNewTab(doc)
        }
        const res = await fetch(`http://localhost:${this.port}/print-arabic-receipt`, {
            method: "POST",
            body: doc
        })

        if (!res.ok) {
            throw new Error();
        }
    }

    hasCashDrawerAccess() {
        return false;
    }

    isConnectionBased() {
        return false;
    }

    canPrintSalesSummary() {
        return true;
    }

    canPrintRestaurantDocs() {
        return true;
    }

    getParams() {
        return [
            {
                property: "port",
                name: "Connection ID"
            }
        ]
    }

}

export default ECSPOSServerThermalPrinterDriver;