import { pdf, PDFViewer, usePDF } from "@react-pdf/renderer";
import Invoice from "./pdf/Invoice";
import print from 'print-js';
import Api from "../../session/Api";
import UIUtil from "../../util/UIUtil";

import { Receipt16, Close16, ArrowRight16 } from '@carbon/icons-react'
import { useState } from "react";
import Button from "../../components/Button";
import { INVOICE_ALT_PDF } from "../../app/Config";
import { AdidasRomarioQuotationPdf } from "../../pdfs-alt/adidas-romario/AdidasRomarioQuotationPdf";
import { RahelaRomarioQuotationPdf } from "../../pdfs-alt/rahela-romario/RahelaRomarioQuotationPdf";
import { RunnersRomarioQuotationPdf } from "../../pdfs-alt/runners-romario/RunnersRomarioQuotationPdf";


export function InvoiceDocumentIdBtn({ invoiceDocumentId, mini }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        openInvoiceDocumentId(invoiceDocumentId, () => setLoading(false));
    }

    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    }
}

export async function openInvoiceDocumentId(invoiceDocumentId, onDone) {
    Api.getInvoiceDocument(invoiceDocumentId, response => {
        if (response.status === true) {
            openInvoice(response.payload.invoice)
        } else {
            UIUtil.showError(response.message);
        }
        onDone();
    })
}


export function StockFlowInvoiceBtn({ stockFlowId, mini, ButtonComponent }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        openStockFlowInvoice(stockFlowId, () => setLoading(false));
    }

    if (ButtonComponent) {
        return <ButtonComponent {...{ onClick, loading }} />
    }

    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    }
}

export function SaleInvoiceBtn({ saleId, mini }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        openSaleInvoice(saleId, () => setLoading(false));
    }

    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>Tax Invoice</Button>
    }
}


export function V2SaleInvoiceBtn({ saleId, creditNote, mini }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        openV2SaleInvoice(saleId, creditNote, () => setLoading(false));
    }

    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>{creditNote ? 'Credit Note' : 'Tax Invoice'}</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>{creditNote ? 'Credit Note' : 'Tax Invoice'}</Button>
    }
}



export function AdidasRomarioQuotationBtn({ quotationId, mini, salesOrder, PDFComponent, ButtonComponent }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        const onDone = () => setLoading(false)

        const performPrint = async (props) => {
            Api.getQuotationInvoice(quotationId, salesOrder, async response => {
                if (response.status === true) {
                    const blob = await pdf(
                        PDFComponent
                            ? <PDFComponent invoice={response.payload} {...props} />
                            : <AdidasRomarioQuotationPdf invoice={response.payload} {...props} />
                    ).toBlob();
                    window.open(URL.createObjectURL(blob))
                } else {
                    UIUtil.showError(response.message);
                }
                onDone();
            })
        }

        UIUtil.showOverlay2(onClose => (
            <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Print</h4>
                    <button onClick={() => {
                        onClose();
                        onDone();
                    }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <Close16 />
                    </button>
                </div>

                {/* <div style={{ marginTop: '0.5rem' }}>
                    <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div> */}

                <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                    <div onClick={() => {
                        performPrint({});
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>Default</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image and RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true, showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image, RRP and memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showHsCode: true, showCoo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With HS Code and COO</p>
                    </div>
                </div>
            </div>
        ), onDone)
    }

    if (ButtonComponent) {
        return <ButtonComponent {...{ onClick, loading }} />
    }

    const name = salesOrder ? "Proforma Invoice" : "Quotation"
    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>{name}</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>{name}</Button>
    }
}


export function RahelaRomarioQuotationBtn({ quotationId, mini, salesOrder, PDFComponent }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        const onDone = () => setLoading(false)

        const performPrint = async (props) => {
            Api.getQuotationInvoice(quotationId, salesOrder, async response => {
                if (response.status === true) {
                    const blob = await pdf(
                        PDFComponent
                            ? <PDFComponent invoice={response.payload} {...props} />
                            : <RahelaRomarioQuotationPdf invoice={response.payload} {...props} />
                    ).toBlob();
                    window.open(URL.createObjectURL(blob))
                } else {
                    UIUtil.showError(response.message);
                }
                onDone();
            })
        }

        UIUtil.showOverlay2(onClose => (
            <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Print</h4>
                    <button onClick={() => {
                        onClose();
                        onDone();
                    }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <Close16 />
                    </button>
                </div>

                {/* <div style={{ marginTop: '0.5rem' }}>
                    <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div> */}

                <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                    <div onClick={() => {
                        performPrint({});
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>Default</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image and RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true, showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image, RRP and memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showHsCode: true, showCoo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With HS Code and COO</p>
                    </div>
                </div>
            </div>
        ), onDone)
    }

    const name = salesOrder ? "Proforma Invoice" : "Quotation"
    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>{name}</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>{name}</Button>
    }
}

export function RunnersRomarioQuotationBtn({ quotationId, mini, salesOrder, PDFComponent }) {
    const [loading, setLoading] = useState(false);
    const onClick = e => {
        e.stopPropagation()
        e.preventDefault();

        setLoading(true)
        const onDone = () => setLoading(false)

        const performPrint = async (props) => {
            Api.getQuotationInvoice(quotationId, salesOrder, async response => {
                if (response.status === true) {
                    const blob = await pdf(
                        PDFComponent
                            ? <PDFComponent invoice={response.payload} {...props} />
                            : <RunnersRomarioQuotationPdf invoice={response.payload} {...props} />
                    ).toBlob();
                    window.open(URL.createObjectURL(blob))
                } else {
                    UIUtil.showError(response.message);
                }
                onDone();
            })
        }

        UIUtil.showOverlay2(onClose => (
            <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Print</h4>
                    <button onClick={() => {
                        onClose();
                        onDone();
                    }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <Close16 />
                    </button>
                </div>

                {/* <div style={{ marginTop: '0.5rem' }}>
                    <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div> */}

                <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                    <div onClick={() => {
                        performPrint({});
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>Default</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image and RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true, showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image, RRP and memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showHsCode: true, showCoo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With HS Code and COO</p>
                    </div>
                </div>
            </div>
        ), onDone)
    }

    const name = salesOrder ? "Proforma Invoice" : "Quotation"
    if (mini) {
        return <Button kind="secondary" size="sm" onClick={onClick} loading={loading} renderIcon={Receipt16}>{name}</Button>
    } else {
        return <Button onClick={onClick} style={{ width: '100%' }} loading={loading} renderIcon={Receipt16}>{name}</Button>
    }
}



export async function openStockFlowInvoice(stockFlowId, onDone) {
    const performPrint = (props) => {
        Api.getStockFlowInvoice(stockFlowId, response => {
            if (response.status === true) {
                openInvoice(response.payload, props)
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    if (INVOICE_ALT_PDF === "adidas-romario" || INVOICE_ALT_PDF === "romario-shop" || INVOICE_ALT_PDF === "rahela-romario" || INVOICE_ALT_PDF === "runners-romario") {
        UIUtil.showOverlay2(onClose => (
            <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Print</h4>
                    <button onClick={() => {
                        onClose();
                        onDone();
                    }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <Close16 />
                    </button>
                </div>

                {/* <div style={{ marginTop: '0.5rem' }}>
                    <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div> */}

                <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                    <div onClick={() => {
                        performPrint({});
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>Default invoice</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image and RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true, showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image, RRP and memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showHsCode: true, showCoo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With HS Code and COO</p>
                    </div>
                </div>
            </div>
        ), onDone)
        // onDone()
    } else {
        performPrint()
    }
}


export async function openSaleInvoice(saleId, onDone) {
    const performPrint = (props) => {
        Api.getSaleInvoice(saleId, response => {
            if (response.status === true) {
                openInvoice(response.payload, props)
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }

    if (INVOICE_ALT_PDF === "adidas-romario" || INVOICE_ALT_PDF === "romario-shop" || INVOICE_ALT_PDF === "rahela-romario" || INVOICE_ALT_PDF === "runners-romario") {
        UIUtil.showOverlay2(onClose => (
            <div style={{ width: 400, borderRadius: 15, background: 'white', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0rem' }}>
                    <h4 style={{ fontWeight: 'bold', flex: 1 }}>Print</h4>
                    <button onClick={() => {
                        onClose();
                        onDone();
                    }} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                        <Close16 />
                    </button>
                </div>

                {/* <div style={{ marginTop: '0.5rem' }}>
                    <TextInput placeholder="Search" value={search} onChange={e => setSearch(e.target.value)} />
                </div> */}

                <div style={{ maxHeight: 350, minHeight: 150, overflow: 'auto', paddingTop: '0.5rem' }}>
                    <div onClick={() => {
                        performPrint({});
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>Default invoice</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image and RRP</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showImage: true, showRRP: true, showMemo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With image, RRP and memo</p>
                    </div>
                    <div onClick={() => {
                        performPrint({ showHsCode: true, showCoo: true });
                        onClose();
                    }} className="recipe-manager-ingredient-select-item" style={{ height: '3rem', paddingInline: '0.5rem', display: 'flex', alignItems: 'center', gap: '0.5rem', borderBottom: '1px solid #00000010' }}>
                        <p style={{ fontSize: 14, }}>With HS Code and COO</p>
                    </div>
                </div>
            </div>
        ), onDone)
        // onDone()
    } else {
        performPrint()
    }
}


export async function openV2SaleInvoice(saleId, creditNote, onDone) {
    const performPrint = (props) => {
        Api.getInvoiceForV2Sale(saleId, response => {
            if (response.status === true) {
                openInvoice(response.payload, props)
            } else {
                UIUtil.showError(response.message);
            }
            onDone();
        })
    }
    performPrint({ creditNote })
}


export async function openInvoice(invoice, props = {}) {
    const blob = await pdf(<Invoice invoice={invoice} {...props} />).toBlob();
    window.open(URL.createObjectURL(blob))
    // print(URL.createObjectURL(blob));
}

export async function printInvoice(invoice, props = {}) {
    const blob = await pdf(<Invoice invoice={invoice} {...props} />).toBlob();
    print(URL.createObjectURL(blob));
}

export function InvoiceDocument({ invoice }) {
    return (
        <PDFViewer style={{ width: '100%', height: '100%' }}>
            <Invoice invoice={invoice} />
        </PDFViewer>
    )
}

export const InvoiceDocumentDownload = ({ invoice }) => {
    const [instance, updateInstance] = usePDF({ document: <Invoice invoice={invoice} /> });

    return (
        <a href={instance.url} target="_blank" style={{ width: '100%', textDecoration: 'none' }}>
            <Button style={{ width: '100%' }} loading={instance.loading} disabled={instance.error} renderIcon={Receipt16}>Invoice</Button>
        </a>
    )
}