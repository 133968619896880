import { DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_SUPPLIER, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_SUPPLIER } from "../constants/Constants";

/*
if (stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal()) {
            subtitle = "Purchase"
        } else if (!this.isSourceExternal() && stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
            subtitle = this.isQuotation() ? "Quotation" : "Sales";
        } else if (!this.isSourceExternal() && stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
            subtitle = "Purchase Return"
        } else if (stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal()) {
            subtitle = "Sales Return";
        } else if (!this.isSourceExternal() && !this.isDestinationExternal()) {
            subtitle = "Stock Transfer";
        }
*/

class StockFlowUtil {

    isSales(stockFlow) {
        return !this.isSourceExternal(stockFlow) && stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER;
    }

    isCreation(stockFlow) {
        return stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION && !this.isDestinationExternal(stockFlow);
    }

    isPurchase(stockFlow) {
        return stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !this.isDestinationExternal(stockFlow)
    }

    isCreationOrPurchase(stockFlow) {
        return this.isCreation(stockFlow) || this.isPurchase(stockFlow);
    }

    isPurchaseReturn(stockFlow) {
        return !this.isSourceExternal(stockFlow) && stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER;
    }

    isSalesReturn(stockFlow) {
        return stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !this.isDestinationExternal(stockFlow);
    }

    isReturn(stockFlow) {
        return this.isPurchaseReturn(stockFlow) || this.isSalesReturn(stockFlow);
    }

    isSourceExternal(stockFlow) {
        return stockFlow.sourceType == SOURCE_TYPE_SUPPLIER || stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION || stockFlow.sourceType == SOURCE_TYPE_CUSTOMER;
    }

    isDestinationExternal(stockFlow) {
        return stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER || stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER || stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION;
    }

}

export default new StockFlowUtil();