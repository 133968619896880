import React from 'react'
import Button from '../../components/Button'

import { Add16, Download16, Delete16, Edit16, ErrorFilled16, Save16 } from '@carbon/icons-react'
import NoteItem from './NoteItem'
import Api from '../../session/Api'
import { ButtonSet, ComposedModal, FileUploaderDropContainer, FileUploaderItem, ModalBody, ModalFooter, ModalHeader, TextArea, TextInput } from 'carbon-components-react'
import UIUtil from '../../util/UIUtil'
import Util from '../../util/Util'

class NoteView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            notes: [],

            createNewNote: false,
            createNewNoteTitle: "",
            createNewNoteDesc: "",
            createNewNoteAttachment: undefined,
            creatingNote: false,

            showNote: false,
            selectedNote: undefined,
            showDeleteConfirm: false,

            showEditNote: false,
            updateNoteTitle: "",
            updateNoteDesc: "",
            editingNote: false
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.dialogVisibilityListener) {
            this.props.dialogVisibilityListener(this.state.createNewNote || this.state.showNote || this.state.showDeleteConfirm || this.state.showEditNote)
        }

        if (this.props.onCreatingNoteChange && prevState.creatingNote !== this.state.creatingNote) {
            this.props.onCreatingNoteChange(this.state.creatingNote)
        }
    }

    componentDidMount() {
        this.setState({ loading: true })
        Api.getNotes(this.props.objectType, this.props.objectId, response => {
            this.setState({ loading: false, notes: response.status === true ? response.payload : [] })
        })
    }

    createNote() {
        if (this.props.requiredUpload && !this.state.createNewNoteAttachment) {
            UIUtil.showError("No file selected");
            return false;
        }

        this.setState({ creatingNote: true })
        Api.saveNote({
            title: this.state.createNewNoteTitle,
            note: this.state.createNewNoteDesc,

            objectId: this.props.objectId,
            objectType: this.props.objectType
        }, this.state.createNewNoteAttachment, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState(prevState => ({ creatingNote: false, notes: [response.payload, ...prevState.notes] }))
            } else {
                UIUtil.showError(response.message);
                this.setState({ creatingNote: false })
            }
        })
        return true;
    }

    updateNote() {
        this.setState({ editingNote: true })
        Api.saveNote({
            id: this.state.selectedNote.id,
            title: this.state.updateNoteTitle,
            note: this.state.updateNoteDesc,
        }, undefined, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                this.setState(prevState => {
                    let notes = [...prevState.notes];
                    for (let i = 0; i < notes.length; i++) {
                        const note = notes[i];
                        if (response.payload.id == note.id) {
                            notes[i] = response.payload;
                            break;
                        }
                    }

                    return {
                        editingNote: false, showEditNote: false, selectedNote: response.payload, notes
                    };
                })
            } else {
                UIUtil.showError(response.message);
                this.setState({ editingNote: false })
            }
        })
    }

    deleteNote() {
        Api.deleteNote(this.state.selectedNote.id, () => { })
        this.setState(prevState => ({ notes: prevState.notes.filter(note => note !== this.state.selectedNote), showNote: false, selectedNote: undefined }))
    }

    onAddBtn() {
        this.setState({ createNewNote: true, createNewNoteTitle: "", createNewNoteDesc: "", createNewNoteAttachment: undefined })
    }

    render() {
        if (this.state.loading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>Loading...</p>
                </div>
            )
        }

        return (
            <div>
                {!this.props.hideAddBtn && !this.props.readonly && <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <Button loading={this.state.creatingNote} onClick={this.onAddBtn.bind(this)} renderIcon={Add16}>New</Button>
                </div>}

                {this.state.notes.length == 0 && <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>No {this.props.itemsName ?? 'notes/docs'} available</p>
                </div>}

                <div style={{ display: 'flex', flexWrap: 'wrap', gap: this.props.miniMode ? '0.5rem' : '1rem', marginTop: this.props.hideAddBtn ? 0 : (this.props.miniMode ? '0.5rem' : '1rem') }}>
                    {this.state.notes.map(note => <NoteItem light={this.props.light} note={note} onClick={() => this.setState({ showNote: true, selectedNote: note, showDeleteConfirm: false, showEditNote: false })} />)}
                </div>

                <ComposedModal key="create-note-dialog" size="sm" open={this.state.createNewNote} onClose={() => this.setState({ createNewNote: false })}>
                    <ModalHeader label="Creating" title={this.props.itemName ?? "Note/Doc"} />
                    <ModalBody>
                        <TextInput
                            data-modal-primary-focus
                            value={this.state.createNewNoteTitle}
                            onChange={e => this.setState({ createNewNoteTitle: e.target.value })}
                            labelText="Title"
                            placeholder="Optional title"
                        />

                        <div style={{ height: '1rem' }} />

                        {!this.props.skipBody && <TextArea
                            value={this.state.createNewNoteDesc}
                            onChange={e => this.setState({ createNewNoteDesc: e.target.value })}
                            labelText={this.props.itemNoteName ?? "Note"}
                            //placeholder="Optional note"
                            placeholder={"Optional " + (this.props.itemNoteName?.toLowerCase() ?? 'note')}
                        />}

                        <div style={{ height: '1rem' }} />

                        {this.state.createNewNoteAttachment !== undefined ? (
                            <FileUploaderItem name={this.state.createNewNoteAttachment.name} status="edit" onDelete={() => this.setState({ createNewNoteAttachment: undefined })} />
                        ) : (
                            <FileUploaderDropContainer
                                labelText={this.props.requiredUpload ? "Drag and drop files here or click to upload" : "Drag and drop files here or click to upload (optional)"}
                                onAddFiles={(e, { addedFiles }) => {
                                    if (addedFiles.length > 0) {
                                        this.setState({ createNewNoteAttachment: addedFiles[0] })
                                    }
                                }} />
                        )}
                    </ModalBody>
                    <ModalFooter
                        //primaryButtonDisabled={(posState.getShowQuantitySetItem() !== undefined && posState.getQuantitySetValue() == posState.getShowQuantitySetItem().quantityValue) || (isNaN(posState.getQuantitySetValue()) || posState.getQuantitySetValue() === "")}
                        onRequestClose={() => this.setState({ createNewNote: false })}
                        onRequestSubmit={() => {
                            if (this.createNote()) {
                                this.setState({ createNewNote: false })
                            }
                        }}
                        primaryButtonText="Create" //secondaryButtonText="Cancel" 
                    />
                </ComposedModal>

                {this.state.selectedNote !== undefined &&
                    <ComposedModal key="view-note-dialog" size="sm" open={this.state.showNote} onClose={() => this.setState({ showNote: false })}>
                        <ModalHeader label="Viewing" title={this.props.itemName ?? "Note"} />
                        <ModalBody>
                            <h2>{this.state.selectedNote.title}</h2>

                            {/* <div style={{height: '1rem'}} /> */}

                            <p style={{ whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden' }}>{this.state.selectedNote.note}</p>

                            {Util.isStringExists(this.state.selectedNote.attachmentName) &&
                                <a download href={Api.downloadNoteAttachment(this.state.selectedNote.id)} style={{ textDecoration: 'none' }}><Button style={{ marginTop: '1rem' }} renderIcon={Download16}>{this.state.selectedNote.attachmentName}</Button></a>}
                        </ModalBody>
                        <ButtonSet>

                        </ButtonSet>
                        <ModalFooter>
                            {!this.props.readonly && <>
                                <Button kind="danger" onClick={() => this.setState({ showDeleteConfirm: true })} renderIcon={Delete16}>
                                    Delete
                                </Button>
                                <Button onClick={() => this.setState({ showNote: false, showEditNote: true, updateNoteTitle: this.state.selectedNote.title, updateNoteDesc: this.state.selectedNote.note })} renderIcon={Edit16}>
                                    Edit
                                </Button>
                            </>}
                        </ModalFooter>
                        {/* <ModalFooter
                    //primaryButtonDisabled={(posState.getShowQuantitySetItem() !== undefined && posState.getQuantitySetValue() == posState.getShowQuantitySetItem().quantityValue) || (isNaN(posState.getQuantitySetValue()) || posState.getQuantitySetValue() === "")}
                    onRequestClose={() => this.setState({showNote: false})}
                    onRequestSubmit={() => {
                        // UIUtil.confirm(() => this.deleteNote());
                        this.setState({showDeleteConfirm: true})
                        // if (window.confirm("Are you sure you want to delete this?")) {
                        //     this.deleteNote();
                        // }
                    }}
                    danger
                    primaryButtonText="Delete" //secondaryButtonText="Cancel" 
                    /> */}
                    </ComposedModal>}

                {this.state.selectedNote !== undefined &&
                    <ComposedModal
                        open={this.state.showDeleteConfirm}
                        onClose={() => this.setState({ showDeleteConfirm: false })}
                        size="xs">
                        <ModalHeader label="Confirm" title="Are you sure?" />
                        <div style={{ height: 15 }} />
                        <ModalFooter>
                            <ButtonSet style={{ width: '100%' }}>
                                <Button kind="secondary" onClick={() => this.setState({ showDeleteConfirm: false })}>
                                    Cancel
                                </Button>
                                <Button onClick={() => {
                                    this.deleteNote()
                                }}>
                                    Confirm
                                </Button>
                            </ButtonSet>
                        </ModalFooter>
                    </ComposedModal>}

                {this.state.selectedNote !== undefined &&
                    <ComposedModal
                        key="note-editor-modal"
                        open={this.state.showEditNote}
                        onClose={() => this.setState({ showEditNote: false })}
                        size="sm">
                        <ModalHeader label="Editing" title={this.props.itemName ?? "Note"} />
                        <ModalBody style={{ paddingRight: '1rem' }}>
                            <TextInput
                                data-modal-primary-focus
                                value={this.state.updateNoteTitle}
                                onChange={e => this.setState({ updateNoteTitle: e.target.value })}
                                labelText="Title"
                                placeholder="Optional title"
                            />

                            <div style={{ height: '1rem' }} />

                            {!this.props.skipBody && <TextArea
                                value={this.state.updateNoteDesc}
                                onChange={e => this.setState({ updateNoteDesc: e.target.value })}
                                labelText={this.props.itemNoteName ?? "Note"}
                                placeholder={"Optional " + (this.props.itemNoteName?.toLowerCase() ?? "note")} />}
                        </ModalBody>
                        <ModalFooter>
                            <ButtonSet style={{ width: '100%' }}>
                                <Button kind="secondary" onClick={() => this.setState({ showEditNote: false })} renderIcon={ErrorFilled16}>
                                    Cancel
                                </Button>
                                <Button onClick={() => this.updateNote()} renderIcon={Save16} loading={this.state.editingNote}>
                                    Save
                                </Button>
                            </ButtonSet>
                        </ModalFooter>
                    </ComposedModal>}
            </div>
        )
    }

}

export default NoteView;