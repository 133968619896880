import { ClickableTile, Tile } from 'carbon-components-react'
import React from 'react'
import Util from '../../util/Util'

export default ({note, onClick, light}) => (
    <ClickableTile light={light} handleClick={onClick}>
        <p style={{fontSize: 12}}>{Util.getDate(note.dateCreated)}</p>
        <h4>{note.title}</h4>
        <p style={{whiteSpace: 'pre-wrap', wordBreak: 'break-all', overflow: 'hidden'}}>{note.note}</p>
    </ClickableTile>
)