
import {
    Pin16, PinFilled16,

    Dashboard32, User32, Product32, Screen32, Category32, Barcode32, Box32, Compare32, Rocket32,
    Delivery32, AirlinePassengerCare32, CutOut32, Headset32, Percentage32, Store32, DataVis_232,
    Calculator32, WatsonHealthTextAnnotationToggle32, Archive32, StringText32, Chip32, InventoryManagement32,
    FlowStream32, Cube32, DocumentImport32, Purchase32, Money32, Finance32, Account32, Receipt32, Events32,
    Gift32, TagGroup32, QrCode32, Close16, SubtractAlt32, Currency32, IncreaseLevel32, ShoppingCartArrowUp32,
    Api32, License32, RequestQuote32, Notebook32, Table32, ShoppingCartArrowDown32, InProgress32,
    DataFormat32, TreeView32, Catalog32, ListDropdown32, Course32, Grid32, DataShare32, Data_132,
    CaretRight16, ArrowDown32, ArrowUp32, PenFountain32, DeliveryParcel32, LicenseGlobal32,
    Network_132, CategoryNewEach32, PhraseSentiment32, Gradient32, App32, Book32, Document32,
    Wallet32, ReportData32, Van32, RulerAlt32, Types32, ShoppingCart32, Classification32, Settings32,
    Alarm32, Categories32, Boolean32, LocationCompany32, InsertPage32, Bullhorn32,
    Locked32, ViewFilled32, Blog32, Home32, TextLink32, LicenseDraft32, MediaLibrary32,
    UserData32, Enterprise32, Code32, RecentlyViewed32, Building32, ChartBullet32, BuildingInsights_132,
    AirlineManageGates32, Migrate32, ServiceDesk32, Application32, Certificate32, GraphicalDataFlow32,
    Wikis32, Map32, TwoPersonLift32, Industry32, Milestone32,
    Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, Roadmap32, EventsAlt32, UserProfile32,
    StarFilled32
} from '@carbon/icons-react'
import { hasCapabilitySupport } from '../../app/Capabilities';
import { MENU_PACKAGE } from '../../app/Config';
import { ACCOUNT_TYPE_ACCOUNTANT, ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_CMS_MANAGER, ACCOUNT_TYPE_DELIVERY_MANAGER, ACCOUNT_TYPE_GUARDIAN, ACCOUNT_TYPE_LEARNING_MANAGER, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_REAL_ESTATE_AGENT, ACCOUNT_TYPE_REST_DELIVERY_MANAGER, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SALES_ADMIN, ACCOUNT_TYPE_SHIPPING_PORT_STAFF, ACCOUNT_TYPE_STORE_MASTER, ACCOUNT_TYPE_STUDENT, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_TUTOR, ACCOUNT_TYPE_TYPE_KITCHEN, ACCOUNT_TYPE_TYPE_WAITER, ACCOUNT_TYPE_VENUE_MANAGER, ACCOUNT_TYPE_WAREHOUSE_KEEPER } from '../../constants/Constants';
import { getAccountRole } from '../../session/SessionManager';
import { crmMenu } from './packages/crm';
import { guardianMenu } from './packages/lms/guardian';
import { studentMenu } from './packages/lms/student';
import { tutorMenu } from './packages/lms/tutor';
import { normalMenu } from './packages/normal';
import { productManagerMenu } from './packages/product-manager';
import { cmsManager } from './packages/roles/cms-manager';
import { deliveryManager } from './packages/roles/delivery-manager';
import { venueManager } from './packages/roles/venue-manager';
import { portStaff } from './packages/roles/port-staff';
import { learningManager } from './packages/roles/learning-manager';
import { warehouseKeeper } from './packages/roles/warehouse-keeper';
import { salesAdmin } from './packages/roles/sales-admin';
import { waiter } from './packages/roles/waiter';
import { restDeliveryManager } from './packages/roles/rest-delivery-manager';
import { restSupervisor } from './packages/rest-supervisor';
import { restCashier } from './packages/roles/rest-cashier';

/**
 * 
 * @returns {Array<{title: string, subMenus: Array<{title: string, menus: Array<{icon: React.Component, title: string, desc: string}>}>}>} 
 */
export function getMenu() {
    const role = getAccountRole();
    // console.log(role);
    if (role == ACCOUNT_TYPE_STUDENT) {
        return studentMenu();
    } else if (role == ACCOUNT_TYPE_TUTOR) {
        return tutorMenu();
    } else if (role == ACCOUNT_TYPE_GUARDIAN) {
        return guardianMenu();
    } else if (role == ACCOUNT_TYPE_VENUE_MANAGER) {
        return venueManager();
    } else if (role == ACCOUNT_TYPE_CMS_MANAGER) {
        return cmsManager();
    } else if (role == ACCOUNT_TYPE_DELIVERY_MANAGER) {
        return deliveryManager();
    } else if (role == ACCOUNT_TYPE_PRODUCT_MANAGER) {
        return productManagerMenu();
    } else if (role == ACCOUNT_TYPE_WAREHOUSE_KEEPER) {
        return warehouseKeeper();
    } else if (role == ACCOUNT_TYPE_SHIPPING_PORT_STAFF) {
        return portStaff();
    } else if (role == ACCOUNT_TYPE_LEARNING_MANAGER) {
        return learningManager();
    } else if (role == ACCOUNT_TYPE_TYPE_KITCHEN) {
        return [];
    } else if (role == ACCOUNT_TYPE_SALES_ADMIN) {
        return salesAdmin();
    } else if (role == ACCOUNT_TYPE_TYPE_WAITER) {
        return waiter()
    } else if (role == ACCOUNT_TYPE_REST_DELIVERY_MANAGER) {
        return restDeliveryManager()
    }

    if (hasCapabilitySupport("restaurant")) {
        if (role === ACCOUNT_TYPE_SUPERVISOR) {
            return restSupervisor()
        } else if (role === ACCOUNT_TYPE_CASHIER) {
            return restCashier();
        }
    }

    if (MENU_PACKAGE === "crm") {
        return crmMenu();
    } else {
        return normalMenu();
    }
}
// export const getMenu = () => {
//     const accountRole = getAccountRole();
//     const hasSalesSystem = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR || accountRole == ACCOUNT_TYPE_SALES) && hasCapabilitySupport("salesSystem")

//     const hasOrderManagement = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("orderManagementSystem")
//     const hasOnlineStore = (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) && hasCapabilitySupport("onlineStore");

//     let menu;
//     if (accountRole == ACCOUNT_TYPE_STORE_MASTER) {
//         menu = [
//             {
//                 title: "Store Operations",
//                 color: "green",
//                 subMenus: [
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: Calculator32,
//                                 title: "POS",
//                                 link: "/"
//                             },
//                             {
//                                 icon: DeliveryParcel32,
//                                 title: "Stock Request",
//                                 link: "/my-stock-requests"
//                             }
//                         ]
//                     },
//                     // {
//                     //     title: "Reporting",
//                     //     menus: [
//                     //         {
//                     //             icon: RecentlyViewed32,
//                     //             title: "Order History",
//                     //             link: "/order-history"
//                     //         },
//                     //         {
//                     //             icon: Cube32,
//                     //             title: "Inventory",
//                     //             link: "/inventory"
//                     //         },
//                     //     ]
//                     // }
//                 ]
//             }
//         ]


//     } else {
//         menu = [
//             {
//                 title: "Portal",
//                 color: "black",
//                 subMenus: [
//                     {
//                         menus: (accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
//                             {
//                                 icon: Dashboard32,
//                                 title: "Dashboard",
//                                 desc: "Get an overview of the system",
//                                 link: "/"
//                             },
//                             {
//                                 icon: Chip32,
//                                 title: "Monitoring",
//                                 // desc: "Monitor active POS terminals",
//                                 link: "/monitoring"
//                             },
//                             ...((hasCapabilitySupport("terminalPos") || hasCapabilitySupport("thirdPartyPos")) ? [{
//                                 icon: Calculator32,
//                                 title: "POS",
//                                 desc: "Open POS terminal",
//                                 link: "/pos"
//                             }] : []),
//                         ] : (
//                             hasCapabilitySupport("terminalPos") ? (
//                                 [
//                                     {
//                                         icon: Calculator32,
//                                         title: "POS",
//                                         desc: "Open POS terminal",
//                                         link: "/"
//                                     }
//                                 ]
//                             ) : (
//                                 [
//                                     {
//                                         icon: Home32,
//                                         title: "Home",
//                                         link: "/"
//                                     }
//                                 ]
//                             )
//                         )
//                     }
//                 ]
//             },
//             //Task32, CalendarHeatMap32, SplitScreen32, ChoroplethMap32, Legend32, EventsAlt32, UserProfile32
//             ...((hasCapabilitySupport("activities")) ? [{
//                 title: "Activities",
//                 color: "green2",
//                 subMenus: [
//                     {
//                         menus: [
//                             {
//                                 icon: Task32,
//                                 title: "Activities",
//                                 link: "/activities"
//                             },
//                             {
//                                 icon: CalendarHeatMap32,
//                                 title: "Calendar",
//                                 link: "/activities-calendar"
//                             },
//                             {
//                                 icon: SplitScreen32,
//                                 title: "Board",
//                                 link: "/activities-board"
//                             },
//                         ]
//                     },
//                     ...((accountRole == ACCOUNT_TYPE_ADMINISTRATION || accountRole == ACCOUNT_TYPE_SUPERVISOR) ? [
//                         {
//                             title: "Definitions",
//                             menus: [
//                                 {
//                                     icon: ChoroplethMap32,
//                                     title: "Blueprints",
//                                     link: "/activity-blueprints"
//                                 },
//                                 {
//                                     icon: Legend32,
//                                     title: "Activity Statuses",
//                                     link: "/activity-statuses"
//                                 },
//                                 {
//                                     icon: UserProfile32,
//                                     title: "Member Tags",
//                                     link: "/activity-member-tags"
//                                 },
//                                 // {
//                                 //     icon: EventsAlt32,
//                                 //     title: "Teams",
//                                 //     link: "/activity-teams"
//                                 // },
//                             ]
//                         }
//                     ] : [])
//                 ]
//             }] : []),
//             {
//                 title: "Operation",
//                 color: "green",
//                 subMenus: [
//                     {
//                         //title: "Management",
//                         menus: [
//                             {
//                                 icon: Migrate32,
//                                 title: "Purchase",
//                                 link: "/purchases"
//                             },
//                             {
//                                 icon: Certificate32,
//                                 title: "Sale",
//                                 link: "/sales"
//                             },
//                         ]
//                     },
//                 ]
//             },
//             ...((true) ? [{
//                 title: "Sales",
//                 color: "orange",
//                 subMenus: [
//                     ...((hasSalesSystem) ? [{
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: ShoppingCart32,
//                                 title: "Carts",
//                                 link: "/sales-carts"
//                             },
//                             {
//                                 icon: Types32,
//                                 title: "Cart Types",
//                                 link: "/sales-cart-types"
//                             },
//                         ]
//                     }] : []),
//                     {
//                         title: "Sales Reporting",
//                         menus: [
//                             ...(hasCapabilitySupport("thirdPartyPos") ? [{
//                                 icon: BuildingInsights_132,
//                                 title: "Venue Sales Report",
//                                 link: "/venue-sales-report"
//                             }] : []),
//                             {
//                                 icon: ShoppingCartArrowUp32,
//                                 title: "Sales Report",
//                                 desc: "Analyze sales",
//                                 link: "/sales-report"
//                             },
//                             {
//                                 icon: Data_132,
//                                 title: "Sales Summary",
//                                 link: "/sales-summary-report"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Performance Reporting",
//                         menus: [
//                             {
//                                 icon: DataShare32,
//                                 title: "Product Performance",
//                                 link: "/product-performance-report"
//                             },
//                             {
//                                 icon: Compare32,
//                                 title: "Price Performance",
//                                 link: "/price-performance-report"
//                             },
//                             {
//                                 icon: Alarm32,
//                                 title: "Hourly Performance",
//                                 link: "/hourly-performance-report"
//                             }
//                         ]
//                     }
//                 ]
//             }] : []),
//             ...((hasOrderManagement) ? [{
//                 title: "Order Management",
//                 color: "purple",
//                 subMenus: [
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: LocationCompany32,
//                                 title: "Deliverables/Rentals",
//                                 link: "/deliverables"
//                             }
//                         ]
//                     },
//                     {
//                         title: "Definitions",
//                         menus: [
//                             {
//                                 icon: Boolean32,
//                                 title: "Order States",
//                                 link: "/sales-order-states"
//                             },
//                         ]
//                     }
//                 ]
//             }] : []),
//             ...((hasCapabilitySupport("terminalPos")) ? [{
//                 title: "Point of Sale",
//                 color: "blue",
//                 subMenus: [
//                     {
//                         title: "Reporting",
//                         menus: [
//                             {
//                                 icon: DataFormat32,
//                                 title: "POS Summary",
//                                 link: "/pos-sales-summary-report"
//                             },
//                             {
//                                 icon: Receipt32,
//                                 title: "POS Sales",
//                                 link: "/pos-sales-report"
//                             },
//                             {
//                                 icon: InProgress32,
//                                 title: "Work Periods",
//                                 link: "/work-period-report"
//                             },
//                             {
//                                 icon: Account32,
//                                 title: "Cash Closed",
//                                 link: "/cash-closed-report"
//                             },
//                             {
//                                 icon: Rocket32,
//                                 title: "POS Product Performance",
//                                 link: "/pos-product-performance-report"
//                             },
//                         ]
//                     }
//                 ]
//             }] : []),
//             {
//                 title: "Accounting",
//                 color: "dark-pink",
//                 subMenus: [
//                     {
//                         title: "Transactions",
//                         menus: [
//                             {
//                                 icon: ChartBullet32,
//                                 title: "Bill",
//                                 link: "/bills"
//                             },
//                             {
//                                 icon: Currency32,
//                                 title: "Transaction",
//                                 desc: "View and record inward/outward transactions",
//                                 link: "/transactions"
//                             },
//                             {
//                                 icon: ArrowDown32,
//                                 title: "Receipt Voucher",
//                                 link: "/receipt-voucher-creator"
//                             },
//                             {
//                                 icon: ArrowUp32,
//                                 title: "Payment Voucher",
//                                 link: "/payment-voucher-creator"
//                             },
//                             {
//                                 icon: PenFountain32,
//                                 title: "Cheques",
//                                 link: "/cheques"
//                             },
//                         ]
//                     },
//                     ...((hasCapabilitySupport("thirdPartyPos")) ? [{
//                         title: 'Documents',
//                         menus: [
//                             {
//                                 icon: Document32,
//                                 title: "Invoice Documents",
//                                 link: "/invoice-documents"
//                             },
//                         ]
//                     }] : []),
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: Enterprise32,
//                                 title: "Vendors",
//                                 link: "/vendors"
//                             },
//                             {
//                                 icon: RequestQuote32,
//                                 title: "Journal Entries",
//                                 link: "/journal-entries"
//                             },
//                             ...(hasCapabilitySupport("CRV") ? [{
//                                 icon: Wallet32,
//                                 title: "Cash Receipt Voucher",
//                                 link: "/cash-receipt-vouchers"
//                             }] : []),
//                             {
//                                 icon: Finance32,
//                                 title: "Taxes",
//                                 link: "/taxes"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Reporting",
//                         menus: [
//                             {
//                                 icon: IncreaseLevel32,
//                                 title: "Receipts",
//                                 desc: "Analyze income",
//                                 link: "/receipts-report"
//                             },
//                             {
//                                 icon: SubtractAlt32,
//                                 title: "Payments",
//                                 desc: "Analyze expenses",
//                                 link: "/payments-report"
//                             },
//                             {
//                                 icon: Money32,
//                                 title: "Net Cash Flow",
//                                 desc: "Calculate profits",
//                                 link: "/net-cash-flow-report"
//                             },
//                             ...(hasCapabilitySupport("CRV") ? [{
//                                 icon: ReportData32,
//                                 title: "CRV Report",
//                                 link: "/crv-report"
//                             }] : []),
//                         ]
//                     }
//                 ]
//             },
//             {
//                 title: "Inventory",
//                 color: "yellow",
//                 subMenus: [
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: FlowStream32,
//                                 title: "Stock Flow",
//                                 desc: "View and initiate stock flow",
//                                 link: "/stock-flows"
//                             },
//                             {
//                                 icon: Delivery32,
//                                 title: "Suppliers",
//                                 desc: "View, add and edit suppliers",
//                                 link: "/suppliers"
//                             },
//                             ...(hasCapabilitySupport("thirdPartyPos") ? [{
//                                 icon: DeliveryParcel32,
//                                 title: "Stock Requests",
//                                 link: "/stock-requests"
//                             }] : []),
//                         ]
//                     },
//                     {
//                         title: "Reporting",
//                         menus: [
//                             {
//                                 icon: InventoryManagement32,
//                                 title: "Stock Report",
//                                 desc: "Analyze stock",
//                                 link: "/stock-report"
//                             },
//                             {
//                                 icon: Grid32,
//                                 title: "Product Stocks",
//                                 link: "/product-stock-report"
//                             },
//                             // {
//                             //     icon: ShoppingCartArrowUp32,
//                             //     title: "Sales Report",
//                             //     desc: "Analyze sales",
//                             //     link: "/sales-report"
//                             // },
//                             {
//                                 icon: ShoppingCartArrowDown32,
//                                 title: "Purchases Report",
//                                 link: "/purchases-report"
//                             },
//                             // {
//                             //     icon: Data_132,
//                             //     title: "Sales Summary",
//                             //     link: "/sales-summary-report"
//                             // },
//                             // {
//                             //     icon: DataShare32,
//                             //     title: "Product Performance",
//                             //     link: "/product-performance-report"
//                             // },
//                         ]
//                     }
//                 ]
//             },
//             {
//                 title: "Finance Management",
//                 color: "pink",
//                 subMenus: [
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: TreeView32,
//                                 title: "Account Tree",
//                                 link: "/account-tree"
//                             },
//                             {
//                                 icon: Api32,
//                                 title: "Account Ledger Groups",
//                                 link: "/account-ledger-groups"
//                             },
//                             {
//                                 icon: License32,
//                                 title: "Account Ledgers",
//                                 link: "/account-ledgers"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Statements",
//                         menus: [
//                             {
//                                 icon: Notebook32,
//                                 title: "Balance Sheet",
//                                 link: "/balance-sheet"
//                             },
//                             {
//                                 icon: Money32,
//                                 title: "Profit & Loss Statement",
//                                 link: "/profit-and-loss-statement"
//                             },
//                             {
//                                 icon: Table32,
//                                 title: "Trial Balance",
//                                 link: "/trial-balance"
//                             },
//                             // {
//                             //     icon: Course32,
//                             //     title: "Cash Book",
//                             // },
//                         ]
//                     },
//                     {
//                         title: "Reporting",
//                         menus: [
//                             {
//                                 icon: UserData32,
//                                 title: "Statement of Accounts",
//                                 link: "/statement-of-accounts"
//                             },
//                             {
//                                 icon: Classification32,
//                                 title: "Outstanding Report",
//                                 link: "/outstanding-report"
//                             },
//                             {
//                                 icon: Catalog32,
//                                 title: "General Ledger Report",
//                                 link: "/general-ledger-report"
//                             },
//                             {
//                                 icon: ListDropdown32,
//                                 title: "Subsidiary Ledger Report",
//                                 link: "/subsidiary-ledger-report"
//                             }
//                         ]
//                     }
//                 ]
//             },
//             ...((hasCapabilitySupport("realEstate")) ? [{
//                 title: "Real Estate",
//                 color: "purple2",
//                 subMenus: [
//                     {
//                         title: "Listing",
//                         menus: [
//                             {
//                                 icon: Industry32,
//                                 title: "Projects",
//                                 link: "/real-estate-project"
//                             },
//                             {
//                                 icon: Milestone32,
//                                 title: "Properties",
//                                 link: "/real-estate-property"
//                             },
//                         ]
//                     },
//                     ...(hasCapabilitySupport("onlineStore") ? [
//                         {
//                             title: "Website",
//                             menus: [
//                                 ...(hasCapabilitySupport("realEstateFeaturedOffPlans") ? [{
//                                     icon: StarFilled32,
//                                     title: "Featured Off Plans",
//                                     link: "/featured-off-plans"
//                                 }] : []),
//                                 ...(hasCapabilitySupport("realEstateFeaturedProperties") ? [{
//                                     icon: StarFilled32,
//                                     title: "Featured Properties",
//                                     link: "/featured-properties"
//                                 }] : []),
//                             ]
//                         }
//                     ] : []),
//                     {
//                         title: "Connections",
//                         menus: [
//                             {
//                                 icon: Product32,
//                                 title: "Developers",
//                                 link: "/real-estate-developer"
//                             },
//                             {
//                                 icon: TwoPersonLift32,
//                                 title: "Owners/Tenants",
//                                 link: "/real-estate-individual"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Classification",
//                         menus: [
//                             {
//                                 icon: Wikis32,
//                                 title: "Cities",
//                                 link: "/real-estate-city"
//                             },
//                             {
//                                 icon: Map32,
//                                 title: "Areas",
//                                 link: "/real-estate-area"
//                             },
//                             {
//                                 icon: Types32,
//                                 title: "Project Types",
//                                 link: "/real-estate-project-type"
//                             },
//                             {
//                                 icon: Types32,
//                                 title: "Property Types",
//                                 link: "/real-estate-property-type"
//                             },
//                         ]
//                     }
//                 ]
//             }] : []),
//             {
//                 title: "Products",
//                 color: "light-blue",
//                 subMenus: [
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: Product32,
//                                 title: "Products",
//                                 desc: "View, add and edit products",
//                                 link: "/products"
//                             },
//                             {
//                                 icon: Box32,
//                                 title: "Bundles",
//                                 desc: "View, add and edit product bundles",
//                                 link: "/product-bundles"
//                             },
//                             {
//                                 icon: Categories32,
//                                 title: "Product Groups",
//                                 link: "/product-groups"
//                             },
//                             ...(hasCapabilitySupport("continuousStockType") ? [{
//                                 icon: RulerAlt32,
//                                 title: "Measurement Types",
//                                 link: "/measurement-types"
//                             }] : []),
//                             {
//                                 icon: StringText32,
//                                 title: "Attribute Packs",
//                                 link: "/product-attribute-packs"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Classification",
//                         menus: [
//                             {
//                                 icon: PhraseSentiment32,
//                                 title: "Brands",
//                                 desc: "View, add and edit product brands",
//                                 link: "/product-brands"
//                             },
//                             {
//                                 icon: Network_132,
//                                 title: "Departments",
//                                 desc: "View, add and edit departments",
//                                 link: "/product-departments"
//                             },
//                             {
//                                 icon: Category32,
//                                 title: "Categories",
//                                 desc: "View, add and edit categories",
//                                 link: "/product-categories"
//                             },
//                             {
//                                 icon: CategoryNewEach32,
//                                 title: "Subcategories",
//                                 desc: "View, add and edit subcategories",
//                                 link: "/product-subcategories"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Labeling",
//                         menus: [
//                             {
//                                 icon: Barcode32,
//                                 title: "Product Labels",
//                                 desc: "Generate barcode and QR code labels",
//                                 link: "/product-labels"
//                             },
//                             {
//                                 icon: Barcode32,
//                                 title: "Bundle Labels",
//                                 desc: "Generate barcode and QR code labels",
//                                 link: "/bundle-labels"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Reporting",
//                         menus: [
//                             {
//                                 icon: DeliveryParcel32,
//                                 title: "Low Stock Products",
//                                 link: "/low-stock-product-report"
//                             }
//                         ]
//                     }
//                     // {
//                     //     title: "Reporting",
//                     //     menus: [
//                     //         {
//                     //             icon: Rocket32,
//                     //             title: "Performance",
//                     //             desc: "Compare product performance"
//                     //         },
//                     //     ]
//                     // }
//                 ]
//             },
//             {
//                 title: "Services",
//                 color: "blue",
//                 subMenus: [
//                     {
//                         title: "Sales",
//                         menus: [
//                             {
//                                 icon: GraphicalDataFlow32,
//                                 title: "Service Sales",
//                                 link: "/service-sales"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Management",
//                         menus: [
//                             {
//                                 icon: ServiceDesk32,
//                                 title: "Services",
//                                 link: "/services"
//                             },
//                             {
//                                 icon: Application32,
//                                 title: "Packages",
//                                 link: "/service-packages"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Classification",
//                         menus: [
//                             {
//                                 icon: Network_132,
//                                 title: "Departments",
//                                 link: "/service-departments"
//                             },
//                             {
//                                 icon: Category32,
//                                 title: "Categories",
//                                 link: "/service-categories"
//                             },
//                             {
//                                 icon: CategoryNewEach32,
//                                 title: "Subcategories",
//                                 link: "/service-subcategories"
//                             },
//                         ]
//                     },
//                 ]
//             },
//             ...((hasOnlineStore) ? [{
//                 title: "Online Store",
//                 color: "light-blue-2",
//                 subMenus: [
//                     // {
//                     //     title: "Management",
//                     //     menus: [
//                     //         {
//                     //             icon: LocationCompany32,
//                     //             title: "Deliverables/Rentals",
//                     //             link: "/404"
//                     //         }
//                     //     ]
//                     // },
//                     {
//                         title: "Content",
//                         menus: [
//                             {
//                                 icon: Home32,
//                                 title: "Home Page",
//                                 link: "/slides"
//                             },
//                             {
//                                 icon: AirlineManageGates32,
//                                 title: "Customer Testimonies",
//                                 link: "/customer-testimonies"
//                             },
//                         ]
//                     },
//                     {
//                         title: "CMS",
//                         menus: [
//                             // {
//                             //     icon: TextLink32,
//                             //     title: "Menus and Links",
//                             //     // link: "/online-store-custom-pages"
//                             // },
//                             {
//                                 icon: LicenseDraft32,
//                                 title: "Dynamic Content",
//                                 link: "/dynamic-content"
//                             },
//                             {
//                                 icon: Code32,
//                                 title: "Page Heads",
//                                 link: "/page-heads",
//                             }
//                         ]
//                     },
//                     {
//                         title: "Posting",
//                         menus: [
//                             {
//                                 icon: MediaLibrary32,
//                                 title: "Gallery Posts",
//                                 link: "/gallery-posts"
//                             },
//                             {
//                                 icon: Blog32,
//                                 title: "Blog Posts",
//                                 link: "/blog-posts"
//                             },
//                             {
//                                 icon: InsertPage32,
//                                 title: "Blog Categories",
//                                 link: "/blog-categories"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Custom Pages",
//                         menus: [
//                             {
//                                 icon: ViewFilled32,
//                                 title: "Public Pages",
//                                 link: "/online-store-custom-pages"
//                             },
//                             {
//                                 icon: Locked32,
//                                 title: "Private Pages",
//                                 link: "/private-online-store-custom-pages"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Definitions",
//                         menus: [
//                             {
//                                 icon: Purchase32,
//                                 title: "Payment Methods",
//                                 link: "/online-store-payment-methods"
//                             },
//                         ]
//                     }
//                 ]
//             }] : []),
//             {
//                 title: "CRM",
//                 color: "green",
//                 subMenus: [
//                     {
//                         title: "Customers",
//                         menus: [
//                             {
//                                 icon: AirlinePassengerCare32,
//                                 title: "Customers",
//                                 desc: "View, add and edit customers",
//                                 link: "/customers"
//                             },
//                             {
//                                 icon: Events32,
//                                 title: "Groups",
//                                 desc: "View, add and edit customer groups",
//                                 link: "/customer-groups"
//                             },
//                         ]
//                     },
//                     ...((hasCapabilitySupport("thirdPartyPos")) ? [{
//                         title: 'Relations',
//                         menus: [
//                             {
//                                 icon: Building32,
//                                 title: "Venues",
//                                 link: "/venues"
//                             },
//                         ]
//                     }] : []),
//                     {
//                         title: 'Communications',
//                         menus: [
//                             {
//                                 icon: Headset32,
//                                 title: "Contacts",
//                                 desc: "View, add and edit contact informations",
//                                 link: "/contacts"
//                             },
//                             ...(hasCapabilitySupport("onlineStore") ? [{
//                                 icon: Bullhorn32,
//                                 title: "Newsletter",
//                                 link: "/newsletter-subscriptions"
//                             }] : []),
//                         ]
//                     },
//                     {
//                         title: "Pricing",
//                         menus: [

//                             {
//                                 icon: Gift32,
//                                 title: "Loyalty",
//                                 desc: "View, add and edit loyalty cards",
//                                 link: "/loyalty-cards"
//                             },
//                             {
//                                 icon: CutOut32,
//                                 title: "Coupons",
//                                 desc: "View, add and edit coupons",
//                                 link: "/coupons"
//                             },
//                             {
//                                 icon: TagGroup32,
//                                 title: "Discounts",
//                                 desc: "View, add and edit store discount periods",
//                                 link: "/discounts"
//                             },
//                         ]
//                     }
//                 ]
//             },

//             /*
            
//         public static final int OBJECT_TYPE_LEAVE_TYPE = 49;
        
        
        
//         public static final int OBJECT_TYPE_LEAVE = 53;
//             */
//             {
//                 title: "HRM",
//                 color: "hr-color",
//                 subMenus: [
//                     {
//                         title: 'Employment',
//                         menus: [
//                             {
//                                 icon: User32,
//                                 title: "Employees",
//                                 link: "/employees"
//                             },
//                             {
//                                 icon: User32,
//                                 title: "Designations",
//                                 link: "/designations"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Payroll",
//                         menus: [
//                             {
//                                 icon: User32,
//                                 title: "Pay Heads",
//                                 link: "/pay-heads"
//                             },
//                             {
//                                 icon: User32,
//                                 title: "Salary Package",
//                                 link: "/salary-packages"
//                             },
//                         ]
//                     },
//                     {
//                         title: "Leaves",
//                         menus: [
//                             {
//                                 icon: User32,
//                                 title: "Leaves",
//                                 link: "/leaves"
//                             },
//                             {
//                                 icon: User32,
//                                 title: "Leave Types",
//                                 link: "/leave-types"
//                             },
//                         ]
//                     }
//                 ]
//             },
//             {
//                 title: "Company",
//                 color: "red",
//                 subMenus: [
//                     {
//                         menus: [
//                             {
//                                 icon: User32,
//                                 title: "Staff Users",
//                                 desc: "View, add and edit staff users",
//                                 link: "/staff-users"
//                             },
//                             {
//                                 icon: Store32,
//                                 title: "Stores",
//                                 desc: "View, add and edit stores",
//                                 link: "/stores"
//                             },
//                             {
//                                 icon: DataVis_232,
//                                 title: "Warehouses",
//                                 desc: "View, add and edit warehouses",
//                                 link: "/warehouses"
//                             },
//                             ...(hasCapabilitySupport("mobileUnit") ? [{
//                                 icon: Van32,
//                                 title: "Mobile Units",
//                                 link: "/mobile-units"
//                             }] : []),
//                             ...(hasCapabilitySupport("terminalPos") ? [{
//                                 icon: Screen32,
//                                 title: "Terminals",
//                                 desc: "View, add and edit terminals",
//                                 link: "/terminals"
//                             }] : []),
//                         ]
//                     },
//                 ]
//             },
//             {
//                 title: "System",
//                 color: "white",
//                 subMenus: [
//                     {
//                         menus: [
//                             {
//                                 icon: Gradient32,
//                                 title: "Templates",
//                                 link: "/templates"
//                             },
//                             {
//                                 icon: LicenseGlobal32,
//                                 title: "Supported Currencies",
//                                 link: "/supported-currencies"
//                             },
//                             {
//                                 icon: WatsonHealthTextAnnotationToggle32,
//                                 title: "System Values",
//                                 desc: "Set system values",
//                                 link: "/system-values"
//                             },
//                             // {
//                             //     icon: StringText32,
//                             //     title: "Attributes",
//                             //     desc: "Define custom system attributes"
//                             // },
//                             {
//                                 icon: DocumentImport32,
//                                 title: "Import",
//                                 desc: "Import spreadsheet data into system",
//                                 link: "/import-data"
//                             },
//                             {
//                                 icon: Archive32,
//                                 title: "Logs",
//                                 desc: "View system logs",
//                                 link: "/system-logs",
//                             },
//                             ...(hasCapabilitySupport("orderManagementSystem") ? [{
//                                 icon: App32,
//                                 title: "Delivery App",
//                                 link: "/delivery-app"
//                             }] : []),
//                             ...(hasCapabilitySupport("salesApp") ? [{
//                                 icon: App32,
//                                 title: "Sales App",
//                                 link: "/sales-app"
//                             }] : []),
//                             ...(hasCapabilitySupport("pocketPosApp") ? [{
//                                 icon: App32,
//                                 title: "Pocket POS App",
//                                 link: "/pocket-pos-app"
//                             }] : []),
//                             {
//                                 icon: Settings32,
//                                 title: "System Settings",
//                                 link: "/system-settings",
//                             },
//                         ]
//                     },
//                 ]
//             },
//         ];



//         if (accountRole == ACCOUNT_TYPE_SUPERVISOR) {
//             for (const menuGroup of menu) {
//                 for (const subMenu of menuGroup.subMenus) {
//                     subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/system-logs" && menuItem.link != "/system-settings" && menuItem.link != "/staff-users" && menuItem.link != "/supported-currencies" && menuItem.link != "/sales-app" && menuItem.link != "/delivery-app" && menuItem.link != "/pocket-pos-app" && menuItem.link != "/system-values");
//                 }
//             }
//         } else if (accountRole == ACCOUNT_TYPE_ACCOUNTANT) {
//             //menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finances" || menu.title == "Point of Sale"));
//             menu = menu.filter(menu => (menu.title == "Accounting" || menu.title == "Finance Management" || menu.title == "Point of Sale" || menu.title == "Activities"));
//         } else if (accountRole == ACCOUNT_TYPE_SALES) {
//             if (hasCapabilitySupport("salesSystem")) {
//                 menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Sales" || menu.title == "Activities"));

//                 for (const menuGroup of menu) {
//                     if (menuGroup.title == "Sales") {
//                         menuGroup.subMenus = menuGroup.subMenus.filter(subMenu => subMenu.title == "Management")
//                     }
//                 }
//             } else {
//                 menu = menu.filter(menu => (menu.title == "Portal" || menu.title == "Products" || menu.title == "Relations" || menu.title == "Activities"));
//             }

//             for (const menuGroup of menu) {
//                 for (const subMenu of menuGroup.subMenus) {
//                     subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link != "/monitoring");
//                 }

//                 if (menuGroup.title == "Relations") {
//                     for (const subMenu of menuGroup.subMenus) {
//                         subMenu.menus = subMenu.menus.filter(menuItem => menuItem.link == "/customers" || menuItem.link == "/customer-groups" || menuItem.link == "/contacts");
//                     }
//                 }
//             }
//         } else if (accountRole == ACCOUNT_TYPE_REAL_ESTATE_AGENT) {
//             menu = menu.filter(menu => (menu.title == "Real Estate" || menu.title == "CRM" || menu.title == "Activities"));

//             for (const menuGroup of menu) {
//                 if (menuGroup.title == "Activities") {
//                     continue;
//                 }

//                 for (const subMenu of menuGroup.subMenus) {
//                     subMenu.menus = subMenu.menus.filter(menuItem =>
//                         menuItem.link == "/real-estate-project" ||
//                         menuItem.link == "/real-estate-property" ||
//                         menuItem.link == "/customers" ||
//                         menuItem.link == "/contacts");
//                 }
//             }
//         }
//     }

//     return menu;
// }

