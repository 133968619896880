import { Checkbox, ComboBox, ContentSwitcher, FormGroup, Select, SelectItem, Switch, TextArea, TextInput, TooltipIcon } from "carbon-components-react";
import { useCallback, useEffect, useMemo, useState } from "react";
import useStore from "../../hooks/useStore";
import { makeObservable } from "../../util/makeObservable";
import Util from "../../util/Util";
import { Information16 } from '@carbon/icons-react'
import { StatelessDatePicker, StatelessDateTimePicker } from "../../components/DateTimePicker";
import TimePicker from "../../components/TimePicker";
import ReactSelect from 'react-select'

export const DisplayFormValueField = ({ title, children, style = {} }) => (
    <div style={{ marginBottom: '0.75rem', ...style }}>
        <label style={{ marginBottom: 0, opacity: 0.65, color: 'black' }} className="bx--label">{title}</label>
        <p style={{ fontSize: 16 }}>{children}</p>
    </div>
)

export const DisplayFormGroup = ({ title, titleMargin, children }) => (
    <div style={{ marginBottom: '3rem', }}>
        <h4 style={{ fontWeight: 'bold', marginBottom: titleMargin ? '1rem' : undefined }}>{title}</h4>

        {children}
    </div>
)

export function FormSection({ title, children, titleStyle = {} }) {
    return (
        <FormGroup legendText={<p style={{ fontWeight: 'bold', color: 'black', ...titleStyle }}>{title}</p>} hasMargin>
            {children}
        </FormGroup>
    )
}

export function FormSectionWithoutMargin({ title, children }) {
    return (
        <FormGroup legendText={<p style={{ fontWeight: 'bold', color: 'black', }}>{title}</p>} hasMargin={false}>
            {children}
        </FormGroup>
    )
}




const TextFormField = ({ store, title, fieldKey, config, help, placeholder }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, "")
    // if (fieldKey === "capacity") {
    //     console.log({ store: store.toObject() })
    // }
    return (
        <>
            <TextInput placeholder={placeholder} helperText={help} style={{ background: config?.forceGrayBg ? '#f4f4f4' : undefined }} light={config?.light} labelText={title} value={value} onChange={e => setValue(e.target.value)} />
        </>
    )
}

const TextAreaFormField = ({ store, title, fieldKey, config, rows = 4, style }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, "")
    return (
        <>
            <TextArea light={config?.light} rows={rows} style={style} labelText={title} value={value} onChange={e => setValue(e.target.value)} />
        </>
    )
}

const DateTimeFormField = ({ store, title, fieldKey, config }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, undefined)
    return (
        <>
            <StatelessDateTimePicker light={config?.light} labelText={title} date={value} setDate={setValue} />
        </>
    )
}

const DateFormField = ({ store, title, fieldKey, config, help }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, undefined)
    return (
        <>
            <StatelessDatePicker light={config?.light} labelText={title} date={value} setDate={setValue} helperText={help} />
        </>
    )
}

const TimeFormField = ({ store, title, fieldKey, config }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, undefined)
    return (
        <>
            <TimePicker light={config?.light} labelText={title} value={value} onChange={setValue} />
        </>
    )
}

const CheckBoxFormField = ({ store, title, fieldKey, config }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, false)
    return (
        <>
            <div onClick={() => setValue(v => !v)}>
                <Checkbox labelText={title} checked={value} />
            </div>
        </>
    )
}

const SwitchFormField = ({ store, title, help, options, fieldKey, config }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, options ? options[0].id : undefined)

    return (
        <div style={{ marginBottom: '1rem' }}>
            {help ? (
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem' }}>
                    <label style={{ marginBottom: 0, marginRight: '0.25rem' }} className="bx--label">{title}</label>
                    <TooltipIcon tooltipText={help} renderIcon={Information16} />
                </div>
            ) : (
                <label className="bx--label">{title}</label>
            )}
            <ContentSwitcher
                selectedIndex={Math.max(options.findIndex(option => option.id == value), 0)}
                onChange={({ name, index }) => setValue(name)}>
                {options.map(option => <Switch name={option.id} text={option.title ?? option.value} />)}
            </ContentSwitcher>
        </div>
    )
}

const ComboBoxFormField = ({ store, title, help, options, fieldKey, config, onChange }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, null)
    const [key, setKey] = useState(() => Util.newTempId())

    useEffect(() => {
        if (!value) {
            setKey(Util.newTempId())
        }

        onChange?.(value)
    }, [value])

    return (
        <div style={{ marginBottom: '1rem' }}>
            <ComboBox
                light={config?.light}
                key={key}
                titleText={title}
                items={options}
                helperText={help}
                itemToString={item => item !== null ? (item.title ?? item.value) : ""}
                selectedItem={options.find(option => option.id === value)}
                onChange={e => {
                    setValue(e.selectedItem ? e.selectedItem.id : null)
                    // if (e.selectedItem === null) {
                    //     setKey(Util.newTempId());
                    // }
                }} />
        </div>
    )
}

const DataListFormField = ({ store, title, help, options, fieldKey, config, placeholder, onValueSet, __data_list_height }) => {
    const light = config?.light;
    const [value, setValue] = useFormField(config, store, fieldKey, null)
    const mapper = useCallback(opt => opt ? ({ ...opt, value: opt.id, label: opt.label ?? opt.value }) : opt, [])

    return (<>
        {title && <label className="bx--label">{title}</label>}
        <ReactSelect
            placeholder={placeholder}
            value={mapper(options.find(option => option.id === value))}
            onChange={newVal => {
                setValue(newVal?.id)
                if (onValueSet) {
                    onValueSet(newVal?.id)
                }
            }}
            styles={{
                container: (baseStyles) => ({
                    ...baseStyles,
                    paddingRight: 1,
                    background: 'transparent',
                    height: '100%',
                }),
                valueContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '2.5rem',
                    padding: '0 6px'
                }),
                control: (baseStyles, state) => ({
                    ...baseStyles,
                    borderRadius: 0,
                    fontSize: '0.875rem',
                    fontWeight: 400,
                    lineHeight: 1.29,
                    letterSpacing: '0.16px',
                    outline: '2px solid transparent',
                    outlineOffset: '-2px',
                    width: '100%',
                    minHeight: '2.5rem',
                    padding: '0 0.5rem',
                    border: 'none',
                    borderBottom: '1px solid #8d8d8d',
                    //backgroundColor: ,
                    backgroundColor: light ? '#ffffff' : '#f4f4f4',
                    color: '#161616',
                    transition: 'background-color 70ms cubic-bezier(0.2, 0, 0.38, 0.9), outline 70ms cubic-bezier(0.2, 0, 0.38, 0.9)',

                    height: __data_list_height
                }),
                input: (baseStyles) => ({
                    ...baseStyles,
                    color: 'black',
                    height: '100%',
                    margin: 0
                }),
                indicatorsContainer: (baseStyles, state) => ({
                    ...baseStyles,
                    minHeight: '2.5rem',
                }),
                indicatorSeparator: state => ({
                    display: 'none',
                }),
            }}
            options={options.map(mapper)} />
    </>)
}

const SelectFormField = ({ store, title, help, options, fieldKey, config }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, null)
    const [key, setKey] = useState(() => Util.newTempId())

    useEffect(() => {
        if (!value) {
            setKey(Util.newTempId())
        }
    }, [value])

    return (
        <div style={{ marginBottom: '1rem' }}>
            <Select
                key={key}
                light={config?.light}
                labelText={title}
                helperText={help}

                value={value ?? -1}
                onChange={e => {
                    setValue(e.target.value !== -1 ? e.target.value : null);
                }}
            >
                <SelectItem value={-1} text={"Select"} />
                {options.map(option => <SelectItem value={option.id} text={option.title} />)}
            </Select>
        </div>
    )
}



const CustomFormField = ({ store, title, fieldKey, defValue, config, children, childrenProps = {} }) => {
    const [value, setValue] = useFormField(config, store, fieldKey, defValue)
    return (
        <>
            {title && <label className="bx--label">{title}</label>}
            {children({ value, setValue, config, ...childrenProps })}
        </>
    )
}

const ExtFormField = ({ store, title, config, children, childrenProps = {} }) => {
    return (
        <>
            {title && <label className="bx--label">{title}</label>}
            {children({ store, config, ...childrenProps })}
        </>
    )
}


const IfTargetGroup = ({ store, config, targetKey, checkTargetIf, children }) => {
    const [value] = useFormField(config, store, targetKey, false)
    if (value === true || (checkTargetIf && checkTargetIf(value))) {
        return children;
    } else {
        return null;
    }
}

const IfTwoTargetGroup = ({ store, config, targetKeyA, targetKeyB, checkTargetIf, children }) => {
    const [valueA] = useFormField(config, store, targetKeyA, false)
    const [valueB] = useFormField(config, store, targetKeyB, false)
    if ((valueA === true && valueB === true) || (checkTargetIf && checkTargetIf(valueA, valueB))) {
        return children;
    } else {
        return null;
    }
}

const NotIfTargetGroup = ({ store, config, targetKey, children }) => {
    const [value] = useFormField(config, store, targetKey, false)
    if (!value) {
        return children;
    } else {
        return null;
    }
}



export const useFormField = (config, store, fieldKey, defValue) => {
    const [value, setValue] = useStore(store, fieldKey);
    useEffect(() => {
        if (config?.skipFieldEffect) {
            return;
        }

        store.set(fieldKey, defValue)
        return () => store.set(fieldKey, undefined);
    }, [])
    return [value ?? defValue, setValue]
}

const makeFormField = (Field, props, store, config) => (
    <div style={{ marginBottom: props?._skipFormMargin ? 0 : '1rem' }}>
        <Field {...props} store={store} config={config} />
    </div>
)

export function useForm(observableSetter, config) {
    const [store, fields] = useMemo(() => {
        const observable = makeObservable();
        if (observableSetter) {
            observableSetter(observable)
        }
        const makeField = (Field, props) => makeFormField(Field, props, observable, config)
        return [observable, {
            TextField: (props) => makeField(TextFormField, props),
            TextAreaField: (props) => makeField(TextAreaFormField, props),
            CheckBoxField: (props) => makeField(CheckBoxFormField, props),
            SwitchField: (props) => makeField(SwitchFormField, props),
            ComboBoxField: (props) => makeField(config?.replaceComboBoxWithSelect ? SelectFormField : config?.replaceComboBoxWithDataList ? DataListFormField : ComboBoxFormField, props),
            CustomField: (props) => makeField(CustomFormField, props),
            ExtField: (props) => makeField(ExtFormField, props),

            DateTimeField: (props) => makeField(DateTimeFormField, props),
            DateField: (props) => makeField(DateFormField, props),
            TimeField: (props) => makeField(TimeFormField, props),

            IfTargetGroup: (props) => <IfTargetGroup {...props} store={store} config={config} />,
            NotIfTargetGroup: (props) => <NotIfTargetGroup {...props} store={store} config={config} />,

            IfTwoTargetGroup: (props) => <IfTwoTargetGroup {...props} store={store} config={config} />,
        }];
    }, [])
    return {
        TextField: fields.TextField,
        SwitchField: fields.SwitchField,
        TextAreaField: fields.TextAreaField,
        CheckBoxField: fields.CheckBoxField,
        ComboBoxField: fields.ComboBoxField,
        CustomField: fields.CustomField,
        ExtField: fields.ExtField,
        DateTimeField: fields.DateTimeField,
        DateField: fields.DateField,
        TimeField: fields.TimeField,
        IfTargetGroup: fields.IfTargetGroup,
        NotIfTargetGroup: fields.NotIfTargetGroup,
        IfTwoTargetGroup: fields.IfTwoTargetGroup,

        getFormData: () => store.toObject(),
        setData: data => {
            if (data === undefined) {
                store.clear();
                if (observableSetter) {
                    observableSetter(store)
                }
                return;
            }

            const currentData = store.toObject();
            const update = data;


            let newData = {}

            Object.keys(currentData)
                .forEach(key => newData[key] = currentData[key])
            Object.keys(update)
                .forEach(key => newData[key] = update[key])


            store.setObject(newData)

            // console.log(data, store.toObject())
        },
        store,

        compareData: (data) => {
            const currentData = store.toObject();
            console.log({ firstData: data, secondData: currentData })
        },

        setField: (key, value) => store.set(key, value),

        useChangeListener: (key, listener) => useEffect(() => store.subscribe(key, listener), [store])
    }
}