import Moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import isEqual from 'lodash.isequal';
import Big from 'big.js';
import { VER_NO } from '../app/Config';
import { TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_DIRECTION_TYPE_OUTWARD } from '../constants/Constants';
import { hasCapabilitySupport } from '../app/Capabilities';

export const DAY_MS = 86400000;
export const handleEndDate = epochTimestamp => {
    const date = new Date(epochTimestamp);
    date.setHours(23, 59, 59, 999);

    const endOfDayTimestamp = date.getTime();
    return endOfDayTimestamp + 1
}

export const isV2 = () => {
    // return false;
    // return window.location.hostname === "localhost";
    return VER_NO === "v2";
}

export function min(a, b) {
    return a.gt(b) ? b : a;
}

export function max(a, b) {
    return a.gt(b) ? a : b;
}

class Util {

    makeid(length) {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }


    isDebug() {
        return window.location.hostname === "localhost" || window.location.hostname === "192.168.0.50";
    }

    isDebug2() {
        return window.location.hostname === "192.168.0.50";
    }

    formatDate(dateMilli, format) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }

        return Moment(new Date(dateMilli)).format(format);
        // return Moment(new Date(dateMilli)).format('DD MMMM YYYY HH:mm')
    }

    getDate(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }

        let moment = Moment(new Date(dateMilli));

        let diffDay = Moment(new Date()).diff(moment, 'days');
        // console.log({ diffDay })
        //if (diffDay >= 1) {
        if (diffDay != 0) {
            return moment.format('DD MMMM YYYY');
        } else {
            return moment.format('HH:mm');
        }
    }

    getStatementDate(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('MMMM DD YYYY');
    }

    getFullDate(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('DD MMMM YYYY HH:mm');
    }

    formatMoney(num, dec = 2) {
        if (typeof num === "object" && num?.__override_format_money_fn !== undefined) {
            return num.__override_format_money_fn;
        }

        if (!num) {
            num = 0;
        }
        return parseFloat(parseFloat(num).toFixed(dec)).toLocaleString("en-US", { minimumFractionDigits: dec });
    }

    formatMoneyWithNeg(num) {
        if (!num) {
            num = 0;
        }
        let amt = parseFloat(parseFloat(num).toFixed(2)).toLocaleString("en-US", { minimumFractionDigits: 2 });
        if (amt.startsWith("-")) {
            amt = "(" + amt.replace("-", "") + ")"
        }
        return amt;
    }

    formatAmount(num) {
        if (!num) {
            num = 0;
        }
        return parseFloat(parseFloat(num).toFixed(4)).toLocaleString("en-US", { minimumFractionDigits: 2 });
    }

    formatAmountWithNoDigits(num) {
        if (!num) {
            num = 0;
        }
        return parseFloat(parseFloat(num).toFixed(4)).toLocaleString("en-US", { minimumFractionDigits: 0 });
    }

    /**
     * 
     * @param {number} milli1 
     * @param {number} milli2 
     * @returns {number | string}
     */
    daysBetweenDates(milli1, milli2) {
        if (milli1 <= 0 || milli2 <= 0) {
            return "-";
        }

        const date1 = new Date(milli1);
        const date2 = new Date(milli2);
        // Convert both dates to UTC to avoid timezone issues
        const utcDate1 = Date.UTC(date1.getFullYear(), date1.getMonth(), date1.getDate());
        const utcDate2 = Date.UTC(date2.getFullYear(), date2.getMonth(), date2.getDate());

        // Calculate the difference in milliseconds
        const millisecondsPerDay = 24 * 60 * 60 * 1000;
        const timeDifference = utcDate2 - utcDate1;

        // Calculate the number of days
        const days = Math.floor(timeDifference / millisecondsPerDay);

        return days;
    }

    milliToDays(milliseconds) {
        // 1 day = 24 hours, 1 hour = 60 minutes, 1 minute = 60 seconds, 1 second = 1000 milliseconds
        const oneDayInMilliseconds = 24 * 60 * 60 * 1000;

        // Calculate the number of days
        const days = milliseconds / oneDayInMilliseconds;

        return days;
    }

    getDateOnlyWithoutYear(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('DD MMMM');
    }

    getDateOnly(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('DD MMMM YYYY');
    }

    getTime(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('HH:mm');
    }

    getDay(dateMilli) {
        if (!dateMilli || dateMilli == 0) {
            return "-";
        }
        return Moment(new Date(dateMilli)).format('dddd');
    }

    formatDuration(duration) {
        const hours = Math.floor(duration / (1000 * 60 * 60));
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}`;
    }

    formatDurationWithSeconds(duration) {
        const hours = Math.floor(duration / (1000 * 60 * 60));
        const minutes = Math.floor((duration % (1000 * 60 * 60)) / (1000 * 60));
        const seconds = Math.floor((duration % (1000 * 60)) / 1000);

        const formattedHours = String(hours).padStart(2, '0');
        const formattedMinutes = String(minutes).padStart(2, '0');
        const formattedSeconds = String(seconds).padStart(2, '0');

        return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
    }

    getCurrentTimeAndDay() {
        return Moment(new Date()).format('dddd hh:mm A');
    }

    isStringExists(string) {
        return string !== undefined && string !== null && string.trim !== undefined && string.trim() !== "";
    }

    isNumberExist(number) {
        return number !== undefined && number !== null && !isNaN(number) && number > 0;
    }

    isANumber(value) {
        return value !== undefined && value !== null && value.length > 0 && value.trim().length > 0 && !isNaN(value) && !isNaN(parseFloat(value));
    }

    newTempId() {
        return uuidv4()
    }

    arrayEqual(a, b) {
        if (a.length != b.length) {
            return false;
        }

        for (let i = 0; i < a.length; i++) {
            if (!isEqual(a[i], b[i])) {
                return false;
            }
        }

        return true;
    }

    formatSeconds(duration) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        // Output like "1:01" or "4:03:59" or "123:03:59"
        let ret = "";

        if (hrs > 0) {
            ret += "" + hrs + ":" + (mins < 10 ? "0" : "");
        }

        ret += "" + mins + ":" + (secs < 10 ? "0" : "");
        ret += "" + secs;
        return ret;
    }

    formatSecondsPretty(duration) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        if (hrs > 0) {
            return hrs + " hour" + (hrs > 1 ? "s" : "");
        } else if (mins > 0) {
            return mins + " minute" + (mins > 1 ? "s" : "");
        } else if (secs > 0) {
            return secs + " second" + (secs > 1 ? "s" : "");
        } else {
            return "<1 second";
        }
    }

    formatSecondsPrettySmall(duration) {
        // Hours, minutes and seconds
        const hrs = ~~(duration / 3600);
        const mins = ~~((duration % 3600) / 60);
        const secs = ~~duration % 60;

        if (hrs > 0) {
            return hrs + "h"
        } else if (mins > 0) {
            return mins + "m";
        } else if (secs > 0) {
            return secs + "s";
        } else {
            return "<1 second";
        }
    }

    getTransactionVoucherNumber(id, dirType, sequenceNo) {
        if (!hasCapabilitySupport("shipping")) {
            return this.getVoucherNumber(id);
        }

        let prefix = (() => {
            switch (dirType) {
                case TRANSACTION_DIRECTION_TYPE_INWARD: return "RV";
                case TRANSACTION_DIRECTION_TYPE_OUTWARD: return "PV";
                default: return "TR";
            }
        })()

        //return `${prefix}-${this.getVoucherNumber(id)}`
        return `${prefix}-${this.getVoucherNumber(sequenceNo)}`
    }

    /**
     * @param {string} id 
     */
    getIdFromTransactionVoucherNumber(voucherNo) {
        const parsable = voucherNo.replace("RV", "").replace("PV", "").replace("TR", "").replace("-", "");
        return this.getIdFromVoucherNumber(parseInt(parsable))
    }



    getJournalEntryVoucherNumber(id, sequenceNo) {
        if (!hasCapabilitySupport("shipping")) {
            return this.getVoucherNumber(id);
        }

        return `JV-${this.getVoucherNumber(sequenceNo)}`
    }

    /**
     * @param {string} id 
     */
    getIdFromJournalEntryVoucherNumber(voucherNo) {
        const parsable = voucherNo.replace("JV", "").replace("-", "");
        return this.getIdFromVoucherNumber(parseInt(parsable))
    }


    getVoucherNumber(id) {
        if (id > 0) {
            return id + 10000;
        } else {
            return 0;
        }
    }

    getIdFromVoucherNumber(id) {
        if (id > 0) {
            return id - 10000;
        } else {
            return 0;
        }
    }

    limitString(string) {
        return string.substring(0, 200) + (string.length > 200 ? '...' : '')
    }

    /**
     * 
     * @param {string} paragraph
     * @param {number} width 
     * @returns {string}
     */
    limitParagraphWidth(paragraph, width) {
        /**
         * @param {string} line 
         */
        const limitLineWidth = (line) => {
            if (line.length > width) {
                const fittingPart = line.substring(0, width);
                const restPart = limitLineWidth(line.substring(width));

                return [fittingPart, ...restPart];
            } else {
                return [line]; // already <= width
            }
        }

        return paragraph
            .split("\n")
            .flatMap(limitLineWidth)
            .join("\n");
    }


}

export function flattenItems(xs) {
    return (JSON.parse(JSON.stringify(xs))).reduce((acc, x) => {
        acc = acc.concat(x);
        if (x.items) {
            acc = acc.concat(flattenItems(x.items));
            x.items = [];
        }
        return acc;
    }, []);
}

export function flattenItems2(xs) {
    return (JSON.parse(JSON.stringify(xs))).reduce((acc, x) => {
        acc = acc.concat(x);
        if (x.items) {
            acc = acc.concat(flattenItems(x.__f ? [] : x.items));
            x.__fd = true;
        }
        return acc;
    }, []);
}

export function bigEq(a, b) {
    // console.log({
    //     a: big(a.toFixed(4)).eq(big(b.toFixed(4))),
    //     b: a.toFixed(4),
    //     c: b.toFixed(4)
    // })
    return big(a.toFixed(4)).eq(big(b.toFixed(4)))
}

export function big(amount) {
    if (amount === undefined || amount === null || isNaN(amount) || (amount.trim && amount.trim() === '')) {
        amount = 0;
    }

    if (typeof amount === "string") {
        amount = amount.trim();
    }

    try {
        return Big(amount);
    } catch (e) {
        console.error(e);
        return Big(0);
    }
}

export function absAmt(amount) {
    if (amount === undefined || amount === null || isNaN(amount) || (amount.trim && amount.trim() === '')) {
        return amount;
    }

    if (typeof amount === "string") {
        return amount.startsWith("-") ? big(amount).abs() : amount;
    } else {
        return amount;
    }
    // console.log
    // return big(amount).abs();
    // return big(amount);
}

export function sliceIntoChunks(arr, chunkSize) {
    const res = [];
    for (let i = 0; i < arr.length; i += chunkSize) {
        const chunk = arr.slice(i, i + chunkSize);
        res.push(chunk);
    }
    return res;
}

export function onEnterKeyPressed(func) {
    return {
        onKeyDown: (e) => {
            if (e.key === "Enter") {
                func();
            }
        },
    };
}

export const sleep = ms => new Promise(r => setTimeout(r, ms));

export function sendWhatsappMessage(number, msg) {
    if (msg) {
        return 'https://wa.me/' + number + "?text=" + encodeURIComponent(msg)
    } else {
        return 'https://wa.me/' + number
    }
}

// export function getVideoLengthPromise(media) {
//     return new Promise((resolve, reject) => {
//         if (!media) {
//             resolve(0);
//             return;
//         }

//         // Create a new video element
//         const video = document.createElement("video");

//         // Set the source of the video element to the selected file
//         video.src = URL.createObjectURL(media);

//         // Add an event listener to wait for the video metadata to load
//         video.addEventListener("loadedmetadata", function () {
//             // Access the video duration
//             var duration = video.duration;

//             // Clean up by revoking the object URL
//             URL.revokeObjectURL(video.src);

//             resolve(duration);
//         });

//         // Load the video
//         video.load();
//     });
// }

// export function getVideoLength(media) {
//     if (!media) {
//         return 0
//     }

//     // Create a new video element
//     const video = document.createElement("video");

//     // Set the source of the video element to the selected file
//     video.src = URL.createObjectURL(media);

//     // Add an event listener to wait for the video metadata to load
//     const loadedMetadata = false;
//     video.addEventListener("loadedmetadata", function () {
//         loadedMetadata = true;
//     });

//     // Load the video
//     video.load();

//     // Wait for the video metadata using a while loop
//     const startTime = new Date();
//     while (!loadedMetadata) {
//         const currentTime = new Date();
//         if ((currentTime - startTime) > 5000) {
//             // If it takes more than 5 seconds, return 0
//             URL.revokeObjectURL(video.src);
//             return 0;
//         }
//     }

//     // Access the video duration
//     const duration = video.duration;

//     // Clean up by revoking the object URL
//     URL.revokeObjectURL(video.src);

//     return duration;
// }


export default new Util();

