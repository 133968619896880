import React from 'react'
import Page from '../../../base/Page';
import Api from '../../../session/Api';
import UIUtil from '../../../util/UIUtil';
import Util, { DAY_MS } from '../../../util/Util';

import {
    ReportData16,
    ArrowLeft16,
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Calendar16,
} from '@carbon/icons-react'
import { Checkbox, DatePicker, DatePickerInput, Link } from 'carbon-components-react';
import Button from '../../../components/Button';
import { ACCOUNTING_STATEMENT_BALANCE_SHEET, ACCOUNTING_STATEMENT_INCOME_SHEET, ACCOUNTING_STATEMENT_TRIAL_BALANCE } from '../../../constants/Constants';
import BalanceSheet from '../../../views/accounting/BalanceSheet';
import IncomeSheet from '../../../views/accounting/IncomeSheet';
import TrialBalance from '../../../views/accounting/TrialBalance';


import { subDays, addDays } from 'date-fns';
//import { DateRangePicker, Calendar } from 'react-date-range';
// import { Calendar } from 'react-date-range';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import DateRangePicker from '../../../components/date/DateRangePicker';
import Calendar from '../../../components/date/Calendar';
// import { Calendar } from 'react-date-range';



const Section = ({ children, icon, title, extraTopMargin }) => (
    <div style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            {React.createElement(icon)}
            <p>{title}</p>

        </div>
        {children}
    </div>
)

class StatementStatementPage extends Page {

    constructor(props) {
        super(props)

        this.state = {
            ...this.state,

            generatingStatement: false,
            generatedStatement: false,

            statement: undefined,

            dateValue: new Date().getTime(),
            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),

            hideZeroBalance: false
        }
    }

    isOneDay() {
        switch (this.getType()) {
            case ACCOUNTING_STATEMENT_BALANCE_SHEET:
                return true;
            case ACCOUNTING_STATEMENT_INCOME_SHEET:
                return false;
            case ACCOUNTING_STATEMENT_TRIAL_BALANCE:
                return true;
        }
    }

    getType() {
        return this.props.statementType;
    }

    getTitle() {
        switch (this.getType()) {
            case ACCOUNTING_STATEMENT_BALANCE_SHEET:
                return "Balance Sheet";
            case ACCOUNTING_STATEMENT_INCOME_SHEET:
                return "Profit & Loss Statement"
            case ACCOUNTING_STATEMENT_TRIAL_BALANCE:
                return "Trial Balance";
        }
    }

    getStatementView() {
        switch (this.getType()) {
            case ACCOUNTING_STATEMENT_BALANCE_SHEET:
                return BalanceSheet;
            case ACCOUNTING_STATEMENT_INCOME_SHEET:
                return IncomeSheet;
            case ACCOUNTING_STATEMENT_TRIAL_BALANCE:
                return TrialBalance;
        }
    }

    clearStatement() {
        this.setState({
            generatingStatement: false,
            generatedStatement: false,

            dateValue: new Date().getTime(),
            startDateValue: subDays(new Date(), 1).getTime(),
            endDateValue: new Date().getTime(),
        })
    }

    canGenerateStatement() {
        if (this.isOneDay()) {
            return Util.isNumberExist(this.state.dateValue);
        } else {
            return Util.isNumberExist(this.state.startDateValue) && Util.isNumberExist(this.state.endDateValue);
        }
    }

    generateStatementBtn() {
        this.setState({ generatingStatement: true })
        const listener = response => {
            if (response.status === true) {
                this.setState({ generatingStatement: false, statement: response.payload, generatedStatement: true, })
                UIUtil.showSuccess();
            } else {
                this.setState({ generatingStatement: false, generatedStatement: false })
                UIUtil.showError(response.message);
            }
        }

        if (this.isOneDay()) {
            Api.getSingleDayStatement(this.getType(), this.state.dateValue, listener);
        } else {
            Api.getRangeStatement(this.getType(), this.state.startDateValue, this.state.endDateValue, listener);
        }
    }

    onPageStart() {

    }

    renderRangePicker() {
        return (
            <DateRangePicker
                //onChange={item => setState([item.selection])}
                onChange={item => this.setState({ startDateValue: item.selection.startDate.getTime(), endDateValue: item.selection.endDate.getTime() })}
                showSelectionPreview={true}
                moveRangeOnFirstSelection={false}
                months={2}
                ranges={[{
                    startDate: new Date(this.state.startDateValue),
                    endDate: new Date(this.state.endDateValue),
                    key: 'selection'
                }]}
                direction="horizontal"
            />
            // <DatePicker datePickerType={"range"}
            // value={[this.state.startDateValue, this.state.endDateValue]}
            // onChange={e => {
            //     if (e.length > 1) {
            //         this.setState({
            //             startDateValue: e[0].getTime(),
            //             endDateValue: e[1].getTime()
            //         })
            //     } else if (e.length == 1) {
            //         this.setState({
            //             startDateValue: e[0].getTime(),
            //         })
            //     } else {
            //         this.setState({
            //             startDateValue: 0,
            //             endDateValue: 0
            //         })
            //     }
            // }}
            // >
            //     <DatePickerInput
            //         style={{flex: 1}}
            //         placeholder="mm/dd/yyyy"
            //         labelText={"Start date"}
            //     />
            //     <DatePickerInput
            //         placeholder="mm/dd/yyyy"
            //         labelText="End date"
            //     />
            // </DatePicker>
        )
    }

    renderDatePicker() {
        return (
            <Calendar onChange={date => this.setState({ dateValue: date.getTime() })} date={new Date(this.state.dateValue)} />
            // <DatePicker datePickerType={"single"}
            // value={[this.state.dateValue]}
            // onChange={e => {
            //     if (e.length > 0) {
            //         this.setState({
            //             dateValue: e[0].getTime(),
            //         })
            //     } else {
            //         this.setState({
            //             dateValue: 0,
            //         })
            //     }
            // }}
            // >
            //     <DatePickerInput
            //         style={{flex: 1}}
            //         placeholder="mm/dd/yyyy"
            //         labelText={"Date"}
            //     />
            // </DatePicker>
        )
    }

    renderInput() {
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '6rem', paddingBottom: '6rem' }}>
                <div style={{ width: '75vw' }}>
                    <h1>{this.getTitle()}</h1>
                    <p style={{ fontSize: 18 }}>Statement</p>

                    <Section icon={Number_132} title={this.isOneDay() ? "Select Date" : "Select Range"}>
                        {this.isOneDay() ? this.renderDatePicker() : this.renderRangePicker()}
                    </Section>

                    <Section icon={Number_232} title="Filtering">
                        <div onClick={() => this.setState(prevState => ({ hideZeroBalance: !prevState.hideZeroBalance }))}>
                            <Checkbox labelText="Hide accounts with a zero balance" checked={this.state.hideZeroBalance} />
                        </div>
                    </Section>

                    <Section icon={Number_332} title="Statement" extraTopMargin={false}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            <Button onClick={this.generateStatementBtn.bind(this)} disabled={!this.canGenerateStatement()} loading={this.state.generatingStatement} renderIcon={ReportData16}>Generate Statement</Button>
                        </div>
                    </Section>
                </div>
            </div>
        )
    }

    renderStatement() {
        return (
            <div className="main-content">
                <div onClick={this.clearStatement.bind(this)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', marginBottom: '1rem' }}>
                    <Link><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Generate another statement</Link>
                </div>
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center', }}>
                    <div style={{ width: '75%' }}>
                        {React.createElement(this.getStatementView(), {
                            statement: this.state.statement,
                            hideZeroBalance: this.state.hideZeroBalance
                        })}
                    </div>
                </div>
            </div>
        )
    }

    getLayout() {
        return this.state.generatedStatement ? this.renderStatement() : this.renderInput()
    }

    isPageLoadable() {
        return false;
    }

}

export default StatementStatementPage;