
import { Barcode24, Launch16, Barcode16, Erase16 } from '@carbon/icons-react'
import { TextInput } from 'carbon-components-react'
import React, { createRef, useEffect, useLayoutEffect, useRef, useState } from 'react'
import Button from '../../../components/Button'
import useSize from '../../../util/useSize'
import Modal from 'react-modal';
import Util from '../../../util/Util'
import ReactTooltip from 'react-tooltip';

const NumPadBtn = ({num, expanded, loading, onClick}) => (
    <Button loading={loading} style={{flex: expanded ? 2 : 1, justifyContent: 'center', alignItems: 'center', maxWidth: 'none', padding: 0, fontSize: 18}} onClick={onClick}>
        {num}
    </Button>
)

const HEIGHT_MIN = 175;
const HEIGHT_MID = 275;

const OPTIMAL_WIDTH = 338;
const OPTIMAL_HEIGHT = 387;

const Content = ({state, size, barcodeValue, setBarcodeValue, onSearchBarcode, mini}) => {
    return (<>
        {size.height >= HEIGHT_MID && <div style={{display: 'flex', alignItems: 'center'}}>
            <Barcode24 style={{marginRight: 5}} />
            <h4>Barcode</h4>
        </div>}
        <div style={{display: 'flex', width: '100%', marginTop: !mini ? '1rem' : 0}}>
            {(mini && size.height < HEIGHT_MIN) && <Button onClick={mini} hasIconOnly data-tip="Open" className="bx--tooltip--hidden" renderIcon={Barcode16} style={{height: 48}} />}
            <div style={{flex: 1}}>
                <TextInput 
                className="pos-barcode-input"
                disabled={state.isLoadingCartUpdate()}
                value={barcodeValue} 
                onChange={e => setBarcodeValue(e.target.value)} 
                onPaste={e => onSearchBarcode((e.clipboardData || window.clipboardData).getData('text'))}
                onKeyPress={e => {
                    if (e.key == "Enter") {
                        onSearchBarcode();
                    }
                }}
                light style={{borderBottom: 'none', //outline: '2px solid #0f62fe', 
                height: 48}} />
            </div>
            <Button disabled={barcodeValue.length == 0} onClick={() => setBarcodeValue(barcodeValue.substring(0, barcodeValue.length - 1))} hasIconOnly kind="danger" data-tip="Backspace" className="bx--tooltip--hidden" renderIcon={Erase16} style={{height: 48}} />
        </div>

        {size.height >= HEIGHT_MID ? (
            <div style={{marginTop: 0, display: 'flex', gap: 1, flexDirection: 'column', flex: 1}}>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="1" onClick={() => setBarcodeValue(barcodeValue + "1")} />
                    <NumPadBtn num="2" onClick={() => setBarcodeValue(barcodeValue + "2")} />
                    <NumPadBtn num="3" onClick={() => setBarcodeValue(barcodeValue + "3")} />
                </div>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="4" onClick={() => setBarcodeValue(barcodeValue + "4")} />
                    <NumPadBtn num="5" onClick={() => setBarcodeValue(barcodeValue + "5")} />
                    <NumPadBtn num="6" onClick={() => setBarcodeValue(barcodeValue + "6")} />
                </div>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="7" onClick={() => setBarcodeValue(barcodeValue + "7")} />
                    <NumPadBtn num="8" onClick={() => setBarcodeValue(barcodeValue + "8")} />
                    <NumPadBtn num="9" onClick={() => setBarcodeValue(barcodeValue + "9")} />
                </div>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="0" expanded onClick={() => setBarcodeValue(barcodeValue + "0")} />
                    <NumPadBtn loading={state.isLoadingCartUpdate()} num="=" onClick={() => onSearchBarcode()} />
                </div>
            </div>
        ) : size.height >= HEIGHT_MIN ? (
            <div style={{marginTop: 0, display: 'flex', gap: 1, flexDirection: 'column', flex: 1}}>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="1" onClick={() => setBarcodeValue(barcodeValue + "1")} />
                    <NumPadBtn num="2" onClick={() => setBarcodeValue(barcodeValue + "2")} />
                    <NumPadBtn num="3" onClick={() => setBarcodeValue(barcodeValue + "3")} />
                    <NumPadBtn num="4" onClick={() => setBarcodeValue(barcodeValue + "4")} />
                    <NumPadBtn num="5" onClick={() => setBarcodeValue(barcodeValue + "5")} />
                    <NumPadBtn num="6" onClick={() => setBarcodeValue(barcodeValue + "6")} />
                </div>
                <div style={{flex: 1, display: 'flex', gap: 1}}>
                    <NumPadBtn num="7" onClick={() => setBarcodeValue(barcodeValue + "7")} />
                    <NumPadBtn num="8" onClick={() => setBarcodeValue(barcodeValue + "8")} />
                    <NumPadBtn num="9" onClick={() => setBarcodeValue(barcodeValue + "9")} />
                    <NumPadBtn num="0" onClick={() => setBarcodeValue(barcodeValue + "0")} />
                    <NumPadBtn expanded loading={state.isLoadingCartUpdate()} num="=" onClick={() => onSearchBarcode()} />
                </div>
            </div>
        ) : (
            null
        )}
    </>)
}

export default ({state}) => {
    const [barcodeValue, setBarcodeValue] = useState("")
    
    const onSearchBarcode = (forcedValue) => {
        state.scanBarcode(forcedValue !== undefined ? forcedValue : barcodeValue);
        setBarcodeValue("")
    }




    const targetRef = useRef();
    const size = useSize(targetRef)
    const content = <Content state={state} size={size} barcodeValue={barcodeValue} setBarcodeValue={setBarcodeValue} onSearchBarcode={onSearchBarcode} />

    const [contentOpen, setContentOpen] = useState(false)

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    return (
        <div ref={targetRef} className="barcode-widget dashboard-card" style={{width: '100%', height: '100%', background: '#f4f4f4', padding: '1rem', overflow: 'hidden', display: 'flex', flexDirection: 'column'}}>
            {size.height > HEIGHT_MIN ? (
                content
            ) : (<>
                {React.cloneElement(content, { mini: () => setContentOpen(true) })}

                <Modal
                    isOpen={contentOpen}
                    onRequestClose={() => setContentOpen(false)}
                    style={{
                        overlay: { zIndex: 8999, background: 'rgba(0,0,0,0.75)' },
                        content: { 
                            height: OPTIMAL_HEIGHT, width: OPTIMAL_WIDTH, background: 'rgb(244, 244, 244)', border: 'none',
                            top: '50%',
                            left: '50%',
                            right: 'auto',
                            bottom: 'auto',
                            transform: 'translate(-50%, -50%)',
                            display: 'flex', flexDirection: 'column', borderRadius: 0
                        }
                    }}
                >
                    {React.cloneElement(content, { size: { height: 10000, width: 10000 } })}
                </Modal>
            </>)}

            
        </div>
    )
}