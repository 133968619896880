import { RowDelete32, Maximize32, Barcode16, Error16, Tag32 } from '@carbon/icons-react';
import { ComboBox, ComposedModal, ModalBody, ModalFooter, ModalHeader, TextInput } from 'carbon-components-react';
import React, { createRef, useEffect, useState } from 'react'
import { ALL_BARCODE_TYPES, BARCODE_TYPE_CODE128 } from '../constants/BarcodeConstants';
import Button from './Button';
import MultipleInputEditor from './MultipleInputEditor'

import JsBarcode from 'jsbarcode'
import Util from '../util/Util';
import UIUtil from '../util/UIUtil';
import { printProductLabels } from '../pdfs/product-labels/ProductLabelsPdf';
import { getAccountRole } from '../session/SessionManager';
import { ACCOUNT_TYPE_ADMINISTRATION } from '../constants/Constants';
import LabelPrintViewDialog from '../pages/label-print/LabelPrintViewDialog';

const BarcodeRecord = ({ showTemplateLabelPrint, input, nonExistent, onInputUpdate, onInputRemoved, readOnly }) => {
    if (readOnly) {
        nonExistent = false;
    }

    const [value, setValue] = useState(input.value);
    const [type, setType] = useState(input.type);
    const [isInvalidValue, setIsInvalidValue] = useState(false);

    const [isEnlargedBarcode, setIsEnlargedBarcode] = useState(false);
    const [openTemplatePrintDialog, setOpenTemplatePrintDialog] = useState(false);

    const barCodeDisplay = createRef();
    const enlargedBarCodeDisplay = createRef();

    useEffect(() => {
        try {
            JsBarcode(barCodeDisplay.current, value, {
                format: type
            })
            setIsInvalidValue(false);
        } catch (e) {
            setIsInvalidValue(true);
        }
    }, [value, type])

    useEffect(() => {
        if (isEnlargedBarcode) {
            try {
                JsBarcode(enlargedBarCodeDisplay.current, value, {
                    format: type
                })
            } catch (e) {
                UIUtil.showError();
                setIsEnlargedBarcode(false);
            }
        }
    }, [isEnlargedBarcode])

    return (
        <div style={{ display: 'flex', width: '100%' }}>
            <TextInput
                size="lg"
                labelText={"Value"} value={value} onChange={e => {
                    if (!input.systemGenerated) {
                        onInputUpdate('value', e.target.value);
                        setValue(e.target.value);
                    }
                }} />
            <div style={{ width: 5 }} />

            {input.systemGenerated ? <div style={{ width: 150, height: 48, alignSelf: 'flex-end', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '0rem' }}>
                <h4 style={{ fontSize: 14 }}>System Generated</h4>
                <p style={{ fontSize: 12, opacity: 0.65 }}>{input.type}</p>
            </div> : <ComboBox
                titleText="Type"
                size="lg"
                disabled={input.systemGenerated}
                style={{ width: 150, }}
                items={ALL_BARCODE_TYPES}
                selectedItem={type}
                onChange={item => {
                    if (!input.systemGenerated) {
                        onInputUpdate('type', item.selectedItem)
                        setType(item.selectedItem)
                    }
                }}
            />}

            <div style={{ width: 5 }} />

            <div style={{ width: 262, height: 48, position: 'relative', alignSelf: 'flex-end', }}>
                <canvas ref={barCodeDisplay}
                    download="barcode.png"
                    style={{ position: 'absolute', left: 0, top: 0, width: 262, height: 48, objectFit: 'contain', objectPosition: 'center', opacity: Util.isStringExists(value) && !isInvalidValue ? 1 : 0, pointerEvents: !(Util.isStringExists(value) && !isInvalidValue) ? 'none' : undefined }} />

                {Util.isStringExists(value) ? (
                    isInvalidValue && (
                        <div style={{ width: 262, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'red' }}>
                            <Error16 style={{ marginRight: 5, opacity: 0.65 }} />
                            <p style={{ fontSize: 12, opacity: 1, }}>Invalid value and type</p>
                        </div>
                    )
                ) : (
                    <div style={{ width: 262, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        <Barcode16 style={{ marginRight: 5, opacity: 0.65 }} />
                        <p style={{ fontSize: 12, opacity: 1, }}>No value set</p>
                    </div>
                )}
            </div>


            {(input.systemGenerated || !(getAccountRole() == ACCOUNT_TYPE_ADMINISTRATION || !Util.isNumberExist(input.id))) && <div style={{ width: 5 + 48 }} />}

            <div style={{ width: 5 }} />

            {showTemplateLabelPrint && <><Button kind={(nonExistent || !(Util.isStringExists(value) && !isInvalidValue)) ? "primary" : "tertiary"} hasIconOnly renderIcon={Tag32} size="lg"
                iconDescription="Print Label" onClick={() => setOpenTemplatePrintDialog(true)}
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !(Util.isStringExists(value) && !isInvalidValue)} />
                <div style={{ width: 5 }} /></>}

            <Button kind={(nonExistent || !(Util.isStringExists(value) && !isInvalidValue)) ? "primary" : "tertiary"} hasIconOnly renderIcon={Maximize32} size="lg"
                iconDescription="Enlarge" onClick={() => setIsEnlargedBarcode(true)}
                style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent || !(Util.isStringExists(value) && !isInvalidValue)} />

            {/* {!input.systemGenerated && (getAccountRole() == ACCOUNT_TYPE_ADMINISTRATION || !Util.isNumberExist(input.id)) &&  */}
            {!input.systemGenerated &&
                <> <div style={{ width: 5 }} />
                    <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="lg"
                        iconDescription="Remove"
                        style={{ height: 48, alignSelf: 'flex-end' }} disabled={nonExistent} onClick={onInputRemoved} /> </>}

            {isEnlargedBarcode &&
                //414
                <ComposedModal size="sm" open={isEnlargedBarcode} onClose={() => setIsEnlargedBarcode(false)}>
                    <ModalHeader label="Barcode" title="Barcode Viewer" />
                    <ModalBody style={{ paddingRight: '1rem' }}>
                        <canvas ref={enlargedBarCodeDisplay} style={{ width: '100%', height: 150, objectFit: 'contain', objectPosition: 'center', }} />
                    </ModalBody>
                    <ModalFooter>
                        <Button kind="secondary" onClick={() => setIsEnlargedBarcode(false)}>
                            Close
                        </Button>
                        <Button
                            onClick={() => {
                                printProductLabels("Barcode only", {}, [{
                                    id: value,
                                    barcode: value,
                                    barcodeFormat: type
                                }])
                            }}>
                            Print
                        </Button>

                    </ModalFooter>
                </ComposedModal>}

            {openTemplatePrintDialog &&
                <LabelPrintViewDialog
                    itemId={showTemplateLabelPrint.itemId}
                    forceBarcode={{ value, type }}
                    productBundle={showTemplateLabelPrint.productBundle} onClose={() => setOpenTemplatePrintDialog(false)} />}
        </div>
    )
}

export default ({ defaultBarcodes, onBarcodesUpdate, showTemplateLabelPrint, readonly }) => (
    <MultipleInputEditor hideDrag={readonly} readOnly={readonly} showTemplateLabelPrint={showTemplateLabelPrint} defaultInputs={defaultBarcodes} onInputsUpdated={onBarcodesUpdate} ItemComponent={BarcodeRecord} />
)