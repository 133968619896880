import { InlineLoading, Link, Table, TableBody, TableCell, TableContainer, TableHead, TableHeader, TableRow, TableToolbar } from 'carbon-components-react'
import React, { useEffect, useRef, useState } from 'react'
import UIUtil from '../../util/UIUtil';

import { ErrorFilled16, Warning16 } from '@carbon/icons-react'
import { API_RESPONSE_MESSAGE_EVENT_FINAL_PAGE } from '../../constants/Constants';
import Util from '../../util/Util';
import { isAbshar, isAbsharRestaurant, isAnyAbshar, isFoodBazaar } from '../../app/app-util';

const ROW_COUNT = 32;

const LoadingBar = () => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        <InlineLoading style={{ width: 'unset' }} />
        <p style={{ fontSize: 12, opacity: 0.65 }}>Loading</p>
    </div>
)

const ErrorBar = ({ onTryAgainBtn }) => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center', }}>
        <ErrorFilled16 style={{ color: 'darkred', marginRight: '0.5rem' }} />
        <p style={{ fontSize: 12, opacity: 0.65, color: 'darkred' }}>Failed to load</p>

        <Link onClick={onTryAgainBtn} style={{ marginLeft: '0.5rem', cursor: 'pointer' }}>Try again</Link>
    </div>
)

const LoadedAllBar = ({ rowCount }) => (
    <div style={{ display: 'flex', width: '100%', height: '100%', justifyContent: 'center', alignItems: 'center' }}>
        {/* <InlineLoading style={{width: 'unset'}} /> */}
        <p style={{ fontSize: 12, opacity: 0.65 }}>Loaded all {rowCount} row{rowCount != 1 && 's'}</p>
    </div>
)

const Aggregate = ({ color, label, value, sub }) => (
    <div style={{ display: 'flex', background: '#f4f4f4', height: 30, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', borderRadius: 5, overflow: 'hidden' }}>
        <div style={{ background: "#0f62fe", width: 4, height: '100%' }} />
        <div style={{ display: 'flex', height: '100%', alignItems: 'center', paddingInline: '0.75rem', gap: '0.25rem' }}>
            <p style={{ fontSize: 14, }}>{label}: </p>
            <p style={{ fontSize: 16, fontWeight: 'bold', color }}>{value}</p>
            {sub !== undefined && <p style={{ fontSize: 14, fontWeight: 'bold' }}>({sub} no)</p>}
        </div>
    </div>
)

export default ({ loader, loadResponseHandler, columns, title, description, children, childrenFn, onClick, reloadRequest, hideToolbar, hasSort, rowStyle, hasAggregates, reloadTwoPages }) => {
    const [items, setItems] = useState([]);
    const [aggregate, setAggregate] = useState(undefined);
    const [firstRes, setFirstRes] = useState(undefined)

    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [inError, setInError] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const [sortedColumn, setSortedColumn] = useState({});

    const tableBodyRef = useRef();
    const fetching = useRef(false)
    const pageRef = useRef(0)
    const firstTimeColumnHook = useRef(true)
    const firstTimeReloadHook = useRef(true)

    const clearPageState = () => {
        setItems([])
        setAggregate(undefined)
        setFirstRes(undefined)
        setPage(0);
        setLoading(false);
        setInError(false)
        setHasMore(true);
        pageRef.current = 0;
    }

    const shouldLoadPage = () => {
        if (loading || inError || !hasMore) {
            return false;
        }

        if (!tableBodyRef.current) {
            return false;
        }

        const remainingScroll = document.documentElement.scrollHeight - document.documentElement.scrollTop - window.innerHeight;
        const scrollThreshold = (tableBodyRef.current.offsetHeight / items.length) * (ROW_COUNT / 2);

        return remainingScroll <= scrollThreshold;
    };

    const getSortRequest = () => {
        if (sortedColumn.id) {
            return {
                property: sortedColumn.id,
                asc: sortedColumn.direction == "ASC"
            }
        }
    }

    const loadPage = () => {
        if (fetching.current) {
            return;
        }

        const page = pageRef.current;

        fetching.current = true;
        setLoading(true)
        loader(page, getSortRequest(), response => {
            fetching.current = false;

            if (response.status === true) {
                setPage(page + 1);
                pageRef.current = page + 1;
                //setPage(page => page + 1)

                const res = loadResponseHandler ? loadResponseHandler(response) : {
                    loadedItems: response.payload.stockFlows,
                    loadedHasMore: response.message != API_RESPONSE_MESSAGE_EVENT_FINAL_PAGE,
                    aggregate: undefined
                }
                const { loadedItems, loadedHasMore, aggregate } = res;


                setItems(items => [...items, ...loadedItems])
                setHasMore(loadedHasMore)
                if (page === 0 && aggregate) {
                    setAggregate(aggregate);
                }
                if (page === 0) {
                    setFirstRes(res);
                }
                if (page === 0 && reloadTwoPages) {
                    loadPage()
                }
            } else {
                setInError(true)
            }
            setLoading(false)
        })
    }


    useEffect(() => {
        loadPage()
    }, []);
    useEffect(() => {
        if (firstTimeColumnHook.current) {
            firstTimeColumnHook.current = false;
            return;
        }

        clearPageState();
        loadPage();
    }, [sortedColumn])
    useEffect(() => {
        if (firstTimeReloadHook.current) {
            firstTimeReloadHook.current = false;
            return;
        }

        clearPageState();
        loadPage();
    }, [reloadRequest])
    useEffect(() => {
        const onScroll = () => shouldLoadPage() && loadPage();
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll);
    }, [page, loading, hasMore]);

    const createSortManagement = (column) => ({
        isSortable: true,
        isSortHeader: column.id == sortedColumn.id,
        sortDirection: sortedColumn ? sortedColumn.direction : "NONE",
        onClick: () => setSortedColumn(sortedColumn => {
            if (column.id == sortedColumn.id) {
                return sortedColumn.direction == "ASC" ? { ...sortedColumn, direction: "DESC" } : {}
            } else {
                return { id: column.id, direction: "ASC" }
            }
        })
    })

    const getRowStyle = item => rowStyle?.(item) ?? {}

    return (
        <TableContainer title={title} description={description}>
            {!hideToolbar && <TableToolbar>
                {typeof children === "function" ? children(firstRes) : children}
            </TableToolbar>}
            {hasAggregates && aggregate &&
                <div style={{ background: '#1c1c1c', width: '100%', minHeight: '3rem', padding: '0.5rem', gap: '0.5rem', display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                    {Util.isStringExists(aggregate.reqNotValidMsg) ? <>
                        <ErrorFilled16 style={{ color: 'white', marginLeft: '0.5rem' }} />
                        <p style={{ fontSize: 14, opacity: 0.65, color: 'white' }}>{aggregate.reqNotValidMsg}</p>
                    </> : <>
                        <Aggregate label={"Total Sales"} value={`AED ${Util.formatMoney(aggregate.total)}`} sub={aggregate.totalCount} color={undefined} />
                        <div />
                        <Aggregate label={"Cash"} value={`AED ${Util.formatMoney(aggregate.cash)}`} sub={aggregate.cashCount} color={"green"} />
                        <Aggregate label={"Card"} value={`AED ${Util.formatMoney(aggregate.card)}`} sub={aggregate.cardCount} color={"green"} />
                        <Aggregate label={"Online"} value={`AED ${Util.formatMoney(aggregate.online)}`} sub={aggregate.onlineCount} color={"green"} />
                        {isAnyAbshar() && <>
                            <div />
                            <Aggregate label={"Talabat"} value={`AED ${Util.formatMoney(aggregate.talabat)}`} sub={aggregate.talabatCount} color={"darkorange"} />
                            <Aggregate label={"Zomato"} value={`AED ${Util.formatMoney(aggregate.zomato)}`} sub={aggregate.zomatoCount} color={"darkorange"} />
                            <Aggregate label={"Deliveroo"} value={`AED ${Util.formatMoney(aggregate.deliveroo)}`} sub={aggregate.deliverooCount} color={"darkorange"} />
                            <Aggregate label={"Careem"} value={`AED ${Util.formatMoney(aggregate.careem)}`} sub={aggregate.careemCount} color={"darkorange"} />
                            {isAbsharRestaurant() && <>
                                <Aggregate label={"Noon"} value={`AED ${Util.formatMoney(aggregate.noon)}`} sub={aggregate.noonCount} color={"darkorange"} />
                            </>}
                        </>}
                        {isFoodBazaar() && <>
                            <div />
                            <Aggregate label={"Fresh Bazaar"} value={`AED ${Util.formatMoney(aggregate.freshBazaar)}`} sub={aggregate.freshBazaarCount} color={"purple"} />
                            <Aggregate label={"Art Bazaar"} value={`AED ${Util.formatMoney(aggregate.artBazaar)}`} sub={aggregate.artBazaarCount} color={"purple"} />
                            <Aggregate label={"Company Expense"} value={`AED ${Util.formatMoney(aggregate.companyExpense)}`} sub={aggregate.companyExpenseCount} color={"red"} />
                        </>}

                        <div />
                        <Aggregate label={"Open Bills"} value={`AED ${Util.formatMoney(aggregate.unpaid)}`} sub={aggregate.unpaidCount} color={"red"} />
                        <div />
                        <Aggregate label={"Dine in"} value={aggregate.dinein} />
                        <Aggregate label={"Take out"} value={aggregate.takeout} />
                        <Aggregate label={"Delivery"} value={aggregate.delivery} />
                        <Aggregate label={"Pickup"} value={aggregate.pickup} />
                    </>}

                </div>}
            <Table>
                <TableHead>
                    <TableRow>
                        {columns.map(column => (
                            <TableHeader key={column.id} {...(hasSort ? createSortManagement(column) : {})} >
                                {column.name}
                            </TableHeader>
                        ))}
                    </TableRow>
                </TableHead>

                <tbody ref={tableBodyRef}>
                    {items.map(item => (
                        <TableRow key={item.id} onClick={() => onClick && onClick(item)} style={{ ...getRowStyle(item) }}>
                            {columns.map(column => (
                                <TableCell key={column.id + ':c-r:' + item.id}>
                                    {column.render(item)}
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}
                </tbody>


            </Table>
            <TableToolbar>

                {loading ? <LoadingBar /> :
                    inError ? <ErrorBar onTryAgainBtn={() => loadPage()} /> :
                        !hasMore && <LoadedAllBar rowCount={items.length} />}
            </TableToolbar>
        </TableContainer>
    )
}