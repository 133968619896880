import Button from "../../../components/Button";
import { AddFilled16, Add16, ArrowLeft16 } from '@carbon/icons-react'
import { useMemo } from "react";
import { getReport } from "../../reports/reports";
import Util from "../../../util/Util";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useState } from "react";
import { SidePanel } from "../../../templates/draft/components/side-panel";
import { useForm } from "../../../templates/form/form";
import { getReportLink, listDetailItemId } from "../../base/report-page";


function Form({ reportName, report, endpoint, state }) {
    const history = useHistory();
    const location = useLocation();
    const fullScreen = report.sideViews.fullScreen;
    const { Component, openDetailOnCreate } = report.sideViews.newForm;

    const [visible, setVisible] = useState(() => state?.get("openNewPanelInitially") === true);

    const onNewItem = item => {
        //update the table
        //open that row

        // history.replace(getReportLink(reportName))

        if (openDetailOnCreate) {
            history.push("/__redirect");
            history.replace(location.pathname, { __open_item_id: item.id })
        } else {
            history.go(0);
        }
    }

    return (<>
        <Button onClick={() => setVisible(true)} kind="ghost" renderIcon={AddFilled16} size="sm" style={{ borderRadius: 8, textDecoration: 'none' }}>Create</Button>

        {visible && <SidePanel md2={!fullScreen} lg={fullScreen} backBtn onClose={() => setVisible(false)}>
            <Component {...{ reportName, onNewItem, endpoint, onClose: () => setVisible(false) }} />
        </SidePanel>}
    </>)
}


export function NewBtn({ reportName, isListMode, endpoint, state }) {
    const report = useMemo(() => getReport(reportName), [reportName])
    const [dialogVisible, setDialogVisible] = useState(false);
    const history = useHistory();

    if (report?.sideViews?.newForm) {
        return <Form reportName={reportName} report={report} endpoint={endpoint} state={state} />
    }


    if (!Util.isStringExists(report.newLink) && !report.newDialog) {
        return null;
    }
    if (report.newDialog) {
        return (<>
            {isListMode ? (
                <Button onClick={() => setDialogVisible(true)} kind="ghost" renderIcon={AddFilled16} size="sm" style={{ borderRadius: 8, textDecoration: 'none' }}>Create</Button>
            ) : (
                <Button onClick={() => setDialogVisible(true)} style={{ borderRadius: 25, marginLeft: '1rem' }} size="sm" renderIcon={Add16}>New</Button>
            )}

            <report.newDialog endpoint={endpoint} visible={dialogVisible} onClose={() => setDialogVisible(false)} onSuccess={item => {
                if (report.itemToOpenLink) {
                    history.push(report.itemToOpenLink(item))
                }
            }} />
        </>)
    } else {
        return (
            <Link to={report.newLink} style={{ textDecoration: 'none' }}>
                {isListMode ? (
                    <Button kind="ghost" renderIcon={AddFilled16} size="sm" style={{ borderRadius: 8, textDecoration: 'none' }}>Create</Button>
                ) : (
                    <Button style={{ borderRadius: 25, marginLeft: '1rem' }} size="sm" renderIcon={Add16}>New</Button>
                )}
            </Link>
        )
    }
}