import { hasCapabilitySupport } from "../../../app/Capabilities";
import { isRivas } from "../../../app/app-util";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants"
import { getAccountRole } from "../../../session/SessionManager"
import { DateRange } from "../../components/basic-filter/date-range";
import { DateTimeRange } from "../../components/basic-filter/date-time-range";
import { Divider } from "../../components/basic-filter/divider";
import { MultiSelect, MultiSelectWithId } from "../../components/basic-filter/multi-select";
import { MultiSelector } from "../../components/basic-filter/mutli-selector";
import { TextBox } from "../../components/basic-filter/text-box";

export const SalesByItem = {
    filter: Filter,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR;
    }
}

function Filter({ endpoint, state }) {
    return (<>
        {/* <DateRange state={state} property="date" skipAutoFilter /> */}
        {(hasCapabilitySupport("restaurant") && !isRivas()) ? (
            <DateTimeRange state={state} property="date" skipAutoFilter />
        ) : (
            <DateRange state={state} property="date" skipAutoFilter />
        )}
        <Divider />
        <div style={{ height: 30, width: 150 }}>
            <MultiSelectWithId isMulti={false} skipAutoFilter placeholder="Supplier" fieldKey="supplierId" options={endpoint.suppliers} state={state} property="supplierId" />
        </div>
        <div style={{ height: 30, width: 150 }}>
            <MultiSelect isMulti={false} skipAutoFilter placeholder="Store" fieldKey="storeName" options={endpoint.stores.map(item => item.value)} state={state} property="storeName" />
        </div>
        <div style={{ height: 30, }}>
            <TextBox state={state} label="Item" property="itemName" />
        </div>
        <MultiSelector fieldKey="itemClassification" placeholder="Select classes..." options={endpoint.classifications} state={state} property="itemClassification" />
    </>)
}
