import { ComposedModal, ModalBody, ModalHeader } from 'carbon-components-react';
import React, { useState } from 'react'
import Button from '../../components/Button';
import { POS_SESSION_TYPE_MOBILE_UNIT, POS_SESSION_TYPE_ONLINE_STORE, SESSION_TYPE_THIRD_PARTY } from '../../constants/Constants';
import PosState, { getDefaultPosState } from '../pos/state/PosState';
import { PosInfoView } from '../pos/view/PosInfoView';
import CartContent from '../pos/widgets/CartContent';
import CustomerInfo from '../pos/widgets/CustomerInfo';
import PaymentWidget from '../pos/widgets/PaymentWidget';
import PosInfoWidget from '../pos/widgets/PosInfoWidget';


import {
    ErrorFilled16, SubtractAlt16
} from '@carbon/icons-react'
import Api from '../../session/Api';
import UIUtil from '../../util/UIUtil';
import { PosFreeFormDialog } from '../pos/PosDialogs';
import Portal from '../../util/Portal';
import CouponWidget from '../pos/widgets/CouponWidget';

const RejectBtn = ({ posSessionId }) => {
    const [loading, setLoading] = useState(false);

    const onForceBtn = () => {
        UIUtil.confirm(() => {
            setLoading(true);
            Api.forceStopPosSession(posSessionId, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }

    return (
        <div style={{ flex: 1 }}>
            <Button loading={loading} onClick={onForceBtn} kind="danger" renderIcon={ErrorFilled16}>Force Stop Session</Button>
        </div>
    )
}

class AdminMonitoringView extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            ...getDefaultPosState(),
        }

        this.posState = new PosState(() => this.state, this.setState.bind(this))
        this.posState.readOnly = true;
        this.posState.monitoringSession = true;

        this.props.onStateListener(this.posState);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevState.showPosInfoDialog != this.state.showPosInfoDialog) {
            this.props.onStateListener(this.posState);
        }

        if (prevProps.terminal !== this.props.terminal) {
            this.posState.onUpdateSession(this.props.terminal.session)
        }
    }

    componentDidMount() {
        this.posState.loadExternalSession(this.props.terminal.session);
    }

    render() {
        if (this.posState.isLoadingPos() || !this.posState.hasPos()) {
            return null;
        }

        return (
            <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>

                <div style={{ display: 'flex', width: '100%', gap: '0.5rem' }}>
                    <div style={{ flex: 2, display: 'flex' }}>
                        {this.props.terminal && this.props.terminal.type != SESSION_TYPE_THIRD_PARTY &&
                            <CustomerInfo state={this.posState} light hideTitle />}
                        {/* {this.props.terminal &&
                            this.props.terminal.type != POS_SESSION_TYPE_ONLINE_STORE &&
                            this.props.terminal.type != POS_SESSION_TYPE_MOBILE_UNIT &&
                            // this.props.terminal.type != POS_SESSION_TYPE_MOBILE_UNIT &&
                            <PosInfoWidget state={this.posState} light hideLayoutOptions />} */}
                    </div>
                    {this.props.terminal.type != POS_SESSION_TYPE_ONLINE_STORE &&
                        this.props.terminal?.session?.posSessionId !== undefined &&
                        <div style={{ flex: 1, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#1c1c1c', color: 'white', paddingLeft: '1rem' }}>
                            <h5 style={{ flex: 1, fontSize: 16 }}>Manage Session</h5>
                            <RejectBtn posSessionId={this.props.terminal.session.posSessionId} />
                        </div>}
                </div>
                <div style={{ display: 'flex', gao: 0, flex: 1, minHeight: 0 }}>
                    <div style={{ flex: 2 }}>
                        <CartContent state={this.posState} />
                    </div>
                    {this.props.terminal && this.props.terminal.type != POS_SESSION_TYPE_MOBILE_UNIT &&
                        <div style={{ flex: 1, background: '#1c1c1c', margin: 15, marginRight: 0, padding: 5, borderRadius: 15, border: '1px solid #00000040' }}>
                            <div style={{ width: '100%', height: '100%', borderRadius: 10, overflow: 'hidden' }}>
                                <CouponWidget state={this.posState} showDiscountOnly />
                            </div>
                        </div>}
                </div>
                <div>
                    <PaymentWidget state={this.posState} />
                </div>

                {/* <Button onClick={() => this.posState.setShowFreeFormDiscountDialog(true)} loading={this.posState.isShowFreeFormDiscountDialog()} style={{ flex: 1 }} kind="secondary" renderIcon={SubtractAlt16}>Apply Discount</Button> */}

                <Portal>
                    <PosFreeFormDialog posState={this.posState} showFakeTextFieldAtFirst />
                </Portal>
            </div>
        )
    }

}

export default AdminMonitoringView;