import PaginatedDataTable from "../../../components/data-table/PaginatedDataTable"
import Util from "../../../util/Util"

import {
    Calendar16, Filter16, Search16, Close16, Alarm16, WatsonHealthStackedScrolling_116, ArrowsVertical16, SortAscending16, SortDescending16, Save16, Delete16
} from '@carbon/icons-react'
import StatusIndicator from "../views/StatusIndicator";
import { useHistory } from "react-router-dom";
import Api from "../../../session/Api";
import { STATUS_PENDING, addressToString } from "../base/sales-order";
import Button from "../../../components/Button";
import { Popover, ArrowContainer } from 'react-tiny-popover'
import { useEffect, useMemo, useRef, useState } from "react";
import { ContentSwitcher, MultiSelect, Link, Switch, TextInput, ComboBox, Tag, ComposedModal, ModalHeader, ModalBody, ModalFooter, DatePicker, DatePickerInput } from "carbon-components-react";
import UIUtil from "../../../util/UIUtil";
import useLocalStorage, { useObjectLocalStorage } from "../../../hooks/useLocalStorage";
import Portal from "../../../util/Portal";
import { CirclePicker } from "react-color";
import { classExistsInSomeParent } from "../../label-designer/util/ParentFinder";
import { hasCapabilitySupport } from "../../../app/Capabilities";
import { TypeTag } from "../../pos/restaurant/components/type-tag";
import { fromEpoch, fromEpochUsingDate, toEpoch, toEpochUsingDate } from "../../../reporting-engine/components/basic-filter/date-range";

const isLightColor = color => ((1 - (0.299 * (parseInt(color.substring(1, 3), 16)) + 0.587 * (parseInt(color.substring(3, 5), 16)) + 0.114 * (parseInt(color.substring(5, 7), 16))) / 255) < 0.5)

const isReqExist = req => {
    for (const key in req) {
        const val = req[key];
        if (typeof val === "string") {
            if (val.trim().length > 0) {
                return true;
            }
        } else if (typeof val === "number") {
            if (val > 0) {
                return true;
            }
        } else if (Array.isArray(val)) {
            if (val.length > 0) {
                return true;
            }
        }
    }
    return false;
}

const useIsInFilter = (req) => {
    return useMemo(() => isReqExist(req), [req]);
}

const renderAmount = (salesOrder) => {
    if (Util.isNumberExist(salesOrder.amount)) {
        const amount = salesOrder.amount;
        const amountPaid = salesOrder.paidAmount;

        const amountLeft = amount - amountPaid;

        let status;
        let statusColor;
        if (amountLeft <= 0) {
            status = "Paid";
            statusColor = "green";
        } else {
            status = amountPaid == 0 ? "Not paid" : "Partially paid";
            statusColor = amountPaid == 0 ? "red" : undefined;
        }

        return (<>

            {Util.isStringExists(salesOrder.actualPaymentMethod) ? (
                <div style={{ marginBottom: '0.5rem', }}>
                    <label style={{ marginBottom: '-0.25rem', color: '#009900' }} className="bx--label">Payment Method</label>
                    <p style={{ color: '#009900' }}>
                        {salesOrder.actualPaymentMethod}
                    </p>
                </div>
            ) : (
                <div style={{ marginBottom: '0.5rem', }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Preferred Payment Method</label>
                    <p>
                        {salesOrder.preferredPaymentMethodName}
                    </p>
                </div>
            )}

            <div style={{ marginBottom: '0.5rem', }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount</label>
                <p>
                    AED {amount.toFixed(2)}
                </p>
            </div>

            {(Util.isNumberExist(salesOrder.salesStockFlowId) || Util.isNumberExist(salesOrder.v2SaleId)) ? (<>
                <div style={{ marginBottom: 0, }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">Amount Paid <span style={{ marginLeft: '0.25rem', color: statusColor }}>({status})</span></label>
                    <p style={{ color: amountLeft > 0 ? 'red' : 'green' }}>
                        AED {amountPaid.toFixed(2)}
                    </p>
                </div>
            </>) : (
                <p style={{ opacity: 0.65, fontSize: 12 }}>No sales occurred yet</p>
            )}


            {(salesOrder?.discounts?.length ?? 0) > 0 && (
                <div style={{ borderRadius: 5, marginTop: '0.25rem', marginBottom: 15, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#bae6ff' }}>
                    <p style={{ textAlign: 'center', fontSize: 12, opacity: 1 }}>
                        Coupon / loyalty points applied
                    </p>
                </div>
            )}

        </>)
    } else {
        if ((salesOrder?.discounts?.length ?? 0) > 0) {
            return (
                <div style={{ borderRadius: 5, marginBottom: 15, width: '100%', paddingBlock: '0.25rem', display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#bae6ff' }}>
                    <p style={{ textAlign: 'center', fontSize: 12, opacity: 1 }}>
                        Coupon / loyalty points applied
                    </p>
                </div>
            )
        } else {
            return null;
        }
    }
}

const renderDate = row => {
    return (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            <h6>Order date/time</h6>
            <p><Calendar16 /> {Util.getFullDate(row.dateCreated)}</p>
            {row.status == STATUS_PENDING ? (
                <>
                    <div style={{ height: 5 }} />
                    <h6>Preferred Delivery Date</h6>
                    <p><Calendar16 /> {Util.getFullDate(row.preferredDeliveryDate)}</p>

                    {row.requiresRentalPickup && row.preferredRentalPickupDate > 0 && <>
                        <div style={{ height: 5 }} />
                        <h6>Preferred Pickup Date</h6>
                        <p><Calendar16 /> {Util.getFullDate(row.preferredRentalPickupDate)}</p>
                    </>}
                </>
            ) : <>

                {row.invoicedDate > 0 ? (<>
                    <div style={{ height: 5 }} />
                    <h6 style={{ color: 'green' }}>Invoiced Date</h6>
                    <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.invoicedDate)}</p>
                </>) : (<>
                    <div style={{ height: 5 }} />
                    <h6>Target Delivery Date</h6>
                    <p><Calendar16 /> {Util.getFullDate(row.targetDeliveryDate)}</p>
                </>)}

                {row.requiresRentalPickup && (row.targetRentalPickupDate > 0 || row.dateRentalStockReturned > 0) && <>

                    {row.dateRentalStockReturned > 0 ? (<>
                        <div style={{ height: 5 }} />
                        <h6 style={{ color: 'green' }}>Picked up Date</h6>
                        <p style={{ color: 'green' }}><Calendar16 /> {Util.getFullDate(row.dateRentalStockReturned)}</p>
                    </>) : (<>
                        <div style={{ height: 5 }} />
                        <h6 style={{ color: 'red' }}>Target Pickup Date</h6>
                        <p style={{ color: 'red' }}><Calendar16 /> {Util.getFullDate(row.targetRentalPickupDate)}</p>
                    </>)}
                </>}
            </>}


        </div>
    )
}

const renderCustomer = row => {
    if (hasCapabilitySupport("restaurant")) {
        return (
            <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
                <h6>Customer</h6>
                <p>{row.customerName ?? "Guest"}</p>
                <div style={{ height: 5 }} />

                {row.shippingAddress && <>
                    <h6>Address</h6>
                    <p>{addressToString(row.shippingAddress)}</p>
                </>}
            </div>
        )
    }

    return (
        <div style={{ paddingTop: '1rem', paddingBottom: '1rem' }}>
            {/* <h6>Customer</h6>
            <p>{row.customerName ?? "Guest"}</p>
            <div style={{ height: 5 }} /> */}
            <h6>Full Name</h6>
            <p>{row.shippingAddress?.firstName} {row.shippingAddress?.lastName}</p>
            {//Util.isStringExists(row.shippingAddress) && 
                <>
                    <h6 style={{ color: 'magenta' }}>Area</h6>
                    <p style={{ color: Util.isStringExists(row.shippingAddress?.area) ? 'magenta' : '#000000AA' }}>{row.shippingAddress?.area ?? 'No area set'}</p>
                </>}
        </div>
    )
}

const get = (req, page, listener) => Api.getSalesOrders({ ...req, page }, listener)

const HEADERS = [
    {
        id: "id",
        name: "Order ID",
        render: item => Util.getVoucherNumber(item.id)
    },
    {
        id: "orderNo",
        name: "Order No",
        render: item => <>
            {hasCapabilitySupport("restaurant") && <>
                <TypeTag type={item.type} />
                <br />
            </>}
            <span>{item.orderNo}</span>
        </>
    },
    {
        id: "customerId",
        name: "Customer",
        render: renderCustomer
    },
    {
        id: "dateCreated",
        name: "Date",
        render: renderDate
    },
    {
        id: "status",
        name: "Status",
        render: item => (
            <>
                <StatusIndicator status={item.status} state={item.orderState} assignedDriverName={item.currentAssignedDriverId ? item.assignedDriverName : ""} />

                {Util.isNumberExist(item.creationToDeliveryTime) && <>
                    <br />
                    <h6>Creation-To-Delivery Time</h6>
                    <p><Alarm16 /> {Util.formatSecondsPrettySmall(item.creationToDeliveryTime / 1000)} ({Util.formatSeconds(item.creationToDeliveryTime / 1000)})</p>
                </>}

                {Util.isStringExists(item.deliveredByName) && <>
                    <br />
                    <h6>Delivered by</h6>
                    <p style={{ color: '#0f62fe' }}>{item.deliveredByName}</p>
                </>}
            </>
        )
    },
    {
        id: "amount",
        name: "Amount",
        render: renderAmount
    }
]

const isReqSearch = req => Util.isStringExists(req.orderId) || Util.isStringExists(req.orderNo)

const useOrderSearch = (req) => {
    const [searchValueObj, setSearchValueObj] = useState(() => {
        if (Util.isStringExists(req.orderId)) {
            return { key: 'orderId', value: req.orderId };
        } else if (Util.isStringExists(req.orderNo)) {
            return { key: 'orderNo', value: req.orderNo };
        } else {
            return { key: 'orderId', value: "" };
        }
    })
    const searchValue = key => searchValueObj.key === key ? searchValueObj.value : ""
    const setSearchValue = (key, value) => setSearchValueObj({ key, value })
    const getSearchKV = () => searchValueObj;
    return [searchValue, setSearchValue, getSearchKV]
}

const useFilterFields = (lists, req) => {
    const importList = (listKey, reqKey) => {
        const listArr = lists[listKey] ?? [];
        const reqArr = req[reqKey] ?? [];

        if (listArr.length === 0 || reqArr.length === 0) {
            return [];
        }

        return reqArr
            .map(id => listArr.find(i => i.id == id))
            .filter(item => item !== undefined)
    }

    const [state, setState] = useState(() => ({
        statuses: importList("statuses", "statuses"),
        stateIds: importList("stateIds", "stateIds") ?? [],
        customerIds: importList("customerIds", "customerIds") ?? [],
        couponIds: importList("couponIds", "couponIds") ?? [],
        assignedDriverIds: importList("driverIds", "assignedDriverIds") ?? [],
        deliveredByDriverIds: importList("driverIds", "deliveredByDriverIds") ?? [],
        assignedOrDeliveredByDriverIds: importList("driverIds", "assignedOrDeliveredByDriverIds") ?? [],
        amountStatuses: importList("amountStatuses", "amountStatuses") ?? [],
        actualPaymentMethods: importList("actualPaymentMethods", "actualPaymentMethods") ?? [],
        shippingFullName: req.shippingFullName ?? "",
        phoneNumber: req.phoneNumber ?? "",
        startDate: req.startDate ?? 0,
        endDate: req.endDate ?? 0,

        invoiceStartDate: req.invoiceStartDate ?? 0,
        invoiceEndDate: req.invoiceEndDate ?? 0,
    }))

    const getField = key => state[key] ?? []
    const setField = (key, value) => setState(prev => ({ ...prev, [key]: value }))

    const exportFields = () => {
        let o = {}
        for (const field in state) {
            const val = state[field];
            if (Array.isArray(val)) {
                o[field] = val.map(v => v.id);
            } else {
                o[field] = val;
            }
        }
        return o;
    }

    const clear = () => {
        setState({
            statuses: [],
            stateIds: [],
            customerIds: [],
            couponIds: [],
            assignedDriverIds: [],
            deliveredByDriverIds: [],
            assignedOrDeliveredByDriverIds: [],
            amountStatuses: [],
            actualPaymentMethods: [],
            shippingFullName: "",
            phoneNumber: "",
            startDate: 0,
            endDate: 0,
            invoiceStartDate: 0,
            invoiceEndDate: 0,
        })
    }

    return [getField, setField, exportFields, clear]
}

const SaveQueryBtn = ({ setSavedReqs, onClose, createReq, onSaved }) => {
    const [dialogVisible, setDialogVisible] = useState(false)
    const [name, setName] = useState("")
    const [color, setColor] = useState("");

    useEffect(() => {
        if (dialogVisible) {
            setName("")
            setColor("")
        }
    }, [dialogVisible])

    const onSaveCriteriaBtn = () => {
        const req = createReq();
        if (!isReqExist(req)) {
            UIUtil.showInfo("No filter options selected")
            return;
        }

        const savedReq = {
            id: Util.newTempId(),
            name, color, req
        }

        setSavedReqs(prev => [...prev, savedReq])
        onSaved(savedReq);
        onClose()
    }

    return (<>
        <Button renderIcon={Save16} onClick={() => setDialogVisible(true)} kind="ghost">Save Criteria</Button>

        <Portal>
            <div className="table-list-filter-save-dialog">
                <ComposedModal className="modal-that-supports-combo-box" key="opening-balance-dialog" size="sm" open={dialogVisible} onClose={() => setDialogVisible(false)}>
                    <ModalHeader label="Filtering" title="Save Criteria" />
                    <ModalBody>
                        <TextInput
                            data-modal-primary-focus
                            value={name}
                            onChange={e => setName(e.target.value)}
                            hideSteppers
                            style={{ marginBottom: '1rem' }}
                            labelText="Criteria Name"
                        />

                        <CirclePicker
                            color={color}
                            onChangeComplete={e => setColor(e.hex)}
                        />
                    </ModalBody>
                    <ModalFooter>
                        <Button kind="secondary" onClick={() => setDialogVisible(false)}>
                            Cancel
                        </Button>
                        <Button
                            disabled={!Util.isStringExists(name) || !Util.isStringExists(color)}
                            onClick={onSaveCriteriaBtn}>
                            Save
                        </Button>

                    </ModalFooter>
                </ComposedModal>
            </div>
        </Portal>
    </>)
}

const FilterView = ({ lists, req, onSubmit, onClose, setSavedReqs, onSaved }) => {
    const [isSearch, setIsSearch] = useState(isReqSearch(req))
    const [searchValue, setSearchValue, getSearchKV] = useOrderSearch(req);
    const [getField, setField, exportFields, clearFields] = useFilterFields(lists, req);

    const [selectKey, setSelectKey] = useState(() => Util.newTempId())

    const createReq = () => {
        const search = getSearchKV();
        if (Util.isStringExists(search.value)) {
            return {
                [search.key]: search.value
            }
        } else {
            return {
                ...exportFields()
            }
        }
    }

    const onSubmitBtn = () => onSubmit(createReq())


    const onClear = () => {
        setIsSearch(false);
        setSearchValue('orderId', '')
        clearFields();

        setSelectKey(Util.newTempId())
    }

    const getList = key => lists[key] ?? []

    return (
        <div style={{}}>
            <div style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '0.5rem', }}>
                    <h4 style={{ fontSize: 16, flex: 1 }}>Filtering</h4>
                    <Link onClick={onClear} style={{ cursor: 'pointer', fontSize: 12 }}>Clear</Link>
                </div>

                <div style={{ paddingRight: '3rem' }}>
                    <ContentSwitcher size="sm" style={{ width: 175, marginBottom: '1rem' }}
                        selectedIndex={isSearch ? 1 : 0} onChange={({ index }) => setIsSearch(index == 1)}>
                        <Switch text="Find" />
                        <Switch text="Search" />
                    </ContentSwitcher>

                    {isSearch ? (
                        <>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Order ID</label>
                            <TextInput size="sm" value={searchValue('orderId')} onChange={e => setSearchValue('orderId', e.target.value)} />

                            <div style={{ display: 'flex', alignItems: 'center', marginTop: '1rem', marginBottom: '1rem', gap: '0.25rem' }}>
                                <div style={{ flex: 1, height: 1, background: '#00000040' }} />
                                <label className="bx--label" style={{ marginBottom: 0, fontSize: 12 }}>or</label>
                                <div style={{ flex: 1, height: 1, background: '#00000040' }} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Order Number</label>
                            <TextInput size="sm" value={searchValue('orderNo')} onChange={e => setSearchValue('orderNo', e.target.value)} />


                        </>
                    ) : (
                        <>
                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Starting Date</label>
                            <div className="no-label" style={{ width: '100%', marginBottom: '0.5rem', display: 'flex', }}>
                                <DatePicker dateFormat="d/m/Y" datePickerType="single" value={getField('startDate') > 0 ? fromEpochUsingDate(getField('startDate'), false, true) : null}
                                    onChange={e => setField('startDate', toEpochUsingDate(e[0], false, true))}>
                                    <DatePickerInput
                                        // placeholder="mm/dd/yyyy"
                                        placeholder="dd/mm/yyyy"
                                        size="md"
                                    />
                                </DatePicker>
                                {getField('startDate') > 0 &&
                                    <Button size="sm" hasIconOnly iconDescription="Clear" kind="danger" renderIcon={Close16} onClick={() => setField('startDate', 0)} />}
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Ending Date</label>
                            <div className="no-label" style={{ width: '100%', marginBottom: '0.5rem', display: 'flex', }}>
                                <DatePicker dateFormat="d/m/Y" datePickerType="single" value={getField('endDate') > 0 ? fromEpochUsingDate(getField('endDate'), true, true) : null}
                                    onChange={e => setField('endDate', toEpochUsingDate(e[0], true, true))}>
                                    <DatePickerInput
                                        // placeholder="mm/dd/yyyy"
                                        placeholder="dd/mm/yyyy"
                                        size="md"
                                    />
                                </DatePicker>
                                {getField('endDate') > 0 &&
                                    <Button size="sm" hasIconOnly iconDescription="Clear" kind="danger" renderIcon={Close16} onClick={() => setField('endDate', 0)} />}
                            </div>


                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Starting Invoice Date</label>
                            <div className="no-label" style={{ width: '100%', marginBottom: '0.5rem', display: 'flex', }}>
                                <DatePicker dateFormat="d/m/Y" datePickerType="single" value={getField('invoiceStartDate') > 0 ? fromEpochUsingDate(getField('invoiceStartDate'), false, true) : null}
                                    onChange={e => setField('invoiceStartDate', toEpochUsingDate(e[0], false, true))}>
                                    <DatePickerInput
                                        // placeholder="mm/dd/yyyy"
                                        placeholder="dd/mm/yyyy"
                                        size="md"
                                    />
                                </DatePicker>
                                {getField('invoiceStartDate') > 0 &&
                                    <Button size="sm" hasIconOnly iconDescription="Clear" kind="danger" renderIcon={Close16} onClick={() => setField('invoiceStartDate', 0)} />}
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Ending Invoice Date</label>
                            <div className="no-label" style={{ width: '100%', marginBottom: '0.5rem', display: 'flex', }}>
                                <DatePicker dateFormat="d/m/Y" datePickerType="single" value={getField('invoiceEndDate') > 0 ? fromEpochUsingDate(getField('invoiceEndDate'), true, true) : null}
                                    onChange={e => setField('invoiceEndDate', toEpochUsingDate(e[0], true, true))}>
                                    <DatePickerInput placeholder="dd/mm/yyyy" size="md" />
                                </DatePicker>
                                {getField('invoiceEndDate') > 0 &&
                                    <Button size="sm" hasIconOnly iconDescription="Clear" kind="danger" renderIcon={Close16} onClick={() => setField('invoiceEndDate', 0)} />}
                            </div>





                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Status</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-statuses"}

                                    initialSelectedItems={getField('statuses')}
                                    selectedItems={getField('statuses')}
                                    onChange={e => setField('statuses', e.selectedItems)}

                                    compareItems={(a, b) => 0}
                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('statuses')} />
                            </div>


                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Amount Status</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-amountstatuses"}

                                    initialSelectedItems={getField('amountStatuses')}
                                    selectedItems={getField('amountStatuses')}
                                    onChange={e => setField('amountStatuses', e.selectedItems)}

                                    compareItems={(a, b) => 0}
                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('amountStatuses')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Actual Payment Method</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-actualpaymentmethod"}

                                    initialSelectedItems={getField('actualPaymentMethods')}
                                    selectedItems={getField('actualPaymentMethods')}
                                    onChange={e => setField('actualPaymentMethods', e.selectedItems)}

                                    compareItems={(a, b) => 0}
                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('actualPaymentMethods')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Applied Coupon</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-couponIds"}

                                    initialSelectedItems={getField('couponIds')}
                                    selectedItems={getField('couponIds')}
                                    onChange={e => setField('couponIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('couponIds')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Customer</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-customerIds"}

                                    initialSelectedItems={getField('customerIds')}
                                    selectedItems={getField('customerIds')}
                                    onChange={e => setField('customerIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('customerIds')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Shipping Full Name</label>
                            <TextInput
                                value={getField('shippingFullName')} onChange={e => setField('shippingFullName', e.target.value)}
                                style={{ width: 306, marginBottom: '0.5rem' }} size="sm" />

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Phone Number</label>
                            <TextInput
                                value={getField('phoneNumber')} onChange={e => setField('phoneNumber', e.target.value)}
                                style={{ width: 306, marginBottom: '0.5rem' }} size="sm" />

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Assigned Driver</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-assignedDriverIds"}

                                    initialSelectedItems={getField('assignedDriverIds')}
                                    selectedItems={getField('assignedDriverIds')}
                                    onChange={e => setField('assignedDriverIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('driverIds')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Delivered By</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-deliveredByDriverId"}

                                    initialSelectedItems={getField('deliveredByDriverIds')}
                                    selectedItems={getField('deliveredByDriverIds')}
                                    onChange={e => setField('deliveredByDriverIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('driverIds')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>Delivered By or Currently Assigned</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-deliveredByDriverIdOrCurrenlyAssigned"}

                                    initialSelectedItems={getField('assignedOrDeliveredByDriverIds')}
                                    selectedItems={getField('assignedOrDeliveredByDriverIds')}
                                    onChange={e => setField('assignedOrDeliveredByDriverIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('driverIds')} />
                            </div>

                            <label className="bx--label" style={{ marginBottom: '0.25rem', fontSize: 12 }}>State</label>
                            <div className="no-label" style={{ width: 306, marginBottom: '0.5rem' }}>
                                <MultiSelect
                                    key={selectKey + "-stateIds"}

                                    initialSelectedItems={getField('stateIds')}
                                    selectedItems={getField('stateIds')}
                                    onChange={e => setField('stateIds', e.selectedItems)}

                                    size="sm"
                                    hideLabel
                                    itemToString={i => i ? i.value : ""}
                                    items={getList('stateIds')} />
                            </div>


                        </>
                    )}
                </div>

            </div>

            <div style={{ display: 'flex', width: '100%', marginTop: '1rem' }}>
                <div style={{ flex: 1 }} />
                {!isSearch &&
                    <SaveQueryBtn setSavedReqs={setSavedReqs} onClose={onClose} createReq={createReq} onSaved={onSaved} />}
                <Button renderIcon={Search16} onClick={onSubmitBtn}>Submit</Button>
            </div>
        </div>
    )
}

const FilterBtn = ({ req, onSubmit, setSavedReqs, onSaved }) => {
    const [open, setOpen] = useState(false);
    const [lists, setLists] = useState({});

    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        setInError(false)
        Api.getSalesOrdersFilterLists(response => {
            if (response.status === true) {
                setLists(response.payload)
            } else {
                UIUtil.showError("Can't filter")
                setInError(true);
            }
            setLoading(false);
        })
    }, [])

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            // padding={10}
            align="end"
            onClickOutside={e => {
                if (e.target.nodeName === "BODY") {
                    return;
                }

                if (!classExistsInSomeParent(e.target, "table-list-filter-save-dialog") && !classExistsInSomeParent(e.target, "flatpickr-calendar")) {
                    setOpen(false)
                }
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer

                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'white'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container table-filter-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="table-filter-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        <FilterView
                            setSavedReqs={setSavedReqs}
                            onSaved={onSaved}
                            lists={lists}
                            req={req} onSubmit={p => {
                                setOpen(false);
                                onSubmit(p)
                            }}
                            onClose={() => setOpen(false)} />
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => {
                if (!loading && !inError) {
                    setOpen(p => !p)
                }
            }}>
                <Button
                    loading={loading} disabled={inError}
                    kind="secondary" renderIcon={Filter16}>Filter</Button>
            </a>
        </Popover>
    )
}

const SORT_ITEMS = [
    {
        id: "DATE_CREATED",
        name: "Creation Date"
    },
    {
        id: "AMOUNT",
        name: "Amount"
    },
    {
        id: "TARGET_DELIVERY_DATE",
        name: "Target Delivery Date"
    },
    {
        id: "TARGET_RENTAL_DATE",
        name: "Target Pickup Date"
    },
]

const Sort = ({ sort, setSort }) => {
    const isAsc = sort.sortAsc ?? false;

    const selectedItem = useMemo(() => SORT_ITEMS.find(({ id }) => sort.sort === id), [sort])

    return (
        <div style={{ background: '#393939', height: 48, paddingInline: '0.5rem', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
            <label className="bx--label" style={{ margin: 0, fontSize: 12, color: 'white', marginRight: '0.5rem' }}>Sort:</label>
            <ComboBox
                selectedItem={selectedItem}
                onChange={e => setSort(p => ({ ...p, sort: e.selectedItem ? e.selectedItem.id : "DATE_CREATED" }))}

                style={{ flex: 1 }}
                size="sm"
                itemToString={item => item ? item.name : ""}
                items={SORT_ITEMS} />

            <Button size="sm"
                onClick={() => setSort(p => ({ ...p, sortAsc: !p.sortAsc }))}
                renderIcon={isAsc ? SortAscending16 : SortDescending16}>{isAsc ? 'Asc' : 'Desc'}</Button>
        </div>
    )
}

const SortBtn = ({ sort, setSort }) => {
    const [open, setOpen] = useState(false);

    return (
        <Popover
            isOpen={open}
            positions={['bottom', 'right']}
            // padding={10}
            align="end"
            onClickOutside={e => {
                // console.log(e.target);
                setOpen(false)
            }}
            content={({ position, childRect, popoverRect }) => (
                <ArrowContainer

                    position={position}
                    childRect={childRect}
                    popoverRect={popoverRect}
                    arrowColor={'#393939'}
                    arrowSize={10}
                    // arrowStyle={{ opacity: 0.7 }}
                    className='popover-arrow-container table-filter-pop-over-root'
                    arrowClassName='popover-arrow'
                >
                    <div
                        className="table-filter-pop-over"
                    // onClick={() => setOpen(p => !p)}
                    >
                        <Sort sort={sort} setSort={setSort} />
                    </div>
                </ArrowContainer>
            )}
        >
            <a style={{ background: 'transparent', border: 'none' }} onClick={() => setOpen(p => !p)}>
                <Button

                    kind="ghost" renderIcon={ArrowsVertical16}>Sort</Button>
            </a>
        </Popover>
    )
}



const DeleteQueryBtn = ({ savedReq, onDelete, setSavedReqs }) => {
    const onBtn = () => {
        UIUtil.confirm(() => {
            setSavedReqs(prev => prev.filter(item => item.id !== savedReq.id));
            onDelete();
        })
    }

    return (
        <Button kind="danger" renderIcon={Delete16} onClick={onBtn}>
            Delete Saved Criteria
        </Button>
    )
}


const SavedQueries = ({ selectedReq, onSelectReq, savedReqs, inFilter, clearFilter }) => {
    const selectedId = selectedReq ? selectedReq.id : (inFilter ? -1 : 0)
    const otherArr = useMemo(() => !inFilter ? [{ id: 0 }] : [{ id: 0 }, { id: -1 }], [inFilter])

    return (
        <div style={{ display: 'flex', alignItems: 'center', gap: '0.25rem', marginRight: '1rem', padding: '0.5rem', flex: 1, flexWrap: 'wrap' }}>

            {otherArr.concat(savedReqs).map(type => <Tag key={type.id + '-sales-orders-filter-id'}
                className={selectedId != type.id ? "sales-cart-type-filter-tag" : ""}
                style={{
                    // opacity: selectedId == type.id ? 1 : 0.25, color: isLightColor(type.color ?? (!inFilter ? "#ffffff" : "#000000")) ? "black" : "white",
                    // background: type.color ?? (!inFilter ? "#ffffff" : "#000000"), transition: '250ms', marginRight: type.id === 0 ? '1rem' : undefined
                    opacity: selectedId == type.id ? 1 : 0.25, color: isLightColor(type.color ?? (type.id === 0 ? "#ffffff" : "#000000")) ? "black" : "white",
                    background: type.color ?? (type.id === 0 ? "#ffffff" : "#000000"), transition: '250ms', marginRight: type.id === 0 ? '1rem' : undefined
                }}
                // color="high-contrast"
                onClick={selectedId != type.id ? (() => {
                    if (type.id === 0) {
                        // && inFilter
                        clearFilter()
                        onSelectReq(undefined)
                    } else {
                        onSelectReq((type.id === 0 || type.id === -1) ? undefined : type)
                    }
                }) : undefined}
                //renderIcon={type.id === 0 && WatsonHealthStackedScrolling_116}>{type.id === 0 ? (inFilter ? "Filtered" : "All") : type.name}</Tag>)
                renderIcon={type.id === 0 && WatsonHealthStackedScrolling_116}>{type.id === 0 ? "All" : type.id === -1 ? "Filtered" : type.name}</Tag>)
            }
        </div>
    )
}

export default ({ deliveryOnly, customerId }) => {
    const [savedReqs, setSavedReqs] = useObjectLocalStorage("sales-orders-saved-queries")

    const history = useHistory();
    const [tableKey, setTableKey] = useState(() => Util.newTempId());


    const [req, setReq] = useState({});
    const [sort, setSort] = useState({ sort: "DATE_CREATED", sortAsc: false })
    const [savedReq, setSavedReq] = useState(undefined);

    const inFilter = useIsInFilter(req);

    useEffect(() => {
        setTableKey(Util.newTempId())
    }, [req, sort, savedReq])

    const isCustomerList = Util.isNumberExist(customerId)
    const getReq = () => {
        let mapped;
        if (isCustomerList) {
            mapped = ({ customerIds: [customerId], ...sort })
        } else if (savedReq) {
            mapped = ({ ...savedReq.req, ...sort })
        } else {
            mapped = ({ ...req, ...sort });
        }

        if (deliveryOnly) {
            mapped = { ...mapped, deliveryOnly: true }
        }

        return mapped;
    }

    return (
        <div className="tooltip-supported-toolbar">
            <PaginatedDataTable
                reloadRequest={tableKey}
                title={deliveryOnly ? "Deliverables/Rentals" : "Sale Orders"}
                description={deliveryOnly ? "All deliverable/rental sales orders" : "All sale orders"}
                columns={HEADERS}
                loader={(page, _, listener) => get(getReq(), page, listener)}
                loadResponseHandler={response => ({ loadedItems: response.payload.items, loadedHasMore: response.payload.hasMore })}
                //onClick={({ id }) => history.push('/deliverables/' + id)}
                onClick={({ id }) => window.open('/deliverables/' + id, "_blank")}

            >
                <div style={{ display: 'flex', width: '100%' }}>
                    <div style={{ flex: 1 }}>
                        <SavedQueries selectedReq={savedReq} onSelectReq={setSavedReq}
                            savedReqs={savedReqs} inFilter={inFilter} clearFilter={() => setReq({})} />
                    </div>
                    <SortBtn sort={sort} setSort={setSort} />
                    {!isCustomerList && !savedReq &&
                        <FilterBtn req={req} onSubmit={setReq} setSavedReqs={setSavedReqs} onSaved={setSavedReq} />}
                    {savedReq &&
                        <DeleteQueryBtn savedReq={savedReq} setSavedReqs={setSavedReqs} onDelete={() => {
                            setSavedReq(undefined)
                        }} />}
                </div>
            </PaginatedDataTable>

            <div style={{ height: 1000 }} />
        </div>
    )
}