import { CustomStatelessDateTimePicker, StatelessDateTimePicker } from "../../../components/DateTimePicker";
import { useNumberValueFilter } from "../../engine/useValueFilter";


export function DateTimeRange({ state, property, skipAutoFilter }) {
    const [start, setStart] = useNumberValueFilter(state, property + "-start", { property, skipAutoFilter, operator: "GTE" });
    const [end, setEnd] = useNumberValueFilter(state, property + "-end", { property, skipAutoFilter, operator: "LT" });

    return (<>
        <div className="filter-field">
            <label className="bx--label">From: </label>
            <CustomStatelessDateTimePicker date={start} setDate={setStart} />
        </div>
        <div className="filter-field">
            <label className="bx--label">To: </label>
            <CustomStatelessDateTimePicker date={end} setDate={setEnd} />
        </div>
    </>)
}

export function CarbonDateTimeRange({ label, light, start, setStart, end, setEnd }) {
    // const [start, setStart] = useNumberValueFilter(state, property + "-start", { property, skipAutoFilter, operator: "GTE" });
    // const [end, setEnd] = useNumberValueFilter(state, property + "-end", { property, skipAutoFilter, operator: "LT" });

    return (<>
        <StatelessDateTimePicker light={light} labelText={label ? (label + " From Date") : 'From Date'} date={start} setDate={setStart} />
        <StatelessDateTimePicker light={light} labelText={label ? (label + " End Date") : 'End Date'} date={end} setDate={setEnd} />
    </>)
}