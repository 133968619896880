
import { useEffect, useState } from "react"
import CreatableSelect from 'react-select/creatable';
import Select from 'react-select'
import { useListFilter } from "../../engine/useValueFilter";
import Util from "../../../util/Util";
import Button from "../../../components/Button";
import UIUtil from "../../../util/UIUtil";
import ProductFinder from "../../../views/product/ProductFinder";
import { Close16, Search16 } from '@carbon/icons-react';

const createStyle = style => (baseStyles) => ({
    ...baseStyles,
    ...style
})

const DEF_VALUE = null

export function ProductFilter({ state, options }) {
    const [filters, setFilters] = useListFilter(state, 'productStockReportProductId', true)
    const [value, setValue] = state.use('productStockReportProductId', DEF_VALUE)

    useEffect(() => {
        if (Util.isNumberExist(value?.value)) {
            setFilters([{ property: 'itemId', operator: "EQ", value: value.value }])
        } else {
            setFilters([])
        }
    }, [value])



    const onSelectBtn = () => {
        UIUtil.showOverlay2(onClose => <ProductFinder
            showFakeTextFieldAtFirst={true}
            onProductClick={product => setValue({ label: product.name, value: product.id })} onClose={onClose} />)
    }

    return (<>
        {Util.isNumberExist(value?.value) ? (
            <Button renderIcon={Close16} kind="secondary" size="sm" style={{ borderRadius: 5, border: '1px solid rgba(0, 0, 0, 0.6)', minHeight: 'unset', height: 30, maxWidth: 'unset', flex: 1, width: '100%' }} onClick={() => setValue(null)}>{value.label}</Button>
        ) : (
            <Button renderIcon={Search16} size="sm" style={{ borderRadius: 5, border: '1px solid rgba(0, 0, 0, 0.6)', minHeight: 'unset', height: 30, maxWidth: 'unset', flex: 1, width: '100%' }} onClick={onSelectBtn}>Select Product</Button>
        )}
    </>)



    // return (
    //     <Select
    //         value={value}
    //         onChange={setValue}
    //         placeholder="Product"
    //         styles={{
    //             container: (baseStyles) => ({
    //                 ...baseStyles,
    //                 paddingRight: 1,
    //                 background: 'transparent',
    //                 height: 30,
    //             }),
    //             valueContainer: (baseStyles, state) => ({
    //                 ...baseStyles,
    //                 height: '30px',
    //                 padding: '0 6px'
    //             }),
    //             control: (baseStyles, state) => ({
    //                 ...baseStyles,
    //                 borderRadius: 5,
    //                 background: '#ffffff10',
    //                 border: '1px solid #00000099',
    //                 color: 'white',
    //                 minHeight: 30,
    //                 height: 30,
    //                 padding: 0
    //             }),
    //             input: (baseStyles) => ({
    //                 ...baseStyles,
    //                 color: 'white',
    //                 height: 30,
    //                 margin: 0
    //             }),
    //             multiValue: (baseStyles) => ({
    //                 ...baseStyles,
    //                 background: '#000000',
    //                 color: 'white'
    //             }),
    //             multiValueLabel: (baseStyles) => ({
    //                 ...baseStyles,
    //                 background: '#000000',
    //                 color: '#ffffffBB'
    //             }),
    //             indicatorsContainer: (baseStyles, state) => ({
    //                 ...baseStyles,
    //                 height: '30px',
    //             }),
    //             indicatorSeparator: state => ({
    //                 display: 'none',
    //             }),
    //             menu: createStyle({
    //                 background: '#1c1c1c'
    //             }),
    //             option: (baseStyles, { isDisabled, isFocused, isSelected, isAc }) => ({
    //                 ...baseStyles,
    //                 backgroundColor: isFocused ? '#ffffff20' : isSelected ? 'transparent' : 'transparent'
    //             }),
    //             singleValue: createStyle({
    //                 color: 'white'
    //             })
    //         }}
    //         isClearable
    //         options={options.map(opt => ({ value: opt.id, label: opt.value }))} />
    // )
}