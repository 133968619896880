import { TextInput, ExpandableSearch } from 'carbon-components-react';
import React, { useEffect, useState } from 'react'
import ProfilePic from '../../components/ProfilePic';
import { getObjectTypeUrl, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../constants/ObjectTypes';
import Api from '../../session/Api';
import { RowDelete32, Maximize32, Barcode16, Error16 } from '@carbon/icons-react';
import Button from '../../components/Button';
import Util from '../../util/Util';
import UIUtil from '../../util/UIUtil';
import { Link } from 'react-router-dom';
import { API_RESPONSE_FAILED_EVENT_404, DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_SUPPLIER, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_SUPPLIER } from '../../constants/Constants';

export default ({stockFlowId, loadStockFlowRecordId, purchase, preventClick, sales, onStockFlowChange, labelText, nonExistent, onInputRemoved}) => {
    const [loading, setLoading] = useState(false);
    const [searchKey, setSearchKey] = useState(Util.newTempId())
    const [stockFlowRecordIdValue, setStockFlowRecordIdValue] = useState("")

    const [stockFlowMini, setStockFlowMini] = useState(undefined);

    const hasStockFlow = Util.isNumberExist(stockFlowId);

    const stockFlowTitle = purchase ? "Purchase" : "Sales";

    useEffect(() => {
        if (!hasStockFlow && stockFlowMini !== undefined) {
            setStockFlowMini(undefined);
            // Api.getstockFlowMini(productType, stockFlowId, stockItemMode === undefined ? false : true, response => {
            //     if (response.status === true) {
            //         setStockFlowMini(response.payload)

            //     } else {
            //         onStockFlowChange({ type: 0, id: 0 });
            //         UIUtil.showError(response.message);
            //     }
            //     setLoading(false);
            // })
        }
    }, [stockFlowId])

    useEffect(() => {
        if (loadStockFlowRecordId && stockFlowMini === undefined) {
            onSearchBtn(loadStockFlowRecordId, true);
        }
    }, [loadStockFlowRecordId])
    
    const onSearchBtn = (forcedValue, silent) => {
        const value = forcedValue !== undefined ? forcedValue : stockFlowRecordIdValue;
        if (!Util.isStringExists(value)) {
            return value;
        }
        const listener = response => {
            setStockFlowRecordIdValue("")
            if (response.status === true) {
                if (response.payload != API_RESPONSE_FAILED_EVENT_404) {
                    setStockFlowMini(response.payload)
                    onStockFlowChange(response.payload.id);
                    setSearchKey(Util.newTempId())

                    if (!silent) {
                        UIUtil.showSuccess(stockFlowTitle + " Found!")
                    }
                    setLoading(false)
                } else {
                    UIUtil.showInfo(stockFlowTitle + " not found")
                    setLoading(false);
                }
            } else {
                UIUtil.showError(response.message);
                setLoading(false);
            }
        }
        
        setLoading(true)
        if (purchase) {
            Api.getPurchaseStockFlowMiniItem(value, listener);
        } else {
            Api.getSalesStockFlowMiniItem(value, listener);
        }
    }

    const onClearBtn = () => {
        onStockFlowChange(0);
        if (onInputRemoved !== undefined) {
            onInputRemoved();
        }
    }

    return (
        <div>
            {labelText !== undefined && <label className="bx--label">{labelText}</label>}
            <div style={{display: 'flex', width: '100%', alignItems: 'center', padding: '0.5rem', paddingLeft: '1rem', transition: 'background 250ms', background: hasStockFlow ? '#161616' : '#f4f4f4', color: hasStockFlow ? 'white' : undefined, borderRadius: '0.25rem'
        }}>
                {hasStockFlow ? (
                    stockFlowMini !== undefined ? (
                        <div style={{flex: 1, minWidth: '25%',}}>
                            <Link target="_blank" to={"/stock-flow/" + stockFlowId} style={{textDecoration: 'none', color: 'inherit', background: 'red', pointerEvents: preventClick ? 'none' : undefined}}>
                                <p style={{opacity: '1', fontSize: 16}}>{purchase ? stockFlowMini.sourceName : stockFlowMini.destinationName}</p>
                                <p style={{opacity: '0.65', fontSize: 14, marginTop: -4}}>{purchase ? 'Purchase ID ' : 'Sales ID: '} {stockFlowMini.recordId}</p>
                            </Link>
                        </div>
                    ) : (
                        <p style={{opacity: '0.65', fontSize: 14, flex: 1, minWidth: '25%'}}>Loading</p>
                    )
                ) : (
                    <p style={{opacity: '0.65', fontSize: 14, flex: 1, minWidth: '25%'}}>Nothing selected</p>
                )}

                <div className={hasStockFlow ? "white-search" : ""}>  
                    <ExpandableSearch
                    key={searchKey}
                    size="xl"
                    disabled={loading}
                    hideLabel
                    style={hasStockFlow ? {background: 'transparent', color: 'white'} : {}}
                    value={stockFlowRecordIdValue}
                    // renderIcon={<div style={{width: 48, height: 48, display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    //     <Barcode16 />
                    // </div>}
                    placeholder="Find by ID"
                    onPaste={e => onSearchBtn((e.clipboardData || window.clipboardData).getData('text'))}
                    onKeyPress={e => {
                        if (e.key == "Enter") {
                            onSearchBtn();
                        }
                    }}
                    onChange={e => setStockFlowRecordIdValue(e.target.value)} />
                </div>
                
                <div style={{width: 5}} />

                <Button kind="danger" hasIconOnly renderIcon={RowDelete32} size="lg" 
                iconDescription="Remove"
                style={{height: 48, alignSelf: 'flex-end'}} disabled={nonExistent || !hasStockFlow || loading} onClick={onClearBtn} />
            </div>
        </div>
    )
}