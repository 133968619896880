import React, { useState } from 'react'
import Page from '../../base/Page';
import Api from '../../session/Api';

import {
    Number_132,
    Number_232,
    Number_332,
    Number_432,
    Number_532,
    Delivery32,
    Building32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    Delete16,
    CheckboxIndeterminate32,
    Save16,
    Reset16,
    FlowStream32,
    DirectionStraightRight32,
    TextLinkAnalysis32,
    Calendar32,
    Calculator32,
    Run32,
    TreeView32,
    DeliveryParcel32,
    Currency32,
    Notebook16,
    DeliveryParcel16,
    Money16,
    Archive32,
    TagEdit16,
    Edit16,
    Box16,
    Report32,
    Launch32,
    Catalog32,
    Money32,
    FlowStream16,
    User32,
    RequestQuote16,
    Notebook32,
    ArrowLeft16,
    Account32,
    PenFountain16,
    PenFountain32,
    Finance32,
    Security32,
    Wallet32,
    AddAlt16,
    SubtractAlt16,
    Wallet16,
    CheckmarkFilled32,
    Enterprise32,
    Education32,
    UserSpeaker32,
    BuildingInsights_332
} from '@carbon/icons-react'
import Button from '../../components/Button';
import { getObjectTypeUrl, OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TRANSACTION, OBJECT_TYPE_TUTOR, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from '../../constants/ObjectTypes';
import Util, { isV2 } from '../../util/Util';
import { Link } from 'react-router-dom';
import ProfilePic from '../../components/ProfilePic';
import DirectionIndicator from '../../components/DirectionIndicator';
import {
    DESTINATION_TYPE_CUSTOMER, DESTINATION_TYPE_STOCK_TERMINATION, DESTINATION_TYPE_STORE, DESTINATION_TYPE_SUPPLIER, DESTINATION_TYPE_WAREHOUSE, SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_VENUE, SOURCE_TYPE_WAREHOUSE,
    TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_STAFF, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_V2_CUSTOMER, TRANSACTION_PARTY_TYPE_V2_EMPLOYEE, TRANSACTION_PARTY_TYPE_V2_VENDOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE
} from '../../constants/Constants';
import TransactionEditor from './TransactionEditor';
import { ButtonSet, Link as DLink, Tile } from 'carbon-components-react';
import NoteView from '../../views/notes/NoteView';
import { PaymentVoucherDownload } from '../../pdfs/payment-voucher/PaymentVoucherPdf';
import { ReceiptVoucherDownload } from '../../pdfs/receipt-voucher/ReceiptVoucherPdf';

import { CHEQUE_TATUS_DEPOSITED } from '../../constants/Constants'
import { getOptionFromItem } from '../../domain/transaction-against';
import UIUtil from '../../util/UIUtil';
import { AmountTagSelectorNormalField } from '../journal-entries/tag-selector';
import { getReportDesignLink, listDetailItemId } from '../../reporting-engine/base/report-page';
import { SidePanel } from '../../templates/draft/components/side-panel';
import { EditTransaction } from './edit-transaction';

function convertRemToPixels(rem) {
    return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
}

const Section = ({ id, children, icon, title, subtitle, extraTopMargin }) => (
    <div id={id} style={{ marginTop: extraTopMargin ? '6rem' : '3rem' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
            <div style={{ width: '1rem', marginRight: '0.5rem', height: 1, background: 'black', opacity: 0.25 }} />
            {React.createElement(icon, { style: { transform: 'scale(0.75)' } })}
            <p>{title}</p>
            <div style={{ flex: 1, marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            {subtitle !== undefined && <>
                <p style={{ fontSize: 12, opacity: 0.65, marginLeft: '0.5rem' }}>{subtitle}</p>
                <div style={{ width: '1rem', marginLeft: '0.75rem', height: 1, background: 'black', opacity: 0.25 }} />
            </>}
        </div>
        {children}
    </div>
)

const RadioItem = ({ icon, title, desc }) => (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
            {React.createElement(icon)}
            <h4 style={{ marginLeft: '0.5rem' }}>{title}</h4>
        </div>
        {Util.isStringExists(desc) && <p style={{ marginTop: 0, fontSize: 14, opacity: 1 }}>
            {desc}
        </p>}
    </div>
)

const RecordInfo = ({ transaction }) => (
    <Section icon={Account32} title="Transaction Record Info">
        {Util.isStringExists(transaction.refNo) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Transaction No'}</label>
                <p style={{}}>{transaction.refNo}</p>
            </div>}
        {Util.isNumberExist(transaction.transactionDate) &&
            <div style={{ marginBottom: '1rem' }}>
                <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Transaction Date'}</label>
                <p style={{}}>{Util.getDateOnly(transaction.transactionDate)}</p>
            </div>}

        <div style={{ display: 'flex', gap: '0.5rem' }}>
            {Util.isStringExists(transaction.approvedByName) &&
                <div style={{ flex: 1 }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Approved by name'}</label>
                    <p style={{}}>{transaction.approvedByName}</p>
                </div>}
            {Util.isStringExists(transaction.approvedByIdNo) &&
                <div style={{ flex: 1 }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Approved by ID No'}</label>
                    <p style={{}}>{transaction.approvedByIdNo}</p>
                </div>}
            {Util.isStringExists(transaction.receivedByName) &&
                <div style={{ flex: 1 }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Received by name'}</label>
                    <p style={{}}>{transaction.receivedByName}</p>
                </div>}
            {Util.isStringExists(transaction.receivedByIdNo) &&
                <div style={{ flex: 1 }}>
                    <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Received by ID No'}</label>
                    <p style={{}}>{transaction.receivedByIdNo}</p>
                </div>}
        </div>
    </Section>
)


const AgainstItem = ({ againstItem, transaction }) => {
    const [expanded, setExpanded] = useState(false);

    const paymentMethodHeight = 80 + convertRemToPixels(0.5);

    // const isQuotation = () => {
    //     if (againstItem.invoiceDocument || againstItem.bill) {
    //         return false;
    //     }
    //     return againstItem.stockFlow.quotation && (againstItem.stockFlow.transactionIds == null || againstItem.stockFlow.transactionIds.length == 0);
    // }

    // const isSourceExternal = () => {
    //     if (againstItem.invoiceDocument || againstItem.bill) {
    //         return false;
    //     }
    //     return againstItem.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER || againstItem.stockFlow.sourceType == SOURCE_TYPE_STOCK_CREATION || againstItem.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER;
    // }

    // const isDestinationExternal = () => {
    //     if (againstItem.invoiceDocument || againstItem.bill) {
    //         return false;
    //     }
    //     return againstItem.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER || againstItem.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER || againstItem.stockFlow.destinationType == DESTINATION_TYPE_STOCK_TERMINATION;
    // }


    // let stockFlowType;
    // if (againstItem.invoiceDocument) {
    //     stockFlowType = "Invoice Document";
    // } else if (againstItem.bill) {
    //     stockFlowType = "Bill";
    // } else {
    //     if (againstItem.stockFlow.sourceType == SOURCE_TYPE_SUPPLIER && !isDestinationExternal()) {
    //         stockFlowType = "Purchase"
    //     } else if (!isSourceExternal() && againstItem.stockFlow.destinationType == DESTINATION_TYPE_CUSTOMER) {
    //         if (againstItem.stockFlow.sourceType == SOURCE_TYPE_VENUE) {
    //             stockFlowType = "Venue Sales";
    //         } else {
    //             stockFlowType = isQuotation() ? "Quotation" : "Sales";
    //         }
    //     } else if (!isSourceExternal() && againstItem.stockFlow.destinationType == DESTINATION_TYPE_SUPPLIER) {
    //         return "Purchase Return"
    //     } else if (againstItem.stockFlow.sourceType == SOURCE_TYPE_CUSTOMER && !isDestinationExternal()) {
    //         return "Sales Return";
    //     } else if (!isSourceExternal() && !isDestinationExternal()) {
    //         return "Stock Transfer";
    //     }
    // }
    // let id;
    // let link;
    // let date;
    // let amount;

    // if (againstItem.bill) {
    //     id = againstItem.bill.id;
    //     link = '/bill/' + againstItem.bill.id;
    //     date = Util.getDate(againstItem.bill.initiationDate);
    //     amount = againstItem.bill.amount
    // } else {
    //     id = againstItem.invoiceDocument ? againstItem.invoiceDocument.id : againstItem.stockFlow.id;
    //     link = againstItem.invoiceDocument ? '/invoice-documents/' + againstItem.invoiceDocument.id : "/stock-flow/" + againstItem.stockFlow.id
    //     date = againstItem.invoiceDocument ? Util.getDate(againstItem.invoiceDocument.dateCreated) : Util.getDate(againstItem.stockFlow.initiationDate);
    //     amount = againstItem.invoiceDocument ? againstItem.invoiceDocument.amount : againstItem.stockFlow.amount;
    // }

    const {
        id, name, link, date, amount
    } = getOptionFromItem(againstItem);

    return (
        <div style={{
            transition: '250ms', height: expanded ? 75 + (115 + (paymentMethodHeight * 1 - 1)) : 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{
                height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', //cursor: 'pointer' 
            }} //onClick={() => setExpanded(!expanded)}
            >
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>{name} (voucher no: {Util.getVoucherNumber(id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>AED {againstItem.dividedAmount.toFixed(2)}</h2>
                        {amount &&
                            <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {amount.toFixed(2)} (Total {name} Amount)</p>}
                    </div>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem' }}>{date}</p>
                <Link to={link} style={{ textDecoration: 'none', }}>
                    <Button kind="secondary" renderIcon={FlowStream16} style={{ width: 195 }}>Open {name}</Button>
                </Link>
            </div>
            {/* <div style={{height: 115 + (paymentMethodHeight * transaction.paymentMethods.length - 1), paddingLeft: '15px', paddingBottom: 15, paddingRight: 15, overflow: 'auto' }} >
                <div style={{width: '100%', display: 'flex', justifyContent: 'flex-end'}}>
                    <Link to={"/transaction/" + transaction.id} style={{textDecoration: 'none',}}>
                        <Button kind="secondary" renderIcon={FlowStream16} style={{width: 195}}>Open Transaction</Button>
                    </Link>
                </div>

                <TransactionEditor
                hideTotal
                readOnly showColorForIncome
                defaultPaymentMethods={transaction.paymentMethods}
                currency={"AED"}
                incoming={!isSourceExternal}
                totalAmount={transaction.amount} />
            </div> */}
        </div>
    )
}

const CrvItem = ({ crv, transaction }) => {
    return (
        <div style={{
            transition: '250ms', height: 75, overflow: 'hidden',
            background: 'white', marginBottom: '0.5rem', boxShadow: "0px 0px 10px 0px rgba(0, 0, 0, 0.1)", borderRadius: '0.25rem',
        }}>
            <div style={{
                height: 75, display: 'flex', flex: 1, alignItems: 'center', padding: '15px', //cursor: 'pointer' 
            }} //onClick={() => setExpanded(!expanded)}
            >
                <div style={{ paddingLeft: '0.25rem', paddingRight: '0.25rem', flex: 1 }}>
                    <p style={{ fontSize: 14 }}>CRV (voucher no: {Util.getVoucherNumber(crv.id)})</p>
                    <div style={{ display: 'flex', alignItems: 'flex-end' }}>
                        <h2>AED {crv.amountReturnedToTransaction.toFixed(2)}</h2>
                        <p style={{ marginLeft: '0.25rem', paddingBottom: '0.25rem' }}> / AED {crv.amountReturned.toFixed(2)} (Total CRV Amount)</p>
                    </div>
                </div>
                <p style={{ fontSize: 12, opacity: 0.65, marginRight: '1rem' }}>{Util.getDate(crv.initiationDate)}</p>
                <Link to={"/crv/" + crv.id} style={{ textDecoration: 'none', }}>
                    <Button kind="secondary" renderIcon={Wallet16} style={{ width: 195 }}>Open CRV</Button>
                </Link>
            </div>
        </div>
    )
}

class TransactionItemPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            transaction: undefined,
            showEditDialog: false
        }

        this.componentMode = this.props.componentMode;
    }

    onPageStart() {
        this.callPageApi(listener => Api.getTransactionItem(this.getPathParams().transactionId, listener), payload => ({
            transaction: payload
        }))
    }

    isInward() {
        return this.state.transaction.directionType == TRANSACTION_DIRECTION_TYPE_INWARD;
    }

    renderPrintBtn() {
        let btn;
        if (this.isInward()) {
            //print receipt voucher
            btn = <ReceiptVoucherDownload transaction={this.state.transaction} />
        } else {
            //print payment voucher
            btn = <PaymentVoucherDownload transaction={this.state.transaction} />
        }

        if (btn) {
            return (
                <div style={{ marginBottom: '1rem', marginTop: '1rem' }}>
                    {btn}
                </div>
            )
        } else {
            return null;
        }
    }

    getUndepositedCheques() {
        let cheques = [];
        const transaction = this.state.transaction;
        if (transaction.paymentMethods) {
            for (const paymentMethod of transaction.paymentMethods) {
                if (paymentMethod.cheque && paymentMethod.cheque.status != CHEQUE_TATUS_DEPOSITED) {
                    cheques.push(paymentMethod.cheque)
                }
            }
        }
        return cheques;
    }

    hasUndepositedCheques() {
        return this.getUndepositedCheques().length > 0;
    }

    onDeleteBtn() {
        UIUtil.confirmWithMsg(`Deleting the transaction is irreversible and will be permanent!`, () => {
            Api.deleteTransaction(this.state.transaction.id, response => {
                if (response.status === true) {
                    this.props.history.goBack();
                    UIUtil.showSuccess();
                } else {
                    UIUtil.showError(response.message);
                }
            })
        })
    }

    renderAmount() {
        const transaction = this.state.transaction;
        if (Util.isNumberExist(transaction.processingFeeAmount)) {
            const actualAmount = transaction.amount - transaction.processingFeeAmount;
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DirectionIndicator inward={this.isInward()} suffix={this.isInward() ? "(receipt)" : "(payment)"} />
                    <p style={{ marginLeft: '0.25rem' }}>{actualAmount.toFixed(2)} + {transaction.processingFeeAmount.toFixed(2)} <span style={{ fontSize: 12, opacity: 0.65 }}>(processing fee)</span> = <strong>AED {transaction.amount.toFixed(2)}</strong></p>
                </div>
            )
        } else {
            return (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <DirectionIndicator inward={this.isInward()} suffix={this.isInward() ? "(receipt)" : "(payment)"} />
                    <p style={{ marginLeft: '0.25rem' }}>AED {transaction.amount.toFixed(2)}</p>
                </div>
            )
        }
    }

    canEdit() {
        const transaction = this.state.transaction;
        return !isV2() && transaction.canEdit
    }

    getLayout() {
        const transaction = this.state.transaction;
        return (
            <div style={{ width: '100%', display: 'flex', justifyContent: 'center', paddingBlock: this.componentMode ? 0 : '6rem', padding: this.componentMode ? '3rem' : 0 }}>
                <div style={{ width: this.componentMode ? '100%' : '75vw' }}>
                    {!this.componentMode &&
                        <DLink onClick={() => this.props.history.goBack()} style={{ marginBottom: '1rem', cursor: 'pointer', display: 'flex', alignItems: 'center' }}><ArrowLeft16 style={{ marginRight: '0.25rem' }} /> Back to list</DLink>}
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Currency32 style={{ marginRight: '0.25rem', width: 40, height: 40 }} />
                        <h1 style={{ flex: 1 }}>Transaction</h1>

                        {/* {
                        // Util.isNumberExist(transaction.stockFlowId) ? (
                        //     <ButtonSet style={{width: '392px', gap: 2}}>
                        //         <Link to={"/stock-flow/" + transaction.stockFlowId} style={{textDecoration: 'none',}}>
                        //             <Button kind="secondary" renderIcon={FlowStream16} style={{width: 195}}>Associated Stock Flow</Button>
                        //         </Link>
                        //         <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{width: 195}}>Notes/Docs</Button>
                        //     </ButtonSet>
                        // ) : 
                        Util.isNumberExist(transaction.journalEntryId) ? (
                            <ButtonSet style={{width: '392px', gap: 2}}>
                                <Link to={"/journal-entry/" + transaction.journalEntryId} style={{textDecoration: 'none',}}>
                                    <Button kind="secondary" renderIcon={RequestQuote16} style={{width: 195}}>Associated Journal Entry</Button>
                                </Link>
                                <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16} style={{width: 195}}>Notes/Docs</Button>
                            </ButtonSet>
                        ) : (
                            <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                        )} */}

                        {transaction.canDelete &&
                            <Button kind="danger" onClick={() => this.onDeleteBtn()} renderIcon={Delete16} style={{ marginRight: 2, width: 195 }}>Delete</Button>}
                        <Button onClick={() => window.location.href = "#note-docs"} renderIcon={Notebook16}>Notes/Docs</Button>
                    </div>
                    {this.canEdit() &&
                        <Button size="sm" style={{ borderRadius: 25, marginTop: '0.25rem' }} kind="secondary" renderIcon={Edit16} onClick={() => this.setState({ showEditDialog: true })}>Edit</Button>}

                    {(
                        Util.isNumberExist(transaction.transactionDate) ||
                        Util.isStringExists(transaction.refNo) ||
                        Util.isStringExists(transaction.approvedByName) ||
                        Util.isStringExists(transaction.receivedByName) ||
                        Util.isStringExists(transaction.approvedByIdNo) ||
                        Util.isStringExists(transaction.receivedByIdNo)
                    ) && <RecordInfo transaction={transaction} />}

                    <Section icon={Report32} title="Transaction Info">
                        {this.hasUndepositedCheques() && <Tile style={{ marginBottom: '1rem' }}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <PenFountain16 style={{ marginRight: '0.5rem', fill: 'red' }} />
                                <h6 style={{ color: 'red' }}>Undeposited cheques!</h6>
                            </div>
                            <p style={{ marginTop: '0.5rem', fontSize: 12 }}>
                                The following cheques in this transaction are not yet deposited:
                                <br />
                                <br />

                                {this.getUndepositedCheques().map(cheque =>
                                    <>
                                        <Link target="_blank" key={'undeposited-cheque-' + cheque.id} to={'/cheques/' + cheque.id}>{cheque.bankName} - {cheque.chequeNo}</Link>
                                        <br />
                                    </>
                                )}
                            </p>
                        </Tile>}

                        <label style={{ marginBottom: '0.25rem' }} className="bx--label">Total Amount</label>
                        {this.renderAmount()}

                        <div style={{ marginTop: '1rem', }}>
                            <label style={{ marginBottom: '-0.25rem' }} className="bx--label">{'Voucher Number'}</label>
                            <p style={{}}>{Util.getTransactionVoucherNumber(this.state.transaction.id, this.state.transaction.directionType, this.state.transaction.sequenceNo)}</p>
                        </div>

                        <label style={{ marginTop: '1rem', marginBottom: '0.25rem' }} className="bx--label">Transaction ID</label>
                        <p style={{}}>{transaction.transactionId}</p>

                        {this.renderPrintBtn()}

                        <label style={{ marginTop: '1rem', marginBottom: '0.5rem' }} className="bx--label">{this.isInward() ? "Payer" : "Recipient"}</label>
                        {this.renderOtherPartyCard()}
                    </Section>

                    {//transaction.crvIds && transaction.crvIds.length > 0 &&
                        transaction.requireCashReceipt &&
                        <Section icon={Wallet32} title="CRV">
                            <div style={{ display: 'flex', marginBottom: "1.5rem", justifyContent: 'center', alignItems: 'center' }}>
                                {transaction.amountRemainingToBeReturned ? (<>
                                    <div style={{ flex: 1, color: 'red' }}>
                                        <p>Total amount to be returned</p>
                                        <h1 style={{ marginBottom: "1.5rem" }}>AED {transaction.totalAmountToBeReturned.toFixed(2)}</h1>
                                    </div>

                                    <div style={{ flex: 1, color: 'green' }}>
                                        <p>Amount returned</p>
                                        <h1 style={{ marginBottom: "1.5rem" }}>AED {transaction.totalAmountReturned.toFixed(2)}</h1>
                                    </div>

                                    <div style={{ flex: 1 }}>
                                        <p>Amount remaining</p>
                                        <h1 style={{ marginBottom: "1.5rem" }}>AED {transaction.amountRemainingToBeReturned.toFixed(2)}</h1>
                                    </div>
                                </>) : (<>
                                    <CheckmarkFilled32 style={{ color: 'green', marginRight: '0.25rem' }} />
                                    <h4 style={{ color: 'green' }}>Amount Fully Returned</h4>
                                </>)}
                            </div>

                            {transaction.cashReceiptVouchers.map(crv => (
                                <CrvItem key={crv.id} crv={crv} transaction={transaction} />
                            ))}
                        </Section>}

                    {transaction.againstItems != null && transaction.againstItems.length > 0 &&
                        <Section icon={FlowStream32} title="Against">
                            <p>Total Amount</p>
                            <h1 style={{ marginBottom: "1.5rem" }}>AED {transaction.amount.toFixed(2)}</h1>
                            {transaction.againstItems.map(item => (
                                <AgainstItem key={item.id} againstItem={item} transaction={transaction} />
                            ))}
                        </Section>}

                    {Util.isNumberExist(transaction.journalEntryId) &&
                        <Section icon={TreeView32} title="Accounting">
                            <Link to={"/journal-entry/" + transaction.journalEntryId} style={{ textDecoration: 'none', }}>
                                <Button kind="secondary" renderIcon={RequestQuote16} style={{ width: 195 }}>Associated Journal Entry</Button>
                            </Link>

                            <div style={{ height: '1rem' }} />
                            <label className="bx--label">Class</label>
                            <div style={{ height: 40, }}>
                                <AmountTagSelectorNormalField readonly options={[]} value={transaction.tags.map(opt => (({ value: opt.id, label: opt.value })))} setValue={tagsValue => { }} />
                            </div>
                        </Section>}

                    <Section icon={Run32} title="Initiation">
                        <label style={{}} className="bx--label">Initiator</label>
                        <Link style={{ textDecoration: 'none', color: 'black' }} to={getObjectTypeUrl(OBJECT_TYPE_ACCOUNT) + "/" + transaction.initiatorId}>
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <ProfilePic size={30} style={{ marginRight: '0.5rem' }} src={Api.getThumbnail(OBJECT_TYPE_ACCOUNT, transaction.initiatorId)} />
                                <p>{transaction.initiatorFullName}</p>
                            </div>
                        </Link>

                        <label style={{ marginTop: '1rem' }} className="bx--label">Initiation Date</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Calendar32 style={{ marginRight: '0.5rem' }} />
                            <p>{Util.getDate(transaction.initiationDate)}</p>
                        </div>
                    </Section>

                    {Util.isStringExists(transaction.info) && <Section icon={Catalog32} title="Additional Information">
                        <p style={{ whiteSpace: 'pre-line' }}>{transaction.info}</p>
                    </Section>}

                    {Util.isNumberExist(transaction.chequeId) && <Section icon={Account32} title="Cheque">
                        <label style={{}} className="bx--label">Bank</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Finance32 style={{ marginRight: '0.5rem' }} />
                            <p>{transaction.associatedCheque.bankName}</p>
                        </div>

                        <label style={{ marginTop: '1rem' }} className="bx--label">Cheque No</label>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <PenFountain32 style={{ marginRight: '0.5rem' }} />
                            <p>{transaction.associatedCheque.chequeNo}</p>
                        </div>

                        <label style={{ marginTop: '1rem' }} className="bx--label">Cheque ID</label>
                        <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                            <Security32 style={{ marginRight: '0.5rem' }} />
                            <p>{transaction.associatedCheque.recordId}</p>
                        </div>

                        <Link to={"/cheques/" + transaction.chequeId} style={{ textDecoration: 'none' }}>
                            <Button kind="secondary" renderIcon={PenFountain16} style={{ width: 195 }}>View Cheque</Button>
                        </Link>
                    </Section>}

                    <Section icon={Money32} title="Payment Methods">
                        <TransactionEditor
                            readOnly hideDirection
                            defaultPaymentMethods={this.state.transaction.paymentMethods}
                            currency={"AED"}
                            incoming={this.isInward()}
                            totalAmount={this.state.transaction.amount} />
                    </Section>

                    <Section id="note-docs" icon={Notebook32} title="Notes/Docs" extraTopMargin>
                        <NoteView objectId={transaction.id} objectType={OBJECT_TYPE_TRANSACTION} />
                    </Section>
                </div>

                {this.state.showEditDialog && <SidePanel md onClose={() => this.setState({ showEditDialog: false })}>
                    <EditTransaction itemId={this.state.transaction.id} onClose={() => this.setState({ showEditDialog: false })} />
                </SidePanel>}
            </div>
        )
    }

    renderOtherPartyCard() {
        let objectType;
        switch (this.state.transaction.otherPartyType) {
            case TRANSACTION_PARTY_TYPE_SUPPLIER:
                objectType = OBJECT_TYPE_SUPPLIER;
                break;
            case TRANSACTION_PARTY_TYPE_CUSTOMER:
                objectType = OBJECT_TYPE_CUSTOMER;
                break;
            case TRANSACTION_PARTY_TYPE_STAFF:
                objectType = OBJECT_TYPE_ACCOUNT;
                break;
            case TRANSACTION_PARTY_TYPE_VENDOR:
                objectType = OBJECT_TYPE_VENDOR;
                break;
            case TRANSACTION_PARTY_TYPE_STORE:
                objectType = OBJECT_TYPE_STORE;
                break;
            case TRANSACTION_PARTY_TYPE_VENUE:
                objectType = OBJECT_TYPE_VENUE;
                break;
            case TRANSACTION_PARTY_TYPE_STUDENT:
                objectType = OBJECT_TYPE_STUDENT;
                break;
            case TRANSACTION_PARTY_TYPE_TUTOR:
                objectType = OBJECT_TYPE_TUTOR;
                break;
            case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
                objectType = OBJECT_TYPE_MOVIE_DISTRIBUTOR;
                break;
        }

        const card = this.getOtherPartyCard();
        if (Util.isNumberExist(this.state.transaction.otherPartyId) && this.state.transaction.otherPartyType == TRANSACTION_PARTY_TYPE_OTHER) {
            return (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={'/account-ledgers/' + this.state.transaction.otherPartyId}>
                    {card}
                </Link>
            )
        } else if (Util.isNumberExist(this.state.transaction.otherPartyId) && this.state.transaction.otherPartyType == TRANSACTION_PARTY_TYPE_V2_CUSTOMER) {
            return (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={listDetailItemId("CustomerList", this.state.transaction.otherPartyId)}>
                    {card}
                </Link>
            )
        } else if (Util.isNumberExist(this.state.transaction.otherPartyId) && this.state.transaction.otherPartyType == TRANSACTION_PARTY_TYPE_V2_VENDOR) {
            return (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={listDetailItemId("VendorList", this.state.transaction.otherPartyId)}>
                    {card}
                </Link>
            )
        } else if (Util.isNumberExist(this.state.transaction.otherPartyId) && this.state.transaction.otherPartyType == TRANSACTION_PARTY_TYPE_V2_EMPLOYEE) {
            return (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={listDetailItemId("EmployeeList", this.state.transaction.otherPartyId)}>
                    {card}
                </Link>
            )
        } else if (Util.isNumberExist(this.state.transaction.otherPartyId) && objectType !== undefined) {
            return (
                <Link style={{ textDecoration: 'none', color: 'inherit' }} to={getObjectTypeUrl(objectType) + '/' + this.state.transaction.otherPartyId}>
                    {card}
                </Link>
            )
        } else {
            return card;
        }
    }

    getOtherPartyCard() {
        switch (this.state.transaction.otherPartyType) {
            case TRANSACTION_PARTY_TYPE_SUPPLIER:
                return <RadioItem icon={Delivery32} title="Supplier" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_CUSTOMER:
                return <RadioItem icon={AirlinePassengerCare32} title="Customer" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_STAFF:
                return <RadioItem icon={User32} title="Staff" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_OTHER:
                return <RadioItem icon={Launch32} title="Other" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_VENDOR:
                return <RadioItem icon={Enterprise32} title="Vendor" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_STORE:
                return <RadioItem icon={Store32} title="Store" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_VENUE:
                return <RadioItem icon={Building32} title="Venue" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_STUDENT:
                return <RadioItem icon={Education32} title="Student" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_TUTOR:
                return <RadioItem icon={UserSpeaker32} title="Tutor" desc={this.state.transaction.otherPartyName} />
            case TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR:
                return <RadioItem icon={BuildingInsights_332} title="Movie Distributor" desc={this.state.transaction.otherPartyName} />
        }

        return undefined;
    }

}

export default TransactionItemPage;