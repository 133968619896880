import { useState } from "react";
import { makeDraggable } from "../hooks/useDraggable";
import { useDraggable } from "../hooks/useDraggable";
import Util from "../util/Util";
import { AddFilled16 } from '@carbon/icons-react'

const InputFieldItemHeader = () => (
    <div style={{ flex: 2, display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
)

const InputFieldItem = ({ item, setItemValue, onRemove, SortHandler }) => {
    return (
        <div style={{
            width: '100%', background: 'white', marginBottom: '1rem', padding: '0rem', borderRadius: 5,
            boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06)',
            // overflow: 'hidden',
            border: 'solid', borderWidth: 1, borderColor: '#00000090'
        }}>
            {/* <div>
                {JSON.stringify(items)}
                <button onClick={() => setItem2(item)}>sync</button>
            </div> */}
            <div style={{ width: '100%', display: 'flex', alignItems: 'center', paddingInline: '0.5rem' }}>
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{item.content}</p>
                    <input value={item.content} onChange={e => setItemValue('content', e.target.value)} />
                </div>
                <SortHandler />
                <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
                    <p onClick={onRemove} style={{ fontSize: 12, color: 'red', textDecoration: 'underline', cursor: 'pointer' }}>Remove Entry</p>
                </div>
            </div>

        </div>
    )
}

export function makeListInput(record, handler, readonly, newDefaults) {
    return (props) => <ListInput {...props} record={record} handler={handler} readonly={readonly} newDefaults={newDefaults} />
}


const DEF_VAL = []
const arr = (value) => Array.isArray(value) ? value : DEF_VAL;

export function ListInput({ value, setValue, record, handler, itemProps = {}, newDefaults = {}, readonly }) {
    value = arr(value);
    const draggable = useDraggable(record, handler)

    const createNewItem = () => setValue(prev => [...arr(prev), { id: Util.newTempId(), ...(newDefaults ?? {}) }])
    const setItemValue = (id, property, value) => setValue(prev => arr(prev).map(item => item.id === id ? ({
        ...item, [property]: value
    }) : item))
    const updateItem = (id, update) => setValue(prev => arr(prev).map(item => item.id === id ? ({
        ...item, ...update
    }) : item))
    const removeItem = (id) => setValue(prev => arr(prev).filter(item => item.id !== id));

    return (<>
        <draggable.SortContainer inModal onSortEnd={draggable.createOnSortEnd(value, setValue)}>
            {value.map((item, index) =>
                <draggable.SortItem
                    {...itemProps}
                    key={item.id} index={index} item={item}
                    setItemValue={(property, value) => setItemValue(item.id, property, value)}
                    updateItem={update => updateItem(item.id, update)}
                    onRemove={() => removeItem(item.id)} />)}
        </draggable.SortContainer>

        {/* <button onClick={createNewItem}>new</button> */}
        {!readonly && <div style={{ width: '100%', marginBlock: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <button onClick={createNewItem} className="lead-flow-icon-button"><AddFilled16 /></ button>
        </div>}
    </>)
}