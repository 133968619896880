import { isMobileScreen } from "../../../App";
import { ArrowLeft16 } from '@carbon/icons-react'


export function SidePanel({ children, onClose, backBtn, md, md2, lg, highZ, belowSubheader, fullscreen, fullHeight, background = "white" }) {
    const left = fullscreen ? 0 : isMobileScreen ? 0 : lg ? 0 : md2 ? '7%' : md ? '15%' : '25%';
    return (
        <div onClick={() => onClose(false)} style={{
            width: '100%', height: (fullscreen || fullHeight) ? '100%' : belowSubheader ? 'calc(100% - 6rem)' : 'calc(100% - 3rem)',
            paddingLeft: left, zIndex: (fullscreen || highZ) ? 10002 : 1001, position: 'fixed', left: 0, top: (fullscreen || fullHeight) ? 0 : belowSubheader ? '6rem' : '3rem', background: 'rgba(0, 0, 0, 0.65)', boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', animation: '250ms draft-advanced-background-fade-in',
        }}>
            <div onClick={e => e.stopPropagation()} style={{ position: 'relative', height: '100%', width: '100%', background, animation: '250ms draft-advanced-panel-fade-in', overflow: 'auto' }}>
                {children}
            </div>

            {backBtn && (
                <button style={{
                    position: 'absolute', left: left, transform: lg ? 'translateX(50%)' : 'translateX(-50%)', top: '1.5rem',
                    animation: '250ms actual-opacity-fade-in-anim', width: lg ? 30 : 45, height: lg ? 30 : 45,
                    boxShadow: lg ? '0px 2px 5px 0px rgba(0,0,0,0.14) , 0px 1px 10px 0px rgba(0,0,0,0.12) , 0px 2px 4px -1px rgba(0,0,0,0.2) ' : undefined
                }} onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                    <ArrowLeft16 />
                </button>
            )}
        </div>
    )
}