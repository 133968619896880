import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile } from 'carbon-components-react'
import { makeSettingComponent } from '..'



const EnforceUniqueStockFlowSupplierInvoiceNo = makeSettingComponent('enforce_unique_stock_flow_supplier_invoice_no')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Enforce unique purchase supplier invoice no">
                <EnforceUniqueStockFlowSupplierInvoiceNo />
            </AccordionItem>
        </Accordion>
    )
}