import { Checkbox, TextArea, TextInput } from "carbon-components-react";
import { ComboBoxField } from "../../../../../components/ComboBoxField";
import { makeListInput } from "../../../../../components/list-input";
import { Close16 } from '@carbon/icons-react'
import Util from "../../../../../util/Util";
import Api from "../../../../../session/Api";
import UIUtil from "../../../../../util/UIUtil";

const ImageItem = ({ src }) => (
    <img
        onClick={() => {
            UIUtil.showOverlay2(onClose => <img onClick={onClose} src={src} style={{ width: '100%', height: '100%', objectFit: 'contain', objectPosition: 'center' }} />)
        }}
        src={src} style={{ width: 100, height: 100, objectFit: 'cover', objectPosition: 'center', borderRadius: 7, boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }} />
)

const Images = ({ images }) => {
    return (<>
        <label className="bx--label">Inspection images</label>
        <div style={{ background: '#00000010', padding: '1rem', width: '100%', borderRadius: 10, minHeight: 75, display: "flex", gap: '1rem', flexWrap: 'wrap' }}>
            {images?.map(image => <ImageItem key={image.id} src={Api.downloadUploadFieldFileAsImage(image.inspectionImage)} />)}
            {(images?.length ?? 0) === 0 && <p style={{ fontSize: 12, opacity: 0.65 }}>No images uploaded</p>}
        </div>
    </>)
}

export const PackageInspectionListEditor = makeListInput(({ item, setItemValue, onRemove, SortHandler, packageTypes }) => {
    if (!Util.isNumberExist(item.id)) {
        return <div />;
    }
    return (<>
        <div style={{ width: '100%', padding: '1rem', marginBottom: '1rem', background: 'white', border: '1px solid #00000010', borderRadius: 15, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
            <div style={{ display: 'flex', alignItems: 'flex-start' }}>
                <h6>Package: {item.id}</h6>
                <div style={{ flex: 1 }} />
                <button onClick={onRemove} className='lead-flow-icon-button'>
                    <Close16 />
                </button>
            </div>

            <Images images={item.inspectionImages} />


            <div style={{ width: '100%', marginBottom: '1rem', paddingLeft: '6rem' }}>
                {item.items?.map(packageItem => (<>
                    <div style={{ marginTop: '1rem' }}>
                        <h6>Item: {packageItem.id}</h6>
                        {/* <Images /> */}
                        <Images images={packageItem.inspectionImages} />
                    </div>
                </>))}
            </div>

            <div style={{ marginTop: '3rem' }} onClick={() => setItemValue('confirmed', !item.confirmed)}>
                <Checkbox checked={item.confirmed} labelText="Confirm inspection" />
            </div>
            {item.confirmed && <TextArea value={item.confirmNote} placeholder="Confirm note..." onChange={e => setItemValue("confirmNote", e.target.value)} />}

        </div>


    </>)
}, () => (
    <div style={{ display: 'flex', justifyContent: 'center', cursor: 'grab' }}>
        <label style={{ margin: 0, padding: 0, textAlign: 'center', color: 'black', fontSize: 10, cursor: 'grab' }} className="bx--label">drag here to order</label>
    </div>
), true)