import { User32 } from '@carbon/icons-react';
import React from 'react'
import Page from "../../base/Page";
import { getAccount } from '../../session/SessionManager';
import { Link } from "react-router-dom"
import Button from '../../components/Button';
import { ArrowRight16 } from "@carbon/icons-react"
import { hasCapabilitySupport } from '../../app/Capabilities';
import { isMobileScreen } from '../../App';

class DashboardWelcomePage extends Page {

    getLayout() {
        return (
            <div style={{
                width: '100%', height: '100%',
                display: 'flex', flexDirection: 'column', justifyContent: 'center',
                ...(isMobileScreen ? {
                    padding: '1rem'
                } : {
                    paddingLeft: '15rem', paddingRight: '15rem', paddingTop: '3rem', paddingBottom: '3rem',
                })
            }}>
                <div style={{
                    background: '#f4f4f4', display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: '3rem'
                }}>
                    <User32 style={{ transform: 'scale(2)', marginLeft: 10 }} />
                    <h2 style={{ marginTop: '2rem' }}>Welcome, {getAccount().fullName}</h2>
                    <p>{getAccount().typeName}</p>

                    <Link to={"/dashboard"}>
                        <Button style={{ marginTop: '6rem' }} renderIcon={ArrowRight16}>Go to Dashboard</Button>
                    </Link>
                    {hasCapabilitySupport("restaurant") && <Link to={"/business-summary"}>
                        <Button style={{ marginTop: '1rem' }} className="green-button" renderIcon={ArrowRight16}>Go to Business Summary</Button>
                    </Link>}
                </div>
            </div>
        )
    }

    isPageLoadable() {
        return false;
    }

}

export default DashboardWelcomePage;