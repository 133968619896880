
import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile } from 'carbon-components-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants'
import { OBJECT_TYPE_CONTACT, OBJECT_TYPE_PACKAGE_UNIT } from '../../../constants/ObjectTypes'
import Api from '../../../session/Api'
import ItemCreatorDialog from '../../../templates/ItemCreatorDialog'
import TableTemplate from '../../../templates/TableTemplate'
import UIUtil from '../../../util/UIUtil'
import { Warning16 } from '@carbon/icons-react';
import { isSettingsEnabled } from '../SettingsViews'
import { hasCapabilitySupport } from '../../../app/Capabilities'

import {
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    Van32,
    CheckmarkFilled16
} from '@carbon/icons-react'
import Button from '../../../components/Button'


const makeSettingComponent = settingKey => () => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled(settingKey, response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable(settingKey, enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}

const PosSessionAllowCustomPrice = makeSettingComponent('pos_session_allow_custom_price')
const PosSessionLogoutOnSessionEnd = makeSettingComponent('pos_session_logout_on_end_session')
const PosSessionForbidProductsWithZeroPrice = makeSettingComponent('pos_session_forbid_products_with_zero_price')
const PosSessionProductPriceDoesNotIncludeTax = makeSettingComponent('pos_session_product_price_does_not_include_tax')

export default ({ settingsValues }) => {
    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="Allow changing item price in cart">
                <PosSessionAllowCustomPrice />
            </AccordionItem>
            <AccordionItem title="Logout after session is stopped">
                <PosSessionLogoutOnSessionEnd />
            </AccordionItem>
            <AccordionItem title="Prevent products with the price of 0 to be added to the cart">
                <PosSessionForbidProductsWithZeroPrice />
            </AccordionItem>
            <AccordionItem title="Do not include TAX amount in the product price (e.g. VAT TAX)">
                <PosSessionProductPriceDoesNotIncludeTax />
            </AccordionItem>
        </Accordion>
    )
}