import { ArrowLeft16 } from '@carbon/icons-react'
import ProductViewer from '../../widgets/ProductViewer'
import PosState from '../../state/PosState'
import React from 'react'
import Page from '../../../../base/Page'
import RestPaymentWidget from '../RestPaymentWidget'
import RestCartContent from '../RestCartContent'
import { CartList } from '../views/modify-order/cart-list'
import PosDialogs from '../../PosDialogs'
import { ConfirmBtn } from '../views/modify-order/confirm-btn'
import { STATUS_REST_PREPARING } from '../../../sales-order/base/sales-order'
import { RestProducts } from '../RestProducts'
import { REST_ORDER_TYPE } from '../../../../domain/rest-sales-order'
import Util from '../../../../util/Util'

function subtitle(order) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return 'Table ' + order.tableName
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        if (Util.isStringExists(order.memo)) {
            return order.memo
        } else {
            return 'no memo'
        }
    } else if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.PICK_UP) {
        if (order.shippingAddress && (Util.isStringExists(order.shippingAddress.firstName) || Util.isStringExists(order.shippingAddress.lastName))) {
            const fullName = [order.shippingAddress.firstName, order.shippingAddress.lastName].filter(Util.isStringExists).join(" ");
            return fullName;
        } else {
            return "no name";
        }
    } else {
        return null;
    }
}


class Pos extends Page {

    state = {

    }

    posState = new PosState(() => this.state, this.setState.bind(this));

    onPageStart() {
        this.posState.loadSalesOrderSession(this.props.orderId);
    }

    componentWillUnmount() {
        this.posState.cancelSubscriptions();
    }

    getLayout() {
        return (
            <div className="pos" style={{ display: 'flex', height: '100%', width: '100%', padding: '0rem', userSelect: 'none', WebkitUserSelect: 'none', }} >
                <div style={{ height: '100%', flex: 1.5, background: '#f4f4f4' }}>
                    {/* <ProductViewer state={this.posState} /> */}
                    <RestProducts state={this.posState}
                    //showOnlineStore={this.props.order.type === REST_ORDER_TYPE.DELIVERY || this.props.order.type === REST_ORDER_TYPE.PICK_UP} 
                    />
                </div>
                <div style={{ height: '100%', flex: 1, display: 'flex', flexDirection: 'column', borderLeft: '1px solid #00000010' }}>
                    <div style={{ flex: 2, minHeight: 0, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', overflow: 'auto' }}>
                        <CartList state={this.posState} />
                    </div>
                    {/* <div style={{ height: 106, background: '#99000000', borderTop: '0px solid #00000020', }}>
                                        <RestCustomerInfo state={this.posState} responsive />
                                    </div> */}
                    {/* <RestPaymentWidget state={this.posState} /> */}
                    {//this.props.order.status >= STATUS_REST_PREPARING &&
                        this.posState.getSession()?.currentCartItems?.find($ => Util.isStringExists($.restKotNo)) &&
                        <ConfirmBtn state={this.posState} orderId={this.props.orderId} updateOrder={this.props.updateOrder} />}
                </div>

                <PosDialogs posState={this.posState} />
            </div>
        )
    }

    isPageLoading() {
        return this.posState.isLoadingPos() || !this.posState.getSession()
    }

}


export function ModifyOrderView({ order, updateOrder, onClose }) {
    return (
        <div style={{ height: '100%', width: '100%', }}>
            <div style={{ background: 'white', color: 'black', height: '3rem', width: '100%', borderBottom: '1px solid #00000020', paddingInline: '1rem', display: 'flex', alignItems: 'center' }}>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <ArrowLeft16 />
                </button>
                <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>Modify Order</h4>
                <div style={{ flex: 1 }} />
                <p>#{order.orderNo}</p>
                <div style={{ width: '1rem' }} />
                <p style={{ fontWeight: 'bold' }}>{subtitle(order)}</p>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 3rem)', display: 'flex' }}>
                <Pos orderId={order.id} updateOrder={updateOrder} />
            </div>
        </div>
    )
}

export function ModifyOrderViewWithOrderId({ orderId, orderNo, subtitle, onClose }) {
    return (
        <div style={{ height: '100%', width: '100%', }}>
            <div style={{ background: 'white', color: 'black', height: '3rem', width: '100%', borderBottom: '1px solid #00000020', paddingInline: '1rem', display: 'flex', alignItems: 'center' }}>
                <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                    <ArrowLeft16 />
                </button>
                <h4 style={{ fontWeight: 'bold', marginLeft: '0.25rem' }}>Modify Order</h4>
                <div style={{ flex: 1 }} />
                <p>#{orderNo}</p>
                <div style={{ width: '1rem' }} />
                <p style={{ fontWeight: 'bold' }}>{subtitle}</p>
            </div>
            <div style={{ width: '100%', height: 'calc(100% - 3rem)', display: 'flex' }}>
                <Pos orderId={orderId} updateOrder={() => { }} />
            </div>
        </div>
    )
}