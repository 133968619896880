import { Printer16, ShoppingCartPlus16, Maximize16, Chat16, StopFilled16, ChevronUp16, Calendar20, CheckmarkFilled16, ChevronDown16, Tag16, Play16, Checkbox16, Close16, ServiceDesk24, AirlinePassengerCare16, DeskAdjustable16, Checkmark16, Time16 } from '@carbon/icons-react'
import Button from '../../../../components/Button'
import { InlineLoading, Tag } from 'carbon-components-react'
import { KOT_ITEM_STATUS_AWAITING, KOT_ITEM_STATUS_DONE, KOT_ITEM_STATUS_STARTED, REST_ORDER_TYPE, ToggleDeliveryFee, UpdateOrderDetails, getRestOrderNote, payOrderBeforeInvoice, restOrderStatusComponents, restOrderTypeName } from '../../../../domain/rest-sales-order'
import { useMemo, useState } from 'react'
import { SidePanel } from '../../../../templates/draft/components/side-panel'
import Portal from '../../../../util/Portal'
import { ModifyOrderView } from './modify-order-view'
import UIUtil from '../../../../util/UIUtil'
import ProfilePic from '../../../../components/ProfilePic'
import Api from '../../../../session/Api'
import { useEffect } from 'react'
import { TypeTag, getRestOrderDetails } from './type-tag'
import Util from '../../../../util/Util'
import { getOrderAddress } from '../../../sales-order/base/sales-order'
import { OrderPreferencesDialog } from '../dialog/order-preferences'
import { ChangeTableDialog, TableDialog } from '../dialog/table-dialog'
import { DiscountBtn } from './discount-btn'
import { createScheduleFilter } from '../engine/OrderState'
import { RestUpdateOrderDateButton } from '../../../sales-order/views/update-order-date-button'
import React from 'react'
import { CustomerBtn } from './customer-btn'
import usePromise from '../../../../hooks/usePromise'
import { getAccountRole } from '../../../../session/SessionManager'
import { ACCOUNT_TYPE_CASHIER, ACCOUNT_TYPE_TYPE_WAITER } from '../../../../constants/Constants'

const OrderOptionButton = ({ onClick, icon, title, loading }) => (
    <div onClick={onClick} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '0.5rem', pointerEvents: loading ? 'none' : undefined, opacity: loading ? 0.65 : 1 }}>
        <div className="manage-pos-dialog-button">
            {React.createElement(icon)}
        </div>
        <h6 style={{ textAlign: 'center', fontSize: 12 }}>{title}</h6>
    </div>
)

const OrderMaximise = ({ order, maximise, setMaximise, updateOrder }) => {
    return (
        <Portal>
            {maximise && <SidePanel md onClose={() => setMaximise(false)}>
                <div style={{ paddingBlock: '6rem', paddingInline: '3rem' }}>
                    <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                        <ServiceDesk24 />
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>Order #{order.orderNo}</h4>
                        <button onClick={() => setMaximise(false)} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    </div>
                    {/* <pre><code>{JSON.stringify(order, null, 2)}</code></pre> */}

                    {order.kots.map((kot) => (<>
                        <h4>KOT #{kot.kotNo}</h4>
                        <div key={kot.kotNo} style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', marginBottom: '3rem' }}>
                            {kot.itemGroups.flatMap(group => group.items).map(item => (
                                <LargeKotItem key={item.id} item={item} order={order} updateOrder={updateOrder} />
                            ))}
                        </div>
                    </>))}
                </div>
            </SidePanel>}
        </Portal>
    )
}




const Options = ({ orderState, order, updateOrder, mainApp, deliveryLocations, maximise, setMaximise }) => {
    const [visible, setVisible] = useState(false);
    const [showPreferences, setShowPrefrences] = useState(false);
    const [showChangeTable, setShowChangeTable] = useState(false);
    //const [maximise, setMaximise] = useState(false);

    const [loadingTable, setLoadingTable] = useState(false);
    const setTableId = tableId => {
        setLoadingTable(true);
        Api.setOrderTable(order.id, tableId, response => {
            setLoadingTable(false);
            if (response.status === true) {
                UIUtil.showSuccess();
                updateOrder({ ...order, tableName: response.payload })
            } else {
                UIUtil.showError(response.payload);
            }
        })
    }

    // printReceipt
    const [printingBill, setPrintingBill] = useState(false)
    const onPrintBill = async () => {
        if (printingBill) {
            return;
        }

        if (mainApp?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setPrintingBill(true);
            try {
                // console.log(JSON.stringify(order))
                await mainApp.thermalPrinter.driver.printBill(order)
                UIUtil.showSuccess();
            } catch (e) {
                UIUtil.showError()
            } finally {
                setPrintingBill(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    return (<>
        <CustomerBtn order={order} updateOrder={updateOrder} />
        <ToggleDeliveryFee salesOrder={order} onUpdateOrder={updateOrder} />
        <UpdateOrderDetails salesOrder={order} onUpdateOrder={updateOrder} deliveryLocations={deliveryLocations} />
        {/* <RestUpdateOrderDateButton onUpdate={updateOrder} salesOrder={order} /> */}
        <DiscountBtn order={order} updateOrder={updateOrder} />
        {order.type === REST_ORDER_TYPE.DINE_IN &&
            <Button loading={loadingTable} style={{ maxWidth: 'unset' }} onClick={() => setShowChangeTable(true)} renderIcon={DeskAdjustable16} kind="secondary" size="md">Change Table</Button>}
        <Button style={{ maxWidth: 'unset' }} onClick={() => setVisible(true)} renderIcon={ShoppingCartPlus16} kind="secondary" size="md">Modify Order</Button>
        {(Util.isStringExists(order.preferences) || Util.isStringExists(order.restOrderNote)) &&
            <Button style={{ maxWidth: 'unset' }} onClick={() => setShowPrefrences(true)} renderIcon={AirlinePassengerCare16} kind="secondary" size="md">Preferences / Note</Button>}
        {order.kots?.length > 0 &&
            <Button style={{ maxWidth: 'unset' }} onClick={() => setMaximise(true)} renderIcon={Maximize16} kind="secondary" size="md">Maximize</Button>}
        <Button style={{ maxWidth: 'unset' }} loading={printingBill} onClick={onPrintBill} renderIcon={Printer16} kind="secondary" size="md">Print Bill</Button>



        <Portal>
            {showChangeTable && <ChangeTableDialog onClose={() => setShowChangeTable(false)} setTableId={setTableId} />}

            {visible && <SidePanel lg onClose={() => setVisible(false)}>
                <ModifyOrderView order={order} updateOrder={updateOrder} onClose={() => setVisible(false)} />
                {/* <RecipeManager onClose={() => setShowRecipeManager(false)} /> */}
            </SidePanel>}

            {(Util.isStringExists(order.preferences) || Util.isStringExists(order.restOrderNote)) &&
                <OrderPreferencesDialog order={order} open={showPreferences} onClose={() => setShowPrefrences(false)} />}

            <OrderMaximise maximise={maximise} order={order} setMaximise={setMaximise} updateOrder={updateOrder} />
        </Portal>
    </>)
}

const KotItem = ({ order, updateOrder, item }) => {
    const flagRemovedItemRecord = item.flagRemovedItemRecord;

    const [loading, setLoading] = useState(false);
    const onClick = () => {
        if (flagRemovedItemRecord) {
            return;
        }

        setLoading(true)
        const nextStatus =
            item.status === KOT_ITEM_STATUS_AWAITING ? KOT_ITEM_STATUS_STARTED :
                item.status === KOT_ITEM_STATUS_STARTED ? KOT_ITEM_STATUS_DONE : KOT_ITEM_STATUS_AWAITING
        Api.setKotStatusOfItem(order.id, item.id, nextStatus, response => {
            if (response.status === true) {
                updateOrder(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }


    return (
        <div onClick={onClick} style={{ borderTop: '1px solid #00000040', padding: '1rem', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: flagRemovedItemRecord ? undefined : 'pointer' }}>
            <ProfilePic notProfile size={42} style={{ borderRadius: 7 }} src={Api.getThumbnail(item.itemType, item.itemId)} />


            <div style={{ flex: 1 }}>

                {flagRemovedItemRecord ? (<>
                    <p style={{ fontSize: 18, fontWeight: 'bold', color: '#000000CC', textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.name}</p>
                    <p style={{ fontSize: 16, fontWeight: 'bold', color: '#000000CC', textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.qty}x</p>
                </>) : (<>
                    <p style={{ fontSize: 18, fontWeight: 'bold', color: '#000099' }}>{item.name}</p>
                    <p style={{ fontSize: 16, fontWeight: 'bold', color: '#990000' }}>{item.qty}x</p>
                </>)}

                {Util.isStringExists(item.recipeOptionSummary) && <p style={{ fontSize: 24, fontWeight: 'bold', color: "black", marginTop: '0.25rem', whiteSpace: 'pre-wrap' }}>{item.recipeOptionSummary}</p>}
            </div>
            {!flagRemovedItemRecord && <div className='really_centered-progress-bar' style={{ borderRadius: 7, background: '#f4f4f4', width: 26, height: 26, border: '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                {loading ? (
                    <InlineLoading style={{ width: 'unset' }} />
                ) : item.status === KOT_ITEM_STATUS_DONE ? (
                    <div style={{ width: 18, height: 18, background: '#00551a', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        <Checkmark16 />
                    </div>
                ) : item.status === KOT_ITEM_STATUS_STARTED ? (
                    <div style={{ width: 18, height: 18, background: '#0f62fe', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                        <Time16 />
                    </div>
                ) : null}
            </div>}
        </div>
    )
}

const LargeKotItem = ({ order, updateOrder, item }) => {
    const flagRemovedItemRecord = item.flagRemovedItemRecord;

    const [loading, setLoading] = useState(false);
    const onClick = () => {
        if (flagRemovedItemRecord) {
            return;
        }

        setLoading(true)
        const nextStatus =
            item.status === KOT_ITEM_STATUS_AWAITING ? KOT_ITEM_STATUS_STARTED :
                item.status === KOT_ITEM_STATUS_STARTED ? KOT_ITEM_STATUS_DONE : KOT_ITEM_STATUS_AWAITING
        Api.setKotStatusOfItem(order.id, item.id, nextStatus, response => {
            if (response.status === true) {
                updateOrder(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <div onClick={onClick} style={{ boxShadow: '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ', borderRadius: 15, padding: '1rem', display: 'flex', flexDirection: 'column', alignItems: 'center', gap: '0.5rem', cursor: flagRemovedItemRecord ? undefined : 'pointer' }}>
            <ProfilePic notProfile size={128} style={{ borderRadius: 7, width: '100%' }} src={Api.getThumbnail(item.itemType, item.itemId)} />
            <div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>
                <div style={{ flex: 1, }}>
                    {/* <p style={{ fontSize: 14 }}>{item.name}</p>
                    <p style={{ fontSize: 12, opacity: 0.65 }}>{item.qty}x</p> */}
                    {flagRemovedItemRecord ? (<>
                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#000000CC', textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.name}</p>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#000000CC', textDecorationLine: 'line-through', textDecorationColor: 'red', textDecorationThickness: 2 }}>{item.qty}x</p>
                    </>) : (<>
                        <p style={{ fontSize: 18, fontWeight: 'bold', color: '#000099' }}>{item.name}</p>
                        <p style={{ fontSize: 16, fontWeight: 'bold', color: '#990000' }}>{item.qty}x</p>
                    </>)}
                </div>
                {!flagRemovedItemRecord && <div className='really_centered-progress-bar' style={{ borderRadius: 7, background: '#f4f4f4', width: 26, height: 26, border: '1px solid #00000020', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    {loading ? (
                        <InlineLoading style={{ width: 'unset' }} />
                    ) : item.status === KOT_ITEM_STATUS_DONE ? (
                        <div style={{ width: 18, height: 18, background: '#00551a', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                            <Checkmark16 />
                        </div>
                    ) : item.status === KOT_ITEM_STATUS_STARTED ? (
                        <div style={{ width: 18, height: 18, background: '#0f62fe', borderRadius: 5, display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white' }}>
                            <Time16 />
                        </div>
                    ) : null}
                </div>}
            </div>
            {/* {Util.isStringExists(item.recipeOptionSummary) && <p style={{ width: '100%', fontSize: 14, opacity: 1, color: 'black', marginTop: '1rem', whiteSpace: 'pre-wrap', wordBreak: 'break-all', maxWidth: '100%' }}>{item.recipeOptionSummary}</p>} */}
            {Util.isStringExists(item.recipeOptionSummary) && <p style={{ fontSize: 24, fontWeight: 'bold', color: "black", marginTop: '0.25rem', whiteSpace: 'pre-wrap' }}>{item.recipeOptionSummary}</p>}
        </div>
    )
}

const KotCard = ({ order, updateOrder, kot, filterClasses, index, bottomPadding, mainApp }) => {
    const [loading, setLoading] = useState(false);
    const [maximise, setMaximise] = useState(false);

    const onStartAll = () => {
        setLoading(true)
        Api.setKotStatus(order.id, kot.kotNo, KOT_ITEM_STATUS_STARTED, response => {
            if (response.status === true) {
                updateOrder(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }
    const onDoneAll = () => {
        setLoading(true)
        Api.setKotStatus(order.id, kot.kotNo, KOT_ITEM_STATUS_DONE, response => {
            if (response.status === true) {
                updateOrder(response.payload)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    const [printingKot, setPrintingKot] = useState(false)
    const onPrintKot = async () => {
        if (printingKot) {
            return;
        }

        if (mainApp?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setPrintingKot(true);
            try {
                await mainApp.thermalPrinter.driver.printKot(order, kot, filterClasses, "Manual Print")
                UIUtil.showSuccess();
            } catch (e) {
                UIUtil.showError()
            } finally {
                setPrintingKot(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    const kotItems = useMemo(() => {
        if (filterClasses?.length && kot?.itemGroups?.length) {
            const filterValues = filterClasses.map($ => $.label)
            const visible = kot.itemGroups.filter(group => filterValues.includes(group.name));
            const hidden = kot.itemGroups.filter(group => !filterValues.includes(group.name))

            if (hidden.length) {
                return [...visible, {
                    id: Util.newTempId(), items: [], name: (
                        hidden.length === 1 ?
                            `${hidden.length} class hidden` :
                            `${hidden.length} classes hidden`
                    )
                }]
            } else {
                return visible;
            }
        } else {
            return kot.itemGroups;
        }
    }, [kot, filterClasses])

    return (
        <div style={{ width: index ? `calc(100% - ${index * 0.5}rem + 5px)` : '100%', marginLeft: index ? `${index * 0.5}rem` : undefined, marginTop: index ? -25 : undefined, background: 'white', borderRadius: 7, border: '1px solid #00000020', boxShadow: index ? '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' : '0px 4px 6px -1px rgba(0,0,0,0.1) , 0px 2px 4px -1px rgba(0,0,0,0.06) ' }}>
            <div style={{ paddingLeft: '1rem', paddingRight: '0.5rem', paddingBlock: '0.5rem', width: '100%', borderTopLeftRadius: 7, borderTopRightRadius: 7, background: '#1c1c1c', color: 'white', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>
                <p style={{ fontSize: 14, flex: 1 }}>#{kot.kotNo}</p>

                {loading ? (<>
                    <InlineLoading style={{ width: 'unset' }} />
                </>) : (<>
                    <button onClick={onStartAll} className='lead-flow-icon-button lead-flow-icon-button-light'>
                        <Play16 />
                    </button>
                    <button onClick={onDoneAll} className='lead-flow-icon-button lead-flow-icon-button-light'>
                        <Checkmark16 />
                    </button>
                </>)}
                <button className='lead-flow-icon-button lead-flow-icon-button-light' onClick={onPrintKot} disabled={printingKot}>
                    <Printer16 />
                </button>
                <button onClick={() => setMaximise(true)} className='lead-flow-icon-button lead-flow-icon-button-light'>
                    <Maximize16 />
                </button>
            </div>
            <div style={{ background: '#99000000', }}>
                {kotItems.map(group => (
                    <div key={group.id}>
                        <h6 style={{ paddingInline: '1rem', paddingBlock: '0.5rem' }}>{group.name}</h6>
                        {group.items.map(item => (
                            <KotItem key={item.id} item={item} order={order} updateOrder={updateOrder} />
                        ))}
                    </div>
                ))}
            </div>
            {bottomPadding && <div style={{ height: 25 }} />}

            <Portal>
                {maximise && <SidePanel md onClose={() => setMaximise(false)}>
                    <div style={{ paddingBlock: '6rem', paddingInline: '3rem' }}>
                        <div style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', marginBottom: '3rem' }}>
                            <ServiceDesk24 />
                            <h4 style={{ fontWeight: 'bold', flex: 1 }}>KOT #{kot.kotNo}</h4>
                            <button onClick={() => setMaximise(false)} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                                <Close16 />
                            </button>
                        </div>
                        {kotItems.map(group => (
                            <div key={group.id} style={{ marginBottom: '1rem' }}>
                                <h5 style={{ paddingInline: '1rem', }}>{group.name}</h5>
                                <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', }}>
                                    {group.items.map(item => (
                                        <LargeKotItem key={item.id} item={item} order={order} updateOrder={updateOrder} />
                                    ))}
                                </div>
                            </div>
                        ))}

                        {/* <div style={{ display: 'grid', gridTemplateColumns: 'repeat(3, minmax(0, 1fr))', gap: '1rem', }}>
                            {kotItems.flatMap(group => group.items).map(item => (
                                <LargeKotItem key={item.id} item={item} order={order} updateOrder={updateOrder} />
                            ))}
                        </div> */}
                    </div>
                </SidePanel>}
            </Portal>

            {/* <button onClick={() => order.targetDeliveryDate = 0}>click me!!</button>
            <button onClick={() => order.targetDeliveryDate = Number.MAX_SAFE_INTEGER}>FUUTRE!!</button> */}
        </div>
    )
}

const KotList = ({ order, updateOrder, mainApp, filterClasses }) => {
    return (
        <div>
            {order.kots.map((kot, index) => <KotCard key={kot.kotNo} filterClasses={filterClasses} order={order} updateOrder={updateOrder} kot={kot} index={index} bottomPadding={index < order.kots.length - 1} mainApp={mainApp} />)}
        </div>
    )
}

function Subtitle({ order }) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return <p style={{ fontSize: 14, fontWeight: 'bold' }}>Table {order.tableName}</p>
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        if (Util.isStringExists(order.memo)) {
            return <p style={{ fontSize: 14, }}>{order.memo}</p>
        } else {
            return <p style={{ fontSize: 12, opacity: 0.65, }}>no memo</p>
        }
    } else if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.PICK_UP) {
        if (order.shippingAddress && (Util.isStringExists(order.shippingAddress.firstName) || Util.isStringExists(order.shippingAddress.lastName))) {
            const fullName = [order.shippingAddress.firstName, order.shippingAddress.lastName].filter(Util.isStringExists).join(" ");
            return <p style={{ fontSize: 14, }}>{fullName}</p>
        } else {
            return <p style={{ fontSize: 12, opacity: 0.65, }}>no name</p>
        }
    } else {
        return null;
    }
}

const ValueField = ({ label, value }) => (
    <div style={{}}>
        <label style={{ fontWeight: 'bold', marginBottom: 0, opacity: 1, color: 'black' }} className="bx--label">{label}</label>
        <p style={{ fontSize: 14 }}>{value}</p>
    </div>
)

const HoriValueField = ({ label, value }) => (
    <div style={{ display: 'flex', alignItems: 'center' }}>
        <label style={{ fontWeight: 'bold', marginBottom: 0, opacity: 1, color: 'black', flex: 1 }} className="bx--label">{label}</label>
        <p style={{ fontSize: 14 }}>{value}</p>
    </div>
)

function Info({ order }) {
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        return (<>
            <HoriValueField label="Occupancy" value={order.noOfPeopleDineIn + ' people'} />
            <HoriValueField label="Waiter" value={order?.waiterName ?? "Unkown"} />
            {/* <HoriValueField label="Waiter" value={order.orderSummary?.waiterName ?? "Unkown"} /> */}
        </>)
    } else if (order.type === REST_ORDER_TYPE.DELIVERY && order.shippingAddress) {
        return (<>
            <HoriValueField label="Phone" value={order.shippingAddress.readablePhoneNumber} />
            <HoriValueField label="Area" value={order.deliveryLocationName} />
            <br />
            <ValueField label="Address" value={getOrderAddress(order)} />
        </>)
    } else if (order.type === REST_ORDER_TYPE.PICK_UP && order.shippingAddress) {
        return (<>
            <HoriValueField label="Phone" value={order.shippingAddress.readablePhoneNumber} />
        </>)
    } else {
        return null;
    }
}

const ScheduledDate = ({ order }) => {
    const color = useMemo(() => {
        const current = createScheduleFilter("current").predicate(order);
        const future = createScheduleFilter("future").predicate(order);

        if (current) {
            return "red";
        } else if (future) {
            return "green"
        } else {
            return "black";
        }
    }, [order])

    return (<>
        {(Util.isNumberExist(order.preferredDeliveryDate) || Util.isNumberExist(order.targetDeliveryDate)) &&
            <p style={{ fontSize: 12, color: color, fontWeight: "bold" }}>{Util.formatDate(Util.isNumberExist(order.targetDeliveryDate) ? order.targetDeliveryDate : order.preferredDeliveryDate, "DD MMM, HH:mm")}</p>}
    </>)

}


const OrderPrintBtn = ({ order, filterClasses, mainApp }) => {
    const [printingKot, setPrintingKot] = useState(false)
    const onPrintKot = async (e) => {
        e.stopPropagation()

        if (printingKot) {
            return;
        }

        if (mainApp?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
            setPrintingKot(true);
            try {
                await mainApp.thermalPrinter.driver.printAllKots(order, filterClasses, "Manual Print")
                UIUtil.showSuccess();
            } catch (e) {
                UIUtil.showError()
            } finally {
                setPrintingKot(false)
            }
        } else {
            UIUtil.showInfo("Printer not connected")
        }
    }

    return (<>
        {(order?.kots?.length ?? 0) > 0 && <button className='lead-flow-icon-button lead-flow-icon-button-light-on-white' onClick={onPrintKot} disabled={printingKot}>
            <Printer16 />
        </button>}
    </>)
}

export const OrderPreInvoicePaymentIndicator = PaymentIndicator;
function PaymentIndicator({ order, updateOrder }) {
    const role = useMemo(() => getAccountRole(), [order])
    const allowedToPay = role !== ACCOUNT_TYPE_TYPE_WAITER;

    const onClick = () => {
        // UIUtil.showOverlay
    }

    const [loading, onPayOrderBtn] = usePromise(() => payOrderBeforeInvoice(order, updateOrder))

    if (loading) {
        return (
            <div style={{ paddingInline: '0.5rem' }}>
                <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', background: '#00000020', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                    <InlineLoading />
                    <p style={{ fontSize: 14 }}>Loading...</p>
                </div>
            </div>
        )
    }

    if (order.unpaidAmount <= 0) {
        return (
            <div onClick={onClick} style={{ paddingInline: '0.5rem' }}>
                <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', background: '#00551a', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                    <CheckmarkFilled16 />
                    <p style={{ fontSize: 14 }}>Fully Paid</p>
                    {/* <p style={{ fontSize: 12, opacity: 0.65 }}>- view methods</p> */}
                </div>
            </div>
        )
    } else if (order.paidAmount > 0) {
        if (allowedToPay) {
            return (
                <div onClick={onPayOrderBtn} style={{ paddingInline: '0.5rem', cursor: 'pointer', }}>
                    <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', background: '#0f62fe', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                        <Time16 />
                        <p style={{ fontSize: 14 }}>Partially Paid - </p>
                        <p style={{ fontSize: 12, fontWeight: 'bold', color: 'white' }}>Pay Order</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ paddingInline: '0.5rem', }}>
                    <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', background: '#0f62fe', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                        <Time16 />
                        <p style={{ fontSize: 14 }}>Partially Paid</p>
                    </div>
                </div>
            )
        }
    } else {
        if (allowedToPay) {
            return (
                <div onClick={onPayOrderBtn} style={{ paddingInline: '0.5rem', cursor: 'pointer' }}>
                    <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', background: '#00000020', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                        <StopFilled16 />
                        <p style={{ fontSize: 14 }}>Not Paid - </p>
                        <p style={{ fontSize: 12, fontWeight: 'bold', color: '#0f62fe' }}>Pay Order</p>
                    </div>
                </div>
            )
        } else {
            return (
                <div style={{ paddingInline: '0.5rem' }}>
                    <div style={{ borderRadius: 5, width: '100%', marginBottom: '0.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'black', background: '#00000020', gap: '0.15rem', paddingBlock: '0.15rem', paddingInline: '0.5rem' }}>
                        <StopFilled16 />
                        <p style={{ fontSize: 14 }}>Not Paid</p>
                    </div>
                </div>
            )
        }
    }
}

const DEF_STATE = {
    expanded: false,
    maximise: false
}
export function OrderCard({ orderState, order, removeOrder, updateOrder, kot, mainApp, filterClasses, deliveryLocations }) {
    // const [expanded, setExpanded] = useState(false);
    // const [maximise, setMaximise] = useState(false);

    const state = orderState.useOrderCardState(order, kot, DEF_STATE);
    const [expanded, setExpanded] = state.useState("expanded")
    const [maximise, setMaximise] = state.useState("maximise")

    const components = useMemo(() => restOrderStatusComponents(order, updateOrder, removeOrder, kot, mainApp, deliveryLocations), [order, deliveryLocations])
    const { bg, border } = getRestOrderDetails(order.type);

    const orderNote = useMemo(() => getRestOrderNote(order), [order]);
    return (
        // <div style={{ background: 'white', borderRadius: 10 }}>
        <div style={{
            width: '100%', background: bg, borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ', border: '1px solid #00000010', borderColor: border,
            overflow: 'hidden', height: 'fit-content'
        }}>
            <div onClick={() => setExpanded(p => !p)} style={{ padding: '0.5rem', paddingRight: '0.75rem', display: "flex", alignItems: 'center' }}>
                {/* <h6 style={{ flex: 1 }}>{restOrderTypeName(order.type)}</h6> */}
                <div style={{ paddingInline: '0.5rem' }}>
                    {expanded ? <ChevronDown16 /> : <ChevronUp16 />}
                </div>
                <TypeTag type={order.type} />
                <ScheduledDate order={order} />
                <div style={{ flex: 1 }} />
                <Subtitle order={order} />


                {kot && <OrderPrintBtn filterClasses={filterClasses} order={order} mainApp={mainApp} />}
            </div>
            {/* {!kot && order.paidAmount > 0 && <PaymentIndicator order={order} />} */}
            {!kot && <PaymentIndicator order={order} updateOrder={updateOrder} />}
            {components.statusBanner}
            {expanded && <>

                <div style={{ padding: '0.5rem' }}>
                    {kot ? (<>
                        {Util.isStringExists(orderNote) &&
                            <div style={{ borderRadius: 7, background: 'white', border: '1px solid #00000020', padding: '0.5rem', marginBottom: '1rem' }}>
                                {/* <h4>Note</h4> */}
                                <p style={{ fontSize: 16, fontWeight: 'bold', color: "black", whiteSpace: 'pre' }}>
                                    Note: {orderNote}
                                </p>
                            </div>}
                        <KotList order={order} updateOrder={updateOrder} mainApp={mainApp} filterClasses={filterClasses} />

                    </>) : (<>

                        <div style={{ marginBottom: '1rem' }}>
                            {<div style={{ borderRadius: 7, background: bg, border: '1px solid #00000010', borderColor: border, padding: '0.5rem' }}>
                                <HoriValueField label="Order" value={"#" + order.orderNo} />
                                <HoriValueField label="Amount" value={<span style={{ color: 'green', fontWeight: 'bold' }}>{"AED " + order.amount?.toFixed(2)}</span>} />
                                <Info order={order} />
                            </div>}
                        </div>

                        {order?.shippingAddress?.readablePhoneNumber?.includes?.('WhatsApp') && <>
                            <div style={{ marginBottom: '1rem' }}>
                                <div style={{ borderRadius: 7, background: '#e6ffe6', border: '1px solid #009900', padding: '0.5rem', }}>
                                    <div style={{ display: 'flex', gap: '0.25rem', color: '#009900' }}>

                                        <Chat16 />
                                        <p style={{ fontSize: 12, fontWeight: 'bold' }}>WhatsApp Number</p>
                                    </div>
                                    <div style={{ display: 'flex', gap: '0.25rem', justifyContent: 'flex-end' }}>

                                        <p style={{ fontSize: 14, }}>{order?.shippingAddress?.readablePhoneNumber?.replace(' (WhatsApp)', '')}</p>
                                    </div>
                                </div>
                            </div>
                        </>}

                        <div style={{ borderRadius: 7, overflow: 'hidden', gap: 1, display: 'flex', flexDirection: 'column', background: '#1c1c1c' }}>
                            <Options orderState={orderState} order={order} updateOrder={updateOrder} mainApp={mainApp} deliveryLocations={deliveryLocations}
                                maximise={maximise} setMaximise={setMaximise} />
                        </div>


                    </>)}
                </div>

                <div style={{ background: '#00000020', width: '100%', padding: '0.5rem', borderTop: '1px solid #00000010' }}>
                    <div style={{ width: '100%', display: "flex", gap: 0, overflow: 'hidden', borderRadius: 7 }}>
                        {components.actions}
                    </div>
                </div>
            </>}

            {!expanded && kot && <div style={{ padding: '1rem', paddingTop: '1rem' }}>
                {order.kots.map(kot => <Tag key={kot.kotNo} type='high-contrast'>KOT #{kot.kotNo}</Tag>)}
            </div>}
        </div>
        // </div>
    )
}