import { Link, StructuredListBody, StructuredListCell, StructuredListHead, StructuredListRow, StructuredListWrapper } from 'carbon-components-react'
import { ShoppingCart24, Pin16, Tag16, RowDelete16, Edit16, ErrorFilled16, NotebookReference16, ShoppingCartPlus16, AppConnectivity16, QueryQueue16, Barcode16 } from '@carbon/icons-react'
import React, { createRef, useEffect, useMemo, useRef, useState } from 'react'
import Button from '../../../components/Button'
import ImageView from '../../../components/ImageView'
import Api from '../../../session/Api'
import UIUtil from '../../../util/UIUtil'
import Util from '../../../util/Util'
import ProfilePic from '../../../components/ProfilePic'
import ReactTooltip from 'react-tooltip';
import { ProductGroupSetAttribute } from '../../../views/product/ProductGroupSetAttribute'
import { OBJECT_TYPE_PRODUCT } from '../../../constants/ObjectTypes'
import { hasCapabilitySupport } from '../../../app/Capabilities'
import { CinemaTicketCartItem } from '../components/cinema-ticket-cart-item'
import { openCustomPriceDialog } from '../dialogs/set-custom-price-dialog'
import { CartItemEdit } from './components/cart-item-edit'
import { getAccountRole } from '../../../session/SessionManager'
import { SetDineInTakeOut } from './set-dine-in-take-out'
import { ACCOUNT_TYPE_TYPE_WAITER } from '../../../constants/Constants'

const GenderAnyIcon = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 16, height: 16 }}>
        <p style={{ fontFamily: 'IBM Plex Mono', lineHeight: 0, margin: 0 }}>
            F/M
        </p>
    </div>
)
const GenderFemaleIcon = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 16, height: 16 }}>
        <p style={{ fontFamily: 'IBM Plex Mono', lineHeight: 0, margin: 0 }}>
            F
        </p>
    </div>
)
const GenderMaleIcon = () => (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: 16, height: 16 }}>
        <p style={{ fontFamily: 'IBM Plex Mono', lineHeight: 0, margin: 0 }}>
            M
        </p>
    </div>
)

function GenderButton({ state, cartItem }) {
    const gender = cartItem.restGuestGender
    const isMale = gender === "M";
    const isFemale = gender === "F";
    const isNone = !isMale && !isFemale

    const Icon = isMale ? GenderMaleIcon : isFemale ? GenderFemaleIcon : GenderAnyIcon;
    const kind = isNone ? "ghost" : undefined
    const className = isMale ? "gender-button-male" : isFemale ? "gender-button-female" : ""
    const nextGender = isNone ? "F" : isFemale ? "M" : "N"

    const [loading, setLoading] = useState("")
    const onBtnClick = () => {
        setLoading(true)
        state.setCartItemGender(cartItem.id, nextGender, () => setLoading(false))
    }

    return <Button loading={loading} onClick={onBtnClick} kind={kind} className={"bx--tooltip--hidden " + className} style={{ marginLeft: 5, maxHeight: 48 }} hasIconOnly data-tip="Select Attribute" renderIcon={Icon} />
}


export const getQuantityValue = item => {
    let quantity = item.quantityValue;
    let uomSymbol = 'x'
    if (item.continuousStockType) {
        if (Util.isNumberExist(item.displayUom)) {
            const uom = item.measurementType.unitOfMeasurements.filter(u => u.id == item.displayUom)[0]
            if (uom) {
                uomSymbol = ' ' + uom.symbol

                if (item.displayQuantityValue) {
                    quantity = item.displayQuantityValue;
                }
            }
        } else {
            uomSymbol = ' ' + item.measurementType.defaultUomSymbol
        }
    }

    return (<>
        {quantity}{uomSymbol}
    </>)
}

class CartItem extends React.Component {

    shouldComponentUpdate(prevProps) {
        return this.props.cartItem != prevProps.cartItem || this.props.scrollInto != prevProps.scrollInto || this.props.removingItem != prevProps.removingItem || this.props.showCost != prevProps.showCost
    }

    render() {
        return <CartItemF {...this.props} />
    }

}

const CartItemF = ({ state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone, showCost }) => {
    const [key, setKey] = useState(Util.newTempId());
    const [highlight, setHighlight] = useState(false);

    const [quantityKey, setQuantityKey] = useState(Util.newTempId());
    const [quantityHighlight, setQuantityHighlight] = useState(undefined);

    const [cost, setCost] = useState(() => ({ productId: 0, cost: undefined }));

    const ref = createRef();

    const onSelectAttributeBtn = () => {
        UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={cartItem.itemId} open onClose={onClose} groupId={cartItem.defaultProductGroupId} onSelect={id => {
            state.replaceCartItemItemId(cartItem.id, id)
            // onClearBtn(cartItem);
            // state.updateCartItem(id, 1, false)
        }} />)
    }

    useEffect(() => ReactTooltip.rebuild())

    useEffect(() => {
        if (scrollInto) {
            if (ref.current) {
                ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            }
            onScrollDone();
            setKey(Util.newTempId())
            setHighlight(true);
        }
    }, [scrollInto])

    useEffect(() => {
        if (showCost && cartItem && cartItem.itemId !== cost.productId && cartItem.itemType == OBJECT_TYPE_PRODUCT) {
            let cancelled = false;
            Api.getProductCosts([cartItem.itemId], response => {
                if (cancelled) {
                    return;
                }

                if (response.status === true && response.payload && response.payload.items && response.payload.items.length > 0) {
                    setCost({ productId: cartItem.itemId, cost: response.payload.items[0].cost })
                }
            })
            return () => cancelled = true;
        }
    }, [cartItem, showCost])


    const [lastQuantity, setLastQuantity] = useState(undefined);
    useEffect(() => {
        if (lastQuantity !== undefined && lastQuantity != cartItem.quantityValue) {
            setQuantityKey(Util.newTempId())
            setQuantityHighlight(lastQuantity > cartItem.quantityValue ? 'quantity-decrease-anim' : 'quantity-increase-anim');
        }
        setLastQuantity(cartItem.quantityValue);
    }, [cartItem.quantityValue])

    let amount = cartItem.amount;
    if (cartItem.displayAmount) {
        amount = cartItem.displayAmount;
    }


    // return useMemo(() => (
    return (
        <div key={key} ref={ref} style={{

            animation: highlight ? 'scale-bounce-anim 750ms' : '',
            fontSize: '.875rem', color: '#525252', borderBottom: '1px solid #e0e0e0', padding: '1rem .5rem 1.5rem', paddingLeft: '0rem'
        }}>
            <div style={{ display: 'flex', }}>
                {cartItem.bundleProducts.length > 0 ? (
                    <div style={{ flex: 2 }}>
                        <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', marginBottom: -5 }}>
                            <ProfilePic style={{ minWidth: 30 }} size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                            {cartItem.name}
                        </div>
                        {cartItem.bundleProducts.map(bundleProduct => <p style={{ paddingLeft: '0.5rem', marginLeft: 30, opacity: 0.65, fontSize: 12 }}>
                            {bundleProduct.quantity + 'x ' + bundleProduct.name}
                        </p>)}
                    </div>
                ) : (
                    <div style={{ flex: 2, display: 'flex', alignItems: 'center', gap: '0.5rem', }}>
                        <ProfilePic style={{ minWidth: 30 }} size={30} notProfile src={Api.getThumbnail(cartItem.itemType, cartItem.itemId)} />
                        {cartItem.name}
                    </div>
                )}
                {showCost &&
                    <div style={{ flex: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 5 }}>

                        <span style={{}}>AED {cost.cost ? cost.cost.toFixed(2) : '-'}</span>

                        {/* AED {cartItem.amount.price} */}
                    </div>}
                <div style={{ flex: 2, display: 'flex', justifyContent: 'center', flexDirection: 'column', gap: 5 }}>

                    {(amount.price != amount.originalPrice && amount.originalPrice) ? (<>
                        <span style={{ textDecoration: 'line-through', color: 'red' }}>
                            <span style={{ color: 'black' }}>AED {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
                        </span>
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <Tag16 style={{ marginRight: '0.25rem', fill: 'red' }} />
                            <span style={{ color: 'red', fontWeight: 'bold' }}>
                                AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                            </span>
                        </div>
                    </>) : (<>
                        <span style={{}}>AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</span>
                    </>)}

                    {/* <Button renderIcon={Edit16} size="sm" style={{ borderRadius: 25, width: 75, height: 25 }}>Edit</Button> */}

                    {state?.isAllowCustomPricing() &&
                        <Link style={{ textDecoration: 'none', cursor: 'pointer' }} onClick={() => openCustomPriceDialog(state, cartItem)} renderIcon={Edit16}>Edit</Link>}

                    {/* AED {cartItem.amount.price} */}
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                    <span key={quantityKey} style={{ animation: quantityHighlight ? quantityHighlight + ' 750ms' : '' }}>
                        {getQuantityValue(cartItem)}
                    </span>
                </div>
                <div style={{ flex: 1, display: 'flex', alignItems: 'center', gap: 5 }}>
                    AED {cartItem.totalAmount.price}
                </div>

            </div>

            {Util.isStringExists(cartItem.recipeCustomizationSummary) && <div>
                <p style={{ fontSize: 14, whiteSpace: 'pre', color: 'black' }}>{cartItem.recipeCustomizationSummary}</p>
            </div>}

            {!state.readOnly && <div style={{ flex: 2, display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '1rem' }}>
                <CartItemEdit cartItem={cartItem} onUpdateBtn={(options, onDone) => state.updateCartItemRecipeOptions(cartItem.id, options, onDone)} />

                {Util.isNumberExist(cartItem.defaultProductGroupId) &&
                    <Button onClick={onSelectAttributeBtn} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} hasIconOnly data-tip="Select Attribute" renderIcon={AppConnectivity16} />}
                {hasCapabilitySupport("restaurant") &&
                    <GenderButton state={state} cartItem={cartItem} />}
                <Button onClick={() => state.setShowQuantitySetDialog(cartItem)} className="bx--tooltip--hidden" style={{ marginLeft: 5 }} kind="tertiary" renderIcon={ShoppingCartPlus16} hasIconOnly data-tip="Set Quantity" />
                <Button style={{ height: 48, marginLeft: 5 }} loading={removingItem === cartItem} onClick={() => UIUtil.confirm(() => onClearBtn(cartItem))} className="bx--tooltip--hidden" kind="danger--tertiary" renderIcon={RowDelete16} hasIconOnly data-tip="Remove Item" />
            </div>}
        </div>
    )
    //, [removingItem, cartItem])
    //, [state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone])
    //[state, removingItem, onClearBtn, cartItem, scrollInto, onScrollDone])
}

//#e5e5e

export default ({ state, noHeader, noList, showNoKotOnly, children }) => {
    const showCost = state?.monitoringSession ?? false;

    const [loadingHeldCarts, setLoadingHeldCarts] = useState(false);
    const [loadingHolding, setLoadingHolding] = useState(false);
    const [loadingClearing, setLoadingClearing] = useState(false);

    const [removingItem, setRemovingItem] = useState(undefined);
    const [scrollIntoItem, setScrollIntoItem] = useState(undefined);

    const [lastItemCount, setLastItemCount] = useState(undefined);

    useEffect(() => {
        if (lastItemCount !== undefined && lastItemCount < state.getCartItems().length) {
            setScrollIntoItem(state.getCartItems()[state.getCartItems().length - 1])
        }
        setLastItemCount(state.getCartItems().length);
    }, [state.getCartItems().length])

    useEffect(() => {
        if (Util.isStringExists(state.getHighlightProductRequestBarcode())) {
            let found = false;
            for (const item of state.getCartItems()) {
                for (const barcode of item.barcodes) {
                    if (barcode == state.getHighlightProductRequestBarcode()) {
                        setScrollIntoItem(item);
                        found = true;
                        break;
                    }
                }
            }
            if (!found) {
                UIUtil.showInfo("Item not found")
            }

            state.setHighlightProductRequestBarcode("")
        }
    }, [state.getHighlightProductRequestBarcode()])

    const onSelectByBarcode = () => {
        state.setShowHighlightProductDialog(true);
        //setScrollIntoItem(state.getCartItems()[state.getCartItems().length - 1])
    }

    const onClearCart = () => {
        UIUtil.confirm(() => {
            setLoadingClearing(true);
            state.api.clearCart(response => {
                setLoadingClearing(false);
                if (response.status === true) {
                    state.onUpdateSession(response.payload)
                } else {
                    UIUtil.showError();
                }
            })
        })
    }

    const onHoldCart = () => {
        setLoadingHolding(true);
        state.api.newCart(response => {
            setLoadingHolding(false);
            if (response.status === true) {
                state.onUpdateSession(response.payload)
            } else {
                UIUtil.showError();
            }
        })
    }

    const onViewHeldCarts = () => {
        setLoadingHeldCarts(true)
        state.api.getHeldCarts(response => {
            setLoadingHeldCarts(false);
            if (response.status === true) {
                state.setShowHeldCartsDialog(true, response.payload)
            } else {
                UIUtil.showError();
            }
        })
    }

    const onClearBtn = (item) => {
        setRemovingItem(item);
        state.updateCartItem(item.itemId, item.quantityValue, true, false, true, () => {
            setRemovingItem(undefined)
        }, { recipeOptions: item.recipeOptions });
        ReactTooltip.hide()
    }

    const cartItems = useMemo(() => {
        if (showNoKotOnly) {
            return state.getCartItems().filter($ => !Util.isStringExists($.restKotNo));
        } else {
            return state.getCartItems();
        }
    }, [showNoKotOnly, state.getCartItems()])

    // const cartItems = useMemo(() => state.getCartItems().map((cartItem) => (
    //     <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} state={state} 
    //     scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
    //     removingItem={removingItem} onClearBtn={onClearBtn} />
    // )), [state, state.getCartItems(), removingItem])

    const [mode, setMode] = state.useRestOrderType();

    return (
        <div style={{ width: '100%', height: noList ? undefined : '100%', padding: '0rem', display: 'flex', flexDirection: 'column', }}>
            {!noHeader && <div className='payment-widget' style={{ display: 'flex', alignItems: 'center', paddingLeft: '0.5rem', paddingRight: '0.5rem', color: 'white' }}>
                {/* <ShoppingCart24 style={{ marginRight: 5 }} /> */}
                {/* <h4 style={{ fontWeight: 'bold' }}>Cart ({state.getCartItems().length})</h4> */}
                {/* <div style={{ flex: 1 }} /> */}

                {!state.readOnly && <>

                    {getAccountRole() !== ACCOUNT_TYPE_TYPE_WAITER && <>
                        <SetDineInTakeOut mode={mode} setMode={setMode} />
                    </>}


                    <Button
                        className="green-button" style={{ marginLeft: 5, height: 48, borderRadius: 7 }}
                        loading={state.isLoadingPosAction()}
                        onClick={() => state.setShowPosInfoDialog(true)}
                        renderIcon={NotebookReference16} hasIconOnly />

                </>}
            </div>}
            {children}
            {!noList && <div style={{ overflowY: 'auto', overflowX: 'hidden', flex: 1, paddingInline: '1rem' }}>
                <div className="bx--structured-list-th" style={{ background: 'white', display: 'flex', paddingLeft: '0rem', borderBottom: '1px solid #e0e0e0', position: 'sticky', top: 0, zIndex: 1000 }}>
                    <div style={{ flex: 2 }}>
                        Item
                    </div>
                    <div style={{ flex: 2 }}>
                        Price
                    </div>
                    <div style={{ flex: 1 }}>
                        Qty
                    </div>
                    <div style={{ flex: 1 }}>
                        Total
                    </div>
                </div>
                {/* {cartItems} */}

                {cartItems.map((cartItem) => (
                    <CartItem key={cartItem.itemId + "-cart-item"} cartItem={cartItem} state={state}
                        scrollInto={cartItem === scrollIntoItem} onScrollDone={() => setScrollIntoItem(undefined)}
                        removingItem={removingItem} onClearBtn={onClearBtn}
                        showCost={showCost}
                    />
                ))}
            </div>}
        </div>
    )
}