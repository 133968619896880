

// 12 => 71
// 14 => 60
// 16 => 53
// 18 => 47
// 20 => 42
// 22 => 38
// 40 => 21

import Logo from '../../../images/SquareLogo.png';
import ReceiptLogo from '../../../images/ReceiptLogo.png'

import QRCode from "react-qr-code"
import Util from "../../../util/Util"
import { paymentMethodTextWithArabic } from "../../../pages/transaction/TransactionEditor"
import { COMPANY_NAME, THERMAL_RECEIPT_LOGO } from '../../../app/Config';
import { POS_SESSION_DISCOUNT_TYPE_COUPON } from '../../../constants/Constants';


const Line = () => <br />
const Divider = () => <p style={{ fontSize: 12, color: 'black' }}>{Array(71).fill({}).map(i => '-')}</p>

const Text = ({ bold, italic, size, center, right, underline, children }) => <p style={{
    fontWeight: bold ? "bold" : "bold", textAlign: center ? "center" : right ? "right" : "left", fontSize: size,
    textDecoration: underline ? "underline" : undefined,
    fontStyle: italic ? "italic" : undefined,
    whiteSpace: 'pre-wrap'
}}>{children}</p>

const Normal = ({ children }) => <Text bold={false} size={20}>{children}</Text>
const Italic = ({ children }) => <Text italic size={20}>{children}</Text>
const Underline = ({ children }) => <Text underline bold={false} size={20}>{children}</Text>
const ArabicNormal = ({ children }) => <Text right bold={false} size={20}>{children}</Text>
const ArabicBold = ({ children }) => <Text right bold={true} size={20}>{children}</Text>
const ArabicUnderline = ({ children }) => <Text right underline={true} size={20}>{children}</Text>

const BoldCenterNormal = ({ children }) => <Text bold center size={20}>{children}</Text>
const CenterNormal = ({ children }) => <Text center size={20}>{children}</Text>
const CenterBold = ({ children }) => <Text center bold size={20}>{children}</Text>
const RightNormal = ({ children }) => <Text right size={20}>{children}</Text>
const DoubleRightNormal = ({ children }) => <Text right size={40}>{children}</Text>
const LgCenter = ({ children }) => <Text center size={30}>{children}</Text>
const DoubleCenter = ({ children }) => <Text center size={40}>{children}</Text>

const Bold = ({ children }) => <Text bold size={20}>{children}</Text>
const DoubleBold = ({ children }) => <Text bold size={40}>{children}</Text>

const Header = ({ children }) => (
    <>
        <Line />
        <Line />
        <Bold>{children}</Bold>
        <Divider />
    </>
)

const Field = ({ label, children }) => (
    <>
        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Bold>{label}</Bold>
            <RightNormal>{children}</RightNormal>
        </div>
    </>
)

export const SalesSummaryPrint = ({ request, }) => {
    const header1 = (label) => {
        return <Header>{label}</Header>
    }
    const infoField1 = (label, value) => {
        return <Field label={label}>{value}</Field>
    }
    const report = request
    return (
        <div style={{ fontFamily: '"IBM Plex Mono", SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace', width: 512, maxWidth: 512, background: 'transparent' }}>
            <Line />
            <Line />

            <BoldCenterNormal>Robotic ERP</BoldCenterNormal>
            <BoldCenterNormal>POS Sales Summary</BoldCenterNormal>
            <BoldCenterNormal>Printed on {request.__current_date}</BoldCenterNormal>



            <Header>POS Info</Header>
            <Field label={"Store Name:"}>{request.storeName}</Field>
            <Field label={"Terminal Name:"}>{request.terminalName}</Field>
            <Field label={"Operator Name:"}>{request.operatorName}</Field>

            {header1("Session Info")}
            {infoField1("Session ID:", report.posSessionId)}
            {infoField1("Opening Date:", Util.getFullDate(report.openingDate))}
            {Util.isNumberExist(report.closingDate) &&
                infoField1("Closing Date:", Util.getFullDate(report.closingDate))}
            {infoField1("Opening Amount:", report.openingAmount.toFixed(2))}


            {header1("Sales Report")}
            {infoField1("Total Sales:", report.totalSales.toFixed(2))}
            {infoField1("    By Cash:", report.totalSalesByCash.toFixed(2))}
            {infoField1("    By Card:", report.totalSalesByNonCash.toFixed(2))}
            {Util.isNumberExist(report.totalSalesByCustomerCredit) &&
                infoField1("    By Customer Credit:", report.totalSalesByCustomerCredit.toFixed(2))}

            {header1("Return Report")}
            {infoField1("Total Returns:", report.totalReturn?.toFixed(2))}
            {infoField1("      By Cash:", report.cashReturnAmount?.toFixed(2))}
            {infoField1("      By Card:", report.cardReturnAmount?.toFixed(2))}

            {header1("Calculation")}
            {infoField1("Cash in drawer:", report.calculatedCashInDrawer.toFixed(2))}

            <Line />
            <Line />
        </div>
    )

}

function weightText(text, weight, leftAligned) {
    if (text == null) {
        text = "";
    }

    if (!text || !text.substring) {
        text = text + "";
    }

    if (text.length > weight) {
        text = text.substring(0, weight - 3) + "...";
    } else {
        const textLength = text.length;
        for (let i = 0; i < (weight - textLength); i++) {
            if (leftAligned) {
                text += ' ';
            } else {
                text = " " + text;
            }
        }
    }
    return text;
}