import React, { useRef, useState } from 'react'
import { useHistory, withRouter } from 'react-router-dom';
import Page from '../../base/Page';
import Button from '../../components/Button';
import { ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SALES, DISCOUNT_CUSTOM_LIST_PRODUCTS, PRODUCT_BUNDLE_CUSTOM_LIST_PARENT, STOCK_TYPE_DISCRETE, TABLE_RELATION_TYPE_MANY_TO_MANY, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../constants/Constants';
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_CUSTOM_PRICE, OBJECT_TYPE_DISCOUNT, OBJECT_TYPE_PACKAGE_UNIT, OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_ATTRIBUTES, OBJECT_TYPE_PRODUCT_BUNDLE, OBJECT_TYPE_PRODUCT_GROUP } from "../../constants/ObjectTypes";
import Api from '../../session/Api';
import { getAccountRole } from '../../session/SessionManager';
import ItemCreatorDialog from '../../templates/ItemCreatorDialog';
import ItemTemplate from '../../templates/ItemTemplate';
import TableTemplate from '../../templates/TableTemplate';
import UIUtil from '../../util/UIUtil';
import StockFlowListView from '../stock-flow/StockFlowListView';
import TransactionListView from '../transaction/TransactionListView';
import ProductAttributesView from './ProductAttributesView';

class StocksView extends React.Component {

    shouldComponentUpdate(prevProps) {
        if (prevProps !== undefined && this.props !== undefined && prevProps.item !== undefined && this.props.item !== undefined && prevProps.item.id === this.props.item.id) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const item = this.props.item;
        return (
            <div style={{ marginTop: '1rem' }}>
                <StockFlowListView productList={{ id: item.id }} />
            </div>
        )
    }

}

class TransactionsView extends React.Component {

    shouldComponentUpdate(prevProps) {
        if (prevProps !== undefined && this.props !== undefined && prevProps.item !== undefined && this.props.item !== undefined && prevProps.item.id === this.props.item.id) {
            return false;
        } else {
            return true;
        }
    }

    render() {
        const item = this.props.item;
        return (
            <div style={{ marginTop: '1rem' }}>
                <TransactionListView productList={{ id: item.id }} />
            </div>
        )
    }

}


// const StocksView = ({item}) => {
//     return (
//         <div style={{marginTop: '1rem'}}>
//             <StockFlowListView productList={{id: item.id}} />
//         </div>
//     )
// }

// const TransactionsView = ({item}) => {
//     return (
//         <div style={{marginTop: '1rem'}}>
//             <TransactionListView productList={{id: item.id}} />
//         </div>
//     )
// }

const DiscountsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: DISCOUNT_CUSTOM_LIST_PRODUCTS,
                    params: {
                        productId: item.id,
                        categoryId: item.categoryId
                    }
                },
                hideCreateButton: true,
                embedded: true,
                title: "Discounts",
                subTitle: "Product discounts",
                objectType: OBJECT_TYPE_DISCOUNT,
                pagePath: "/discounts/",
                history: history
            })}
        </div>
    )
}


const RelatedProductsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,

                    parentId: item.id,
                    parentType: OBJECT_TYPE_PRODUCT,

                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: false,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Related Products",
                subTitle: "Customer Suggestions",
                objectType: OBJECT_TYPE_PRODUCT,
                pagePath: "/products/",
                history: history
            })}
        </div>
    )
}

const ProductBundlesList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                getCustomListRequest: {
                    requestType: PRODUCT_BUNDLE_CUSTOM_LIST_PARENT,
                    params: {
                        parentId: item.id,
                    }
                },
                customToolbarContent: (
                    <div style={{ display: 'flex', alignItems: 'center', paddingLeft: '1rem', flex: 1 }}>
                        <p>Bundles containing this product</p>
                    </div>
                ),
                hideCreateButton: true,
                embedded: true,
                title: "Product Bundles",
                subTitle: "Bundles containing this product",
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE,
                pagePath: "/product-bundles/",
                history: history
            })}
            {/* {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,
                    
                    parentType: OBJECT_TYPE_PRODUCT_BUNDLE,

                    childId: item.id,
                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: false,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Product Bundles", 
                subTitle: "Bundles containing this product", 
                objectType: OBJECT_TYPE_PRODUCT_BUNDLE, 
                pagePath: "/product-bundles/", 
                history: history
            })} */}
        </div>
    )
}


export const PackageUnitsList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Package Units",
                subTitle: "Product package units",
                objectType: OBJECT_TYPE_PACKAGE_UNIT,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_PACKAGE_UNIT}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}



const CustomPriceList = ({ item }) => {
    const [showCreateDialog, setShowCreateDialog] = useState(false)
    const [selectedItem, setSelectedItem] = useState(undefined);

    const history = useHistory();

    const tableRef = useRef()

    return (
        <div style={{ marginTop: '1rem' }} className="white-product-field modal-that-supports-combo-box">
            {TableTemplate.renderTemplate({
                ref: tableRef,
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_ONE_TO_MANY,
                    objectProperty: 'parentId',
                    parentId: item.id,
                    showNotIncluded: false,
                    showNewBtn: true
                },
                embedded: true,
                // hideCreateButton: true,
                title: "Price Rules",
                subTitle: "Product price rules",
                objectType: OBJECT_TYPE_CUSTOM_PRICE,
                //pagePath: "/discounts/", 
                history: history,

                customNewButtonListener: () => setShowCreateDialog(true),
                customRowButtonListener: (id) => setSelectedItem(id)
            })}

            <ItemCreatorDialog
                fullSized
                size="lg"
                itemId={selectedItem}
                objectType={OBJECT_TYPE_CUSTOM_PRICE}
                preventDeletePhraseModal
                returnItemOnSave
                productFinderShowFakeTextFieldAtFirst
                parentId={item.id}
                open={showCreateDialog || selectedItem} onClose={() => {
                    setShowCreateDialog(false)
                    setSelectedItem(undefined)
                }}
                onPreSave={update => update.parentId = item.id}
                onCreatedItem={createdItem => {
                    UIUtil.showSuccess();
                    setShowCreateDialog(false)
                    tableRef.current.addItem(createdItem)
                    //tableRef.current.addItem({...createdItem, itemName: createdItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onSave={savedItem => {
                    UIUtil.showSuccess();
                    setSelectedItem(undefined)
                    tableRef.current.saveItem(savedItem)
                    //tableRef.current.saveItem({...savedItem, itemName: savedItem['productId-NAME-CUSTOM-FIELD-value']})
                }}
                onDelete={id => tableRef.current.deleteItem(id)}
            />
        </div>
    )
}



const AttributesList = ({ item }) => {
    return <ProductAttributesView item={item} />
}


const ProductGroupsList = ({ item }) => {
    const history = useHistory();
    return (
        <div style={{ marginTop: '1rem' }}>
            {TableTemplate.renderTemplate({
                tableRelationMode: {
                    tableRelationType: TABLE_RELATION_TYPE_MANY_TO_MANY,
                    parentType: OBJECT_TYPE_PRODUCT_GROUP,
                    childId: item.id,
                    childType: OBJECT_TYPE_PRODUCT,
                    manyToManyChildView: false,
                    manyToManyShowChild: true,

                    showNotIncluded: false
                },
                embedded: true,
                title: "Product Groups",
                subTitle: "Product groups containing this product",
                objectType: OBJECT_TYPE_PRODUCT_GROUP,
                pagePath: "/product-groups/",
                history: history
            })}
        </div>
    )
}

class ProductBundleDetailPage extends Page {

    constructor(props) {
        super(props);

        this.state = {
            ...this.state,
            itemResult: undefined
        }
    }

    isCreating() {
        return this.getPathParams().itemId == "new";
    }

    onPageStart() {
        this.callPageApi(listener => {
            if (this.isCreating()) {
                Api.getItemCreator(OBJECT_TYPE_PRODUCT, listener)
            } else {
                Api.getItem(OBJECT_TYPE_PRODUCT, this.getPathParams().itemId, listener)
            }
        }, payload => ({
            itemResult: payload
        }))
    }

    getLayout() {
        return (
            <div className="main-content">
                {ItemTemplate.renderTemplate({
                    overrideBacktToListBtn: this.props.overrideBacktToListBtn,
                    objectType: OBJECT_TYPE_PRODUCT,
                    itemResult: this.state.itemResult,
                    pagePath: "/products/",
                    history: this.props.history,
                    customTabs: [
                        {
                            title: "Attributes",
                            component: AttributesList
                        },
                        {
                            title: "Package Units",
                            component: PackageUnitsList
                        },
                        {
                            title: "Price Rule",
                            component: CustomPriceList
                        },
                        {
                            title: "Discount",
                            component: DiscountsList
                        },
                        {
                            title: "Bundles",
                            component: ProductBundlesList
                        },
                        {
                            title: "Product Groups",
                            component: ProductGroupsList
                        },
                        ...(getAccountRole() != ACCOUNT_TYPE_PRODUCT_MANAGER && getAccountRole() != ACCOUNT_TYPE_SALES ? [
                            {
                                title: "Stocks",
                                component: StocksView
                            },
                            {
                                title: "Transactions",
                                component: TransactionsView
                            }
                        ] : [])
                    ]
                })}
            </div>
        )
    }

}

export default withRouter(ProductBundleDetailPage);