
import { AccordionItem, Accordion, Toggle, Tile, TileGroup, RadioTile, SelectableTile, TextInput } from 'carbon-components-react'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { SOURCE_TYPE_CUSTOMER, SOURCE_TYPE_MOBILE_UNIT, SOURCE_TYPE_STOCK_CREATION, SOURCE_TYPE_STORE, SOURCE_TYPE_SUPPLIER, SOURCE_TYPE_WAREHOUSE, TABLE_RELATION_TYPE_ONE_TO_MANY } from '../../../constants/Constants'
import { OBJECT_TYPE_CONTACT, OBJECT_TYPE_PACKAGE_UNIT } from '../../../constants/ObjectTypes'
import Api from '../../../session/Api'
import ItemCreatorDialog from '../../../templates/ItemCreatorDialog'
import TableTemplate from '../../../templates/TableTemplate'
import UIUtil from '../../../util/UIUtil'
import { Warning16 } from '@carbon/icons-react';
import { isSettingsEnabled } from '../SettingsViews'
import { hasCapabilitySupport } from '../../../app/Capabilities'

import {
    Delivery32,
    AirlinePassengerCare32,
    DataVis_232,
    Store32,
    Cube32,
    Van32,
    CheckmarkFilled16,
    Save16
} from '@carbon/icons-react'
import Button from '../../../components/Button'


const NewDeliverableSmsNumber = ({ }) => {
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);
    const [number, setNumber] = useState();

    useEffect(() => {
        setLoading(true);
        Api.getNewDeliverableSmsNumber(response => {
            if (response.status === true) {
                setLoading(false);
                setNumber(response.payload)
            }
        })
    }, [])

    const onSave = () => {
        setSaving(true);
        Api.updateNewDeliverableSmsNumber(number, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
            }
            setSaving(false);
        })
    }

    return (<>
        <TextInput disabled={loading} labelText="Phone Number [Format: E.164] (e.g. +97150xxxxxxx)" value={number} onChange={e => setNumber(e.target.value)} />
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '0.25rem' }}>
            <Button loading={saving} onClick={onSave} renderIcon={Save16}>Save</Button>
        </div>
    </>)
}

const ReassignDriverAfterDeliveryForPickup = ({ }) => {
    const [active, setActive] = useState(false)
    const [loading, setLoading] = useState(true);
    const [inError, setInError] = useState(false);

    useEffect(() => {
        setLoading(true)
        Api.isSettingEnabled('delivery_reassign_driver_after_delivery_for_pick_up', response => {
            if (response.status === true) {
                setActive(response.payload)
                setLoading(false)
            } else {
                setInError(true)
                UIUtil.showError(response.message)
            }
        })
    }, [])

    const onActiveToggle = enabled => {
        const currentActive = active;
        setActive(enabled)
        setLoading(true)
        Api.setSettingEnable('delivery_reassign_driver_after_delivery_for_pick_up', enabled, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
            } else {
                UIUtil.showError(response.message);
                setActive(currentActive)
            }
            setLoading(false)
        })
    }


    return (
        <div>
            <label className="bx--label">Active</label>
            <Toggle
                checked={active}
                disabled={loading}
                size="md"
                labelA="Disabled"
                labelB="Enabled"
                onToggle={onActiveToggle}
            />
        </div>
    )
}


export default ({ settingsValues }) => {

    return (
        <Accordion className="no-padding-accordion">
            <AccordionItem title="New deliverable order SMS update phone number">
                <NewDeliverableSmsNumber />
            </AccordionItem>
            <AccordionItem title="Reassign the same driver automatically to rental pickup after delivery is done">
                <ReassignDriverAfterDeliveryForPickup />
            </AccordionItem>
        </Accordion>
    )
}