import { useMemo } from "react";
import { ACCOUNT_TYPE_SALES } from "../../../constants/Constants";
import { Memo } from "./Memo";
import { NoTax } from "./NoTax";
import { Salesperson } from "./Salesperson";
import { getAccountRole } from "../../../session/SessionManager";


export function WorkDetails({ sessionStore, editable }) {
    const role = useMemo(() => getAccountRole(), [])
    return (
        <div style={{ paddingLeft: '1rem', paddingRight: '1rem', paddingBottom: '1rem', display: 'flex', alignItems: 'flex-start', gap: '1rem' }}>
            {role !== ACCOUNT_TYPE_SALES && <div style={{ flex: 1 }}>
                <Salesperson sessionStore={sessionStore} editable={editable} />
            </div>}
            <div style={{}}>
                <NoTax sessionStore={sessionStore} editable={editable} />
            </div>
            <div style={{ flex: 2 }}>
                <Memo sessionStore={sessionStore} editable={editable} />
            </div>
        </div>
    )
}