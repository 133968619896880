import React from 'react';
import { Page, Text, View, Image, Document, StyleSheet, pdf, usePDF } from '@react-pdf/renderer';
import Util, { big } from '../../../../../../util/Util';
import Logo from '../../../../../../images/doc-logo.png'
import { PDFTable } from '../../../../../../pdfs/invoice-document/pdf/Invoice';
import Api from '../../../../../../session/Api';
import { stringifyBlueprintInputFields } from '../../../../../activities/components/blueprint-input-fields-read';
import { getBLNo } from '../../../../../../domain/shipment';

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 20,
        paddingLeft: 15,
        paddingRight: 15,
        lineHeight: 1.5,
        flexDirection: 'column',
    }
});


const COLS = [
    {
        id: "no",
        name: "SN",
        flex: 1,
        alignment: "center",
        render: row => <Text>{row.no}</Text>
    },
    {
        id: "bl",
        name: "BL",
        flex: 2,
        alignment: "center",
        render: row => <Text>{row.bl}</Text>
    },

    {
        id: "consignor",
        name: "Consignor",
        flex: 3,
        alignment: "center",
        render: row => <Text>{row.consignor}</Text>
    },
    {
        id: "consignee",
        name: "Consignee",
        flex: 3,
        alignment: "center",
        render: row => <Text>{row.consignee}</Text>
    },

    {
        id: "description",
        name: "Description of Goods",
        flex: 4,
        alignment: "flex-start",
        render: row => <Text style={{ paddingLeft: 5 }}>{row.description}</Text>
    },

    {
        id: "weight",
        name: "Unit Weight",
        flex: 2,
        alignment: "center",
        render: row => <Text>{row.weight}</Text>
    },
    {
        id: "qty",
        name: "Qty",
        flex: 2,
        alignment: "center",
        render: row => <Text>{row.qty}</Text>
    },
]



function PDF({ payload: master }) {
    const vessel = (() => {
        return (master.transportationLegs.map(leg => leg.vesselName).join(" -> "))
    })();
    const voyageNo = (() => {
        return (master.transportationLegs.map(leg => leg.voyageNo).join(" -> "))
    })();
    const pol = (() => {
        const firstLeg = master.transportationLegs[0];
        return (firstLeg?.departurePortName ?? "-")
    })();
    const pod = (() => {
        const lastLeg = master.transportationLegs[master.transportationLegs.length - 1];
        return (lastLeg?.arrivalPortName ?? "-")
    })();
    const arrivalDate = (() => {
        const lastLeg = master.transportationLegs[master.transportationLegs.length - 1];
        return (Util.getDateOnly(Util.isNumberExist(lastLeg?.actualEndDate) ? lastLeg?.actualEndDate : lastLeg?.scheduledEndDate))
    })();
    return (
        <Document>
            <Page size="A4" orientation='landscape' style={styles.page}>
                <View style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', }}>
                    <Image src={Logo} style={{ width: 85, height: 85 }} />
                    <View style={{ flex: 2, paddingLeft: 25 }}>
                        <View style={{ flexDirection: 'row', alignItems: 'center' }}>
                            <Text style={{ fontSize: 18, flex: 1, fontFamily: "Helvetica-Bold" }}>MASTER MANIFEST</Text>
                            <View style={{ flex: 1, alignItems: 'flex-end' }}>
                                <Text>Manifest No: <Text style={{ fontFamily: "Helvetica-Bold" }}>{master.id}</Text></Text>
                                <Text>ETD: <Text style={{ fontFamily: "Helvetica-Bold" }}>{arrivalDate}</Text></Text>
                            </View>
                        </View>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <View style={{ flex: 1 }}>
                                <Text>Vessel: <Text style={{ fontFamily: "Helvetica-Bold" }}>{vessel}</Text></Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>Voyage No: <Text style={{ fontFamily: "Helvetica-Bold" }}>{voyageNo}</Text></Text>
                            </View>
                            <div />
                        </View>
                        <View style={{ flex: 1, display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                            <View style={{ flex: 1 }}>
                                <Text>POL: <Text style={{ fontFamily: "Helvetica-Bold" }}>{pol}</Text></Text>
                            </View>
                            <View style={{ flex: 1 }}>
                                <Text>POD: <Text style={{ fontFamily: "Helvetica-Bold" }}>{pod}</Text></Text>
                            </View>
                            <div />
                        </View>
                    </View>
                </View>
                <View style={{ width: '100%', height: 2, marginTop: 5, marginBottom: 15, backgroundColor: '#68adf7' }} />

                {/* <PDFTable rowBorder normalPaddingTop cols={COLS} rows={master.shipments.map((shipment, index) => {
                    const blNo = (() => {
                        return getBLNo(shipment)
                    })();

                    const totalWeight = shipment.packages
                        .flatMap($ => $.items)
                        .map($ => $.weight)
                        .filter(val => Util.isNumberExist(val))
                        .map(val => big(val))
                        .reduce((t, c) => t.add(c), big(0))
                        .round(2).toNumber()

                    const totalQty = shipment.packages
                        .flatMap($ => $.items)
                        .map($ => $.quantity)
                        .filter(val => Util.isNumberExist(val))
                        .map(val => big(val))
                        .reduce((t, c) => t.add(c), big(0))
                        .round(2).toNumber()

                    const descOfGoods = shipment.packages
                        .flatMap($ => $.items)
                        .map(item => stringifyBlueprintInputFields(item.formFieldValues))
                        .join("\n\n")

                    return {
                        no: index + 1,
                        bl: blNo,
                        consignor: shipment.consignorIdName,
                        consignee: shipment.consigneeIdName,
                        description: descOfGoods,
                        weight: Util.formatAmount(totalWeight),
                        qty: totalQty + ' unit' + (totalQty != 1 ? 's' : ''),
                    }
                })} /> */}
                <PDFTable rowBorder normalPaddingTop cols={COLS} rows={master.shipments.flatMap((shipment, shipmentIndex) => {
                    const allItems = shipment.packages.flatMap($ => $.items).map($ => ({
                        ...$, value: $.remark
                    }))

                    return allItems.map((item, itemIndex) => {
                        const items = [item]

                        const blNo = getBLNo({ ...shipment, _blNoSuffix: allItems.length > 1 ? itemIndex + 1 : undefined })

                        const totalWeight = items
                            .map($ => $.weight)
                            .filter(val => Util.isNumberExist(val))
                            .map(val => big(val))
                            .reduce((t, c) => t.add(c), big(0))
                            .round(2).toNumber()

                        const totalQty = items
                            .map($ => $.quantity)
                            .filter(val => Util.isNumberExist(val))
                            .map(val => big(val))
                            .reduce((t, c) => t.add(c), big(0))
                            .round(2).toNumber()

                        const descOfGoods = items
                            .map(item => item.remark)
                            .join("\n\n")

                        const showDisplayQty = Util.isStringExists(item.displayQuantity)

                        return {
                            no: master.shipments.slice(0, shipmentIndex).flatMap(shipment => shipment.packages.flatMap(pkg => pkg.items)).length + itemIndex + 1,
                            bl: blNo,
                            // consignor: shipment.consignorIdName,
                            // consignee: shipment.consigneeIdName,
                            consignor: shipment.customer?.fullName,
                            consignee: shipment.consignee?.name,
                            description: descOfGoods,
                            weight: Util.formatAmount(totalWeight),
                            qty: showDisplayQty ? item.displayQuantity : (totalQty + ' UNIT' + (totalQty != 1 ? 'S' : '')),
                        }
                    })
                })} />
            </Page>
        </Document>
    )
}
export function createDetailedManifest(shipment) {
    return {
        PDF,
        canGenerate: Util.isNumberExist(shipment.shipmentMasterId),
        getPayload: (shipment, listener) => {
            Api.getShipmentMaster(shipment.shipmentMasterId, response => {
                if (response.status === true) {
                    listener(true, response.payload.item)
                } else {
                    listener(false, response.message);
                }
            })
        }
    }
}