

import { UnknownFilled16, CheckmarkFilled16, Close20, Van16, Calendar16, InProgress16, StopOutlineFilled20, InProgressWarning16, Van20, ErrorFilled16, Restaurant16, Package20, Cube20, Package16, Renew16, Time20, Time16, ServiceDesk16, Hourglass16, ServiceDesk20, Save16, CheckmarkFilled20, ErrorFilled20, Receipt16, DeskAdjustable16, DeskAdjustable20, Location16, ShoppingCart16 } from '@carbon/icons-react'
import React, { useMemo } from 'react';
import UIUtil from '../util/UIUtil';
import Api from '../session/Api';
import Button from '../components/Button';
import { ButtonSet, ComposedModal, InlineLoading, ModalBody, ModalFooter, ModalHeader, Tag, TextArea, TextInput } from 'carbon-components-react';
import { useState } from 'react';
import { useEffect } from 'react';
import Util, { big } from '../util/Util';
import { STATUS_PENDING, STATUS_PROCESSING, STATUS_REST_AWAITING_FINALIZE, STATUS_REST_PACKING, STATUS_REST_PREPARING, STATUS_REST_SERVING, getAddressCustomerName, getOrderAddress } from '../pages/sales-order/base/sales-order';
import { PaymentMethodDialog } from '../pages/pos/dialogs/payment-method-dialog';
import DateTimePicker from '../components/DateTimePicker';
import LocationPicker from '../components/LocationPicker';
import DriverAssignationDialog from '../pages/sales-order/views/DriverAssignationDialog';
import { ComboBoxField } from '../components/ComboBoxField';
import { getAccount, getAccountRole } from '../session/SessionManager';
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_REST_DELIVERY_MANAGER, ACCOUNT_TYPE_SUPERVISOR, ACCOUNT_TYPE_TYPE_WAITER } from '../constants/Constants';

export const REST_ORDER_TYPE = {
    DINE_IN: 1,
    TAKE_OUT: 2,
    PICK_UP: 3,
    DELIVERY: 4
}

export const KOT_ITEM_STATUS_AWAITING = 0;
export const KOT_ITEM_STATUS_STARTED = 1;
export const KOT_ITEM_STATUS_DONE = 2;

export function getOrderCustomerName(order) {
    return (Util.isStringExists(order.customerName) && order.customerName !== 'Guest' && order.customerName !== 'Unknown') ? order.customerName.trim() : getAddressCustomerName(order.shippingAddress)
}

export function getRestOrderDate(order) {
    const orderDate = Util.isNumberExist(order.targetDeliveryDate) ? order.targetDeliveryDate : order.preferredDeliveryDate;
    if (Util.isNumberExist(orderDate)) {
        return orderDate;
    } else {
        if (order.type === REST_ORDER_TYPE.DELIVERY || order.type === REST_ORDER_TYPE.TAKE_OUT) {
            return order.dateCreated;
        } else {
            return 0;
        }
    }
}

export function getRestOrderNote(order) {
    if (Util.isStringExists(order?.restOrderNote)) {
        return order.restOrderNote;
    }

    try {
        if (order?.preferences) {
            return JSON.parse(order.preferences)?.find($ => $.id === "id" || $.id === "note" || $.id === "Additional Notes")?.value
        }
    } catch (e) { }

    return ""
}

export function restOrderTypeName(type) {
    switch (type) {
        case REST_ORDER_TYPE.DINE_IN: return "Dine-in";
        case REST_ORDER_TYPE.TAKE_OUT: return "Take-out";
        case REST_ORDER_TYPE.PICK_UP: return "Pickup";
        case REST_ORDER_TYPE.DELIVERY: return "Delivery";
        default: return "Order";
    }
}

export function restOrderTypeNameWithDate(order) {
    const dateSuffix = (Util.isNumberExist(order.preferredDeliveryDate) || Util.isNumberExist(order.targetDeliveryDate)) ? ' (' + Util.formatDate(Util.isNumberExist(order.targetDeliveryDate) ? order.targetDeliveryDate : order.preferredDeliveryDate, "DD MMM, HH:mm") + ')' : '';
    switch (order.type) {
        case REST_ORDER_TYPE.DINE_IN: return "Dine-in";
        case REST_ORDER_TYPE.TAKE_OUT: return "Take-out";
        case REST_ORDER_TYPE.PICK_UP: return "Pickup" + dateSuffix;
        case REST_ORDER_TYPE.DELIVERY: return "Delivery" + dateSuffix;
        default: return "Order";
    }
}


export function getProgress(order) {
    let flow = []
    if (order.type === REST_ORDER_TYPE.DINE_IN) {
        flow = [STATUS_PROCESSING, STATUS_REST_PREPARING, STATUS_REST_SERVING, STATUS_REST_AWAITING_FINALIZE];
    } else if (order.type === REST_ORDER_TYPE.TAKE_OUT) {
        flow = [STATUS_PROCESSING, STATUS_REST_PREPARING, STATUS_REST_PACKING, STATUS_REST_AWAITING_FINALIZE];
    } else if (order.type === REST_ORDER_TYPE.PICK_UP) {
        flow = [STATUS_PENDING, STATUS_PROCESSING, STATUS_REST_PREPARING, STATUS_REST_PACKING, STATUS_REST_AWAITING_FINALIZE];
    } else if (order.type === REST_ORDER_TYPE.DELIVERY) {
        flow = [STATUS_PENDING, STATUS_PROCESSING, STATUS_REST_PREPARING, STATUS_REST_PACKING, STATUS_REST_AWAITING_FINALIZE];
    }

    return ((flow.indexOf(order.status) + 1) / (flow.length + 1)) * 100;
}


export function restOrderStatusComponents(order, onUpdate, onRemove, isKot, mainApp, deliveryLocations) {
    if (order.status === STATUS_PENDING) {
        return {
            actions: (<>
                <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
                <ConfirmBtn salesOrder={order} onUpdateOrder={onUpdate} deliveryLocations={deliveryLocations} />
            </>),
            statusBanner: (<>
                <Banner order={order} onUpdate={onUpdate} bg="rgb(237 195 0)" color="black" icon={InProgressWarning16} text="Awaiting Confirmation" />
            </>)
        }
    } else if (order.status === STATUS_PROCESSING) {
        return {
            actions: (<>
                <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
                <StartPrepareBtn salesOrder={order} onUpdateOrder={onUpdate} />
            </>),
            statusBanner: (<>
                <Banner order={order} onUpdate={onUpdate} bg="#a200c9" color="white" icon={Hourglass16} text="Awaiting Preparation" />
            </>)
        }
    } else if (order.status === STATUS_REST_PREPARING) {
        return {
            actions: (<>
                {!isKot && <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />}
                {isKot && <>
                    {order.type === REST_ORDER_TYPE.DINE_IN ? (
                        <ServeBtn salesOrder={order} onUpdateOrder={onUpdate} />
                    ) : (
                        <PackBtn salesOrder={order} onUpdateOrder={onUpdate} />
                    )}
                </>}
            </>),
            statusBanner: (<>
                <Banner order={order} onUpdate={onUpdate} bg="rgb(64 121 0)" color="white" icon={Restaurant16} text="Preparing" />
            </>)
        }
    } else if (order.status === STATUS_REST_SERVING) {
        const role = getAccountRole();
        return {
            actions: (<>
                <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
                {(role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR) &&
                    <StopServingBtn salesOrder={order} onUpdateOrder={onUpdate} />}
                <ServedBtn salesOrder={order} onUpdateOrder={onUpdate} />
            </>),
            statusBanner: (<>
                <Banner order={order} onUpdate={onUpdate} bg="rgb(255 103 0)" color="white" icon={ServiceDesk16} text="Serving" />
            </>)
        }
    } else if (order.status === STATUS_REST_PACKING) {
        const role = getAccountRole();
        return {
            actions: (<>
                <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
                {(role === ACCOUNT_TYPE_ADMINISTRATION || role === ACCOUNT_TYPE_SUPERVISOR) &&
                    <StopPackingBtn salesOrder={order} onUpdateOrder={onUpdate} />}
                <PackedBtn salesOrder={order} onUpdateOrder={onUpdate} />
            </>),
            statusBanner: (<>
                <Banner order={order} onUpdate={onUpdate} bg="rgb(255 103 0)" color="white" icon={Package16} text="Packing" />
            </>)
        }
    } else if (order.status === STATUS_REST_AWAITING_FINALIZE) {
        return {
            actions: (<>
                <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
                {order.type === REST_ORDER_TYPE.DELIVERY ? (
                    <SendOrderForDelivery salesOrder={order} onRemoveOrder={onRemove} />
                ) : (
                    <FinalizeBtn salesOrder={order} onRemoveOrder={onRemove} mainApp={mainApp} />
                )}
            </>),
            statusBanner: (<>
                {order.type === REST_ORDER_TYPE.DINE_IN ? (
                    <Banner order={order} onUpdate={onUpdate} bg="#00000020" color="black" icon={InProgress16} text="Awaiting Bill" />
                ) : (
                    <Banner order={order} onUpdate={onUpdate} bg="#00000020" color="black" icon={InProgress16} text="Awaiting Send Off" />
                )}
            </>)
        }
    }


    return {
        actions: (<>
            {/* <ActionButton red icon={<ErrorFilled20 />} text="Revoke" /> */}
            {/* <RevokeBtn salesOrder={order} onUpdateOrder={onUpdate} />
            <ActionButton icon={<CheckmarkFilled20 />} text="Confirm" /> */}
            <RevokeBtn salesOrder={order} onRemoveOrder={onRemove} />
        </>),
        statusBanner: (<>
            <Banner order={order} onUpdate={onUpdate} bg="#00000020" color="black" icon={UnknownFilled16} text="Unknown Status" />
        </>)
    }
}


export async function payOrderBeforeInvoice(salesOrder, onOrderUpdate) {
    let stopLoading;
    try {
        const paymentMethods = await UIUtil.promptUI(onSelect => (
            <PaymentMethodDialog customerId={salesOrder.customerId} amount={salesOrder.unpaidAmount} open onClose={() => onSelect(undefined)} onConfirmBtn={onSelect} />
        ))
        if (!paymentMethods) {
            return;
        }

        // stopLoading = await UIUtil.pageLoader();
        const updatedOrder = await Api.asyncPayOrderBeforeInvoice(salesOrder.id, paymentMethods);
        onOrderUpdate?.(updatedOrder)
    } catch (e) {
        if (e?.roboErpApiErr) {
            UIUtil.showError(e.msg);
        } else {
            UIUtil.showError()
        }
    } finally {
        stopLoading?.();
    }
}


const FinalizeBtn = ({ salesOrder, onRemoveOrder, mainApp }) => {
    const [visible, setVisible] = useState(false);
    const [loading, setLoading] = useState(false);

    const onConfirmBtn = () => {
        if (salesOrder.unpaidAmount > 0) {
            setVisible(true);
        } else {
            UIUtil.confirmMsg("No payment is required", () => onConfirm([]))
        }
    }
    const onPaymentMethodConfirmed = async (paymentMethods) => {
        const amountPaid = (paymentMethods ?? []).map($ => big($.amount)).reduce((t, c) => t.add(c), big(0));
        const orderAmount = big(salesOrder.unpaidAmount);

        const notFullyPaid = orderAmount.gt(amountPaid);
        if (notFullyPaid) {
            const confirm = await UIUtil.confirmPrompt(salesOrder.hasCredit ? {
                message: "No payment method selected",
                positiveAction: "Use Customer Credit"
            } : {
                message: "No payment method selected. Credit not sufficient.",
                positiveAction: "Create Unpaid Sale"
            })
            if (confirm) {
                onConfirm(paymentMethods);
            }
        } else {
            onConfirm(paymentMethods);
        }
    }

    const onConfirm = (paymentMethods) => {
        setLoading(true);
        Api.createSalesOrderStockFlow(salesOrder.id, paymentMethods, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onRemoveOrder(response.payload);
                setVisible(false);

                try {
                    if (mainApp?.thermalPrinter?.driver?.canPrintRestaurantDocs?.()) {
                        mainApp.thermalPrinter.driver.printBill(response.payload, paymentMethods)
                    }
                } catch (e) { }
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (<>
        {getAccountRole() !== ACCOUNT_TYPE_TYPE_WAITER &&
            <ActionButton green icon={<CheckmarkFilled20 />} text="Finalize" onClick={onConfirmBtn} loading={loading} />}
        <PaymentMethodDialog customerId={salesOrder.customerId} amount={salesOrder.unpaidAmount} open={visible} onClose={() => setVisible(false)} onConfirmBtn={onPaymentMethodConfirmed} loading={loading} />
    </>)
}


const ServedBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.servedOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<DeskAdjustable20 />} text="Done Serving" onClick={onClick} loading={loading} />
    )
}

const ServeBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.serveOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<ServiceDesk20 />} text="Start Serving" onClick={onClick} loading={loading} />
    )
}


const PackedBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.packedOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<Cube20 />} text="Done Packing" onClick={onClick} loading={loading} />
    )
}

const PackBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.packOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<Package20 />} text="Start Packing" onClick={onClick} loading={loading} />
    )
}


const StopServingBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.stopServingOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<Close20 />} text="Back to KOT" yellow onClick={onClick} loading={loading} />
    )
}
const StopPackingBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.stopPackingOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<Close20 />} text="Back to KOT" yellow onClick={onClick} loading={loading} />
    )
}


const StartPrepareBtn = ({ salesOrder, onUpdateOrder }) => {
    const [loading, setLoading] = useState(false);
    const onClick = () => {
        setLoading(true);
        Api.startRestOrder(salesOrder.id, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <ActionButton icon={<CheckmarkFilled20 />} text="Start" onClick={onClick} loading={loading} />
    )
}


const SendOrderForDelivery = ({ salesOrder, onRemoveOrder }) => {
    const [loading, setLoading] = useState(false);
    const [assignVisibility, setAssignVisibility] = useState(false);

    const onBtn = (driverId) => {
        setLoading(true);
        Api.sendOrderForDelivery(salesOrder.id, driverId, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onRemoveOrder(response.payload);
                setAssignVisibility(false)
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    return (
        <>
            <ActionButton icon={<Van20 />} text="Assign Driver" onClick={() => setAssignVisibility(true)} loading={loading} />
            <DriverAssignationDialog
                salesOrderId={salesOrder.id} label='Sending off for delivery' onButtonOverride={onBtn}
                open={assignVisibility} onClose={() => setAssignVisibility(false)} onUpdateOrder={onRemoveOrder} />
        </>
    )
}

export const ToggleDeliveryFee = ({ salesOrder, onUpdateOrder }) => {
    const hasFee = !big(salesOrder.deliveryFee).eq(big(0));
    const [loading, setLoading] = useState(false);


    const onToggleBtn = async () => {
        setLoading(true);
        const reason = prompt("Please input reason (required)");
        Api.toggleOrderDeliveryFee(salesOrder.id, !hasFee, reason, response => {
            setLoading(false);
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload)
            } else {
                UIUtil.showError(response.message);
            }
        })
    }

    if (salesOrder.type !== REST_ORDER_TYPE.DELIVERY) {
        return <div />
    }

    return (
        <Button loading={loading} style={{ maxWidth: 'unset' }} onClick={onToggleBtn} renderIcon={Van16} kind="secondary" size="md">
            {hasFee ? 'Remove' : "Recover"} Delivery Fee
            <Tag type={hasFee ? 'green' : 'magenta'}>{Util.formatMoney(salesOrder.deliveryFee)}</Tag>
        </Button>
    )
}


export const UpdateOrderDetails = ({ salesOrder, onUpdateOrder, deliveryLocations }) => {
    const [loading, setLoading] = useState(false);

    const [dateDialogVisible, setDateDialogVisible] = useState(false);
    const [targetDeliveryDate, setTargetDeliveryDate] = useState(getRestOrderDate(salesOrder));
    const [targetRentalPickupDate, setTargetRentalPickupDate] = useState(salesOrder.preferredRentalPickupDate);

    const [location, setLocation] = useState("");

    const [buildingName, setBuildingName] = useState(salesOrder?.shippingAddress?.buildingName)
    const [deliveryAreaId, setDeliveryId] = useState(salesOrder?.shippingAddress?.deliveryLocationId)
    const [addressLineOne, setAddressLineOne] = useState(salesOrder?.shippingAddress?.addressLineOne)

    const [orderNote, setOrderNote] = useState(() => getRestOrderNote(salesOrder));


    const onBtn = () => {
        setLoading(true);
        Api.setConfirmOrderData({
            salesOrderId: salesOrder.id,
            shippingLocation: location,
            targetDeliveryDate,
            orderNote,
            buildingName,
            deliveryLocationId: deliveryAreaId,
            addressLineOne
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
            setDateDialogVisible(false)
        })
    }

    // Api.revokeQuotation
    return (
        <>
            {/* <ActionButton icon={<CheckmarkFilled20 />} text="Confirm" onClick={() => setDateDialogVisible(true)} loading={loading} /> */}
            <Button style={{ maxWidth: 'unset' }} onClick={() => setDateDialogVisible(true)} renderIcon={Calendar16} kind="secondary" size="md">Update Order Details</Button>

            <ComposedModal open={dateDialogVisible} onClose={() => setDateDialogVisible(false)}>
                <ModalHeader label="Updating details" title="Order Details" />
                <ModalBody>
                    <TextArea labelText="Order Note" value={orderNote} onChange={e => setOrderNote(e.target.value)} />
                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Schedule Date</h6>
                            <DateTimePicker defaultDate={targetDeliveryDate} onDateChanged={setTargetDeliveryDate} />
                        </div>
                        {salesOrder.requiresRentalPickup && <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Target Pickup Date</h6>
                            <DateTimePicker defaultDate={targetRentalPickupDate} onDateChanged={setTargetRentalPickupDate} />
                        </div>}
                    </div>

                    {Util.isNumberExist(salesOrder.shippingAddressId) && <div style={{ marginTop: '1rem' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Shipping Address</h6>
                        <ComboBoxField light title={"Delivery Area"} options={deliveryLocations} value={deliveryAreaId} setValue={setDeliveryId} />
                        <div style={{ height: '1rem' }} />
                        <TextInput labelText="Building Name" value={buildingName} onChange={e => setBuildingName(e.target.value)} />
                        <div style={{ height: '1rem' }} />
                        <TextArea labelText='Address' value={addressLineOne} onChange={e => setAddressLineOne(e.target.value)} />




                        {dateDialogVisible && <LocationPicker
                            location={location}
                            onLocationChange={setLocation}
                            label="Select shipping location (optional)" />}
                    </div>}
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={() => setDateDialogVisible(false)}>
                        Close
                    </Button>
                    <Button
                        loading={loading}
                        // disabled={!Util.isStringExists(location)}
                        onClick={onBtn}>
                        Save
                    </Button>

                </ModalFooter>
            </ComposedModal>
        </>
    )
}


const ConfirmBtn = ({ salesOrder, onUpdateOrder, deliveryLocations }) => {
    const [loading, setLoading] = useState(false);

    const [dateDialogVisible, setDateDialogVisible] = useState(false);
    const [targetDeliveryDate, setTargetDeliveryDate] = useState(salesOrder.preferredDeliveryDate);
    const [targetRentalPickupDate, setTargetRentalPickupDate] = useState(salesOrder.preferredRentalPickupDate);

    const [location, setLocation] = useState("");

    const [buildingName, setBuildingName] = useState(salesOrder?.shippingAddress?.buildingName)
    const [deliveryAreaId, setDeliveryId] = useState(salesOrder?.shippingAddress?.deliveryLocationId)
    const [addressLineOne, setAddressLineOne] = useState(salesOrder?.shippingAddress?.addressLineOne)

    const [orderNote, setOrderNote] = useState(() => getRestOrderNote(salesOrder));


    const onBtn = () => {
        setLoading(true);
        Api.confirmSalesOrderOrderWithObjReq({
            salesOrderId: salesOrder.id,
            shippingLocation: location,
            targetDeliveryDate,
            orderNote,
            buildingName,
            deliveryLocationId: deliveryAreaId,
            addressLineOne
        }, response => {
            if (response.status === true) {
                UIUtil.showSuccess()
                onUpdateOrder(response.payload);
            } else {
                UIUtil.showError(response.message)
            }
            setLoading(false);
        })
    }

    // Api.revokeQuotation
    return (
        <>
            <ActionButton icon={<CheckmarkFilled20 />} text="Confirm" onClick={() => setDateDialogVisible(true)} loading={loading} />

            <ComposedModal open={dateDialogVisible} onClose={() => setDateDialogVisible(false)}>
                <ModalHeader label="Updating Status" title="Confirm Order" />
                <ModalBody>
                    <TextArea labelText="Order Note" value={orderNote} onChange={e => setOrderNote(e.target.value)} />
                    <div style={{ marginTop: '1rem' }}>
                        <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Target Delivery Date</h6>
                            <DateTimePicker defaultDate={targetDeliveryDate} onDateChanged={setTargetDeliveryDate} />
                        </div>
                        {salesOrder.requiresRentalPickup && <div style={{ flex: 1 }}>
                            <h6 style={{ marginBottom: '0.25rem' }}>Target Pickup Date</h6>
                            <DateTimePicker defaultDate={targetRentalPickupDate} onDateChanged={setTargetRentalPickupDate} />
                        </div>}
                    </div>

                    <div style={{ marginTop: '1rem' }}>
                        <h6 style={{ marginBottom: '0.25rem' }}>Shipping Address</h6>
                        <ComboBoxField light title={"Delivery Area"} options={deliveryLocations} value={deliveryAreaId} setValue={setDeliveryId} />
                        <div style={{ height: '1rem' }} />
                        <TextInput labelText="Building Name" value={buildingName} onChange={e => setBuildingName(e.target.value)} />
                        <div style={{ height: '1rem' }} />
                        <TextArea labelText='Address' value={addressLineOne} onChange={e => setAddressLineOne(e.target.value)} />




                        {dateDialogVisible && <LocationPicker
                            location={location}
                            onLocationChange={setLocation}
                            label="Select shipping location (optional)" />}
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button kind="secondary" onClick={() => setDateDialogVisible(false)}>
                        Close
                    </Button>
                    <Button
                        loading={loading}
                        // disabled={!Util.isStringExists(location)}
                        onClick={onBtn}>
                        Confirm
                    </Button>

                </ModalFooter>
            </ComposedModal>
        </>
    )
}

const RevokeBtn = ({ salesOrder, onRemoveOrder }) => {
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [reason, setReason] = useState("");

    useEffect(() => {
        if (visible) {
            setReason("")
        }
    }, [visible])
    const onRevokeBtn = () => {
        UIUtil.confirmMsg('This action is irreversible', () => {
            setLoading(true);
            Api.revokeSalesOrder(salesOrder.id, false, reason, response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onRemoveOrder(response.payload);
                    setVisible(false);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }
    const onRejectBtn = () => {
        UIUtil.confirmMsg('This action is irreversible', () => {
            setLoading(true);
            Api.revokeSalesOrder(salesOrder.id, true, "Order was rejected before confirm", response => {
                if (response.status === true) {
                    UIUtil.showSuccess()
                    onRemoveOrder(response.payload);
                    setVisible(false);
                } else {
                    UIUtil.showError(response.message)
                }
                setLoading(false);
            })
        })
    }
    //return 
    return (
        <>
            {salesOrder.status === STATUS_PENDING &&
                <ActionButton black icon={<StopOutlineFilled20 />} text="Reject" onClick={onRejectBtn} loading={loading} />}
            {getAccountRole() !== ACCOUNT_TYPE_TYPE_WAITER &&
                <ActionButton red icon={<ErrorFilled20 />} text="Revoke" onClick={() => setVisible(true)} loading={loading} />}

            <ComposedModal
                key="sales-order-revoke-reason-dialog"
                open={visible}
                onClose={() => setVisible(false)}
                size="sm">
                <ModalHeader label="Sales Order" title="Revoking" />
                <ModalBody style={{ paddingRight: '1rem' }}>
                    <TextArea
                        value={reason}
                        onChange={e => setReason(e.target.value)}
                        labelText="Reason"
                        placeholder="Please state the reason for revoking order" />

                </ModalBody>
                <ModalFooter>
                    <ButtonSet style={{ width: '100%' }}>
                        <Button kind="secondary" onClick={() => setVisible(false)} renderIcon={ErrorFilled16} >
                            Cancel
                        </Button>
                        <Button onClick={onRevokeBtn} renderIcon={Save16} disabled={loading || !Util.isStringExists(reason)}
                        >
                            Save
                        </Button>
                    </ButtonSet>
                </ModalFooter>
            </ComposedModal>
        </>
    )
}

const ActionButton = ({ icon, text, green, red, yellow, black, loading, onClick }) => (
    <button onClick={loading ? null : onClick} className={"really_centered-progress-bar " + (red ? ('red-button') : (yellow ? 'yellow-button' : black ? "black-button" : 'blue-button')) + (green ? " green-button" : "")} style={{
        flex: 1, height: 64, display: 'flex', alignItems: 'center', flexDirection: 'column', justifyContent: 'center', gap: '0.25rem',
        opacity: loading ? 0.65 : 1, pointerEvents: loading ? 'none' : undefined,
        color: (yellow) ? 'black' : 'white'
    }}>
        {loading ? <InlineLoading style={{ width: 'unset' }} /> : icon}
        <p style={{ fontSize: 12, fontWeight: 'bold' }}>{text}</p>
    </button>
)

const HowLongIndicator = ({ val }) => {
    const rangePerc = Math.min(val / 60, 1) * 100;
    return (
        <div style={{ width: '100%', position: 'relative', }}>
            <div style={{
                borderRadius: 25, height: 15, width: '100%', background: 'linear-gradient(90deg, rgba(22,80,0,1) 0%, rgba(219,224,0,1) 49%, rgba(255,0,0,1) 100%)',
                border: '1px solid #ffffff'
            }} />
            <div style={{ background: '#ffffff80', position: 'absolute', height: 15, borderRadius: 50, borderTopLeftRadius: 0, borderBottomLeftRadius: 0, width: (100 - rangePerc) + '%', left: rangePerc + '%', top: 0 }} />
            <div style={{ background: '#0f62fe', height: 18, width: 4, position: 'absolute', left: rangePerc + '%', top: '50%', transform: 'translateY(-50%)' }} />
        </div>
    )
}

const DineInServiceBtn = ({ order, onUpdate }) => {
    const serviceDone = order.restServiceDone;

    const [alertBg, setAlertBg] = useState("red");
    const color = alertBg === "yellow" ? "black" : 'white';
    const confirmMessage = serviceDone ? "Is service not done?" : "Is service done?"

    useEffect(() => {
        if (!serviceDone) {
            const interval = setInterval(() => {
                setAlertBg(color => color === "red" ? "yellow" : "red");
            }, 500)
            return () => clearInterval(interval);
        }
    }, [serviceDone])

    const [loading, setLoading] = useState(false)
    const onClick = () => {
        setLoading(true);
        Api.setOrderServiceDone(order.id, !serviceDone, response => {
            if (response.status === true) {
                UIUtil.showSuccess();
                onUpdate(response.payload)
            } else {
                UIUtil.showError(response.message);
            }
            setLoading(false);
        })
    }

    return (
        <div onClick={() => UIUtil.confirmWithMsg(confirmMessage, onClick)} style={{
            borderRadius: 7, background: serviceDone ? "green" : alertBg, color, width: 50, height: 50, minWidth: 50, display: 'flex', justifyContent: 'center', alignItems: 'center',
            opacity: loading ? 0.65 : 1, pointerEvents: loading ? 'none' : undefined
        }}>
            <ServiceDesk20 />
        </div>
    )
}

const TimerStat = ({ order, onUpdate }) => {
    const [tick, setTick] = useState(0);
    const stat = useMemo(() => ({
        millisPassed: (new Date().getTime() - order.dateCreated),
        minutesPassed: (new Date().getTime() - order.dateCreated) / 60000
    }), [tick])

    useEffect(() => {
        const interval = setInterval(() => {
            setTick(t => t + 1);
        }, 500)
        return () => clearInterval(interval);
    }, [])
    return (<>
        <div style={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <div style={{ display: 'flex', gap: '0.25rem', alignItems: 'center', color: 'white', background: '#ffffff20', borderRadius: 7, paddingInline: '0.5rem', paddingBlock: '0.25rem' }}>
                <div>
                    <Time20 />
                </div>
                <div>
                    <p>{Util.formatDurationWithSeconds(stat.millisPassed)}</p>
                </div>
            </div>
            <HowLongIndicator val={stat.minutesPassed} />
            {order.type === REST_ORDER_TYPE.DINE_IN &&
                <DineInServiceBtn order={order} onUpdate={onUpdate} />}
        </div>
    </>)
}

const Banner = ({ order, onUpdate, icon, bg, color, text }) => (
    <div style={{ width: '100%', background: bg, }}>
        <div style={{ display: "flex", alignItems: 'center', paddingInline: '0.5rem', paddingBlock: '0.25rem', color: color, gap: '0.25rem' }}>
            {React.createElement(icon)}
            <p style={{ fontSize: 14, flex: 1 }}>{text}</p>
            {order.fromOnlineStore && <Tag type="blue" size='sm'>online store</Tag>}
        </div>
        <div style={{ width: '100%', background: '#00000060', padding: '1rem' }}>
            <TimerStat order={order} onUpdate={onUpdate} />
            {/* <div style={{ position: 'relative', borderRadius: 25, height: 15, marginTop: '0.5rem', width: '100%', background: '#ffffff20', overflow: 'hidden' }}>
                <div style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: getProgress(order) + '%', background: 'white' }} />
            </div> */}
        </div>
    </div>
)