import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import Logo from "../../../images/doc-logo.png"
import { SHIPMENT_DIR_TYPE } from "../shipment"
import { ArrowRight16 } from '@carbon/icons-react'
import { useHistory } from "react-router-dom"
import Util from "../../../util/Util"

const DetailValueEntry = ({ label, value }) => (<>
    <div style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: 'end' }}>{value}</p>
    </div>
</>)
function Detail({ shipment }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Details</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <DetailValueEntry label="Shipment No" value={shipment.shipmentNo} />
                    <DetailValueEntry label="Type" value={shipment.dirType === SHIPMENT_DIR_TYPE.IMPORT ? 'Import' : 'Export'} />
                    <DetailValueEntry label="Loading Mode" value={shipment.loadingModeName} />
                    <DetailValueEntry label="Status" value={shipment.status} />
                    {Util.isNumberExist(shipment.shipmentMasterId) &&
                        <ButtonValueEntry label="Master" href={"/shipment-master-qr/" + shipment.shipmentMasterId} />}
                </div>
            </div>

        </div>
    )
}

const ButtonValueEntry = ({ label, href }) => {
    const history = useHistory();
    return (<>
        <div onClick={() => history.push(href)} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
            <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
            <ArrowRight16 style={{ opacity: 0.65 }} />
        </div>
    </>)
}
function Packages({ shipment }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Packages</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    {shipment.packages.map(aPackage => <ButtonValueEntry label={"Package " + aPackage.id} href={"/shipment-package-qr/" + aPackage.id} />)}
                </div>
            </div>

        </div>
    )
}

function View({ payload: { item: shipment } }) {
    return (
        <div style={{ padding: '1rem' }}>
            <img src={Logo} style={{ width: '100%', height: 150, marginBlock: '1rem', objectFit: 'contain', objectPosition: 'center', }} />
            <h4 style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center', width: '100%' }}>Shipment</h4>
            <p style={{ fontSize: 16, opacity: 0.65, textAlign: 'center', width: '100%' }}>{shipment.shipmentNo}</p>

            <div style={{ height: '1rem' }} />

            <Detail shipment={shipment} />
            <Packages shipment={shipment} />

            <div style={{ height: 300 }} />
        </div>
    )
}

export const ShipmentJobQr = withLoadablePageWithParams(params => listener => Api.getPublicShipment(params.shipmentId, listener), View)

