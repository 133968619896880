import { Product24, Renew16, Tag16, AppConnectivity16, Cube16, Table16, Grid16 } from '@carbon/icons-react'
import { Checkbox, ComboBox, InlineLoading, Search, Select, SelectItem, Tag, TextInput } from 'carbon-components-react'
import React, { useEffect, useRef, useState } from 'react'
import Badge from '../../../components/Badge'
import Button from '../../../components/Button'
import ProfilePic from '../../../components/ProfilePic'
import { OBJECT_TYPE_PRODUCT, OBJECT_TYPE_PRODUCT_BUNDLE } from '../../../constants/ObjectTypes'
import Api from '../../../session/Api'
import useSize from '../../../util/useSize'
import ReactTooltip from 'react-tooltip';
import Util from '../../../util/Util'
import InfiniteScroll from 'react-infinite-scroller';
import { getTerminal } from '../../../session/SessionManager'
import UIUtil from '../../../util/UIUtil'
import { ProductGroupSetAttribute } from '../../../views/product/ProductGroupSetAttribute'
import { useBooleanLocalStorage } from '../../../hooks/useLocalStorage'


const HEIGHT_MIN = 200;
const HEIGHT_MID = 225;

const OPTIMAL_WIDTH = 600;
const OPTIMAL_HEIGHT = 286;




const ProductItem = ({id, name, amount, lowStock, stock, noStock, bundle, onClick, onSpecificIdClick, defaultProductGroupId, allowNegativeStock, skipStockInfo}) => (
    <div onClick={(noStock && !allowNegativeStock) ? (() => {}) : onClick} 
    // onClick={onClick}
    style={{
        position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', cursor: noStock ? undefined : 'pointer'}}>
        <ProfilePic style={{opacity: (noStock && !allowNegativeStock) ? 0.65 : 1,}} size={90} notProfile src={Api.getThumbnail(bundle ? OBJECT_TYPE_PRODUCT_BUNDLE : OBJECT_TYPE_PRODUCT, id)} />
        <p style={{whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis',
        fontSize: 14, marginTop: 10, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, maxWidth: 90, }}>{name}</p>

        {(amount.price != amount.originalPrice && amount.originalPrice) ? (<> 
            <p style={{fontSize: 12, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, textDecoration: 'line-through', color: 'red'}}>
                <span style={{color: 'black'}}>AED {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
            </p>
            <div style={{display: 'flex', alignItems: 'center'}}>
                <Tag16 style={{marginRight: '0.25rem', fill: 'red'}} />
                <p style={{fontSize: 14, opacity: 0.65, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, color: 'red', fontWeight: 'bold'}}>
                    AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                </p>
            </div>
        </>) : (<>
            <p style={{fontSize: 12, opacity: 0.65, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1,}}>AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</p>
        </>)}

        {noStock && !skipStockInfo && <p style={{fontWeight: 'bold', color: 'red', fontSize: 14}}>NO STOCK</p>}

        {Util.isNumberExist(defaultProductGroupId) && 
            <Button onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={id} open onClose={onClose} groupId={defaultProductGroupId} onSelect={onSpecificIdClick} />)
            }} size="sm" style={{borderRadius: 15, margin: 0, padding: 0, height: 22, minHeight: 'unset', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 75, fontSize: 12}}>Attribute</Button>}

        {/* {noStock ? (
            <>
            </>
        ) : (
            <>
            </>
        )} */}

        {!noStock && lowStock && !skipStockInfo && <div style={{position: 'absolute', background: 'red', borderRadius: 25, color: 'white', zIndex: 1000, 
        top: 0, right: -15,
        paddingTop: 5, paddingBottom: 5, paddingLeft: 12, paddingRight: 12}}>
            <p style={{fontSize: 12}}>Low stock <strong>{stock}</strong></p>
        </div>}
        {!noStock && !lowStock && !skipStockInfo && Util.isNumberExist(stock) && <Tag renderIcon={Cube16} type="high-contrast" size="sm">{stock}x</Tag>}

        {/* {Util.isNumberExist(defaultProductGroupId) && 
        <div style={{position: 'absolute', overflow: 'hidden', background: 'red', borderRadius: 50, color: 'white', zIndex: 1000, bottom: 0, left: -15, paddingTop: 5, paddingBottom: 5, paddingLeft: 12, paddingRight: 12}}>
            
        </div>} */}
    </div>
)

const FullWidthProductItem = ({id, name, amount, lowStock, stock, noStock, bundle, onClick, onSpecificIdClick, defaultProductGroupId, allowNegativeStock, skipStockInfo}) => (
    <div onClick={(noStock && !allowNegativeStock) ? (() => {}) : onClick} 
    // onClick={onClick}
    style={{
        width: '100%',
        background: 'white', borderRadius: 5, border: '1px solid #00000020', paddingInline: '0.5rem', paddingBlock: '0.25rem',
        position: 'relative', display: 'flex', alignItems: 'center', gap: '0.5rem', cursor: (noStock && !allowNegativeStock) ? undefined : 'pointer'}}>
        <ProfilePic style={{opacity: (noStock && !allowNegativeStock) ? 0.65 : 1,}} size={32} notProfile src={Api.getThumbnail(bundle ? OBJECT_TYPE_PRODUCT_BUNDLE : OBJECT_TYPE_PRODUCT, id)} />
        <div style={{flex: 1}}>
            <p style={{whiteSpace: 'nowrap', wordBreak: 'break-all', overflow: 'hidden', textOverflow: 'ellipsis',
            fontSize: 14, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, width: '100%', }}>{name}</p>

            {(amount.price != amount.originalPrice && amount.originalPrice) ? (<> 
                <p style={{fontSize: 12, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, textDecoration: 'line-through', color: 'red'}}>
                    <span style={{color: 'black'}}>AED {amount !== null ? amount.originalPrice.toFixed(2) : (0).toFixed(2)}</span>
                </p>
                <div style={{display: 'flex', alignItems: 'center'}}>
                    <Tag16 style={{marginRight: '0.25rem', fill: 'red'}} />
                    <p style={{fontSize: 14, opacity: 0.65, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1, color: 'red', fontWeight: 'bold'}}>
                        AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}
                    </p>
                </div>
            </>) : (<>
                <p style={{fontSize: 12, opacity: 0.65, opacity: (noStock && !allowNegativeStock) ? 0.65 : 1,}}>AED {amount !== null ? amount.price.toFixed(2) : (0).toFixed(2)}</p>
            </>)}
        </div>
        {noStock && !skipStockInfo && <p style={{fontWeight: 'bold', color: 'red', fontSize: 14}}>NO STOCK</p>}
            {Util.isNumberExist(defaultProductGroupId) && 
                <Button onClick={e => {
                    e.preventDefault();
                    e.stopPropagation();

                    UIUtil.showOverlay2(onClose => <ProductGroupSetAttribute defaultProductId={id} open onClose={onClose} groupId={defaultProductGroupId} onSelect={onSpecificIdClick} />)
                }} size="sm" style={{borderRadius: 15, margin: 0, padding: 0, height: 22, minHeight: 'unset', display: 'flex', justifyContent: 'center', alignItems: 'center', width: 75, fontSize: 12}}>Attribute</Button>}
            {!noStock && lowStock && !skipStockInfo && <div style={{ background: 'red', borderRadius: 25, color: 'white', zIndex: 1000, paddingInline: '0.5rem', paddingBlock: '0.15rem', }}>
                <p style={{fontSize: 12}}>Low stock <strong>{stock}</strong></p>
            </div>}

        {!noStock && !lowStock && !skipStockInfo && Util.isNumberExist(stock) && <Tag renderIcon={Cube16} type="high-contrast" size="sm">{stock}x</Tag>}
    </div>
)


export default ({state, hideTitle, skipStockInfo, overrideStoreId}) => {
    const [allowNegativeStock, setAllowNegativeStock] = useState(true);

    const [fullWidth, setFullWidth] = useBooleanLocalStorage('pos-product-view-full-width')

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);

    const [categories, setCategories] = useState([]);

    const [selectedCategoryId, setSelectedCategoryId] = useState(0)
    const [searchValue, setSearchValue] = useState("");

    const [items, setItems] = useState([]);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);

    const loadingRef = useRef(false);
    const firstTimeRef = useRef(true);
    
    const reqIdRef = useRef(Util.newTempId());

    useEffect(() => {
        if (page == -1) {
            setPage(0);
            return;
        }

        setLoading(true);
        loadingRef.current = true;
        const reqId = reqIdRef.current;
        // const storeId = getTerminal().storeId;
        const storeId = overrideStoreId ?? (getTerminal() ? getTerminal().storeId : 0);

        const listener = response => {
            if (reqIdRef.current != reqId) {
                return;
            }

            if (response.status === true) {
                setItems(items => [...items, ...response.payload.items])
                setHasMore(response.payload.hasMore)
            } else {
                setError(true)
            }
            setLoading(false)
            loadingRef.current = false;
        };

        if (searchValue && searchValue.trim && searchValue.trim() != "") {
            //Api.searchProductsForPos2(searchValue, page, storeId, listener)
            Api.getPosProducts({
                page, storeId,
                categoryId: 0,
                search: searchValue
            }, listener)
        } 
        // else if (selectedCategoryId == -1) {
        //     Api.getBundlesForPos2(page, storeId, listener)
        // } 
        else {
            //Api.getProductsForPos2(selectedCategoryId == 0 ? -1 : selectedCategoryId, page, storeId, listener)
            Api.getPosProducts({
                page, storeId,
                categoryId: selectedCategoryId
            }, listener)
        }

    }, [page])
    
    useEffect(() => {
        if (firstTimeRef.current === true) {
            firstTimeRef.current = false;
            return;
        }

        reqIdRef.current = Util.newTempId();
        setItems([])
        setPage(-1);
    }, [selectedCategoryId, searchValue])

    const reset = () => {
        reqIdRef.current = Util.newTempId();
        setItems([])
        setPage(-1)
    }

    const loadCategories = () => {
        setLoading(true);
        Api.getAllCategories(response => {
            setLoading(false);
            setError(response.status !== true);
            if (response.status === true) {
                setCategories(response.payload)
            }
        })
    }

    const getNextItems = () => {
        if (loadingRef.current === true) {
            return;
        }

        loadingRef.current = true;
        setPage(page + 1);
    }

    useEffect(() => {
        loadCategories();
    }, [state.getState().sessionUpdateId]);

    const targetRef = useRef();
    const size = useSize(targetRef)

    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])



    useEffect(() => {
        Api.isSettingEnabled('allow_negative_stock', response => {
            if (response.status === true) {
                setAllowNegativeStock(response.payload)
            }
        })
    }, [])

    return (
        <div ref={targetRef} className="large-search-box dashboard-card" style={{width: '100%', height: '100%', background: '#f4f4f4', padding: '1rem', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
            {size.height > HEIGHT_MIN && <div style={{display: 'flex', alignItems: 'center',}}>
                {size.width >= 780 && !hideTitle && <> 
                <Product24 style={{marginRight: 5}} />
                <h4>Products</h4>
                </>}

                {size.width >= 780 && <div style={{flex: 1}} />}

                <Search 
                light
                size="lg"
                // disabled={loading}
                value={searchValue}
                onChange={e => setSearchValue(e.target.value)}
                placeholder="Search"
                style={{height: 48}}
                />

                <div style={{width: 5}} />

                <ComboBox
                light
                size="lg"
                style={{width: 250}}
                //disabled={loading || Util.isStringExists(searchValue)}
                disabled={Util.isStringExists(searchValue)}
                //items={[{id: -1, value: "Bundles"}, ...categories]}
                items={categories}
                itemToString={category => category !== null ? category.value : ""}
                placeholder="Select Category"
                onChange={e => setSelectedCategoryId(e.selectedItem !== null ? e.selectedItem.id : 0)}
                />

                

                <Button loading={loading} kind="tertiary" hasIconOnly renderIcon={fullWidth ? Table16 : Grid16} size="lg" 
                data-tip="Change View" tooltipPosition="bottom" tooltipAlignment="end"
                style={{height: 48, alignSelf: 'flex-end', marginLeft: 5}} onClick={() => setFullWidth(!fullWidth)} />
                <Button loading={loading} kind="tertiary" hasIconOnly renderIcon={Renew16} size="lg" 
                data-tip="Reload" tooltipPosition="bottom" tooltipAlignment="end"
                style={{height: 48, alignSelf: 'flex-end', marginLeft: 5}} onClick={reset} />
                
            </div>}

            {!error ? (
                <InfiniteScroll
                    loadMore={getNextItems}
                    hasMore={hasMore}
                    useWindow={false}
                    threshold={size.height - (0.80 * size.height)}
                    loader={
                        <div className="centered-progress-bar" style={{display: 'flex', justifyContent: 'center', alignItems: 'center', width: 90, height: 90}}>
                            <InlineLoading />
                        </div>
                    }
                    style={{overflow: 'visible'}}>
                        
                        <div style={{display: 'flex', flex: 1,}}>
                            {size.height <= HEIGHT_MIN && <div style={{marginRight: '1.5rem'}}>
                            <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
                                <Product24 style={{marginRight: 5}} />
                                <h4>Products</h4>
                            </div>

                            <Search 
                            light
                            size="lg"
                            // disabled={loading}
                            value={searchValue}
                            onChange={e => setSearchValue(e.target.value)}
                            placeholder="Search"
                            style={{height: 48, marginBottom: '0.25rem'}}
                            />

                            <ComboBox
                            light
                            size="lg"
                            style={{flex: 1}}
                            disabled={loading || Util.isStringExists(searchValue)}
                            //items={[{id: -1, value: "Bundles"}, ...categories]}
                            items={categories}
                            itemToString={category => category !== null ? category.value : ""}
                            placeholder="Select Category"
                            onChange={e => setSelectedCategoryId(e.selectedItem !== null ? e.selectedItem.id : 0)}
                            />

                            <Button loading={loading} kind="tertiary" renderIcon={fullWidth ? Table16 : Grid16} size="sm" 
                            style={{height: 48, alignSelf: 'flex-end', marginTop: 5}} onClick={() => setFullWidth(!fullWidth)}>
                                Change View
                            </Button>
                            <Button loading={loading} kind="tertiary" renderIcon={Renew16} size="sm" 
                            style={{height: 48, alignSelf: 'flex-end', marginTop: 5}} onClick={reset}>
                                Reload
                            </Button>

                        </div>}

                        <div style={{display: 'flex', gap: fullWidth ? '0.5rem' : (size.height > HEIGHT_MIN ? '3rem' : '1rem'), flexWrap: 'wrap', padding: '0rem', flex: 1, paddingTop: size.height > HEIGHT_MIN ? '1rem' : 0}}>

                            {fullWidth ? (
                                items.map(product => 
                                    <FullWidthProductItem key={product.id + "-product-preview"} 
                                    onClick={() => state.updateCartItem(product.id, 1, false)}
                                    onSpecificIdClick={id => state.updateCartItem(id, 1, false)}
                                    defaultProductGroupId={product.defaultProductGroupId}
                                    lowStock={product.lowStock} stock={product.stock} noStock={product.bundle ? false : product.stock == 0}
                                    id={product.id} name={product.name} amount={product.amount} bundle={product.bundle}
                                    allowNegativeStock={allowNegativeStock || skipStockInfo} 
                                    skipStockInfo={skipStockInfo}
                                    />)
                            ) : (
                                items.map(product => 
                                <ProductItem key={product.id + "-product-preview"} 
                                    onClick={() => state.updateCartItem(product.id, 1, false)}
                                    onSpecificIdClick={id => state.updateCartItem(id, 1, false)}
                                    defaultProductGroupId={product.defaultProductGroupId}
                                    lowStock={product.lowStock} stock={product.stock} noStock={product.bundle ? false : product.stock == 0}
                                    id={product.id} name={product.name} amount={product.amount} bundle={product.bundle}
                                    allowNegativeStock={allowNegativeStock || skipStockInfo} 
                                    skipStockInfo={skipStockInfo}
                                    />)
                            )}

                            {/* {items
                            .map(product => 
                            <ProductItem key={product.id + "-product-preview"} 
                            onClick={() => state.updateCartItem(product.id, 1, false)}
                            onSpecificIdClick={id => state.updateCartItem(id, 1, false)}
                            defaultProductGroupId={product.defaultProductGroupId}
                            lowStock={product.lowStock} stock={product.stock} noStock={product.bundle ? false : product.stock == 0}
                            id={product.id} name={product.name} amount={product.amount} bundle={product.bundle} />)} */}
                        </div>
                    </div>

                </InfiniteScroll>
            ) : (
                <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <p style={{fontSize: 12, opacity: 0.65}}>An error occurred. Please try again later.</p>
                </div>
            )}
        </div>
    )
}

// export default ({state}) => {
//     const [loading, setLoading] = useState(false);
//     const [error, setError] = useState(false);

    
//     const [hasMore, setHasMore] = useState(false);

//     const [searchValue, setSearchValue] = useState("");

//     const [categories, setCategories] = useState([]);
//     const [products, setProducts] = useState([]);
//     const [bundles, setBundles] = useState([]);

//     const [selectedCategoryId, setSelectedCategoryId] = useState(0)

//     const loadProducts = () => {
//         setLoading(true);
//         Api.getAllProducts(response => {
//             setLoading(false);
//             setError(response.status !== true);
//             if (response.status === true) {
//                 setCategories(response.payload.categories)
//                 setProducts(response.payload.products);
//                 setBundles(response.payload.bundles)
//             }
//         })
//     }

//     useEffect(() => {
//         loadProducts();
//     }, [state.getState().sessionUpdateId]);

//     const targetRef = useRef();
//     const size = useSize(targetRef)

//     useEffect(() => {
//         ReactTooltip.rebuild()
//     }, [size])

//     const getItems = () => {
//         if (Util.isStringExists(searchValue)) {
//             return products.filter(product => {
//                 return (Util.isStringExists(product.name) && product.name.toLowerCase().includes(searchValue.toLowerCase())) || (product.barcodes && product.barcodes.includes(searchValue));
//             });
//         } else {
//             return (selectedCategoryId == -1 ? bundles : products.filter(product => selectedCategoryId > 0 ? product.categoryId == selectedCategoryId : true));
//         }
//     }

//     return (
//         <div ref={targetRef} className="large-search-box dashboard-card" style={{width: '100%', height: '100%', background: '#f4f4f4', padding: '1rem', overflow: 'auto', display: 'flex', flexDirection: 'column'}}>
//             {size.height > HEIGHT_MIN && <div style={{display: 'flex', alignItems: 'center',}}>
//                 {size.width >= 780 && <> 
//                 <Product24 style={{marginRight: 5}} />
//                 <h4>Products</h4>
//                 </>}

//                 {size.width >= 780 && <div style={{flex: 1}} />}

//                 <Search 
//                 light
//                 size="lg"
//                 disabled={loading}
//                 value={searchValue}
//                 onChange={e => setSearchValue(e.target.value)}
//                 placeholder="Search"
//                 style={{height: 48}}
//                 />

//                 <div style={{width: 5}} />

//                 <ComboBox
//                 light
//                 size="lg"
//                 style={{width: 250}}
//                 disabled={loading || Util.isStringExists(searchValue)}
//                 items={[{id: -1, value: "Bundles"}, ...categories]}
//                 itemToString={category => category !== null ? category.value : ""}
//                 placeholder="Select Category"
//                 onChange={e => setSelectedCategoryId(e.selectedItem !== null ? e.selectedItem.id : 0)}
//                 />

                

//                 <Button loading={loading} kind="tertiary" hasIconOnly renderIcon={Renew16} size="lg" 
//                 data-tip="Reload" tooltipPosition="bottom" tooltipAlignment="end"
//                 style={{height: 48, alignSelf: 'flex-end', marginLeft: 5}} onClick={loadProducts} />
                
//             </div>}

//             {!error ? (
//                 <InfiniteScroll
//                     loadMore={this.getNextItems.bind(this)}
//                     hasMore={this.state.hasMore}
//                     useWindow={false}
//                     loader={
//                         <div style={{display: 'flex', justifyContent: 'center', marginTop: '45px', marginBottom: '45px'}}>
//                             <InlineLoading />
//                         </div>
//                     }
//                     style={{overflow: 'visible'}}>
                        
//                         <div style={{display: 'flex', flex: 1,}}>
//                             {size.height <= HEIGHT_MIN && <div style={{marginRight: '1.5rem'}}>
//                             <div style={{display: 'flex', alignItems: 'center', marginBottom: '1rem'}}>
//                                 <Product24 style={{marginRight: 5}} />
//                                 <h4>Products</h4>
//                             </div>

//                             <Search 
//                             light
//                             size="lg"
//                             disabled={loading}
//                             value={searchValue}
//                             onChange={e => setSearchValue(e.target.value)}
//                             placeholder="Search"
//                             style={{height: 48, marginBottom: '0.25rem'}}
//                             />

//                             <ComboBox
//                             light
//                             size="lg"
//                             style={{flex: 1}}
//                             disabled={loading || Util.isStringExists(searchValue)}
//                             items={[{id: -1, value: "Bundles"}, ...categories]}
//                             itemToString={category => category !== null ? category.value : ""}
//                             placeholder="Select Category"
//                             onChange={e => setSelectedCategoryId(e.selectedItem !== null ? e.selectedItem.id : 0)}
//                             />
//                         </div>}

//                         <div style={{display: 'flex', gap: size.height > HEIGHT_MIN ? '3rem' : '1rem', flexWrap: 'wrap', padding: '0rem', flex: 1, paddingTop: size.height > HEIGHT_MIN ? '1rem' : 0}}>


//                             {getItems()
//                             .map(product => 
//                             <ProductItem key={product.id + "-product-preview"} 
//                             onClick={() => state.updateCartItem(product.id, 1, false)}
//                             lowStock={product.lowStock} stock={product.stock} noStock={product.bundle ? false : product.stock == 0}
//                             id={product.id} name={product.name} amount={product.amount} bundle={product.bundle} />)}
//                         </div>
//                     </div>

//                 </InfiniteScroll>
//             ) : (
//                 <div style={{width: '100%', height: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
//                     <p style={{fontSize: 12, opacity: 0.65}}>An error occurred. Please try again later.</p>
//                 </div>
//             )}
//         </div>
//     )
// }