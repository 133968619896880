import Button from "../../components/Button";
import { TRANSACTION_DIRECTION_TYPE_INWARD, TRANSACTION_PARTY_TYPE_CUSTOMER, TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR, TRANSACTION_PARTY_TYPE_OTHER, TRANSACTION_PARTY_TYPE_STAFF, TRANSACTION_PARTY_TYPE_STORE, TRANSACTION_PARTY_TYPE_STUDENT, TRANSACTION_PARTY_TYPE_SUPPLIER, TRANSACTION_PARTY_TYPE_TUTOR, TRANSACTION_PARTY_TYPE_V2_CUSTOMER, TRANSACTION_PARTY_TYPE_V2_EMPLOYEE, TRANSACTION_PARTY_TYPE_V2_VENDOR, TRANSACTION_PARTY_TYPE_VENDOR, TRANSACTION_PARTY_TYPE_VENUE } from "../../constants/Constants";
import { OBJECT_TYPE_ACCOUNT, OBJECT_TYPE_BILL, OBJECT_TYPE_COMMISSION, OBJECT_TYPE_CUSTOMER, OBJECT_TYPE_INVOICE_DOCUMENT, OBJECT_TYPE_MOVIE_DISTRIBUTOR, OBJECT_TYPE_PAYOUT, OBJECT_TYPE_PURCHASE, OBJECT_TYPE_SALE, OBJECT_TYPE_SERVICE_SALE, OBJECT_TYPE_STOCK_FLOW, OBJECT_TYPE_STORE, OBJECT_TYPE_STUDENT, OBJECT_TYPE_SUPPLIER, OBJECT_TYPE_TUTOR, OBJECT_TYPE_V2_BILL, OBJECT_TYPE_V2_CUSTOMER, OBJECT_TYPE_V2_EMPLOYEE, OBJECT_TYPE_V2_SALE, OBJECT_TYPE_V2_VENDOR, OBJECT_TYPE_VENDOR, OBJECT_TYPE_VENUE } from "../../constants/ObjectTypes";
import {
    Wallet16
} from '@carbon/icons-react'
import { FormDialog } from "../../templates/form/form-dialog";
import { FormSection } from "../../templates/form/form";
import { useState } from "react";
import Api from "../../session/Api";
import TransactionEditor from "../../pages/transaction/TransactionEditor";
import Util, { big, flattenItems } from "../../util/Util";
import useStore from "../../hooks/useStore";
import { useEffect } from "react";
import { InlineLoading } from "carbon-components-react";
import UIUtil from "../../util/UIUtil";
import CustomComboBox from "../../components/CustomComboBox";
import { getCashGroup } from '../../domain/account-ledgers'
import { AmountTagSelectorNormalField } from "../../pages/journal-entries/tag-selector";
import { hasCapabilitySupport } from "../../app/Capabilities";

{/* <TransactionPayButton direction={TRANSACTION_DIRECTION_TYPE_OUTWARD} itemId={bill.id} itemType={OBJECT_TYPE_BILL} partyType={bill.payeeType} partyId={bill.payeeId} /> */ }

const getTransactionInfo = ({ direction, itemId, itemType, partyItemType, partyId, amount }) => {
    const againstItemIdKey = (() => {
        switch (itemType) {
            case OBJECT_TYPE_BILL:
                return 'billId';
            case OBJECT_TYPE_STOCK_FLOW:
                return 'stockFlowId';
            case OBJECT_TYPE_INVOICE_DOCUMENT:
                return 'invoiceDocumentId'
            case OBJECT_TYPE_PURCHASE:
                return 'purchaseId'
            case OBJECT_TYPE_SERVICE_SALE:
                return 'serviceSaleId'
            case OBJECT_TYPE_SALE:
                return 'saleId'
            case OBJECT_TYPE_PAYOUT:
                return 'payoutId'
            case OBJECT_TYPE_COMMISSION:
                return 'commissionId'

            case OBJECT_TYPE_V2_SALE:
                return 'v2SaleId'
            case OBJECT_TYPE_V2_BILL:
                return 'v2BillId'
        }
        return null;
    })();
    const otherPartyType = (() => {
        switch (partyItemType) {
            case OBJECT_TYPE_SUPPLIER:
                return TRANSACTION_PARTY_TYPE_SUPPLIER;
            case OBJECT_TYPE_CUSTOMER:
                return TRANSACTION_PARTY_TYPE_CUSTOMER;
            case OBJECT_TYPE_ACCOUNT:
                return TRANSACTION_PARTY_TYPE_STAFF;
            case OBJECT_TYPE_VENDOR:
                return TRANSACTION_PARTY_TYPE_VENDOR;
            case OBJECT_TYPE_VENUE:
                return TRANSACTION_PARTY_TYPE_VENUE;
            case OBJECT_TYPE_STORE:
                return TRANSACTION_PARTY_TYPE_STORE;
            case OBJECT_TYPE_STUDENT:
                return TRANSACTION_PARTY_TYPE_STUDENT;
            case OBJECT_TYPE_TUTOR:
                return TRANSACTION_PARTY_TYPE_TUTOR
            case OBJECT_TYPE_MOVIE_DISTRIBUTOR:
                return TRANSACTION_PARTY_TYPE_MOVIE_DISTRIBUTOR;

            case OBJECT_TYPE_V2_CUSTOMER:
                return TRANSACTION_PARTY_TYPE_V2_CUSTOMER;
            case OBJECT_TYPE_V2_VENDOR:
                return TRANSACTION_PARTY_TYPE_V2_VENDOR;
            case OBJECT_TYPE_V2_EMPLOYEE:
                return TRANSACTION_PARTY_TYPE_V2_EMPLOYEE;
        }
        return null;
    })()

    if (againstItemIdKey === null || otherPartyType === null) {
        return null;
    }

    return {
        directionType: direction,
        otherPartyType: otherPartyType,
        otherPartyId: partyId,

        againstItems: [{
            [againstItemIdKey]: itemId,
            dividedAmount: amount
        }],
        // requestedCashAccountId: this.state.cashAccount.id,
    }
}

const PaymentMethods = ({ store, direction }) => {
    const [amount] = useStore(store, "amount")
    const [value, setValue] = useStore(store, "paymentMethods")
    return (
        <TransactionEditor onUpdate={setValue} currency={"AED"} incoming={direction == TRANSACTION_DIRECTION_TYPE_INWARD}
            // totalAmount={amount ?? 0}
            totalAmount={(!isNaN(amount) && amount != "") ? parseFloat(amount) : 0} />
    )
}

const CashAccountSelector = ({ store, cashAccounts }) => {
    const [cashAccount, setCashAccount] = useStore(store, 'cashAccount')
    return (<>
        {/* <div style={{ height: '1rem' }} /> */}
        <label className="bx--label">Cash Account</label>
        {/* opacity: 0.65, pointerEvents: 'none' */}
        <div style={{ height: 40, }}>
            <CustomComboBox
                white
                defExtended
                preventClear
                items={cashAccounts}
                selectedItem={cashAccount}
                onSelectedItemUpdate={setCashAccount}
            />
        </div>
    </>)
}

function TagSelector({ predefinedClasses, value, setValue }) {
    return <AmountTagSelectorNormalField options={predefinedClasses} value={value} setValue={setValue} />
}


export function TransactionPayButton({ direction, itemId, itemType, partyItemType, partyId, amount, onClickOverride, CustomButton, forceDisable, buttonProps = {} }) {
    const info = getTransactionInfo({ direction, itemId, itemType, partyItemType, partyId, amount });
    const [visible, setVisible] = useState(false);

    const [loading, setLoading] = useState(false);
    const [cashAccounts, setCashAccounts] = useState(undefined)
    const [defCashAccount, setDefCashAccount] = useState(undefined);
    const [predefinedClasses, setPredefinedClasses] = useState([]);

    useEffect(() => {
        setLoading(true)
        Api.getStockFlowEndpointsList(response => {
            if (response.status === true) {
                setDefCashAccount(flattenItems(response.payload.accountTree).find(item => !item.group && item.name == "Cash"))
                setCashAccounts(getCashGroup(response.payload.accountTree[0]).items)
                setPredefinedClasses(response.payload.predefinedClasses);
            } else {
                //UIUtil.showError("Could not load cash accounts")
                setCashAccounts([])
                setPredefinedClasses([])
            }
            setLoading(false)
        })
    }, [])


    const onSave = (data, listener) => {
        let totalPaid = 0;
        if (data.paymentMethods) {
            for (const paymentMethod of data.paymentMethods) {
                if (!isNaN(paymentMethod.amount) && paymentMethod.amount != '') {
                    totalPaid += parseFloat(paymentMethod.amount);
                }
            }
        }
        if (totalPaid != data.amount) {
            listener({ status: false, message: "Payment amount and payment method amount must be equal!" })
            return;
        }
        if (!Util.isStringExists(data.refNo)) {
            listener({ status: false, message: "Ref no is required!" })
            return;
        }
        if (!Util.isNumberExist(data.transactionDate)) {
            listener({ status: false, message: "Transaction date must be selected!" })
            return;
        }

        Api.createTransaction({
            ...info,
            ...data,

            againstItems: [{
                ...info.againstItems[0],
                dividedAmount: big(amount).gt(big(data.amount)) ? data.amount : amount
            }],

            tags: data?.tags.map(val => ({ ...val, name: val.label })),

            requestedCashAccountId: data?.cashAccount?.id
        }, listener)
    }

    if (info) {
        return (<>
            {CustomButton ? (
                <CustomButton {...buttonProps} disabled={(!loading && !cashAccounts) || forceDisable} loading={loading} onClick={() => {
                    if (onClickOverride) {
                        onClickOverride();
                    } else {
                        setVisible(true)
                    }
                }} />
            ) : (
                <Button {...buttonProps} disabled={(!loading && !cashAccounts) || forceDisable} loading={loading} onClick={() => {
                    if (onClickOverride) {
                        onClickOverride();
                    } else {
                        setVisible(true)
                    }
                }} renderIcon={Wallet16} kind="secondary">Record Payment</Button>
            )}

            {cashAccounts && <FormDialog successMsg="Transaction Created!" open={visible} onClose={() => setVisible(false)}
                label="Transaction Creation" title="Record Payment"
                item={{

                    amount, cashAccount: defCashAccount,
                    transactionDate: new Date().getTime(),
                    tags: [],

                }} setItem={() => window.location.reload()} saveApi={onSave}>
                {({ TextField, DateField, TextAreaField, CustomField, ExtField }) => (<>
                    <TextField title="Amount to pay (AED)" fieldKey="amount" />
                    {!hasCapabilitySupport("restaurant") && <>
                        <ExtField childrenProps={{ cashAccounts }}>
                            {CashAccountSelector}
                        </ExtField>
                        <CustomField fieldKey="tags" title="Class" childrenProps={{ predefinedClasses }}>
                            {TagSelector}
                        </CustomField>
                    </>}

                    <FormSection>
                        <ExtField fieldKey="paymentMethods" childrenProps={{ direction }}>
                            {PaymentMethods}
                        </ExtField>
                    </FormSection>

                    <FormSection title="Additional Information">
                        <TextField title="Ref no" fieldKey="refNo" />
                        {/* <DateField title="Date (optional)" fieldKey="transactionDate" /> */}
                        <DateField title="Date" fieldKey="transactionDate" />

                        <TextAreaField title="Note (optional)" fieldKey="info" />
                    </FormSection>
                </>)}
            </FormDialog>}
        </>)
    } else {
        return null;
    }
}