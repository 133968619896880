import { useState } from "react";
import Button from "../../../../components/Button";
import { Tag16, AirlinePassengerCare16 } from '@carbon/icons-react'
import { CartList } from "../views/modify-order/cart-list";
import PosDialogs from "../../PosDialogs";
import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import PosState from "../../state/PosState";
import Page from "../../../../base/Page";
import CouponWidget from "../../widgets/CouponWidget";
import { SidePanel } from "../../../../templates/draft/components/side-panel";
import Util from "../../../../util/Util";
import RestCustomerInfo from "../RestCustomerInfo";

class Pos extends Page {

    state = {

    }

    constructor(props) {
        super(props);

        this.posState = new PosState(() => this.state, this.setState.bind(this));
        this.posState.updateOrder = props.updateOrder;
    }

    onPageStart() {
        this.posState.loadSalesOrderSession(this.props.order.id);
    }

    componentWillUnmount() {
        this.posState.cancelSubscriptions();
    }

    getLayout() {
        return (
            <div className="pos" style={{ display: 'flex', height: '100%', width: '100%', padding: '0rem', background: '#f4f4f4', userSelect: 'none', WebkitUserSelect: 'none', }} >
                <div style={{ background: '#1c1c1c', color: 'white', width: '100%' }}>
                    <RestCustomerInfo state={this.posState} hidePosBtn forOrder />
                </div>

                <PosDialogs posState={this.posState} />
            </div>
        )
    }

    isPageLoading() {
        return this.posState.isLoadingPos() || !this.posState.getSession()
    }

}

export function CustomerBtn({ order, updateOrder }) {
    const [visible, setVisible] = useState(false)
    return (
        <>
            <Button style={{ maxWidth: 'unset' }} onClick={() => setVisible(true)} renderIcon={AirlinePassengerCare16} kind="secondary" size="md">
                <div>
                    <span>{Util.isStringExists(order.customerName) ? order.customerName : "Guest"}</span>
                    {order.customerId ? <p style={{ fontSize: 15, fontWeight: 'bold', color: '#50ff40' }}>Credit: AED {Util.formatMoney(order.currentCustomerCredit)}</p> : null}
                </div>
            </Button>

            {visible && (
                <ComposedModal key="order-discounts" size="sm" open={visible} onClose={() => setVisible(false)}>
                    <ModalHeader label="Order" title="Customer" />
                    <ModalBody style={{ padding: 0 }}>
                        <Pos order={order} updateOrder={updateOrder} />
                    </ModalBody>
                    <ModalFooter
                    //secondaryButtonText="Close"
                    >
                        <Button kind="secondary" onClick={() => setVisible(false)}>
                            Close
                        </Button>
                    </ModalFooter>
                </ComposedModal>

            )}
        </>
    )
}