import { useEffect, useMemo, useRef, useState } from "react";
import Portal from "../../../../util/Portal";
import "./styles.scss"
import { Close16, AirlinePassengerCare20, ArrowRight16, Information16, InformationSquareFilled16 } from '@carbon/icons-react'
import useInfiniteScroll from 'react-infinite-scroll-hook';
import { InlineLoading } from "carbon-components-react";
import Api from "../../../../session/Api";
import { useDebounce } from "../../../../hooks/useDebounce";
import Util from "../../../../util/Util";
import CustomerDetailPage from "../../../customer/CustomerDetailPage";
import { SidePanel } from "../../../../templates/draft/components/side-panel";


const useLoadItems = ({ key, searchValue }) => {
    const [page, setPage] = useState(0);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState([]);
    const [hasNextPage, setHasNextPage] = useState(true);
    const [error, setError] = useState(undefined);

    useEffect(() => {
        setPage(0);
        setLoading(false);
        setItems([]);
        setHasNextPage(true);
        setError(undefined)
    }, [searchValue, key])

    const loadMore = () => {
        setLoading(true);
        Api.searchCustomer(searchValue, page, response => {
            if (response.status === true) {
                setPage(page + 1);
                setLoading(false);
                setItems(items => [...items, ...response.payload.items])
                setHasNextPage(response.payload.hasMore);
                setError(undefined);
            } else {
                setLoading(false);
                setError(response.message);
            }
        })
    }

    return {
        loading, items, hasNextPage, loadMore, error
    }
}

const SearchBar = ({ visible, value, setValue }) => {
    const searchRef = useRef();
    useEffect(() => {
        if (visible && searchRef.current) {
            searchRef.current.focus();
        }
    }, [visible])
    return <input ref={searchRef} value={value} onChange={e => setValue(e.target.value)} placeholder="Name/number/email" style={{ width: '100%', height: 45, border: 'none', background: '#f4f4f4', padding: '1rem', outline: 'none' }} />
}

const Item = ({ item, onClick }) => {
    const [showCustomerInfo, setShowCustomerInfo] = useState(false);

    const values = useMemo(() => {
        let data = [];
        const addToData = (key) => {
            if (item?.[key]) {
                data.push(item[key]);
            }
        }

        // addToData('phoneNumber')
        addToData('readablePhoneNumber')
        addToData('altPhoneNumber')
        addToData('altPhoneNumberTwo')
        addToData('email')

        return data.join('  •  ');
    }, [item])
    return (<>
        <div onClick={onClick} className="item" style={{ minHeight: '4rem', paddingInline: '1rem', display: 'flex', alignItems: 'center', gap: '1rem' }}>
            <AirlinePassengerCare20 />
            <div style={{ flex: 1 }}>
                <p>{item.fullName}</p>
                <p style={{ fontSize: 14, color: '#0f62fe' }}>{values}</p>
            </div>
            <button onClick={e => {
                e.stopPropagation();
                e.preventDefault();
                setShowCustomerInfo(true)
            }} className='lead-flow-icon-button lead-flow-icon-button-primary'>
                <InformationSquareFilled16 />
            </button>
            <ArrowRight16 />
        </div>
        <div style={{ width: 'calc(100% - (1.5rem * 2))', marginLeft: '1.5rem', marginTop: -1, height: 1, background: '#00000020' }} />

        {showCustomerInfo && <Portal>
            <SidePanel fullscreen md2 onClose={() => setShowCustomerInfo(false)}>
                {/* <LockScroll /> */}
                <CustomerDetailPage pagePathParamsOverride={{ itemId: item.id }} overrideBacktToListBtn={() => setShowCustomerInfo(false)} />
            </SidePanel>
        </Portal>}
    </>)
}

export function CustomerSearchDialog({ visible, onClose, onCustomerSelected, focus }) {
    const [searchValue, setSearchValue] = useState("");
    const [key, setKey] = useState(() => Util.newTempId())
    const debouncedSearch = useDebounce(searchValue, 500)
    const { loading, items, hasNextPage, error, loadMore } = useLoadItems({
        key, searchValue: Util.isStringExists(searchValue) ? debouncedSearch : ""
    });

    useEffect(() => {
        if (visible) {
            setKey(Util.newTempId());
            setSearchValue("");
        }
    }, [visible])

    const [sentryRef, { rootRef }] = useInfiniteScroll({
        loading,
        hasNextPage,
        onLoadMore: loadMore,
        disabled: !!error,
        rootMargin: '0px 0px 400px 0px',
    });

    return (
        <Portal>
            <div className="customer-search-dialog">
                <div className={"backdrop " + (visible ? 'backdrop-visible' : "backdrop-hidden")} onClick={onClose} />

                <div className={"card " + (visible ? "card-visible" : "card-hidden")}>
                    <div style={{ display: 'flex', gap: '0.5rem', alignItems: 'center', padding: '1rem' }}>
                        <h4 style={{ fontWeight: 'bold', flex: 1 }}>Customers</h4>
                        <button onClick={onClose} className='lead-flow-icon-button lead-flow-icon-button-light-on-white'>
                            <Close16 />
                        </button>
                    </div>
                    <SearchBar visible={visible} value={searchValue} setValue={setSearchValue} />
                    <div ref={rootRef} style={{ flex: 1, overflow: 'auto', width: '100%' }}>
                        {items.map(item => (
                            <Item key={item.id} item={item} onClick={() => {
                                onClose();
                                onCustomerSelected(item);
                            }} />
                        ))}
                        <div ref={sentryRef} />

                        {(loading || hasNextPage) ? (
                            <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                <InlineLoading style={{ width: 'unset' }} />
                                <p style={{ fontSize: 12, opacity: 0.65 }}>Loading...</p>
                            </div>
                        ) : (
                            <div className="really_centered-progress-bar" style={{ height: '3rem', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '0.25rem' }}>
                                <p style={{ fontSize: 12, opacity: 0.65 }}>{items.length ? 'No more items' : 'No items'}</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Portal>
    )
}