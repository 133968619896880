import React, { useEffect, useRef, useState } from "react"
import { CardView, NoItem } from "./CardView"
import {
    TrashCan16, Add16, SplitScreen24, CaretDown16, Printer16, PaintBrush16, View16, TextSelection16, DocumentExport16,
    CloseFilled16, CheckmarkFilled16, Sun16, Moon16, Collaborate16
} from '@carbon/icons-react'
import Button from "../../components/Button";
import Util, { onEnterKeyPressed } from "../../util/Util";
import ReactTooltip from "react-tooltip";
import { ComposedModal, ModalBody, ModalFooter, ModalHeader } from "carbon-components-react";
import { RenameGroupDialog } from "./rename-group-dialog";
import UIUtil from "../../util/UIUtil";
import Portal from "../../util/Portal";
import { BoardViewList } from "./components/board-view-list";
import { BoardViewAssignation } from "./components/board-view-assignations";
import { isMobileScreen } from "../../App";

export const ColumnView = ({ group, isOver, children, scrollInto, onAddBtn, renameGroup }) => {
    const [showRename, setShowRename] = useState(false);

    const [fadeInAnim, setFadeInAnim] = useState(false);

    const ref = useRef();
    useEffect(() => {
        if (scrollInto && ref.current) {
            ref.current.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
            setFadeInAnim(true);
        }
    }, [scrollInto])
    return (
        <div ref={ref} className="column-view" style={{
            minWidth: 300, maxHeight: '100%', display: 'flex', flexDirection: 'column',
            borderRadius: 5, backdropFilter: 'blur(4px)', borderWidth: 1, borderStyle: 'solid',
            boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25)', overflow: 'hidden',
            animation: fadeInAnim ? 'board-view-column-fade-in-anim 250ms' : ''
        }}>
            <div className="column-view-header" style={{ width: '100%', padding: '0.5rem', paddingTop: '0.25rem', paddingBottom: '0.10rem', display: 'flex', gap: '0.5rem', alignItems: 'center' }}>
                <h5 style={{ flex: 1, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>{group}</h5>
                <div data-no-dnd="true" onClick={() => setShowRename(true)} style={{ background: 'transparent', cursor: 'pointer', height: '100%', width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <TextSelection16 />
                </div>
                <div data-no-dnd="true" onClick={onAddBtn} style={{ background: 'transparent', cursor: 'pointer', height: '100%', width: 20, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <Add16 />
                </div>
            </div>
            <div
                className={`${isOver ? 'column-highlighted' : ''}`}
                style={{
                    padding: '0.5rem', display: 'flex', flexDirection: 'column', gap: '0.75rem',
                    //overflowY: 'auto',
                    overflow: 'hidden',
                    maxHeight: '100%', minHeight: 0, transition: 'background-color 250ms',
                }}>

                {React.Children.count(children) > 0 ? (
                    children
                ) : (
                    <NoItem />
                )}
            </div>

            <Portal>
                <RenameGroupDialog open={showRename} onClose={() => setShowRename(false)} currentTitle={group} onRename={title => {
                    const err = renameGroup(group, title);
                    if (err) {
                        UIUtil.showInfo(err);
                        return;
                    }

                    setShowRename(false)
                }} />
            </Portal>
        </div>
    )
}

const ToolbarIcon = ({ tooltip, icon, onClick }) => (
    <button data-tip={tooltip} data-effect="solid" data-place="bottom" onClick={onClick} className="board-view-toolbar-btn">
        {React.createElement(icon)}
    </button>
)

const RenameBtn = ({ title, onRenameBtn }) => {
    const [visible, setVisible] = useState(false);
    const [value, setValue] = useState('')
    const inputRef = useRef();

    useEffect(() => {
        if (visible && inputRef.current) {
            setValue(title)
            inputRef.current.focus()
        } else {
            ReactTooltip.rebuild()
        }
    }, [visible])
    useEffect(() => {
        setVisible(visible => visible ? false : visible)
    }, [title])

    const canSave = value !== title && Util.isStringExists(value);

    return visible ? (
        <div className="board-view-toolbar-component" style={{ overflow: 'hidden', animation: 'board-view-title-textbox-fade-in-anim 250ms' }}>
            <div style={{ minWidth: 35, width: 35, display: 'flex', justifyContent: 'center', alignItems: 'center', marginRight: '-0.5rem' }}>
                <TextSelection16 />
            </div>
            <input ref={inputRef} placeholder="Input Title" value={value} onChange={e => setValue(e.target.value)}
                {...onEnterKeyPressed(() => {
                    if (canSave) {
                        onRenameBtn(value)
                    }
                })} />
            <div style={{ display: 'flex', alignItems: 'center', gap: '0.5rem', paddingRight: '0.5rem' }}>
                <div onClick={() => setVisible(false)} style={{ display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: 'pointer', color: '#990000', background: 'white', borderRadius: '50%', animation: 'board-view-column-fade-in-anim 250ms' }} >
                    <CloseFilled16 />
                </div>
                <div onClick={() => onRenameBtn(value)} style={{ opacity: !canSave ? 0.4 : 1, display: 'flex', gap: '0.15rem', alignItems: 'center', cursor: !canSave ? undefined : 'pointer', color: 'green', background: 'white', borderRadius: '50%', animation: 'board-view-column-fade-in-anim 250ms' }}>
                    <CheckmarkFilled16 />
                </div>
            </div>
        </div>
    ) : (
        <ToolbarIcon tooltip="Rename Board" onClick={() => setVisible(true)} icon={TextSelection16} />
    )
}

export const Toolbar = ({ boardViews, selectedId, selectId, title, dark, setDark, onRenameBtn, onThemeBtn, onVisibilityBtn, onPrintBtn, allUsers, assignations, setAssignations, canAssign }) => {
    if (isMobileScreen) {
        return (<>
            <div
                className="board-view-toolbar"
                style={{
                    width: '100%', paddingBlock: '0.5rem', backdropFilter: 'blur(4px)', borderBottomWidth: 1,
                    borderStyle: 'solid', boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25)',
                    paddingInline: '1rem',
                }}
            >
                <div style={{ display: 'flex', alignItems: 'center', }}>

                    <SplitScreen24 style={{ marginRight: '0.25rem' }} />


                    <div style={{ flex: 1 }} />
                    <BoardViewList title={title} {...({ boardViews, selectedId, selectId })} />
                </div>

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', marginTop: '0.5rem' }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <RenameBtn title={title} onRenameBtn={onRenameBtn} />
                        {canAssign && <BoardViewAssignation {...({ allUsers, assignations, setAssignations })}>
                            <ToolbarIcon tooltip="Assign Users" icon={Collaborate16} />
                        </BoardViewAssignation>}
                        <ToolbarIcon tooltip={"Toggle Mode"} icon={dark ? Moon16 : Sun16} onClick={() => setDark(d => !d)} />
                        {/* <ToolbarIcon tooltip="Set Theme" onClick={onThemeBtn} icon={PaintBrush16} /> */}
                        {/* <ToolbarIcon tooltip="Set Visibility" onClick={onVisibilityBtn} icon={View16} /> */}
                        {/* <ToolbarIcon tooltip="Take Snapshot" onClick={onPrintBtn} icon={DocumentExport16} /> */}
                    </div>
                </div>
            </div>
        </>)
    }

    return (
        <div
            className="board-view-toolbar"
            style={{
                width: '100%', height: '3rem', backdropFilter: 'blur(4px)', borderBottomWidth: 1,
                borderStyle: 'solid', boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25)',
                display: 'flex', alignItems: 'center', paddingInline: '1rem',
            }}
        >
            <SplitScreen24 style={{ marginRight: '0.25rem' }} />
            <h4 style={{ marginRight: '3rem' }}>To-Do Board</h4>
            {/* <Button style={{ borderRadius: 10, width: 250, height: 'calc(100% - 1rem)', pointerEvents: 'none' }} size="sm">{title}</Button> */}
            {/* <Button renderIcon={CaretDown16} style={{ borderRadius: 10, width: 250, height: 'calc(100% - 1rem)' }} size="sm">{title}</Button> */}
            <BoardViewList title={title} {...({ boardViews, selectedId, selectId })} />
            <div style={{ flex: 1 }} />

            <div style={{ display: 'flex', gap: '0.5rem' }}>
                <RenameBtn title={title} onRenameBtn={onRenameBtn} />
                {canAssign && <BoardViewAssignation {...({ allUsers, assignations, setAssignations })}>
                    <ToolbarIcon tooltip="Assign Users" icon={Collaborate16} />
                </BoardViewAssignation>}
                <ToolbarIcon tooltip={"Toggle Mode"} icon={dark ? Moon16 : Sun16} onClick={() => setDark(d => !d)} />
                {/* <ToolbarIcon tooltip="Set Theme" onClick={onThemeBtn} icon={PaintBrush16} /> */}
                {/* <ToolbarIcon tooltip="Set Visibility" onClick={onVisibilityBtn} icon={View16} /> */}
                <ToolbarIcon tooltip="Take Snapshot" onClick={onPrintBtn} icon={DocumentExport16} />
            </div>
        </div>
    )
}

export const ContentView = React.forwardRef(({ dark, children }, ref) => (
    <div //className={`board-view ${!dark ? 'board-view-dark' : ''}`} 
        ref={ref}
        style={{
            width: '100%', flex: 1, display: 'flex', alignItems: 'flex-start', padding: '1rem', gap: '1rem', overflowX: 'auto'
        }}>
        {children}
        <div style={{ minWidth: '1rem', width: '1rem', height: 45 }} />
    </div>
))
// export const ContentView = ({ dark, children }) => (

// )

export const RootView = ({ dark, children }) => (
    <div className={`${dark ? 'light' : 'dark'}-minimal-scroll-bar ` + `board-view ${!dark ? 'board-view-dark' : ''}`} style={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
        {children}
    </div>
)

export const DeleteBtn = ({ highlight }) => (
    <div className="board-view-delete" style={{ background: highlight ? '#990000' : undefined, height: highlight ? '6rem' : undefined }}>
        <TrashCan16 />
        <p style={{ fontWeight: 'bold', fontSize: 12, }}>Drop here to archive</p>
    </div>
)

export const AddBtn = ({ onClick }) => (
    <button onClick={onClick} className="board-view-add-btn" style={{
        borderRadius: '50%', minWidth: 45, width: 45, height: 45, backdropFilter: 'blur(4px)', borderWidth: 1,
        borderStyle: 'solid', boxShadow: '0px 25px 50px -12px rgba(0,0,0,0.25)',
        display: 'flex', justifyContent: 'center', alignItems: 'center', marginLeft: '1rem', marginTop: '1rem',
    }}>
        <Add16 />
    </button>
)

export const ItemDialog = ({ label, title, open, onClose, item, children }) => (
    <ComposedModal className="no-outline-modal" size="md" open={open} onClose={onClose}>
        <ModalHeader label={label} title={title} />
        <ModalBody style={{ paddingRight: '1rem' }} hasScrollingContent>
            <div style={{ overflow: 'auto', maxHeight: 150, marginBottom: '1rem', }}>
                <p style={{ whiteSpace: 'pre-line' }}>{item?.content}</p>
            </div>
            {children}
        </ModalBody>
        <ModalFooter style={{ justifyContent: 'flex-end' }}>
            <Button kind="secondary" onClick={onClose}>
                Close
            </Button>
        </ModalFooter>
    </ComposedModal>
)