import { Purchase24, WirelessCheckout16, Tag16 } from '@carbon/icons-react'
import { Checkbox, TextInput } from 'carbon-components-react'
import React, { useRef, useEffect } from 'react'
import Button from '../../../components/Button'
import useSize from '../../../util/useSize'
import ReactTooltip from 'react-tooltip';
import Util from '../../../util/Util'
import { Link } from 'react-router-dom'

export default ({ state, hideTitle, responsive, monitorMode, mobileClientMode }) => {
    const targetRef = useRef();
    const size = useSize(targetRef);

    const horizontal = responsive ? (size.height < 200 && size.height < size.width) : true;
    // const horizontal = true;

    const discounted = state.getRawDiscount() > 0;



    //const spacing = discounted ? '0.25rem' : '1rem';
    const spacing = '0.25rem';

    const mini = size.width < 780;


    useEffect(() => {
        ReactTooltip.rebuild()
    }, [size])

    if (mobileClientMode) {
        return (
            <div style={{ background: '#141414', paddingTop: '1rem', paddingBottom: '1rem', paddingLeft: '0.75rem', paddingRight: '0.75rem', overflow: 'hidden', color: 'white', }}>
                <div ref={targetRef} style={{
                    width: '100%', height: '100%',
                    display: 'flex', alignItems: horizontal ? 'center' : 'flex-start', flexDirection: horizontal ? 'row' : 'column',
                }}>
                    <div className="payment-text-input" style={{ flex: 1 }}>
                        <TextInput size="sm" labelText="Subtotal (+)" value={state.getSubtotal()} style={{ background: 'black', color: 'white', width: '100%' }} />
                    </div>
                    <div style={{ width: spacing, height: '1rem' }} />

                    {discounted && <>
                        <div className="payment-text-input" style={{ flex: 1 }}>
                            <TextInput size="sm" labelText="Deductions (-)" value={state.getDiscount()} style={{ background: 'black', color: 'white', width: '100%' }} />
                        </div>
                        <div style={{ width: spacing, height: '1rem' }} />
                    </>}

                    <div className="payment-text-input" style={{ flex: 1 }}>
                        <TextInput size="sm" labelText="Tax (+)" value={state.getTax()} style={{ background: 'black', color: 'white', width: '100%' }} />
                    </div>
                    <div style={{ width: spacing, minHeight: '1rem' }} />
                </div>

                {monitorMode && Util.isNumberExist(state.getTotalSavings()) && <div style={{ marginTop: '0.5rem' }}>
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Tag16 style={{ fill: 'red' }} />
                        <p style={{ fontSize: 14, color: 'red', marginLeft: '0.15rem' }}>You have saved AED {state.getTotalSavings()}!</p>
                    </div>
                    {/* <h5></h5> */}
                </div>}

                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', gap: 5, marginTop: '1rem' }}>
                    {/* <TextInput size="lg" value={state.getTotal()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} /> */}
                    {/* <p style={{opacity: 0.65, fontSize: 14, fontStyle: 'italic'}}>Total: </p> */}
                    <p style={{ opacity: 1, fontSize: 14, flex: 1, fontWeight: 'bold' }}>Total: </p>
                    <h1 style={{ color: 'white', fontWeight: 'bold', width: !horizontal ? '100%' : undefined, lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h1>
                </div>
            </div>
        )
    }

    // {monitorMode && Util.isNumberExist(state.getTotalSavings()) && <div style={{marginRight: '1rem'}}>
    //                 <div style={{display: 'flex', alignItems: 'center'}}>
    //                     <Tag16 style={{fill: 'red'}} />
    //                     <p style={{fontSize: 14, color: 'red', marginLeft: '0.15rem'}}>Total Savings! </p>
    //                 </div>
    //                 <h5>AED {state.getTotalSavings()}</h5>
    //             </div>}

    if (monitorMode) {
        return (
            <div>
                {monitorMode && Util.isNumberExist(state.getTotalSavings()) &&
                    <div style={{ padding: '0.5rem', backgroundColor: 'white' }}>
                        <div style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center', paddingRight: '1rem' }}>
                            <Tag16 style={{ fill: 'red' }} />
                            <p style={{ fontSize: 14, color: 'red', marginLeft: '0.15rem' }}>Congratulations! You have saved <span style={{ fontSize: 16, fontWeight: 'bold' }}>AED {state.getTotalSavings()}!</span></p>
                        </div>
                    </div>}

                <div className="payment-widget" ref={targetRef} style={{
                    width: '100%', height: '100%', padding: '1rem', overflow: 'hidden', color: 'white',
                    display: 'flex', alignItems: horizontal ? 'center' : 'flex-start', flexDirection: horizontal ? 'row' : 'column',
                }}>
                    {!hideTitle && !mini && <> <div style={{ display: 'flex', alignItems: 'center' }}>
                        <Purchase24 style={{ marginRight: 5 }} />
                        <h4>Payment</h4>
                    </div>

                        <div style={{ width: spacing, marginRight: '1rem', height: '1rem' }} />
                    </>}

                    {/* <div className="payment-text-input">
                        <TextInput size="sm" labelText="Subtotal (+) AED" value={state.getSubtotal()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
                    </div>
                    <div style={{width: spacing, height: '1rem'}} />

                    {discounted && <>
                        <div className="payment-text-input">
                            <TextInput size="sm" labelText="Discount (-) AED" value={state.getDiscount()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
                        </div>
                        <div style={{width: spacing, height: '1rem'}} />
                    </>}

                    <div className="payment-text-input">
                        <TextInput size="sm" labelText="Tax (+) AED" value={state.getTax()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
                    </div> */}


                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 14 }}>Subtotal (+)</p>
                        <h5>{state.getSubtotal()}</h5>
                    </div>

                    {discounted && <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 14 }}>Deductions (-)</p>
                        <h5>{state.getDiscount()}</h5>
                    </div>}

                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 14 }}>Tax (+)</p>
                        <h5>{state.getTax()}</h5>
                    </div>


                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 14 }}>Items</p>
                        <h5>{state.getTotalItems()}</h5>
                    </div>
                    <div style={{ marginRight: '1rem' }}>
                        <p style={{ fontSize: 14 }}>Qty</p>
                        <h5>{state.getTotalQty()}</h5>
                    </div>


                    {/* <div style={{flex: 1, minWidth: spacing, minHeight: '1rem'}} /> */}
                    {/* <div style={{minWidth: spacing, minHeight: '1rem'}} /> */}

                    <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                        {/* <TextInput size="lg" value={state.getTotal()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} /> */}

                        <h1 style={{ color: 'white', fontWeight: 'bold', width: !horizontal ? '100%' : undefined, lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h1>
                    </div>
                    <div style={{ width: '0.25rem', height: '1rem' }} />

                    {!state.readOnly && <>
                        <div style={{ width: spacing, height: '1rem' }} />
                        <Button
                            // hasIconOnly={discounted}
                            hasIconOnly
                            // data-tip={discounted ? "Checkout" : undefined}
                            data-tip="Checkout"
                            disabled={state.getAllCartItems().length == 0} loading={state.isCheckoutLoading()} onClick={() => state.setShowCheckoutDialog(true)} renderIcon={WirelessCheckout16}>
                            {/* {!discounted && 'Checkout'} */}
                        </Button>
                    </>}
                </div>
            </div>
        )
    }

    return (
        <div className="payment-widget" ref={targetRef} style={{
            width: '100%', height: '100%', padding: '1rem', overflow: 'hidden', color: 'white',
            display: 'flex', alignItems: horizontal ? 'center' : 'flex-start', flexDirection: horizontal ? 'row' : 'column',
        }}>
            {!hideTitle && !mini && <> <div style={{ display: 'flex', alignItems: 'center' }}>
                <Purchase24 style={{ marginRight: 5 }} />
                <h4>Payment</h4>
            </div>

                <div style={{ width: spacing, marginRight: '1rem', height: '1rem' }} />
            </>}

            {/* <div className="payment-text-input">
                <TextInput size="sm" labelText="Subtotal (+) AED" value={state.getSubtotal()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
            </div>
            <div style={{width: spacing, height: '1rem'}} />

            {discounted && <>
                <div className="payment-text-input">
                    <TextInput size="sm" labelText="Discount (-) AED" value={state.getDiscount()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
                </div>
                <div style={{width: spacing, height: '1rem'}} />
            </>}

            <div className="payment-text-input">
                <TextInput size="sm" labelText="Tax (+) AED" value={state.getTax()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} />
            </div> */}


            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Subtotal (+)</p>
                <h5>{state.getSubtotal()}</h5>
            </div>

            {discounted && <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Deductions (-)</p>
                <h5>{state.getDiscount()}</h5>
            </div>}

            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Tax (+)</p>
                <h5>{state.getTax()}</h5>
            </div>

            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Items</p>
                <h5>{state.getTotalItems()}</h5>
            </div>
            <div style={{ marginRight: '1rem' }}>
                <p style={{ fontSize: 14 }}>Qty</p>
                <h5>{state.getTotalQty()}</h5>
            </div>



            {/* <div style={{flex: 1, minWidth: spacing, minHeight: '1rem'}} /> */}
            {/* <div style={{minWidth: spacing, minHeight: '1rem'}} /> */}

            <div style={{ display: 'flex', alignItems: 'center', gap: 15, flex: 1, justifyContent: 'flex-end' }}>
                {/* <TextInput size="lg" value={state.getTotal()} style={{background: 'black', color: 'white', width: !horizontal ? '100%' : undefined}} /> */}

                <h1 style={{ color: 'white', fontWeight: 'bold', width: !horizontal ? '100%' : undefined, lineHeight: 1, textAlign: 'end' }}>{state.getTotal()}</h1>
            </div>
            <div style={{ width: '0.25rem', height: '1rem' }} />

            {!state.readOnly && <>
                <div style={{ width: spacing, height: '1rem' }} />
                <Button
                    // hasIconOnly={discounted}
                    hasIconOnly
                    // data-tip={discounted ? "Checkout" : undefined}
                    data-tip="Checkout"
                    disabled={state.getAllCartItems().length == 0} loading={state.isCheckoutLoading()} onClick={() => state.setShowCheckoutDialog(true)} renderIcon={WirelessCheckout16}>
                    {/* {!discounted && 'Checkout'} */}
                </Button>
            </>}
        </div>
    )
}