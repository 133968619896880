import Api from "../../session/Api";
import { getThermalPrinterDriverId, getThermalPrinterParams } from "../../session/SessionManager";
import UIUtil from "../../util/UIUtil";
import Util from "../../util/Util";
import ThermalPrinterDriver from "./ThermalPrinterDriver";
import { THERMAL_PRINTER_DRIVERS } from "./ThermalPrinterDriverList";

export const THERMAL_PRINTER_STATUS_DISCONNECTED = 0;
export const THERMAL_PRINTER_STATUS_CONNECTED = 1;
export const THERMAL_PRINTER_STATUS_LOADING = 2;

class ThermalPrinter {

    /**
     * @type {ThermalPrinterDriver}
     */
    driver;
    params;

    status;
    statusListeners = [];

    /**
     * @type {Array<{id: string, posSessionSnapshot: any, date: number, loading: boolean}>}
     */
    failedTasks = [];
    failedTasksListener = [];

    loadConfig() {
        let driverId = getThermalPrinterDriverId();
        if (driverId == undefined || driverId == null) {
            driverId = 0;
        }
        const driverParams = getThermalPrinterParams();

        let driver;
        for (const item of THERMAL_PRINTER_DRIVERS) {
            if (item.id == driverId) {
                driver = new item.driver();
            }
        }
        if (driver === undefined) {
            driver = new THERMAL_PRINTER_DRIVERS[0].driver();
        }

        this.setConfig(driver, driverParams);
    }

    setConfig(driver, params) {
        this.driver = driver;
        this.params = params;

        if (this.isConnected()) {
            this.refresh();
        } else {
            this.connect()
        }
    }

    async refresh() {
        this.onLoading();
        await this.disconnect();
        await this.connect();
    }

    onLoading() {
        if (this.status == THERMAL_PRINTER_STATUS_LOADING) {
            return;
        }

        this.status = THERMAL_PRINTER_STATUS_LOADING;
        this.statusListeners.forEach(listener => listener(this.status));
    }

    onConnected() {
        if (this.status == THERMAL_PRINTER_STATUS_CONNECTED) {
            return;
        }

        this.status = THERMAL_PRINTER_STATUS_CONNECTED;
        this.statusListeners.forEach(listener => listener(this.status));
    }

    onDisconnected() {
        if (this.status == THERMAL_PRINTER_STATUS_DISCONNECTED) {
            return;
        }

        this.status = THERMAL_PRINTER_STATUS_DISCONNECTED;
        this.statusListeners.forEach(listener => listener(this.status));
    }

    destroy() {
        this.statusListeners.splice(0, this.statusListeners.length);
    }

    registerListener(listener) {
        this.statusListeners.push(listener);
        listener(this.status);
    }

    unregisterListener(listener) {
        this.statusListeners.splice(this.statusListeners.indexOf(listener), 1);
    }

    registerFailedTasksListener(listener) {
        this.failedTasksListener.push(listener);
        listener(this.failedTasks);
    }

    unregisterFailedTasksListener(listener) {
        this.failedTasksListener.splice(this.failedTasksListener.indexOf(listener), 1);
    }

    isConnected() {
        return this.status == THERMAL_PRINTER_STATUS_CONNECTED;
    }

    isLoading() {
        return this.status == THERMAL_PRINTER_STATUS_LOADING;
    }

    async connect() {
        try {
            this.onLoading();
            await this.driver.connect(this.params);
            this.onConnected();
        } catch (e) {
            this.onDisconnected();
            throw e;
        }
    }

    async disconnect() {
        try {
            this.onLoading();
            await this.driver.disconnect();
            this.onDisconnected();
        } catch (e) {
            throw e;
        }
    }

    cancelTask(id) {
        for (let i = 0; i < this.failedTasks.length; i++) {
            const failedTask = this.failedTasks[i];
            if (failedTask.id == id) {
                this.failedTasks.splice(i, 1);
                this.failedTasksListener.forEach(listener => listener(this.failedTasks));
                break;
            }
        }
    }

    retryTask(id) {
        for (const failedTask of this.failedTasks) {
            if (failedTask.id == id) {
                failedTask.loading = true;
                this.printReceipt(failedTask.posSessionSnapshot, id);
                this.failedTasksListener.forEach(listener => listener(this.failedTasks));
                return;
            }
        }

        UIUtil.showError("Task not found");
    }

    stopTaskLoading(id) {
        for (const failedTask of this.failedTasks) {
            if (failedTask.id == id) {
                failedTask.loading = false;
                this.failedTasksListener.forEach(listener => listener(this.failedTasks));
                break;
            }
        }
    }

    printReceipt(posSessionSnapshot, id) {
        this.driver.printReceipt(posSessionSnapshot)
            .then(() => {
                if (id !== undefined) {
                    this.cancelTask(id);
                }
            })
            .catch((e) => {
                console.error(e)
                if (id === undefined) {
                    this.failedTasks.push({
                        id: Util.newTempId(),
                        date: new Date().getTime(),
                        posSessionSnapshot,
                    })
                    this.failedTasksListener.forEach(listener => listener(this.failedTasks));
                } else {
                    this.stopTaskLoading(id);
                    UIUtil.showError("Task retry failed");
                }
            })
    }

    printSalesSummary(posSession, onDone, { skipSuccess }) {
        if (this.driver.canPrintSalesSummary()) {
            Api.getPosSalesSummaryReport(posSession.posSessionId, response => {
                if (response.status === true) {
                    // console.log("Cash in drawerL " + response.payload.calculatedCashInDrawer)

                    this.driver.printSalesSummary(response.payload)
                        .then(() => {
                            if (!skipSuccess) {
                                UIUtil.showSuccess()
                            }
                        })
                        .catch(() => UIUtil.showError("Failed to print sales summary report"))
                        .finally(onDone)
                } else {
                    UIUtil.showError(Util.isStringExists(response.message) ? response.message : "Failed to print sales summary report")
                    onDone();
                }
            })
        } else {
            UIUtil.showInfo("No supporting printer connected")
            onDone()
        }
    }

}

export default ThermalPrinter;