import { withLoadablePageWithParams } from "../../../base/Page"
import Api from "../../../session/Api"
import Logo from "../../../images/doc-logo.png"
import { SHIPMENT_DIR_TYPE } from "../shipment"
import { ArrowRight16, DeliveryParcel16 } from '@carbon/icons-react'
import { useHistory } from "react-router-dom"
import { Tag } from "carbon-components-react"
import { useMemo } from "react"
import { stringifyBlueprintInputFields } from "../../activities/components/blueprint-input-fields-read"

const DetailValueEntry = ({ label, value, skipTextAlign }) => (<>
    <div style={{ minHeight: 45, paddingBlock: skipTextAlign ? 10 : undefined, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
        <p style={{ fontSize: 14, opacity: 0.65, flex: 1, outline: 'none' }}>{label}</p>
        <p style={{ fontSize: 14, flex: 2, outline: 'none', textAlign: !skipTextAlign ? 'end' : undefined, wordBreak: 'break-all', }}>{value}</p>
    </div>
</>)
function Detail({ aPackage, shipment }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Info</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    <DetailValueEntry label="Type" value={aPackage.packageTypeName} />
                    <DetailValueEntry label="Seal No" value={aPackage.sealNo} />
                    {aPackage.formFieldValues && aPackage.formFieldValues.length > 0 &&
                        <DetailValueEntry skipTextAlign label="Description" value={stringifyBlueprintInputFields(aPackage.formFieldValues)} />}
                    <ButtonValueEntry label="Shipment" href={"/shipment-job-qr/" + shipment.id} />
                </div>
            </div>
        </div>
    )
}

const ButtonValueEntry = ({ label, href }) => {
    const history = useHistory();
    return (<>
        <div onClick={() => history.push(href)} style={{ height: 45, width: '100%', display: 'flex', alignItems: 'center', borderBottom: '1px solid #00000010', gap: '0.5rem', paddingInline: '1rem' }}>
            <p style={{ fontSize: 14, opacity: 1, flex: 1, outline: 'none' }}>{label}</p>
            <ArrowRight16 style={{ opacity: 0.65 }} />
        </div>
    </>)
}
function Items({ aPackage }) {
    return (
        <div style={{ width: '100%' }}>
            <h4 style={{ fontWeight: 'bold', paddingInline: '1rem', paddingBottom: '0.5rem', }}>Items</h4>
            <div style={{ width: '100%', marginBottom: '3rem', paddingInline: '0rem' }}>
                <div style={{ width: '100%', background: '#f4f4f4', color: 'black', overflow: 'hidden', borderRadius: 10, boxShadow: '0px 10px 15px -3px rgba(0,0,0,0.1) , 0px 4px 6px -2px rgba(0,0,0,0.05) ' }}>
                    {aPackage.items.map(item => <ButtonValueEntry label={"Item " + item.id} href={"/shipment-package-item-qr/" + item.id} />)}
                </div>
            </div>

        </div>
    )
}
function View({ payload: { item: shipment, endpoint } }) {
    const packageId = endpoint.fromPackageId;
    const aPackage = useMemo(() => shipment.packages.find(i => i.id === packageId), [shipment, packageId])
    return (
        <div style={{ padding: '1rem' }}>
            <img src={Logo} style={{ width: '100%', height: 150, marginBlock: '1rem', objectFit: 'contain', objectPosition: 'center', }} />
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Tag renderIcon={DeliveryParcel16} type="blue">Shipment {shipment.shipmentNo}</Tag>
            </div>
            <h4 style={{ fontSize: 32, fontWeight: 'bold', textAlign: 'center', width: '100%' }}>Package</h4>
            <p style={{ fontSize: 16, opacity: 0.65, textAlign: 'center', width: '100%' }}>{packageId}</p>

            <div style={{ height: '1rem' }} />

            {aPackage && <Detail aPackage={aPackage} shipment={shipment} />}
            {aPackage && <Items aPackage={aPackage} />}

            <div style={{ height: 300 }} />
        </div>
    )
}

export const ShipmentPackageQr = withLoadablePageWithParams(params => listener => Api.getPublicShipmentFromPackage(params.packageId, listener), View)

