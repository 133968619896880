import { Checkbox } from "carbon-components-react";
import { ACCOUNT_TYPE_ADMINISTRATION, ACCOUNT_TYPE_PRODUCT_MANAGER, ACCOUNT_TYPE_SALES, ACCOUNT_TYPE_SUPERVISOR } from "../../../constants/Constants";
import { getAccountRole } from "../../../session/SessionManager";
import { CheckboxFilter } from "../../components/basic-filter/check-box-filter";
import { DateRange } from "../../components/basic-filter/date-range"
import { Divider } from "../../components/basic-filter/divider"
import { TextBox } from "../../components/basic-filter/text-box"
import { LocationFilter } from "./location-filter";
import { ProductList } from "../product-list";
import { MultiSelect } from "../../components/basic-filter/multi-select";

export const StockReport = {
    customPath: "/stock-report",
    filter: Filter,

    itemToOpenLink: item => `/products/${item.id}`,

    isAvailable: () => {
        const role = getAccountRole();
        return role == ACCOUNT_TYPE_ADMINISTRATION || role == ACCOUNT_TYPE_SUPERVISOR || role == ACCOUNT_TYPE_PRODUCT_MANAGER || role == ACCOUNT_TYPE_SALES;
    }
}

const HIDE_ZERO_STOCK_FILTER = { property: "stock", operator: "GT", value: 0 }

function Filter({ endpoint, state }) {
    return (<>
        <div style={{ height: 30, width: 300 }}>
            <LocationFilter state={state} accountTree={endpoint.locations} />
        </div>
        <Divider />

        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Ref No" property="ref" fullWidth />
        </div>
        <div style={{ flex: 1 }}>
            <TextBox state={state} placeholder="Description" property="name" fullWidth />
        </div>
        <div style={{ flex: 2, paddingLeft: '1rem' }}>
            <CheckboxFilter state={state} filterId="stockReportHideZeroStock" label="Hide products with 0 stock" filter={HIDE_ZERO_STOCK_FILTER} />
        </div>

        <div style={{ height: 30, marginTop: '0rem', flexBasis: '100%', display: 'flex', alignItems: 'center', gap: '0.25rem' }}>



            {/* <Divider /> */}

            <div style={{ flex: 2 }}>
                <MultiSelect isMulti={false} placeholder="Supplier" fieldKey="productListSupplierField" options={endpoint.suppliers.map(item => item.value)} state={state} property="supplier" />
            </div>
            <div style={{ flex: 2 }}>
                <MultiSelect isMulti={false} placeholder="Brand" fieldKey="productListBrandField" options={endpoint.brands.map(item => item.value)} state={state} property="brand" />
            </div>
            <div style={{ flex: 2 }}>
                <MultiSelect isMulti={false} placeholder="Department" fieldKey="productListDepartmentField" options={endpoint.departments.map(item => item.value)} state={state} property="department" />
            </div>
            <div style={{ flex: 2 }}>
                <MultiSelect isMulti={false} placeholder="Category" fieldKey="productListCategoryField" options={endpoint.categories.map(item => item.value)} state={state} property="category" />
            </div>
            <div style={{ flex: 2 }}>
                <MultiSelect isMulti={false} placeholder="Subcategory" fieldKey="productListSubcategoryField" options={endpoint.subcategories.map(item => item.value)} state={state} property="subcategory" />
            </div>
        </div>

    </>)
}
